import * as React from 'react';
import './ticketComponent.css'
import Util from '../../../../utils';
import { MineBody, ServiceEnroll } from '../../../../service/enroll';
import { message, Spin, Steps, Modal, List, Skeleton } from 'antd';
import { deduplication, subStr, subStrEllipsis } from '../../../../utils/tool';
const { Step } = Steps;
const wait = require('../../../../assets/icon/wait.png');
const process = require('../../../../assets/icon/process.png');
const finish = require('../../../../assets/icon/finish.png');

/**
 * 数字藏品
 * @returns 
 */
interface EnrollDetailProp {
  id: number | string
  toOption: (item: any) => void
  toSumitInfo: (item: any) => void
  cancel: () => void
}
function EnrollDetail(prop: EnrollDetailProp) {
  let { id, toOption, toSumitInfo, cancel } = prop
  const Icons = {
    wait: wait,
    process: process,
    finish: finish,
  };
  const [info, setInfo] = React.useState<MineBody>({} as MineBody)
  const [list, setList] = React.useState<any>([])
  const [steps, setSteps] = React.useState<any>([])
  const [show, setShow] = React.useState<boolean>(false)
  const [loadding, setLoadding] = React.useState<boolean>(true)
  const allIcon = JSON.parse(localStorage.getItem('icons') || '{}')

  React.useEffect(() => {
    ServiceEnroll.queryMine(id)
      .then(res => {
        setInfo(res);
        let stepsArr: any[] = [];
        let list: any[] = []
        !(!res.itemApplyToSign || res.items.length === 0) && list.push({ name: '报名选项', extra: res.itemName, key: 'option' })
        res.hasSubmitContent && list.push({ name: '报名信息', extra: '', key: 'info' })
        res.main && stepsArr.push({ ...res.main });
        res.audit && stepsArr.push({ ...res.audit });
        res.signIn && stepsArr.push({ ...res.signIn });
        res.reimburse && stepsArr.push({ ...res.reimburse });
        list.push({ name: '', extra: '', key: '' })
        setSteps(stepsArr)
        setList(list)
      })
      .catch(err => {
        message.error(err.message);
      }).finally(() => {
        setLoadding(false)
      })
  }, [id]);

  function getValue(key) {
    return info ? info[key] : '';
  }

  function action(type) {
    switch (type) {
      case '取消':
        setShow(true)
        break;
      case '签到':
        // showQRCode();
        break;
      case '申请报销':
        // Util.jumpPage(ROUTER_NAME.SUBMIT_REIMBURSE_INFO.path, {
        //   id: info.enrollmentId,
        // });
        break;
      case '查看详情':
        // Util.jumpPage(ROUTER_NAME.REIMBURSE_PROGRESS.path, {
        //   id: info.enrollmentId,
        //   enrollId: info.id,
        // });
        break;
      default:
        break;
    }
  }
  function handleClick(key) {
    if (key === 'option') {
      toOption(info)
    }
    if (key === 'info') {
      toSumitInfo(info)
    }
  }

  function cancelEnroll() {
    ServiceEnroll.cancel(info.enrollmentId)
      .then(res => {
        setShow(false)
        cancel();
      })
      .catch(err => {
        message.error(err.message);
      });
  }

  const now = new Date().getTime();

  const enrollEndTime = info.signInEndTime
    ? new Date(getValue('signInEndTime')).getTime()
    : undefined;

  const activityStartTime = info.activityStartTime
    ? new Date(getValue('activityStartTime')).getTime()
    : undefined;

  let step = 0;
  if (enrollEndTime && enrollEndTime < now) {
    step = 1;
  }
  if (activityStartTime && activityStartTime < now) {
    step = 2;
  }

  return (
    <Spin spinning={loadding}>
      <div className='detail-top-left'>
        <img src={getValue('logo')} />
        <div className='top-right'>
          <div
            style={{
              fontSize: '1.4rem',
              height: '4.5rem',
              marginBottom: '0.5rem',
            }}
          >
            {subStrEllipsis(info.name, 33)}
          </div>
          <div style={{ fontSize: '12px' }}>
            {`
              报名时间: 
              ${subStr(info.signInStartTime, 0, 16)} ~ 
              ${subStr(info.signInEndTime, 0, 16)}
            `}
          </div>
          <div style={{ fontSize: '12px' }}>
            {`活动时间: 
              ${subStr(info.activityStartTime, 0, 16)} ~ 
              ${subStr(info.activityEndTime, 0, 16)}
            `}
          </div>
        </div>
      </div>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item: any) => (
          <List.Item
            onClick={() => item?.key ? handleClick(item.key) : {}}
            actions={[
              <span  >{item?.extra}</span>]
            }
            style={{ paddingLeft: '10px', cursor: item?.key ? 'pointer' : '' }}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <div>{item.name}</div>
            </Skeleton>
          </List.Item>
        )}
      />
      <div style={{ width: '100%', backgroundColor: '#f5f5f5' }}>
        {
          steps.map((i, index) => {
            let buttonText =
              i.button === '取消'
                ? info.orderId?.length
                  ? null
                  : i.button
                : i.button

            return (
              <div className='step-item'>
                <div className='step-title'>
                  <div style={{ display: 'flex', lineHeight: '27px' }}>
                    <div className='step-tail' />
                    <img
                      src={Icons[i.tag === 1 ? 'finish' : i.tag === 2 ? 'process' : 'wait']}
                      alt={wait}
                      className='step-icon'
                    />
                    <div className='step-title-text'>{i.name}</div>
                  </div>
                  {buttonText && (
                    <div className='step-btn' onClick={() => action(i.button)}>
                      {buttonText}
                    </div>
                  )}
                  {i.title === '待付款' && (
                    <div className='step-btn' onClick={() => { }}>
                      去支付
                    </div>
                  )}
                </div>
                <div className='step-description'>{i.timestamp || i.tint}</div>
              </div>
            )
          }

          )
        }
      </div>
      {/* <div style={{ width: '100%', padding: '20px 0', backgroundColor: '#f5f5f5' }}>
        <Steps style={{ width: '90%', margin: '0 auto', padding: '20px', backgroundColor: 'white' }} progressDot current={1} direction="vertical">
          {
            steps.map((el, index) =>
              <Step status='finish' title={el.name} key={index} description={el.timestamp || el.tint} />
            )
          }
        </Steps>
      </div> */}
      <Modal
        title="提示"
        visible={show}
        onOk={() => cancelEnroll()}
        onCancel={() => setShow(false)}
        okText="确认取消"
        cancelText="暂不取消"
      >
        <p>请确认是否取消报名,多次取消可能会被限制报名哦</p>
      </Modal>

    </Spin>
  );
}
export default EnrollDetail