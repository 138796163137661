import * as React from 'react';

/**
 * TODO: 这个方法以后要用上面的hook替代
 */
export class Countdown extends React.Component<any, any> {
  timer: any;

  constructor(props) {
    super(props);
    this.state = {
      time: props.time < 0 ? 0 : props.time,
      text: '',
    };
    this.timer = null;
  }

  close() {
    clearInterval(this.timer);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidMount() {
    this.startTimting();
  }

  startTimting() {
    clearInterval(this.timer);
    if (this.props.time < 0) {
      this.setState({ time: 0 });

      this.props.timtingEnd && this.props.timtingEnd();

      return;
    }

    this.setState({ time: this.props.time });

    this.timer = setInterval(() => {
      const count = Number(this.state.time) - 1;

      if (count <= 0) {
        clearInterval(this.timer);

        this.setState({ time: 0 });
        this.props.timtingEnd && this.props.timtingEnd();
      } else {
        this.setState({ time: count });
      }
    }, 1000);
  }

  zero(n) {
    n = n.toString();
    return n[1] ? n : '0' + n;
  }

  render() {
    const { time } = this.state;
    return (
      <span>
        {this.props.format
          ? this.props.format(time)
          : `${this.zero(Math.floor(time / 60))} : ${this.zero(
              Math.floor(time % 60)
            )}`}
      </span>
    );
  }
}
