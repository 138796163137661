import * as React from 'react';
import './index.css'
import { ServiceHomePage, NewsBody, ActiveBody, GamesBody } from '../../service/homePage/index';
import { ServiceMember } from '../../service/member/member'
import { Honr } from '../../component/honr/index';
import { Sponsors } from '../../component/sponsors/index'
import { HomeActive } from '../../component/home/active'
import { HomeGoods } from '../../component/home/goods'
import { HomeNew } from '../../component/home/new'
import CarouselHero from '../../component/carouselHero/index'
import Next from '../../component/next/index'
import { initGetTheme } from '../../service/thmem'
import { NavLink as Link } from 'react-router-dom'
import { NONAME } from 'dns';
import Util from '../../utils';
import { message, Spin } from 'antd';
class Home extends React.Component<any, any> {

  state = {
    menuList: [] as any,
    sponsors: [] as any,
    honors: [] as any,
    activity: [] as ActiveBody[],
    News: [] as NewsBody[],
    goodsList: [] as any,
    loading: true,
    thmem: JSON.parse(sessionStorage.getItem('thmem') || '{}')
  };
  componentDidMount() {
    sessionStorage.setItem('headerActive', 0 + '')
    let flag = JSON.stringify(this.state.thmem || {}) === '{}'
    let promise = [
      ServiceHomePage.getSponsors(),
      ServiceHomePage.getHonors(),
      ServiceHomePage.homeNews(),
      ServiceHomePage.homeActivity(),
      ServiceMember.shopAllList({ pageSize: 3, pageNum: 1 })
    ]
    if (flag) {
      promise.push(
        ServiceHomePage.getTheme()
      )
    }
    Promise.all(promise).then(res => {
      // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
      window.scrollTo(0, 0)
      this.setState({
        sponsors: res[0],
        honors: res[1],
        News: res[2],
        activity: res[3],
        goodsList: res[4]?.content,
        // thmem: flag ? res[5] : this.state?.thmem
      })
      if (flag) {
        this.setState({ thmem: res[5] })
      }
    }).catch(err => {
      message.error(err.message)
      console.log(err.message)
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  setKey(key: number) {
    sessionStorage.setItem('headerActive', key + '')
  }

  render() {
    let { sponsors, honors, thmem, News, activity, goodsList, loading } = this.state
    let backgroundImage = ''

    thmem?.images?.map((el: any) => {
      if (el.imageType === 3) {
        backgroundImage = el.imageUrl
      }
    })
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    console.log(menuArray);

    return (
      <Spin spinning={loading} >
        <div style={{ width: '100%', marginTop: ' 35px', background: thmem?.background }}>
          {/* 大图 */}
          <CarouselHero />
          <div className="main-sponsor-box" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="main-sponsor">
              <h4 className="title" style={{ color: '#fff' }}>主赞助商</h4>
              {
                sponsors?.map((el: any) =>
                  el.levelName === '主赞助商' &&
                  el?.sponsors?.map((item: any) =>
                    <a href="#" className="logo-link" >
                      <img src={item.image} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    </a>
                  )
                )
              }
            </div>
          </div>
          <div style={{ padding: '0 1.6rem 0.1rem' }}>
            <div className="wrapper">
              <div className="col-12">
                {/* <section className="js-slider matches-scroller"> */}
                <Next />
                {/* </section> */}
                {
                  activity?.length > 0 &&
                  <section className="matches-scroller">
                    <div className="titleBox">
                      活动
                      <Link to={{ pathname: `/activity` }} >
                        <div onClick={() => this.setKey(menuArray?.indexOf('/activity'))} className="seeAll">查看所有</div>
                      </Link>

                    </div>
                    {
                      activity?.map((el: ActiveBody) =>
                        <HomeActive onChange={() => this.setKey(menuArray?.indexOf('/activity'))} activity={el} type={2} />
                      )

                    }
                  </section>
                }
                {
                  goodsList?.length > 0 &&
                  <section className="matches-scroller">
                    <div className="titleBox">
                      商品
                      <Link to={{ pathname: `/shopping/mall` }} >
                        <div onClick={() => this.setKey(4)} className="seeAll">前往商城</div>
                      </Link>
                    </div>
                    <HomeGoods onChange={() => this.setKey(4)} goodsList={goodsList} />
                  </section>
                }
                {
                  News?.length > 0 &&
                  <section className="matches-scroller">
                    <div className="titleBox">
                      新闻
                      <Link to={{ pathname: `/new` }} >
                        <div onClick={() => this.setKey(menuArray?.indexOf('/new'))} className="seeAll">查看所有</div>
                      </Link>
                    </div>
                    <HomeNew onChange={() => this.setKey(menuArray?.indexOf('/new'))} News={News} />
                  </section>
                }

              </div>

            </div>

          </div>
          {/* 荣誉图片列表 */}
          <Honr honors={honors} />

          {/* 赞助商列表 */}
          <Sponsors sponsors={sponsors} />
          <div
            style={{ padding: '10px 0' }}
            dangerouslySetInnerHTML={{ __html: thmem?.bottomHtml || ' ' }}
          />
        </div >
      </Spin>
    );
  }
}
export default Home