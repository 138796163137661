import * as React from 'react';
import './vipCenter.css'
import Login from '../../loginHome/index'
import { MemberCardsContent, ServiceMember } from '../../../service/member/member';
import { NoData } from '../../../component/noData';
import { Button, message, Spin, Modal, } from 'antd';
import Util from '../../../utils';
/*
 * 数字藏品
 * @returns 
 */
function VipCenter() {
  const [loadding, setLoadding] = React.useState<boolean>(true)
  const [slideIndex, setSlideIndex] = React.useState<number>(0)
  const [memberCards, setMemberCards] = React.useState<MemberCardsContent[]>([]);
  const [isLoadEnd, setIsLoadEnd] = React.useState(false);
  const [rights, setRights] = React.useState<any[]>([]);
  const [cardInfo, setCardInfo] = React.useState<any>();
  const [isLogin, setIsLogin] = React.useState<boolean>(false)

  function getRights(cardId) {
    ServiceMember.queryCard(cardId)
      .then(res => {
        res.rights.map(el => {
          el.eec = el.eec?.split(' ')[0].replace(/\n/, '<br />');
        });
        setRights(res.rights)
        setCardInfo(res)

      })
      .catch(err => {
        message.error(err.message)
      });
  }
  function toPlaceOrder() {
    // 如果实名认证了
    // let gifts = ServiceTicket.queryGiftByGoods(info.goodsId) || [];
    // return
    //
    //   gifts[0].length > 0 ?
    // Util.jumpPage(ROUTER_NAME.SHOPPING_PLACE_ORDER.path, {
    //   giftList: gifts
    // })
    // :
    // info &&
    //   Util.jumpPage(ROUTER_NAME.SHOPPING_PLACE_ORDER.path, {
    //     goodsId: info.goodsId,
    //   });
  }
  function getData() {
    ServiceMember.listAllMemberCard()
      .then(res => {
        let memberCards = res.sort((a: any, b: any) => b.possessed - a.possessed)
        memberCards[0]?.possessed && getRights(memberCards[0]?.card?.id)
        setMemberCards(memberCards);
        setIsLoadEnd(true);
      })
      .catch(err => {
        setIsLoadEnd(true);
        message.error(err.message)
      }).finally(() => {
        setLoadding(false)
      })
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setLoadding(false)
      return
    }
    getData()
  }, []);

  let info = memberCards[slideIndex] || {};
  let funcbtnText = '';

  return (
    <Spin spinning={loadding}>
      <div className="container1" style={{ width: '100%', background: `thmem?.background`, minHeight: '100vh' }}>
        {
          isLoadEnd &&
          <div className="vip_center">
            <div style={{ width: '100%', paddingTop: '10px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              {memberCards.map((val, index) => {
                funcbtnText = '立即开通'
                if (val.renewalPrice && val.payable && !val.possessed) {
                  funcbtnText = '立即续费';
                }
                if (!val.renewalPrice && val.upgradePrice && val.payable && !val.possessed) {
                  funcbtnText = '立即升级';
                }

                return (
                  <div onClick={() => getRights(val?.card?.id)} style={{ color: 'black', width: '20%', height: '260px', marginRight: '10px', overflow: 'hidden', position: 'relative' }} key={val.id}>
                    <img
                      src={val.possessed ? val.backImage : val.card.logo}
                      alt=""
                      style={{ width: '100%', verticalAlign: 'top' }}
                      onLoad={() => {
                        window.dispatchEvent(new Event('resize'));
                      }}
                    />
                    {
                      <div
                        className='top-func-btn'
                        onClick={() => toPlaceOrder()}
                        style={{ display: val.possessed ? 'none' : 'block' }}
                      >
                        {funcbtnText}
                      </div>
                    }
                  </div>
                )
              }
              )}
            </div>
            <div className='rights-container'>
              <div className='rights-sub'>
                {info.rights &&
                  cardInfo?.possessed ?
                  rights?.map((i, index) => (
                    <div
                      key={`rights_${index}`}
                      style={{
                        width: '18%',
                        marginLeft: '1.6%',
                        display: 'block',
                        position: 'relative',
                        top: slideIndex === index ? -10 : 0,
                        height: '100%',
                        color: 'black',
                      }}
                    >
                      <img
                        src={i.image}
                        alt=""
                        style={{ width: '100%', height: '100%', verticalAlign: 'top' }}
                      />
                      <div
                        className='rights-detail-bottom'
                        style={{ display: i?.totalCount ? 'block' : 'none' }}
                      >
                        <div style={{ fontSize: '1.5rem' }}>权益数量</div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '1.5rem',
                          }}
                        >
                          <div>
                            <span style={{ color: '#EE7839', fontSize: '2.5rem' }}>
                              {i?.leftCount}
                            </span>
                            <span style={{ fontSize: '2rem' }}>
                              /{`${i?.totalCount}`}
                            </span>
                            <span style={{ fontSize: '2rem' }}> {i?.unit}</span>
                          </div>
                          <Button style={{ color: '#555', border: '1px solid #eee' }}>
                            使用记录
                          </Button>
                        </div>
                      </div>
                      <div
                        className='rights-detail-bottom'
                        style={{ display: i.eec?.length > 0 ? 'block' : 'none' }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: i?.eec || '' }}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                  : info.rights ?
                    info.rights?.map((i, index) => (
                      <img
                        className='rights-item'
                        src={i.logo}
                        key={`rights_${index}`}
                      />
                    ))
                    :
                    <NoData show={true} />
                }
              </div>
            </div>
          </div>
        }
      </div>
      {
        isLogin &&
        <Login onLogin={() => { getData(); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </Spin>
  );
}
export default VipCenter