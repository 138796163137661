import { promises } from 'dns';
import { Net } from '../../utils/net';
export interface welfareList {
  content: welfareListContent[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  sort: null;
  totalElements: number;
  totalPages: number;
}

export interface welfareListContent {
  description: string;
  image: string;
  myId: number;
  tag: number; //1.已领取 2.待领取 3.不可领取
  ttpId: number;
  ttpType: number;
}
// 

export class ServiceClub {
  public static archiveList() {
    return Net(`/api/w/archive/list?domain=${localStorage.getItem('DOMAIN') || ''}`,).then(res => res.data.title);
  }
  public static archiveQuery(archiveId: number) {
    return Net(`/api/w/archive/query?archiveId=${archiveId}&domain=${localStorage.getItem('DOMAIN') || ''}`).then(res => res.data.content);
  }
}
