import * as React from 'react';
import './index.css'
import Util from '../../utils/'
import { ServiceHomePage, GameListBody, GamesBody } from '../../service/homePage/index';
import { useLocation, useNavigate } from 'react-router-dom'

interface TicketListProp {
  gameList: GamesBody[]
}

export function TicketList(prop: TicketListProp) {
  const navigate = useNavigate()
  let { gameList } = prop
  let newData = [] as any
  let newData1 = [] as any
  const allIcon = JSON.parse(localStorage.getItem('icons') || '{}')
  let date = gameList[0]?.gameTime?.split(' ')[0].substring(0, 7)
  gameList?.map((el: any) => {
    if (date == (el?.gameTime?.split(' ')[0].substring(0, 7))) {
      newData1.push(el)
    } else {
      newData.push({
        time: date,
        list: newData1
      })
      date = el?.gameTime?.split(' ')[0].substring(0, 7)
      newData1 = [el]
    }
  })
  if (newData1.length > 0) {
    newData.push({
      time: date,
      list: newData1,
    })
  }
  let background = ''
  let thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  thmem?.images?.map((el: any) => {
    if (el.imageType == 6) {
      background = el.imageUrl
    }
  })
  return (
    <div className="ticket-list">
      {
        newData?.map((el: any) =>
          <div className="ticket-list-content">
            <div className="split-line" style={{ backgroundImage: `url(${background})` }}></div>
            <div className="content-date" style={{ background: `linear-gradient(90deg,#a71c3f,#712a59,#712a59,#3a3872)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', color: '#fff' }}>{el?.time}</div>
            {
              el?.list?.map((item: any) =>
                <ul className="ticket-list-ul" onClick={() => item.onSale ? navigate('/choose/ticket/list', { state: { gameId: item.gameId } }) : ''}>
                  <li className="ticket-list-li-data">
                    <h3 style={{ fontWeight: 'bold' }}>{Util.getMonthDay(item.gameTime?.split(' ')[0])} {Util.getWeek(item.gameTime?.split(' ')[0])}</h3>
                    <p className="time" style={{ background: `linear-gradient(90deg,#a71c3f,#712a59,#712a59,#3a3872)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', color: '#fff' }}>{item.gameTime?.split(' ')[1].substring(0, 5)}</p>
                  </li>
                  <li className="ticket-list-li-match">
                    <div className="match-img">
                      <img src={item.leagueLogo || allIcon['match']} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" />
                    </div>
                    <strong>{item.leagueName}</strong>
                  </li>
                  <li className="ticket-list-li-turn">第{item.turn}轮</li>
                  <li className="ticket-list-li-team">
                    <div className="home-team">
                      <p className="team-name">{item.hostTeam}</p>
                      <div className="team-img">
                        <img src={item.hostLogo} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" />
                      </div>
                    </div>
                    <div className="VS">
                      <div style={{ background: `linear-gradient(90deg,#a71c3f,#712a59)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', }}>V</div>
                      <div style={{ background: `linear-gradient(90deg,#712a59,#3a3872)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', }}>s</div>
                      <div style={{ background: `linear-gradient(90deg,#3a3872,#154284)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', }}>.</div>
                    </div>
                    <div className="cline-team">
                      <div className="team-img">
                        <img src={item.clientLogo} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" />
                      </div>
                      <p className="team-name">{item.clientTeam}</p>
                    </div>
                  </li>
                  <li className="ticket-list-li-button">
                    {

                      Util.calculationTime(item.gameTime) <= 0 &&
                      <div className="ticket-button" style={{ color: item.onSale ? 'black' : '#fff', backgroundColor: item.onSale ? '#fdc52c' : '#aaaaaa' }}>{item.onSale ? '购票' : '敬请期待'}</div>
                    }
                  </li>
                </ul>
              )
            }

          </div>
        )
      }
    </div>
  );
}
