import * as React from 'react';
import './ticketComponent.css'
import Util from '../../../../utils';
import { MineVoteBody, VoteService, RewardItem } from '../../../../service/vote';
import { message, Spin, Steps, Card, List, Skeleton } from 'antd';
import { deduplication, subStr, subStrEllipsis } from '../../../../utils/tool';
const { Step } = Steps;
const wait = require('../../../../assets/icon/wait.png');
const process = require('../../../../assets/icon/process.png');
const finish = require('../../../../assets/icon/finish.png');

/**
 * 
 * @returns 
 */
interface VoteDetailProp {
  id: number | string
  cancel: () => void
}
function VoteDetail(prop: VoteDetailProp) {
  let { id } = prop
  const Icons = {
    wait: wait,
    process: process,
    finish: finish,
  };
  const [info, setInfo] = React.useState<MineVoteBody>({} as MineVoteBody)
  const [loadding, setLoadding] = React.useState<boolean>(true)

  React.useEffect(() => {
    VoteService.queryMine(id)
      .then(res => {
        setInfo(res);
      })
      .catch(err => {
        message.error(err.message);
      }).finally(() => {
        setLoadding(false)
      })
  }, [id]);

  //rewardMode: 1.数字 2.多选 3.比分
  function switchRewardMode(item: RewardItem) {
    return (
      <div
        // key={item.lotteryActivityId}
        className='quiz-item'
        style={{ display: 'flex' }}
      >
        {/* <img src={logo[item.rewardMode /> */}
        <img src={require('../../../../assets/image/present.png')} />
        <div className='quiz-info'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{item.title}</span>
            {item.button && (
              <div
                className='quiz-btn'
                onClick={() => goLottery(item.lotteryActivityId)}
              >
                {item.button}
              </div>
            )}
          </div>
          <span>{item.description || item.tint}</span>
        </div>
      </div>
    );
  }

  function goLottery(lotteryActivityId) {
    // Util.jumpPage(ROUTER_NAME.LOTTERY_PAGE.path, {
    //   id: lotteryActivityId,
    //   from: 'vote',
    // });
  }

  return (
    <Spin spinning={loadding}>
      {info.reward && (
        <div className='quiz-item-detail'>
          {switchRewardMode(info.reward)}
        </div>
      )}
      <div style={{ padding: '20px 30px' }}>
        {info.items && (
          // <VoteComponent
          //   title={info.title}
          //   dataSource={info.items || [
          //   mode="result"
          // />
          <Card title={info.title} >
            <div className='vote-content'>
              {info.items?.map((item, index) => (
                <div
                  key={`item_${index}`}
                  className='vote-item'
                >
                  <div className='item-main'>
                    {item.logo && <img src={item.logo} style={{ width: '40px', height: '40px', verticalAlign: 'middle' }} />}
                    <div className='item-title'>
                      {`${item.name} ${item.mine === 1 ? '(已投)' : ''}`}
                    </div>
                  </div>
                  <div className='item-propgress'>
                    <div className='propgress-outer'>
                      <div
                        className='propgress-bar'
                        style={{ width: item.percent }}
                      />
                    </div>
                    <div style={{ flex: 1, textAlign: 'center' }}>{`${item.amount}票`}</div>
                    <div style={{}}>{item.percent}</div>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        )}
      </div>
      {/* <Modal
        title="提示"
        visible={show}
        onOk={() => cancelVote()}
        onCancel={() => setShow(false)}
        okText="确认取消"
        cancelText="暂不取消"
      >
        <p>请确认是否取消报名,多次取消可能会被限制报名哦</p>
      </Modal> */}

    </Spin>
  );
}
export default VoteDetail