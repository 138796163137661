import * as React from 'react';
import './index.css'
import { ServiceClub } from '../../service/club/index'
import { ScheduleList } from './ScheduleList'
import { JFLIST } from './jfList'
import { TitleList } from '../../component/TitleList/index'
import { message, Spin } from 'antd';

export function Club() {
  const [index, setIndex] = React.useState(0)
  const [active, setActive] = React.useState(-1)
  const [active1, setActive1] = React.useState(0)
  const [itemId, setItemId] = React.useState(1)
  const [titleList, setTitleList] = React.useState<any[]>([])
  const [title, setTitle] = React.useState<any>()
  const [content, setContent] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')

  React.useEffect(() => {
    function init() {
      setLoading(true)
      window.scrollTo(0, 0)
      let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
      sessionStorage.setItem('headerActive', menuArray?.indexOf('/club'))
      ServiceClub.archiveList().then(res => {
        let archiveList = res
        archiveList?.map((el: any) => {
          el['id'] = el.archiveId
        })
        let titleList = [{ name: '赛事', id: -1 }, ...archiveList]
        setTitleList(titleList)
        setActive(titleList[0]?.id)
        setTitle(titleList[0]?.name)
      }).catch(err => {
        message.error(err.message)
      }).finally(() => {
        setLoading(false)
      })
    }
    init()
  }, [])

  function getData(key: number) {
    ServiceClub.archiveQuery(key).then(res => {
      setContent(res)
      setItemId(res[0]?.itemId)
    }).finally(() => {
      setLoading(false)
    })

  }
  function onChange(key: number, index: number) {
    if (key !== -1) {
      setLoading(true)
      getData(key)
    }
    setIndex(index)
    setItemId(1)
    setActive(key)
    setActive1(0)
  }

  return (
    <div className='club' style={{ marginTop: '55px', minHeight: '100vh', width: '100%', background: thmem?.background, paddingBottom: '20px' }}>
      <div className='container1'></div>
      {/* 标题列表 */}
      <TitleList active={active} titleList={titleList} onChange={(e) => onChange(e, index)} />
      <Spin spinning={loading}>
        {
          active !== -1 &&
          <div className="club-content">
            <div className="club-tabbar">
              {
                content?.map((item: any, index: number) =>
                  <div className={itemId === item.itemId ? "title active" : "title"}
                    onClick={() => { setActive1(index); setItemId(item.itemId) }} >
                    {item.name}
                  </div>
                )
              }

            </div>
            <div className="content-message">
              <h1 style={{ margin: '30px 0' }}>{content[active1]?.name}</h1>
              <div style={{ width: '97%', margin: '10px auto', }}
                dangerouslySetInnerHTML={{ __html: content[active1]?.content || '' }}
              />
            </div>
          </div>
        }
        {
          active == -1 &&
          <div className="club-content">
            <div className="club-tabbar">
              <div className={itemId === 1 ? "title active" : "title"}
                style={{ borderBottomColor: itemId === 1 ? thmem?.background : '' }}
                onClick={() => { setActive1(index); setItemId(1) }} >
                赛程
              </div>
              <div className={itemId === 2 ? "title active" : "title"}
                style={{ borderBottomColor: itemId === 2 ? thmem?.background : '' }}
                onClick={() => {
                  setActive1(index); setItemId(2)
                }} >
                积分
              </div>
            </div>
            {
              itemId == 1 &&
              <ScheduleList />
            }
            {
              itemId == 2 &&
              <JFLIST />

            }
          </div>
        }
      </Spin>
    </div >
  );
}