import { Net } from '../../utils/net';
import { _pageData } from '../common_i';

export interface EnrollBody {
  enrollmentId: number;
  name: string;
  logo: string;
  createTime: string;
  top: boolean;
  portraitUrl: string;
  activityStartTime: string;
  activityEndTime: string;
  signInStartTime: string;
  signInEndTime: string;
}

export interface Item {
  name: string;
  amount: number;
  totalAmount: number;
}
/**
 * @param Field.type: 1.下拉选择框 2.日期控件 3.时间控件 4.日期+时间控件 5.文本输入框 6.图片上传控件
 */
export interface Field {
  id: number;
  length: null | string | number;
  mandatory: boolean;
  maxLength: number;
  minLength: number;
  name: string;
  placeHolder: string;
  sort: number;
  type: number;
  values: any[];
}

export interface QueryBody {
  enrollmentId: number;
  name: string;
  activityStartTime: string;
  activityEndTime: string;
  signInStartTime: string;
  signInEndTime: string;
  logo: string;
  description: string;
  amount: number;
  totalAmount: number;
  leftAmount: number;
  touchAmount: number;
  shareAmount: number;
  fields: Field[];
  items: Item[];
  useForm: boolean;
  useAudit: boolean;
  buttonTxt: string;
  myEnrollmentId: number;
  itemName: string;
  points: number;
  showAmount: boolean;
}

export interface SubmitInfoItem {
  content: string[];
  title: string;
  type: number;
}

export interface MineBody {
  buttonTxt: string;
  id: any;
  enrollmentId: number;
  name: string;
  logo: string;
  createTime: string;
  top: boolean; // 这里没用
  submitTime: string; // 提交时间
  tag: string; // 活动标签
  code: string | null; // 签到二维码；如空，则不需要签到
  activityStartTime: string; // 活动起止时间
  activityEndTime: string;
  signInStartTime: string; // 签到开始时间
  signInEndTime: string;
  canceled: boolean; // 是否已取消
  cancelable: boolean; // 是否可以取消
  hasSubmitContent: boolean; // 是否有提交信息
  itemApplyToSign: boolean; // 是否有报名选项
  itemName: string; //报名选项，不为空，则需要显示，并可以查看统计信息
  items: Item[]; // 报名情况统计
  reimburse: any; // 报销申请状态？如空，则不显示该栏
  reimburseTag: any; // 报销按钮文案
  orderId: string;
  orgDomain: string;
  orgId: number;
  orgLogo: string;
  orgName: string;
  main: {
    name: string;
    timestamp: string;
    button: string;
    tag: number;
    tint: string;
  };
  audit: {
    name: string;
    timestamp: string;
    button: string;
  };
  signIn: {
    name: string;
    timestamp: string;
    button: string;
  };
  submitContent: SubmitInfoItem[];
}

export interface OptionPeople {
  name: string;
  portraitUrl: string;
  phone: string;
  itemName: string;
  status: string;
  createTime: string;
}

// tip:后台实现了取消和删除两种操作；其中，取消操作后，用户是可以看到取消状态的数据；删除操作后，
//  用户是看不到该数据的。即删除=取消+隐藏
export class ServiceEnroll {
  public static list(params = {} as any): Promise<_pageData<EnrollBody>> {
    params.domain = localStorage.getItem('DOMAIN') || '';
    return Net('/member/enroll/list', null, { params }).then(
      res => res.data.list
    );
  }

  public static query(enrollmentId: any): Promise<QueryBody> {
    const domain = localStorage.getItem('DOMAIN') || '';
    return Net('/member/enroll/query', null, {
      params: { enrollmentId, domain },
    }).then(res => res.data.query);
  }
  public static listMine(params = {}): Promise<_pageData<MineBody>> {
    return Net('/member/enroll/listMine', null, { params }).then(
      res => res.data.list
    );
  }

  public static queryMine(id): Promise<MineBody> {
    return Net('/member/enroll/queryMine', null, { params: { id } }).then(
      res => res.data.query
    );
  }

  //检查是否能够报名
  public static check(enrollmentId: any) {
    return Net('/member/enroll/check', null, { params: { enrollmentId } }).then(
      res => res.data
    );
  }
  //提交报名
  public static submit(body) {
    return Net('/member/enroll/submit', body);
  }
  //修改选项
  public static modifyOption(body) {
    return Net('/member/enroll/modifyItem', body);
  }
  //修改提交信息
  public static modifyForms(body) {
    return Net('/member/enroll/modifyForms', body);
  }
  //取消报名
  public static cancel(enrollmentId: any) {
    return Net('/member/enroll/cancel', null, { params: { enrollmentId } });
  }
  //删除报名
  public static delete(enrollmentId: any) {
    return Net('/member/enroll/delete', null, { params: { enrollmentId } });
  }
  //查看报名列表（可签到)
  public static getSignInActivityList(): Promise<_pageData<EnrollBody>> {
    return Net('/member/enroll/list?tag=3').then(res => res.data.list);
  }
  //查看分项统计
  public static getSignInOption(enrollmentId: any): Promise<Item[]> {
    return Net('/member/enroll/listSignItems', null, {
      params: { enrollmentId },
    }).then(res => res.data.items);
  }
  //查看分项人员
  public static getOptionPeoples(
    enrollmentId: any,
    itemName: any
  ): Promise<_pageData<OptionPeople>> {
    return Net('/member/enroll/listAccount', null, {
      params: { enrollmentId, itemName },
    }).then(res => res.data.list);
  }
  //提交二维码及确认签到
  public static signIn(enrollmentId: any, code: any, itemName: any) {
    return Net('/member/enroll/signIn', { enrollmentId, code, itemName });
  }
  //检查二维码签到状态
  public static signInCheck(enrollmentId: any, code: any, itemName: any) {
    return Net('/member/enroll/checkSignIn', {
      enrollmentId,
      code,
      itemName,
    }).then(res => res.data);
  }

  public static addShare(enrollmentId) {
    return Net('/member/enroll/share', null, { params: { enrollmentId } });
  }
}
