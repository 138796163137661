import * as React from 'react';
import './coupon.css'
import Login from '../../loginHome/index'
import Util from '../../../utils';
import QRCode from 'qrcode';
import { ServiceVoucher } from '../../../service/voucher'
import { message, Spin, Modal } from 'antd';
import { NoData } from '../../../component/noData';
const giftCardImage = require('../../../assets/image/giftCard.png');
/**
 * 
 * @returns 
 */
function Coupon() {
  const [list, setList] = React.useState<any[]>([])
  const [show, setShow] = React.useState<boolean>(false)
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [loadding, setLoadding] = React.useState<boolean>(true)
  const [info, setInfo] = React.useState<any>({ code: '', name: '' })

  function getData() {
    let params = { pageSize: 99, pageNum: 1 };
    ServiceVoucher.getMyVouchers(params)
      .then(res => {
        setList(res.content.map(i => ({ voucher: { ...i } })))
      })
      .catch(err => {
        message.error(err.message);
      }).finally(() => {
        setLoadding(false)
      })
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setLoadding(false)
      return
    }
    getData()
  }, []);

  async function showCode(voucher) {

    let qrcode = await QRCode.toDataURL(voucher.code);
    setInfo({ code: qrcode, name: voucher.name })
    setShow(true)
  }

  return (
    <Spin spinning={loadding}>
      {
        list.length > 0 ?
          <div className="voucher">
            {
              list?.map(el => {
                const { voucher, selectable, entity = {} } = el
                return (
                  <div className="voucher-img" onClick={() => { voucher.code && showCode(voucher) }}>
                    <img src={voucher.image || giftCardImage} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  </div>
                )
              })
            }

          </div>
          :
          <NoData show={true} />
      }
      <Modal
        title={info.name}
        visible={show}
        footer={null}
        onCancel={() => { setShow(false) }}
      >
        <div style={{ width: '100%', margin: '30px auto', textAlign: 'center' }}>
          <img src={info.code} alt="" />

        </div>
      </Modal>
      {
        isLogin &&
        <Login onLogin={() => { getData(); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </Spin>
  );
}
export default Coupon