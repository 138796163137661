import * as React from 'react';
import './accountData.css'
import { ServiceAccount } from '../../../service/account';
import { RealNameInfo, ServiceRealName } from '../../../service/member/realName';
import { getCardName, RealNameCardTypeEnum } from '../../../enum';
import { ImageComponent } from '../../../component/imageComponet/ImageComponent';
import { Checkbox, List, Select, message, Spin, Modal } from 'antd'
import Util from '../../../utils';
import { ID_NUMBER_REGEXP, PHONE_REGEXP } from '../../../configs/RegExp';
import { ServicePeoples } from '../../../service/peoples';
const { Option } = Select;
interface RealNameAuthInfoProp {
  isReal: boolean
  onComplete: () => void
}
function RealNameAuthInfo(prop: RealNameAuthInfoProp) {
  let { isReal, onComplete } = prop
  const [name, setName] = React.useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [cardType, setCardType] = React.useState<number>()
  const [cardNum, setCardNum] = React.useState<string>('')
  const [pacePic, setPacePic] = React.useState<string>('')
  const [codePic, setCodePic] = React.useState<string>('')
  const icons = JSON.parse(localStorage.getItem('icons') || '{}')
  const [realNasmeInfo, setInfo] = React.useState<RealNameInfo>(
    {} as RealNameInfo
  );
  React.useEffect(() => {
    async function init() {
      try {
        if (isReal) {
          const realNasmeInfo = await ServiceAccount.myAuthInfo();
          setInfo(realNasmeInfo || {});
        }
      } catch (e) {
        console.log(e);

      }
    }
    init();
  }, [])

  async function submitForm() {
    try {
      if (!cardType) {
        return message.error('请选择您的证件类型!');
      }
      switch (cardType) {
        case 1:
          // 身份证 判断ORC是否开启
          ServicePeoples.contactsORC().then(res => {
            if (res.ocr) {
              // utils.replaceToPage(ROUTER_NAME.NEWCONTACTSSTEP_ID.path);
            } else {
              submit()
            }
          });
          break;
        case 2: //护照
          ServicePeoples.contactsAuth().then(res => {
            if (res.ocr) {
              // utils.replaceToPage(ROUTER_NAME.NEWCONTACTSSTEP_ID.path);
            } else {
              post()
            }
          });

          // utils.replaceToPage(ROUTER_NAME.NEWCONTACTSSTEP_OTHER.path);
          break;
        default:
          break;
      }

    } catch (e: any) {
      message.error(e.message);
    }
  }
  async function post() {
    try {
      const base64Body = Util.getBase64Body(codePic);
      Promise.all([
        ServiceRealName.uploadPassport(base64Body, 'png', cardType),
        ServiceRealName.uploadFace(
          Util.getBase64Body(pacePic),
          'png',
          cardType
        )
      ]).then(res => {
        ServiceRealName.submitRealName(name, cardNum)
          .then(async res => {
            message.success(`提交成功, 请耐心等待审核结果`)
            onComplete()
          })
          .catch(err => {
            message.error(err.message);
          });
      })
    } catch (error: any) {
      message.error(error.message)
    }
  }
  async function submit() {
    try {
      if (!name) {
        return message.error('请输入您的姓名!');
      }
      if (!cardNum || !ID_NUMBER_REGEXP.test(cardNum.toString())) {
        return message.error('请填写正确的证件号码!');
      }
      ServicePeoples.contactsAuth().then(async res => {
        if (res.auth) {
          const res = await ServicePeoples.authCheckIdCard(cardNum, name);
          if (res.success) {
            // 上传证件信息 后台验证信息正确 传证件照
            const pace = ServiceRealName.uploadFace(
              Util.getBase64Body(pacePic),
              'png',
              cardType
            )
            message.success('恭喜您已完成实名认证')
            onComplete()
          } else {
            // 上传证件信息 后台验证信息错误 重新填写再提交
            message.warn('身份证信息错误,请检查证件的信息是否填写正确 确认正确的情况下可联系客服处理')
          }
        } else {
          message.error('网路异常')
        }
      });
    } catch (e: any) {
      message.error(e.message)
    }
  }
  function getImage(data) {
    const imageFIle = Util.dataURLtoFile(data, '图片名称');
    ServiceRealName.checkPeopleFace(
      Util.getBase64Body(data),
      Util.getFileType(imageFIle)
    )
      .then(faceRect => {
        setPacePic(data)
      })
      .catch(err => {
        message.error(err.message)
      })
  }

  let {
    mobileAccountAuth: info = {} as any,
    auditStatus = {} as any,
  } = realNasmeInfo;
  if (auditStatus.name === '审核不通过') {
    Modal.confirm({
      title: '审核不通过',
      content: info.auditMessage,
      okText: '重新提交',
      cancelText: '取消',
      // onOk:()=>{

      // }
    })
  }
  switch (isReal) {
    case true:
      return (
        <div className='real-name'>
          <div
            style={{
              display: auditStatus.name === '无需审核' ? 'none' : 'block',
              margin: '1.691vw 0',
              paddingLeft: '13.5863px',
            }}
          >
            <div style={{ color: '#BF3648', fontSize: '15px' }}>
              {auditStatus.name}
            </div>
          </div>
          <List>
            <List.Item >
              <List.Item.Meta title='姓名' />
              <div>{info.name}</div>
            </List.Item>
            <List.Item >
              <List.Item.Meta title='证件类型' />
              <div>{getCardName(info.idCardType)}</div>
            </List.Item>
            <List.Item >
              <List.Item.Meta title='证件号码' />
              <div>{info.idCardCode}</div>
            </List.Item>
          </List>
          <p className="real-name-title">证件人像面照片</p>
          <img className="real-name-image" src={realNasmeInfo.frontImage} alt="" />
          {
            info.idCardType !== 2 &&
            <div>
              <p className="real-name-title">证件另一面照片</p>
              <img className="real-name-image" src={realNasmeInfo.backImage} alt="" />
            </div>
          }
          <p className="real-name-title">人脸信息</p>
          <img className="real-name-face" src={realNasmeInfo.faceVideoUrl} alt="" />
        </div>
      ); break;
    case false:
      return (
        <div className="add-people">
          <div className="add-people-box">
            <div className="label">姓名</div>
            <div className="input-value">
              <input value={name} onChange={(e) => setName(e.target.value)} type="text" />
            </div>
          </div>

          <div className="add-people-box">
            <div className="label">证件类型</div>
            <div className="input-value">
              <Select value={cardType} style={{ width: 120 }} onChange={(e) => setCardType(e)} >
                <Option value={1}>身份证</Option>
                <Option value={2}>护照</Option>
              </Select>
            </div>
          </div>
          <div className="add-people-box">
            <div className="label">证件号码</div>
            <div className="input-value">
              <input value={cardNum} onChange={(e) => setCardNum(e.target.value)} type="text" />
            </div>
          </div>
          {
            cardType === 2 &&
            <div className="add-people-box">
              <div className="label">证件照片</div>
              <div className="input-value">
                <ImageComponent value={codePic} onChange={(data) => setCodePic(data)} />
              </div>
            </div>
          }
          <div className="add-people-box">
            <div className="label">人脸照片</div>
            <div className="input-value">
              <ImageComponent value={pacePic} onChange={(data) => getImage(data)} />
            </div>
          </div>
          <div className="people-button" onClick={() => submitForm()}>提交</div>
        </div>
      )
  }

}
export default RealNameAuthInfo