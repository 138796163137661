import * as React from 'react';
import { PASSWORD_REGEXP, PHONE_REGEXP } from '../../configs/RegExp';
import { ServiceOrder } from '../../service/order'
import { deduplication } from '../../utils/tool';
import { ImageComponent } from '../../component/imageComponet/ImageComponent';
import { Checkbox, Select, message, Spin, Modal } from 'antd'
import Util from '../../utils'
import './login.css'
const { Option } = Select;

interface ticketBuyerProp {
  nickName: string
  onCancel: () => void
  onLogin: () => void
}
export default function RealName(prop: ticketBuyerProp) {
  let { nickName, onCancel, onLogin } = prop
  const [name, setName] = React.useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [cardType, setCardType] = React.useState<string>('')
  const [cardNum, setCardNum] = React.useState<string>('')
  const [pacePic, setPacePic] = React.useState<string>('')
  React.useEffect(() => {

  }, [])
  async function submitForm() {
    try {

    } catch (e: any) {
      message.error(e.message);
    }
  }

  function checkboxChange(id, index, el, e) {

  }

  return (
    <Modal visible={true} footer={null} onCancel={() => onCancel()}>
      <div className="add-realName">
        <p>恭喜您注册成功</p>
        <div className="realName-title">
          <p>亲爱的 昵称</p>
          <p>您已成功加入到球迷大家庭，为了更好的为您提高服务，请您进行账号的实名认证，实名认证非常重要：</p>
          <p>1. 拥有更多商品的购买资格，包括球票、数字藏品。</p>
          <p>2. 拥有更多活动的参与资格，包括报名、抽奖和投票。</p>
          <p>3. 拥有购买会员的资格，获取更多专属权益。</p>
        </div>
        <div className="realName-title">现在开始填写您的账号实名信息吧！</div>
        <div className="add-realName-box">
          <div className="label">姓名</div>
          <div className="input-value">
            <input value={name} onChange={(e) => setName(e.target.value)} type="text" />
          </div>
        </div>
        <div className="add-realName-box">
          <div className="label">手机号码</div>
          <div className="input-value">
            <input value={phone} type="text" onChange={(e) => setPhone(e.target.value)} />
          </div>
        </div>
        <div className="add-realName-box">
          <div className="label">证件类型</div>
          <div className="input-value">
            <Select defaultValue="" value={cardType} style={{ width: 120 }} onChange={(e) => setCardType(e)} >
              <Option value={1}>身份证</Option>
              <Option value={2}>护照</Option>
            </Select>
          </div>
        </div>
        <div className="add-realName-box">
          <div className="label">证件号码</div>
          <div className="input-value">
            <input value={cardNum} onChange={(e) => setCardNum(e.target.value)} type="text" />
          </div>
        </div>
        <div className="add-realName-box">
          <div className="label">人脸照片</div>
          <div className="input-value">
            <ImageComponent value={pacePic} onChange={(data) => setPacePic(data)} />
          </div>
        </div>
        <div className="realName-button" onClick={() => submitForm()}>提交</div>
      </div>
    </Modal>
  );
}