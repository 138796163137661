import { message } from 'antd';
import * as React from 'react';
import { VoteService } from '../../../service/vote/index'
import Util from '../../../utils';
import './index.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Login from '../../loginHome/index'
// import Rejister from '../../loginHome/register'
// import Retrieve from '../../loginHome/retrieve'
/**
 * 投票
 */
// interface VoteProp{
//   item:any
//   onChange:()=>void
// }
export default function Vote(prop: any) {
  let location = useLocation()
  const navigate = useNavigate()
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const Icons = JSON.parse(localStorage.getItem('icons') || '{}')
  const [id, setId] = React.useState<any>('')
  const [isLogin, setIsLogin] = React.useState<boolean>(false);
  const [info, setInfo] = React.useState<any>({})
  const [mode, setMode] = React.useState<any>('')
  const params = useParams()
  React.useEffect(() => {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    sessionStorage.setItem('headerActive', menuArray?.indexOf('/activity'))
    window.scrollTo(0, 0)
    let state: any = location.state
    function init() {
      getData(state?.id || params?.id)
    }
    init();
  }, []);
  function getData(id) {
    VoteService.query(id)
      .then(res => {
        setInfo(res || {});
        setId(id)
        setMode(res.myVoteId ? 'result' : 'select');
      })
      .catch(err => {
        message.error(err.message)
      });
  }
  const title =
    mode === 'select' ? `${info.title} (可投${info.itemCount}票)` : info.title;
  function handleChange(item: any, index) {
    if (mode === 'select') {
      let selectedItems = info.items?.filter(i => i.selected)
      let arry = selectedItems?.filter(i => i.id == item.id)
      if (arry.length > 0) {
        changeItems('selected', !item.selected, index)
      } else {
        if (info.itemCount > selectedItems.length || (info.itemCount === selectedItems.length && selectedItems.find(i => i.id === id))) {
          changeItems('selected', !item.selected, index)
        } else {
          changeItems('selected', false, index)
          message.error(`最多可选择${info.itemCount}项`)
        }
      }

    }
  }
  function changeItems(key, value, index) {
    info.items[index][key] = value;
    setInfo({ ...info });
  }
  function submit() {
    if (!Util.IsLogin()) {
      // setType(1)
      setIsLogin(true)
      // 登录
      // message.error('未登录')
      return
    }
    let body = {
      voteId: id,
      itemIds: info.items.filter((p: any) => p.selected).map(i => i.id),
    };
    VoteService.submit(body)
      .then(res => {
        message.success('提交成功')
        navigate(-1)
      })
      .catch(err => {
        message.error(err.message);
      });
  }
  return (
    <div style={{ width: '100%', background: thmem?.background, minHeight: '100vh', marginTop: '45px', }}>
      <div className="activity">
        <div className="activity-left-info">
          <div className="info-top">
            <h2 className="top-title">{info.name}</h2>
            <p>投票时间：{info.startTime} ~ {info.endTime}</p>
            <div className="info-bottom">
              <p></p>
              <p>
                <span>阅读 {info.touchAmount}</span>
                <span>分享 {info.shareAmount}</span>
              </p>
            </div>
          </div>
          <div className="active-detail-message">
            <div
              dangerouslySetInnerHTML={{ __html: info.description }}
            />
          </div>
        </div>
        <div className="right-operation">
          <div className="vote">
            <div className="vote-title"> {title} </div>
            {
              info.items?.map((el, index) =>
                <div className="vote-option" onClick={() => handleChange(el, index)}>
                  <div className="option-img">
                    <img src={el.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </div>
                  <div className="option-message">
                    {el.name}
                  </div>
                  <div className="option-radio">
                    <input name={el.id} type="radio" checked={el.selected} />
                  </div>
                </div>
              )
            }
          </div>
          {/* style={{backgroundColor:info.statusTag === '进行中' ? '#c31c32' : '#B8B8B8'}} */}
          <div onClick={() => submit()} className="quiz-button">
            {/* {info.myVoteId
              ? '查看我的投票'
              : info.statusTag === '进行中'
              ? '提交投票'
              : info.statusTag}*/}
            提交

          </div>
        </div>
        <div className='close' onClick={() => navigate('/activity')}>
          {/* 关闭 X */}
          关闭<img src={Icons['close']} alt="" style={{width:'25px',height:'25px'}} />
        </div>
      </div>
      {
        isLogin &&
        <Login onLogin={() => { setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </div >
  );
}