/** 全部订单列表 */
import { Button } from 'antd';
import * as React from 'react';
import { OrderContent } from '../../../../service/order';
import './orderList.css';
// import { IsRender } from '../../../../component/small';

interface RenderOrderItemProps {
  item: OrderContent;
  onDelete: (id: string) => void;
  onClick: (item: OrderContent) => void;
  onClickStatus: (status: any, id: string) => void;
}

export function RenderOrderItem(props: RenderOrderItemProps) {
  const { item, onDelete, onClickStatus, onClick } = props;
  let total = 0;

  numAut();

  function numAut() {
    total = 0;
    item.items.map(i => {
      total += i.item.amount;
    });
  }

  function renderItem(item) {
    switch (item.itemType) {
      case 2:
      case 6:
        return (
          <>
            {item.items.length > 0 &&

              // (item.items || []).map((i, index) => (
              <div
                style={{ display: 'flex', marginBottom: '10px' }}
              // key={index}
              >
                <div className='shop-img'>
                  <img
                    src={item.items[0]?.item.itemLogo}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    alt=""
                  />
                </div>
                <div style={{ flex: 1, padding: '0.725vw' }}>
                  <div className='shop-titles'>
                    <p className='shop-name'>{item.items[0]?.item.itemName}</p>
                    <p className='shop-size'>
                      {item.items[0]?.specification1 || ''}

                      {item.items[0]?.specification2 || ''}
                    </p>

                    <p className='shop-size'>
                      价格：{`￥${item.items[0]?.item.actualCost}.00`} X {item.items[0]?.item.amount}
                    </p>
                  </div>
                </div>
              </div>
              // ) )
            }
          </>
        );
      default:
        return (
          <div style={{ display: 'flex' }}>
            <div className='order-item-cp'>
              <img
                src={item.itemLogo}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                alt=""
              />
            </div>
            <div className='order-item-cc'>
              <div className='order-item-ct'>{item.itemName}</div>
              <p className='shop-size'></p>
              <p className='shop-size'></p>
              <div className='order-item-cf'>
                {`共${item.itemCount}件`}&nbsp;
                {item.statusName === '待付款' ? '待付款' : '实付款'}
                <span className="bold">{`￥${item.actualCost}`}</span>
              </div>
            </div>
          </div>
        );
    }
  }

  return (
    <div className='order-item'>
      <div className='order-item-h' style={{ justifyContent: 'space-between' }}>
        <div className='order-item-hs'>{item.statusName}</div>
        {item.statusName === '待付款' &&
          <Button size='small' onClick={() => onDelete(item.orderId)}>删除</Button>
        }
      </div>
      <div className='order-item-c' onClick={() => onClick(item)}>
        {renderItem(item)}
        <div className='order-item-cf'>
          {item.itemType == 2 && (
            <span>
              <span>共{total}件</span>&nbsp;
              {item.statusName === '待付款' ? '待付款' : '实付款'}
              <span className="bold">{`￥${item.actualCost}`}</span>
            </span>
          )}
        </div>
      </div>
      <div className='order-item-f'>
        <div>
          {item.orgName}
        </div>
        <div
          className='order-item-fs'
          onClick={() => onClickStatus(item.invoiceStatus, item.orderId)}
        >
          {item.invoiceStatusName}
        </div>
      </div>
    </div>
  );
}
