import * as React from 'react';
import './MyCollection.css'
import { ServiceOrder } from '../../../service/order'
import { Spin } from 'antd';
/**
 * 数字藏品
 * @returns 
 */
interface CollectionDetailProp {
  orderId: string
}

function CollectionDetail(props: CollectionDetailProp) {
  const [nfrData, setNfrData] = React.useState<any>({})
  const [loadding, setLoadding] = React.useState<boolean>(true)


  React.useEffect(() => {
    props.orderId && toDetail()
  }, [props.orderId]);

  async function toDetail() {
    const { nft } = await ServiceOrder.orderQuery(props.orderId);
    setNfrData(nft[0])
    setLoadding(false)
  }

  function goLink(key, nftId) {
    window.location.href = key + nftId;
  }

  const spotlight = React.useMemo(() => {
    let icons = JSON.parse(localStorage.getItem('icons') || '{}');
    return icons['Spotlight'];
  }, []);

  const {
    fileUrl,
    contractAddress,
    name,
    accountName,
    releaseTime,
    serialId,
    nfrIssuer,
    nfrProtocol,
    publisherLogo,
    publisherName,
    creatorLogo,
    creatorName,
    hash,
    releaseCount,
    createTime,
    transactionHash,
    platform,
    nftId,
  } = nfrData;

  return (
    <Spin spinning={loadding}>
      <div
        className='nft-top'
        style={{ backgroundImage: `url(${spotlight})` }}
      >
        <img src={nfrData.fileUrl} alt="" className='nft-image' />
      </div>
      <div className={'info-content'}>
        <div style={{ display: 'flex' }}>
          <div className={'info-item'}>
            <div className={'gold'}>发行数量</div>
            <div className={'black'}>{releaseCount}张</div>
          </div>
          <div className={'info-item'}>
            <div className={'gold'}>拥有者</div>
            <div className={'black'}>{accountName}</div>
          </div>
        </div>

        <div className={'info-item2'}>
          <div className={'left'}>藏品哈希</div>
          <div className={'right'}>{hash || '上链确认中...'}</div>
        </div>

        <div className={'info-card'}>
          <div> 藏品名称: {name} </div>
          <div> ID: {serialId} </div>
          <div> 生成时间: {createTime} </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>发行方: </span>
            <img src={publisherLogo} alt="" className={'logo'} />
            <span>{publisherName} </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>创作者: </span>
            <img src={creatorLogo} alt="" className={'logo'} />
            <span>{creatorName} </span>
          </div>
          <div> 发售日期: {releaseTime} </div>
        </div>

        <div className={'info-card'}>
          {platform === 2 ? (
            // <div> 唯一标识: {nftId || '上链确认中...'} </div>
            <div>
              唯一标识:
              <span
                style={{ color: '#108ee9' }}
                onClick={() => goLink('https://zxscan.qq.com/nft/', nftId)}
              >
                {nftId || '上链确认中...'}
              </span>
            </div>
          ) : (
            <div> 合约地址: {contractAddress || '上链确认中...'} </div>
          )}
          {/* <div> 交易哈希: {transactionHash || '上链确认中...'} </div> */}
          <div>
            交易哈希:
            <span
              style={{ color: '#108ee9' }}
              onClick={() =>
                goLink('https://zxscan.qq.com/transactions/', transactionHash)
              }
            >
              {transactionHash || '上链确认中...'}
            </span>
          </div>
          {platform !== 2 && (
            <div> 合约协议: {nfrProtocol || '上链确认中...'} </div>
          )}
          <div> 认证网络: {nfrIssuer || '上链确认中...'} </div>
        </div>
      </div>
    </Spin>
  );
}
export default CollectionDetail
