import * as React from 'react';
import './index.css'
import { NewsBody } from '../../service/homePage/index'
import { useNavigate } from 'react-router-dom';
import Util from '../../utils';

interface HomeNewProp {
  News: NewsBody[]
  onChange: () => void
}

export function HomeNew(prop: HomeNewProp) {
  const { News, onChange } = prop
  const navigate = useNavigate();
  const icons = JSON.parse(localStorage.getItem('icons') || '{}')
  return (
    <div className="newBox1">
      {
        News?.map((el: NewsBody) =>
          <div className="new-one" onClick={() => { onChange && onChange(); navigate('/new/detail', { state: { id: el.id, tagType: el.tagType } }) }} >
            <div className="new-image">
              <img src={el.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <p className="new-title">{el.name}</p>
            <div className="new_bottom">
              <div className="bottom-left">
                <span></span><span>{el.subTypeName} {el.tag}</span>
              </div>
              <div className="bottom-right">
                <img src={icons['time']} alt="" style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#848484' }} />
                <span>{Number(Util.calculationTime(el?.createTime)) > 24 ? el.createTime?.split(' ')[0] : Number(Util.calculationTime(el?.createTime)) <= 0 ? '1小时内' : Util.calculationTime(el.createTime) + '小时前'}</span>
              </div>
            </div>
          </div>
        )
      }

    </div>
  );
}
