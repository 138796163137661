import * as React from 'react';
import './index.css'

interface honorsProp {
  honors: any[]
}

export function Honr(prop: honorsProp) {
  let { honors } = prop
  let thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')

  let backgroundImage = ''
  thmem?.images?.map((el: any) => {
    if (el.imageType == 2) {
      backgroundImage = el.imageUrl
    }
  })

  return (
    honors?.length > 0 ?
      <div className="honr-box" style={{ backgroundImage: `url(${backgroundImage})` }}>
        {
          honors?.map((el: any, index: number) =>
            <div key={index} className="img-one">
              <img src={el.image} alt="" style={{ width: '100%' }} />
            </div>
          )
        }
      </div>
      : <div > </div>
  );
}
