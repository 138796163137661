
import * as React from 'react';
import './index.css'
import { ServicePay, wxPayResult } from '../../../../service/pay'
import { QueryOrderInfo, ServiceOrder } from '../../../../service/order';
import { AccountDetailInfo, ServiceAccount } from '../../../../service/account';
import { ServiceMember } from '../../../../service/member/member';
import { Button, message,Modal,List,Radio } from 'antd';
import { Link } from "react-router-dom";//引入routerdom
import { localStorageEnum } from '../../../../enum';
import Util from '../../../../utils';
import QRCode from 'qrcode';
const wechatImg = require('../../../../assets/image/wechat.png');
const alipayImg = require('../../../../assets/image/ai_pay.png');
const walletImg = require('../../../../assets/image/wallet.png');
enum PayWayEnum {
    wechat = 1,
    ali = 2,
    wallet = 3,
    none = 0,
  }
interface PayMentProp{
    orderId:string
    onCancel:()=>void
}
let isDone = false;
async function getPayStatus(orderId) {
  try {
    const status = await ServicePay.getPayStatus(orderId);

    if (!status) {
      await Util.sleep(1000);
      // 轮询结果,在页面跳转的时候停止轮询
      if (!isDone) {
        await getPayStatus(orderId);
      }
      return;
    }
    isDone = true;
    //本地开发环境是没有/szfc/html/done.html文件的,因为这个文件是静态文件不会请求服务器,这是服务器的文件路径,
    const base = localStorage.getItem('currentDomain');
    window.location.href = `/szfc/html/done.html`;
  } catch (e:any) {
    message.error(e.message);
  }
}
export function PayMent(prop:PayMentProp){
    let {orderId,onCancel} = prop
    const from = React.useRef(null)
    const [information, setInformation] = React.useState<QueryOrderInfo | null>(null)
    const [way, setWay] = React.useState<PayWayEnum>(PayWayEnum.wechat);
    const [userInfo, setUserInfo] = React.useState<AccountDetailInfo>({detail: {},} as any);
    const [times, setTimes] = React.useState<any>({} as any);
    const [qrcode, setQrCode] = React.useState<string>('');
    /**
 * 微信支付
 * @param orderId
 */
async function wxPay(orderId: string) {
    try {
      //  不是微信浏览器时弹出提示用户
      const ua = navigator.userAgent.toLowerCase();
      if (!ua.includes('micromessenger')) {
        // return message.error('请在微信客户端打开链接', 'fail');
      }
      const data = await ServicePay.wechatPay(orderId); //现网环境的微信付款
      if(data){
        let qrcode = await QRCode.toDataURL(data?.orderString?.code_url);
        setQrCode(qrcode)
      }   
    } catch (e:any) {
      message.error(e.message);
    }
  }
  
  /**
   * 支付宝支付
   * @param orderId
   */
  async function aliPay(orderId: string) {
    try {
      
        const res = await ServicePay.aliPayByOtherBrowser(orderId);
        let from1  = res?.orderString as string
        // let ht= from as HTMLElement
        // from.innerHTML=from1;
        //@ts-ignore
        from.current.innerHTML = from1
        document.forms[0].submit()
    } catch (e:any) {
        message.error(e.message);
    }
  }
  
  /**
   * 钱包支付
   * @param orderId
   */
  async function walletPay(orderId: string): Promise<void> {
    ServiceMember.payoff(orderId)
      .then(res => {
        // getPayStatus(orderId);
      })
      .catch(err => {
        message.error(err.message);
      });
  }
    function payment() {
    switch (way) {
        case PayWayEnum.none:
            Modal.confirm({
                title:'提示',
                content:'请先选择支付方式!',
                cancelText:'取消',
                okText:'确认',
            })
        return 
        case PayWayEnum.wechat:
        return wxPay(orderId);
        case PayWayEnum.ali:
        return aliPay(orderId);
        case PayWayEnum.wallet:
        return walletPay(orderId);
    }
    }
    function setCountDown(time: string) {
      const times1 = setInterval(() => {
        setTimes( Util.showtime(time) )
      }, 1000)
    }
    React.useEffect(() => {
      let isDone = false;
      async function getData() {
        try {
          const detail = await ServiceOrder.queryOrder(orderId as string);
          if (detail === null) {
            Modal.confirm({
              title:'提示',
              content:'订单已过期',
              cancelText:'取消',
              okText:'确认',
              onOk:()=>{
                onCancel()
              }
            })
          
            return 
            // expireTime
          }
          setCountDown(detail?.expireTime)
          setInformation(detail);
          
        } catch (err:any) {
          message.error(err.message);
        }
      }
      // loopOrder
      async function getPayStatus(orderId) {
        try {
          const status = await ServicePay.getPayStatus(orderId);

          if (!status) {
            await Util.sleep(1000);
            // 轮询结果,在页面跳转的时候停止轮询
            if (!isDone) {
              await getPayStatus(orderId);
            }
            return;
          }
          window.location.href = `/szfc/html/done.html`;
        } catch (e:any) {
          message.error(e.message);
        }
      }

      async function getUserInfo() {
        try {
          const res = await ServiceAccount.getAccountDetail();
          setUserInfo(res);
        } catch (e:any) {
          message.error(e.message);
        }
      }

      getUserInfo();
      getPayStatus(orderId);
      getData();

      return () => {
        isDone = true;
      };
    }, []);

    return(
      <div>
        {
        qrcode ?
        <div style={{ width: '100%', margin: '30px auto', textAlign: 'center' }}>
          <h4>微信支付 </h4>
          <span> 支付剩余时间 </span>
            {
              JSON.stringify(times) != '{}' &&
              <span>{times?.minute || ''}:{times?.second}</span>
            }
          <img src={qrcode} alt="" />
        </div>
        :
        <div >
          <div className="payment_card">
            <span> 支付剩余时间 </span>
            {
              JSON.stringify(times) != '{}' &&
              <span>{times?.minute || ''}:{times?.second}</span>
            }
            <div className='payment_money'>
              {Util.toFixed(information ? information.actualCost : 0)}
            </div>
            <div className='payment_info'>{information && information.itemName}</div>
          </div>
          <PayWaySelect way={way} onChange={setWay} userInfo={userInfo} />
          <div style={{width:'100%',textAlign:'center'}}>
            <Button className='payment_footer' onClick={payment}>
              确认支付 ￥{Util.toFixed(information ? information.actualCost : 0)}
            </Button>
          </div>
        </div>
        }
          <div ref={from} style={{opacity:0}}></div>
      </div>
      
    )
}
interface PayWaySelectProps {
    way: PayWayEnum;
    onChange: (way: PayWayEnum) => void;
    userInfo: any;
  }
  
  function PayWaySelect(props: PayWaySelectProps) {
    const { way, onChange, userInfo } = props;
    const imageStyle = {
      width: '35px !important',
      height: '35px !important',
      marginRight: 20,
    };
    let look = 0
    return (
        <List style={{ paddingLeft: '10px', backgroundColor: '#fff' }}>
        <List.Item >
          <List.Item.Meta
            style={{ width: '100%' }}
            title={
              <div onClick={() => onChange(PayWayEnum.wechat)} style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                  <Radio checked={way === PayWayEnum.wechat}>
                  </Radio>
                  <img style={imageStyle} src={wechatImg} />
                  <div>微信支付</div>
              </div>
            }
          />
          <div onClick={() =>onChange(PayWayEnum.wechat)} style={{ width: '65%', height: '28px' }}></div>
        </List.Item>
        <List.Item >
          <List.Item.Meta
            style={{ width: '100%' }}
            title={
              <div onClick={() => onChange(PayWayEnum.ali)} style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
              <Radio checked={way === PayWayEnum.ali} >
                 </Radio>
                  <img style={imageStyle} src={alipayImg} />
                  <div>支付宝</div>
                  </div>
              
            }
          />
          <div onClick={() =>onChange(PayWayEnum.ali)} style={{ width: '65%', height: '28px' }}></div>
        </List.Item>
        <List.Item >
          <List.Item.Meta
            style={{ width: '100%' }}
            title={
              <div onClick={() => onChange(PayWayEnum.wallet)} style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
              <Radio checked={way === PayWayEnum.wallet}>
              </Radio>
                  <img style={{width:'58px',height:'58px',marginRight:20}} src={walletImg} />
                  <div>钱包支付</div>
                  </div>
            }
          />
          <div onClick={() =>onChange(PayWayEnum.wallet)} style={{ width: '65%', height: '28px' }}></div>
        </List.Item>
      </List>
    );
  }