import * as React from 'react';
import './index.css'
import Util from '../../utils';
import { NavLink as Link } from 'react-router-dom'
import { ServiceHomePage, GamesBody } from '../../service/homePage/index'
class Next extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      list: [] as GamesBody[],
      flag: 1,
      times: {} as any,
      date: new Date(),
      allIcon: JSON.parse(localStorage.getItem('icons') || '{}'),
      thmem: JSON.parse(sessionStorage.getItem('thmem') || '{}')
    }
  }

  componentDidMount() {
    let tipObj = this.refs.tip as HTMLElement
    tipObj.style.setProperty("--div-width", "230%");
    this.getData()

  }
  getData() {
    let { date } = this.state
    ServiceHomePage.getGameList(1).then(res => {
      let gameTime: string = ''
      res.games?.map((el: any) => {
        if (!gameTime && Util.checkEndTime(date, el.gameTime)) {
          gameTime = el.gameTime
          return
        }
      })
      if (gameTime) {
        this.setCountDown(gameTime)
      } else {
        this.setState({ times: {} })
      }
      this.setState({ list: res.games }, () => {
        this.click2()
      })
    })
  }
  setCountDown(time: string) {
    const times1 = setInterval(() => {
      this.setState({ times: Util.showtime(time) })
    }, 1000)
  }
  click1() {
    let tipObj = this.refs.tip as HTMLElement
    this.setState({ flag: 1 })
    this.setNow();
    tipObj?.classList.remove("animation-center");
    tipObj?.classList.remove("animation-end");
    tipObj?.classList.add("animation-begin")

  }

  click2() {
    this.setState({ flag: 2 })
    this.setNow();
    let tipObj = this.refs.tip as HTMLElement
    tipObj?.classList.remove("animation-begin");
    tipObj?.classList.remove("animation-end");
    tipObj?.classList.add("animation-center")
  }

  click3() {
    this.setState({ flag: 3 })
    this.setNow()
    let tipObj = this.refs.tip as HTMLElement
    tipObj?.classList.remove("animation-center")
    tipObj?.classList.remove("animation-begin")
    tipObj?.classList.add("animation-end")
  }

  setNow() {
    let tipObj = this.refs.tip as HTMLElement
    //@ts-ignore
    let translates = document.defaultView.getComputedStyle(tipObj, null).transform;
    let translateX = parseFloat(translates.substring(7).split(',')[4]);
    tipObj.style.setProperty("--begin-let", translateX + "px");
  }

  goDetail(item) {
    this.props.history.push({
      pathname: '/choose/ticket/list',
      state: {
        gameId: item.gameId
      }
    })
  }

  render() {
    let { flag, list, thmem, date, times, allIcon } = this.state
    let background = ''
    thmem?.images?.map((el: any) => {
      if (el.imageType == 8) {
        background = el.imageUrl
      }
    })
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')

    return (
      <section className="js-slider matches-scroller">
        {
          JSON.stringify(times) !== '{}' ?
            <header className="widget_header" style={{ marginBottom: '25px' }}>
              <h3 className="widget_title">
                比赛
              </h3>
              <div className="countdow countdown--header">
                <div className="countdown_content" style={{ display: 'flex' }}>
                  <div className="countdown_title-container">
                    <span className="countdown_title">距离下一场比赛</span>
                  </div>
                  <div className="countdown__clock1 js-countdown-clock">
                    <div className="countdown__clock1-item days is-zero">
                      <div className="countdown__count">
                        <span className="countdown__value1 js-countdown-days" style={{ background: `linear-gradient(90deg,#a71c3f,#712a59)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', color: '#fff' }}>{times?.day}</span>
                        <span className="countdown__separator">:</span>
                      </div>
                      <div className="countdown__count-label1" >天</div>
                    </div>

                    <div className="countdown__clock1-item hours">
                      <div className="countdown__count">
                        <span className="countdown__value1 js-countdown-hours" style={{ background: `linear-gradient(90deg,#a71c3f,#712a59)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', color: '#fff' }}>{times?.hour}</span>
                        <span className="countdown__separator">:</span>
                      </div>
                      <div className="countdown__count-label1" >时</div>
                    </div>

                    <div className="countdown__clock1-item minutes">
                      <div className="countdown__count">
                        <span className="countdown__value1 js-countdown-minutes" style={{ background: `linear-gradient(90deg,#712a59,#3a3872)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', color: '#fff' }}>{times?.minute}</span>
                        <span className="countdown__separator">:</span>
                      </div>
                      <div className="countdown__count-label1" >分</div>
                    </div>

                    <div className="countdown__clock1-item seconds">
                      <div className="countdown__count">
                        <span className="countdown__value1 js-countdown-seconds" style={{ background: `linear-gradient(90deg,#3a3872,#154284)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', color: '#fff' }}>{times?.second}</span>
                      </div>
                      <div className="countdown__count-label1" >秒</div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            : ''
        }
        <div className="con">
          <div style={{ width: '100%' }}>
            <div ref='tip' className="div">
              {/* <Link to={{ pathname: `/club` }} > */}
              <Link to={{ pathname: `/club` }} className="line " style={{ backgroundImage: `url(${background})` }}>
              </Link>
              {/* </Link> */}
              {
                list?.map((el: GamesBody, index: number) =>
                  <div className="line " key={el.gameId + index}>
                    <header className="header-item" style={{ backgroundColor: Util.checkEndTime(date, el.gameTime) ? 'black' : '#124584' }}>
                      <h3 className="data-day">{el.gameTime?.split(' ')[0]?.split('-')[2]}</h3>
                      <div className="item-data">
                        <p className='data-weekday'>{Util.getWeek(el.gameTime?.split(' ')[0])}</p>
                        <p className='data-month'>{Util.getMonth(el.gameTime?.split(' ')[0]?.split('-')[1])}</p>
                      </div>
                    </header>
                    <div className="match">
                      <div className="match-top">
                        <div className="match-name">
                          <div className="left-img">
                            <img src={el.leagueLogo || allIcon['match']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                          </div>
                          <p style={{ fontWeight: 600, fontSize: '13px', margin: '0 5px' }}>{el.leagueName}</p>
                        </div>
                        <div className="match-turn">第{el.turn}轮</div>
                      </div>
                      <div className="match-team">
                        <div className="home-team">
                          <div className="home-img" style={{ width: index == 2 ? '70px' : '50px', height: index == 2 ? '70px' : '50px' }}>
                            <img src={el.hostLogo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                          </div>
                          {
                            index == 2 &&
                            <p style={{ fontWeight: 'bold' }}>{el.hostTeam}</p>

                          }
                        </div>
                        <div className="match-score">
                          {
                            Util.checkEndTime(date, el.gameTime) &&
                            <div style={{ marginBottom: '0', fontSize: '14px', fontWeight: 'bold' }}>开球时间</div>
                          }
                          {
                            Util.checkEndTime(date, el.gameTime) ?
                              <div style={{ fontSize: '32px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ background: `linear-gradient(90deg,#a71c3f,#712a59)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', color: '#fff' }}>{el.gameTime?.split(' ')[1].substring(0, 2)}</div>
                                <div style={{ width: '16px', textAlign: 'center' }}>:</div>
                                <div style={{ background: `linear-gradient(90deg,#712a59,#3a3872)`, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', color: '#fff' }}>{el.gameTime?.split(' ')[1].substring(3, 5)}</div>
                              </div>
                              :
                              <p className="score">{el.result?.split(':')[0]} - {el.result?.split(':')[1]}</p>
                          }
                        </div>
                        <div className="clien-team">
                          {
                            index == 2 &&
                            <p>{el.clientTeam}</p>

                          }
                          <div className="clien-img" style={{ width: index == 2 ? '70px' : '50px', height: index == 2 ? '70px' : '50px' }}>
                            <img src={el.clientLogo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                          </div>
                        </div>
                      </div>
                      <div className="team-info">
                        <div className="team-name" style={{ opacity: index !== 2 ? 1 : 0 }}>{el.hostTeam} VS {el.clientTeam}</div>
                        <div className="match-address">{el.stadium}</div>
                      </div>
                    </div>
                    <div className="bottom" >
                      {
                        Util.checkEndTime(date, el.gameTime) &&
                        <Link to={{ pathname: `/choose/ticket/list/${el.gameId}` }} >
                          <span onClick={() => sessionStorage.setItem('headerActive', menuArray.indexOf('/ticket'))}>购票</span>
                        </Link>
                        // <span  onClick={()=>this.goDetail(el)}></span>
                      }
                    </div>

                  </div>
                )
              }
              <Link className="line " style={{ backgroundImage: `url(${background})` }} to={{ pathname: `/club` }} >
                {/* <div className="line " style={{ backgroundImage: `url(${background})` }}>
                </div> */}
              </Link>

            </div>
            <div className="pointer-button">
              <div className="button-box">
                <p className={flag === 1 ? "active" : ""} onClick={() => this.click1()}></p>
                <p className={flag === 2 ? "active" : ""} onClick={() => this.click2()}></p>
                <p className={flag === 3 ? "active" : ""} onClick={() => this.click3()}></p>
              </div>
              {/* <button id="sildeInput1" onClick={() => this.click1()}>1</button>
              <button onClick={() => this.click2()}>2</button>
              <button onClick={() => this.click3()}>3</button> */}
            </div>
          </div>
          {/* {
            false &&
            <div style={{ width: '100%', color: thmem?.ticketColor1, marginBottom: '20px' }}>
              <div className="ticket-top__competition-logo" style={{ fontSize: '25px' }}>
                <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="competition-logo" style={{ marginRight: '5px' }}>
                    <img src="" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </div>
                  <p>中超联赛</p>
                </div>
                <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="competition-logo" style={{ marginRight: '5px' }}>
                    <img src="" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </div>
                  <p>足协杯</p>
                </div>
              </div>
              <div className="ticket-top__competition-logo" style={{ height: '10rem' }}>
                <div className="competition-logo" style={{ marginRight: '5px', width: '10rem', height: '10rem' }}>
                  <img src="" alt="" style={{ width: '100%', height: '100%' }} />
                </div>
                <h1 style={{ fontSize: '40px', color: thmem?.ticketColor2 }}>2020赛季主场套票</h1>
              </div>
            </div>
          } */}
        </div>
      </section>

    );
  }
}
export default Next