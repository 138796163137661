import * as React from 'react';
import './clubBenfits.css'
import Login from '../../loginHome/index'
import Util from '../../../utils';
import { TitleList } from '../../../component/TitleList/index'
import { ServiceClubbenefits } from '../../../service/clubBenefits';
import { NoData } from '../../../component/noData';
import { Button, message, Spin, Modal, Pagination } from 'antd';
import { deduplication, subStr, subStrEllipsis } from '../../../utils/tool';
/**
 * 数字藏品
 * @returns 
 */
function ClubBenfits() {
  const [active, setActive] = React.useState<number>(0)
  const [totalElements, setTotalElements] = React.useState<number>(0)
  const [pageNum, setPageNum] = React.useState<number>(1)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [list, setList] = React.useState<any[]>([])
  const [info, setInfo] = React.useState<any>({});
  const [show, setShow] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const buttons = ['已领取', '立即领取', '无法领取'];
  const [type, setType] = React.useState<any>(undefined)
  const [orderId, setOrderId] = React.useState<any>(undefined)
  const [loadding, setLoadding] = React.useState<boolean>(false)
  const allIcon = JSON.parse(localStorage.getItem('icons') || '{}')
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const titleList = [
    { id: 0, name: '全部' },
    { id: 1, name: '已领取' },
    { id: 2, name: '待领取' },
  ]
  const USER_INFO = JSON.parse(localStorage.getItem('USER_INFO') || '{}')
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  function getData(pageNum, pageSize) {
    window.scrollTo(0, 0)
    setLoadding(true);
    ServiceClubbenefits.wfList({ pageSize, pageNum, tag: active + '' })
      .then(res => {
        setList(res.content)
        setPageNum(pageNum)
        setPageSize(pageSize)
        setTotalElements(res.totalElements)
      })
      .catch(e => {
        message.error(e.message);
      })
      .finally(() => {
        setLoadding(false);
      });
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setLoadding(false)
      return
    }
    getData(1, pageSize)
  }, [active]);

  function onChange(key: number) {
    setActive(key)
  }

  function clickOrderItem(item): void {
    setOrderId(item.orderId)
    setShow(true)
    setType(item.itemType === 6 ? 1 : 0)
    //藏品订单详情与周边商品订单详情不一样
    // const path =
    //   item.itemType === 6
    //     ? ROUTER_NAME.COLLECTION_ORDER_DETAIL.path
    //     : ROUTER_NAME.ORDER_INFO.path;
    // Util.jumpPage(path, { status: currentTab, id: item.orderId });
  }
  function cancel() {
    if (type == 0 || type == 1) {
      setShow(false)
      setOrderId(undefined)
      setType(undefined)
    } else {
      setType(0)
    }
  }
  /**
  * 1、判断是否登录
  *  1.1 已登录 =》判断是否可领取
  *    1.1.1 可领取：跳转到领取页面
  *    1.1.2 不可领取：弹框提示 后台返回的不可领取原因
  *  1.2 没登录 提示登录 不能领取
  *
  */
  function submit() {
    setIsSubmit(true);
  }
  function receiveSubmit() {
    ServiceClubbenefits.wfSubmit(info.ttpType, info.ttpId, info.phone)
      .then(res => {
        message.success('领取成功');
        let info1 = Object.assign({}, info);
        info1.tag = 1;
        setInfo(info1);
        setIsSubmit(false);
      })
      .catch(err => {
        // message.error(err.message)
        Modal.confirm({
          title: '领取失败',
          content: err.message,
          okText: '确认',
          cancelText: '取消',
          bodyStyle: { margin: '10px' },
        })
      });
  }
  function bindReceive(item: any) {
    setIsSubmit(true)
    setInfo({ ...item })
  }

  function setInfoObject(item) {
    let newContent = item?.description.replace(/\<img/gi, '<img style="width:100%;objectFit:cover"');
    item.description = newContent
    setInfo(item); setShow(true)
  }

  return (
    <Spin spinning={loadding}>
      <div className="container1"></div>
      <TitleList active={active} titleList={titleList} onChange={(e) => onChange(e)} />
      {
        list?.length > 0 ?
          <div style={{ width: '100%' }}>
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center' }}>
              {
                list?.map(item =>
                  <div
                    className='club-cards '
                    onClick={() => setInfoObject(item)}
                  >
                    <img src={item.image} alt="" style={{ height: '100%', objectFit: 'cover' }} />
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        margin: '0 auto'
                      }}
                    >
                      {item.tag === 1 && (
                        <div className="sign">已领取</div>
                      )}
                      {item.tag === 2 && (
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            bindReceive(item);
                          }}
                          className="button"
                        >
                          <p>领取</p>
                        </div>
                      )}
                      {item.tag === 3 && (
                        <div className="sign">不可领取</div>
                      )}
                    </div>
                  </div>
                )
              }
            </div>
            <div className='goods-pagination'>
              <Pagination
                pageSize={pageSize}
                size="small"
                current={pageNum}
                total={totalElements}
                onChange={(page, pageSize) => { getData(page, pageSize) }}
                onShowSizeChange={(current, size) => { getData(current, size) }}
              />
            </div>
            <Modal
              title={info?.name}
              visible={show}
              footer={null}
              style={{ padding: 'none', overflow: 'unset' }}
              onCancel={() => cancel()}
            >
              {isSubmit && (
                <div
                  style={{
                    width: '100%',
                    overflow: "hidden"
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '40px',
                      backgroundColor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '5%',
                      marginTop: '5px',
                      fontWeight: 500,
                    }}
                  >
                    <p>手机号码：</p>
                    <p>{USER_INFO?.detail?.phone}</p>
                  </div>
                  <div
                    style={{ width: '90%', margin: '20px auto', color: '#919191' }}
                  >
                    请确认使用该手机号码，将为您提交注册成为第三方平台的福利用户
                  </div>
                  <Button
                    onClick={() => receiveSubmit()}
                    style={{
                      background: '#c31c32',
                      color: 'white',
                      position: 'fixed',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                    }}
                    size="large"
                  >
                    领取福利
                  </Button>
                </div>
              )}
              {!isSubmit && (
                <div>
                  <div
                    id="content"
                    style={{
                      padding: '20px 10px 41px 10px',
                      backgroundColor: 'white',
                    }}
                    dangerouslySetInnerHTML={{ __html: (!isSubmit && info?.description) || '' }}
                  />
                  <Button
                    onClick={() => submit()}
                    disabled={info?.tag != 2}
                    style={{
                      background: info?.tag === 2 ? '#c31c32' : '#333333',
                      color: 'white',
                      width: '94%',
                    }}
                    size="large"
                  >
                    {buttons[info?.tag - 1]}
                  </Button>
                </div>
              )}
            </Modal>
            {
              isLogin &&
              <Login onLogin={() => { getData(1, pageSize); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
            }
          </div>
          :
          <NoData show={true} />
      }


    </Spin>
  );
}
export default ClubBenfits