import './index.css';
import * as React from 'react';

/**
 * @author: hukun
 * @Date: 2019-06-05 11:52
 * @description
 */

interface RenderGoodsCellProps {
  title: string;
  header: string;
  footer: string;
}

function RenderGoodsCell(props: RenderGoodsCellProps) {
  const { title, footer, header } = props;
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}
      className='goods-col'
    >
      <div className='col-title'>{title}</div>
      <div className='cell'>
        <span>{header}</span>
        <span className='cell-b' />
        <div>{footer}</div>
      </div>
    </div>
  );
}

interface RenderCellProps {
  header: string;
  footer: string | number;
  red?: boolean;
}

function RenderCell(props: RenderCellProps) {
  const { header, footer, red } = props;
  return (
    <div className='cell'>
      <span>{header}</span>
      <span className='cell-b' />
      <div className={red ? 'red' : ''}>{footer}</div>
    </div>
  );
}

function getTime(s, e) {
  const start = new Date(s).getTime();
  const end = new Date(e).getTime();
  return Math.floor((end - start) / 1000);
}

function toFixed(num: number) {
  return num ? num.toFixed(2) : '0.00';
}

function getStatusName(type) {
  switch (type) {
    case 1:
      return '待付款';
    case 2:
      return '待收货';
    case 5:
      return '已完成';
    case 4:
      return '待收货';
    default:
      return '已完成';
  }
}

export { RenderGoodsCell, RenderCell, getTime, toFixed, getStatusName };
