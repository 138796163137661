/**
 * @author: hukun
 * @Date: 2019-05-24
 * @Time: 16:05
 * @function
 */
import { Net } from '../../utils/net';
import { localStorageEnum } from '../../enum';
import exp from 'constants';

export interface GameListBody {
  games: GamesBody[]
  packages: []
}
export interface GameTopBody {
  game: {
    clientLogo: string
    clientTeam: string
    createTime: string
    gameId: number
    gameTime: string
    hostLogo: string
    hostTeam: string
    leagueId: string
    leagueLogo: string
    leagueName: string
    name: string
    onSale: boolean
    released: boolean
    result: string
    stadium: string
    seasonName: string
    setId: number
    turn: number
  }
  gameTime: string
  ticket: {
    ticketId: number
    logo: string
    name: string
    teamLogo: string
    leagues: [
      {
        leagueName: string
        seasonName: string
        logo: string
      }
    ]
  }
}

export interface GamesBody {
  clientLogo: string
  clientTeam: string
  createTime: string
  gameId: number
  gameTime: string
  hostLogo: string
  hostTeam: string
  leagueId: number
  leagueLogo: string
  leagueName: string
  name: string
  onSale: boolean
  released: boolean
  result: string
  seasonName: string
  setId: number
  turn: number
  stadium: string
}

export interface TicketBody {
  abbrName: string
  accountMaxAmount: number
  accountMinAmount: number
  afterSaleType: number
  backImage: string
  childAllowed: boolean
  childHeight: number
  contentImages: any[]
  description: string
  detail: string
  forms: any[]
  frontImage: string
  id: number
  invoiceSupply: boolean
  invoiceType: number
  itemType: number
  leagues: any[]
  leftAmount: number
  logo: string
  maxPromPrice: number
  maximumAmount: number
  maximumPrice: number
  mimPromPrice: number
  minimumAmount: number
  minimumPrice: number
  name: string
  notes: string
  orderMaxAmount: number
  orderMinAmount: number
  possessed: boolean
  reserveNotes: string
  restrictAccount: boolean
  restrictMount: number
  restricted: boolean
  saleType: number
  seatchoosable: boolean
  sections: []
  selectable: boolean
  stadiumId: number
  stadiumSvgUrl: string
  status: number
  statusStr: string
  ticketId: number
  tktClazz: number
  tktSubClazz: number
  topImages: any[]
  totalAmount: null
  useSvg: boolean
  orgInfo: {
    orgId: number
    orgName: string
    orgLogo: string
    orgDomain: string
    clientTitle: string
  }
}

export interface NewsBody {
  createTime: string
  id: number
  logo: string
  name: string
  shareAmount: number
  showAmount: boolean
  statusTag: string
  subType: number
  subTypeName: string
  tag: string
  tagType: number
  top: boolean
  touchAmount: number
  url: string
}
export interface ActiveBody {
  createTime: string
  id: number
  logo: string
  name: string
  shareAmount: number
  showAmount: boolean
  statusTag: string
  subType: number
  subTypeName: string
  tag: string
  tagType: number
  top: boolean
  touchAmount: number
  url: string
  endTime: string
  startTime: string
  orgInfo: {
    orgId: number
    orgName: string
    orgLogo: string
    orgDomain: string
    clientTitle: string
  }
}
export interface MenuBody {
  children: MenuBody[]
  menuType: number
  name: string
  url: string
}
const domain = localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]

export class ServiceHomePage {

  /**
   * 获取菜单
   */
  public static getMenus(): Promise<MenuBody[]> {
    return Net(`/api/w/home/menus?domain=${domain}`).then(
      res => res.data.menus
    );
  }
  /**
   * 顶部照片
   * @returns  
   */
  public static getHomeTop() {
    return Net(`/api/w/home/top?domain=${domain}`).then(
      res => res.data.top
    );
  }
  /**
   * 获取赞助商列表
   */
  public static getSponsors() {
    return Net(`/api/w/home/sponsors?domain=${domain}`).then(
      res => res.data.sponsors
    );
  }

  /**
   * 获取荣誉列表
   */
  public static getHonors() {
    return Net(`/api/w/home/honors?domain=${domain}`).then(
      res => res.data.honors
    );
  }

  /**
   * 俱乐部配置信息
   */
  public static getTheme() {
    return Net(`/api/w/home/theme?domain=${domain}`).then(
      res => res.data.theme
    );
  }

  /**
   * 比赛列表
   * m:1、首页查看5场比赛；2、比赛首页查看所有未来比赛
   */
  public static getGameList(m: any): Promise<GameListBody> {
    return Net(`/api/w/game/list?domain=${domain}&m=${m}`).then(
      res => res.data
    );
  }
  public static getGameList2(gameId: number): Promise<TicketBody[]> {
    return Net(`/api/w/ticket/list?domain=${domain}&gameId=${gameId}`).then(
      res => res.data.tickets
    );
  }
  public static getGameTop(params: any): Promise<GameTopBody> {
    return Net(`/api/w/game/top`, null, { params }).then(
      res => res.data
    );
  }
  /**
   * 票品列表
   */
  public static getGameTicket(gameId: any) {
    return Net(`/api/w/game/tickets?domain=${domain}&gameId=${gameId}`).then(
      res => res.data.tickets
    );
  }

  /**
   * /api/w/home/news?domain=szfc
   */
  public static homeNews(): Promise<NewsBody> {
    return Net(`/api/w/home/news?domain=${domain}`).then(
      res => res.data.news
    );
  }
  ///api/w/home/activity?domain=szfc
  public static homeActivity(): Promise<any> {
    return Net(`/api/w/home/activity?domain=${domain}`).then(
      res => res.data.activity
    );
  }

  //友情链接
  public static links(): Promise<any> {
    return Net(`/api/w/home/links?domain=${domain}`).then(
      res => res.data.links
    );
  }
}
