import * as React from 'react';
import './index.css'
import { HomeActive } from '../home/active'
import { ActiveBody } from '../../service/homePage';
import Util from '../../utils/index'
import { PropertySafetyFilled } from '@ant-design/icons';

interface ContentMessageProp {
  articleList: any[],
  type: number //是新闻还是活动
  onClick: () => void
}

export function ContentMessage(prop: ContentMessageProp) {
  let { articleList, type, onClick } = prop
  let thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  let background = ''
  thmem?.images?.map((el: any) => {
    if (el.imageType == 6) {
      background = el.imageUrl
    }
  })
  return (
    <div className="new_content">
      {articleList?.length > 0 &&
        articleList?.map((el: any) =>
          <div className="content-info" key={el?.list[0].id + el?.time}>
            <div className="split-line" style={{ backgroundImage: `url(${background})` }}></div>
            <div className="info-box">
              <div className="data-info">
                <div className="data-day">{Util.getMonthDay(el.time)}</div>
                <div className="data-week">{Util.getWeek(el.time)}</div>
              </div>
              {
                <div style={{ width: '100%' }}>
                  {
                    el?.list?.map((item: any) =>
                      <HomeActive key={item.id} activity={item} type={type} />
                    )
                  }
                </div>
              }

            </div>
          </div>
        )
      }

      {/* 查看更多按钮 */}
      <div className="see-more" onClick={() => onClick()}>
        <span className="see-more-button" style={{ backgroundColor: thmem?.background1, color: thmem?.fontColor1 }}>查看更多</span>
      </div>
    </div>
  );
}
