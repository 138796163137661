import * as React from 'react';
import './index.css'
import { ServiceHomePage } from '../../service/homePage/index';
import { ServiceArticle, queryBody } from '../../service/article/index';
import { Honr } from '../../component/honr/index';
import { Sponsors } from '../../component/sponsors/index'
import { PictureBackground } from '../../component/PictureBackground/index'
import { ContentMessage } from '../../component/ContentMessage/index'
import { TitleList } from '../../component/TitleList/index'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
export function NewDetail() {
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const Icons = JSON.parse(localStorage.getItem('icons') || '{}')
  let location = useLocation()
  const navigate = useNavigate()
  const [article, setArticle] = React.useState<any>({})
  const [tagType, setTagType] = React.useState<any>(undefined)
  const [clientHeight, setClientHeight] = React.useState<string>('')
  const params = useParams()

  // const [searchParams, setSearchParams] = useSearchParams();
  React.useEffect(() => {// 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    sessionStorage.setItem('headerActive', menuArray?.indexOf('/new'))
    let state: any = location.state
    setTagType(state?.tagType || params?.tagType)
    function init() {
      console.log(state?.tagType);
      let tagType = state?.tagType || params?.tagType
      if (tagType == 3) {
        ServiceArticle.videoQuery(state?.id || params?.id).then(res => {
          setArticle(res)
        })
      } else {
        ServiceArticle.query(state?.id || params?.id).then(res => {
          setArticle(res)
        })
      }

    }
    init();
  }, []);

  React.useEffect(() => {
    setClientHeight(Number(document.getElementById('topFixed')?.offsetHeight) + 60 + 'px')
  }, [document.getElementById('topFixed')?.offsetHeight])



  return (
    <div style={{ marginTop: '35px', width: '100%', minHeight: '100vh', background: thmem?.background }}>
      <div className="new-detail">
        <div id='topFixed' style={{ width: '70%', position: 'fixed', zIndex: 3, backgroundColor: '#fff' }}>
          <h1 className="title">{article?.name}</h1>
          <div className="new-info">
            <p className="date">{article?.createTime}</p>
            <div className="info-right">
              <p>
                <span>阅读 {article?.touchAmount}</span>
              </p>
              <p>
                <span>分享 {article?.shareAmount}</span>
              </p>

            </div>
          </div>
          <div className='close' onClick={() => navigate('/new')}>
          关闭  <img src={Icons['close']} alt="" style={{width:'25px',height:'25px'}} />
          </div>
        </div>
        <div style={{ width: '100%', height: clientHeight }}>
        </div>
        <div className="new-detail-message">
          {
            tagType == 3
              ?
              <video autoPlay controls src={article?.url}></video>
              :
              <div dangerouslySetInnerHTML={{ __html: article?.description || '' }} />
          }

        </div>

      </div>

    </div >
  );
}