import * as React from 'react';
import './index.css'
import { GameTopBody } from '../../service/homePage/index'
import Util from '../../utils';
import { useLocation, useNavigate } from 'react-router-dom'

interface TicketTopProp {
  top: GameTopBody
  isButtom: boolean
}

export function TicketTop(prop: TicketTopProp) {
  let { top, isButtom } = prop
  let thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  let allIcon = JSON.parse(localStorage.getItem('icons') || '{}')
  const [times, setTime] = React.useState<any>({})
  const { game, gameTime = '', ticket } = top
  const navigate = useNavigate()

  React.useEffect(() => {
    function init() {
      let date = game?.gameTime || gameTime
      if (date?.length > 0 && Util.calculationTime(date) <= 0) {
        setCountDown(date)
      }
    }
    init();
  }, []);
  function setCountDown(time: string) {
    console.log();

    if (Util.calculationTime(time)) {
      const times = setInterval(() => {
        setTime(Util.showtime(time))
      }, 1000)
    }

  }
  function goDetail() {
    // navigate('/choose/ticket/purchase', { state: { id: game.gameId } })
    navigate('/choose/ticket/list', { state: { gameId: game?.gameId || ticket?.ticketId } })
  }
  let background = ''
  thmem?.images?.map((el: any) => {
    if (el.imageType == 5) {
      background = el.imageUrl
    }
  })
  return (
    <div className="ticket-top" style={{ backgroundImage: `url(${background})` }}>
      {
        !ticket && game &&
        <div style={{ width: '100%', color: thmem?.ticketColor1, marginBottom: '20px' }}>
          <div className="ticket-top__competition-logo">
            <div className="competition-logo" style={{ width: '60px', height: '60px', marginRight: '5px' }}>
              <img src={game?.leagueLogo || allIcon['match']} alt="" style={{ width: '100%', height: '100%' }} />
            </div>
            <p>{game?.leagueName} 第{game?.turn}轮</p>
          </div>
          <div className="ticket-date" style={{ color: thmem?.ticketColor2 }}>
            {game?.gameTime?.split(' ')[0]}
          </div>
          <div className="ticket_link">
            <div className="ticket-team ticket-home-team">
              <div className="team-name">{game?.hostTeam}</div>
              <div className="team-logo">
                <img src={game?.hostLogo} alt="" style={{ width: '100%', height: '100%' }} />
              </div>
            </div>
            <div className="ticket-team-content">
              <p className="match-begin" style={{ color: thmem?.ticketColor2, margin: '0' }}>开球时间</p>
              <p className="match-time" style={{ fontWeight: 'bold' }}>{game?.gameTime?.split(' ')[1]?.substring(0, 5)}</p>
              <p className="match-adress">{game?.stadium}</p>
            </div>
            <div className="ticket-team ticket-cline-team">
              <div className="team-logo">
                <img src={game?.clientLogo} alt="" style={{ width: '100%', height: '100%' }} />
              </div>
              <div className="team-name">{game?.clientTeam}</div>
            </div>
          </div>
        </div>
      }
      {
        ticket &&
        <div style={{ width: '100%', color: thmem?.ticketColor1, marginBottom: '20px' }}>
          <div className="ticket-top__competition-logo" style={{ fontSize: '25px' }}>
            {
              ticket.leagues?.map(el =>
                <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="competition-logo" style={{ marginRight: '5px', height: '65px' }}>
                    <img src={el.logo || allIcon['match']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </div>
                  <p>{el.leagueName}</p>
                </div>
              )
            }

            {/* <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
              <div className="competition-logo" style={{ marginRight: '5px' }}>
                <img src={ticket.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              </div>
              <p>{ticket.name}</p>
            </div> */}
          </div>
          <div className="ticket-top__competition-logo" style={{ height: '10rem' }}>
            <div className="competition-logo" style={{ marginRight: '5px', width: '10rem', height: '10rem' }}>
              <img src={ticket.teamLogo} alt="" style={{ width: '100%', height: '100%' }} />
            </div>
            <h1 style={{ fontSize: '40px', color: thmem?.ticketColor2 }}>{ticket.name}</h1>
          </div>
        </div>
      }
      {
        (!ticket && !game) &&
        <div style={{ width: '100%', color: thmem?.ticketColor1, marginBottom: '20px' }}>
          <div className="ticket-top__competition-logo" style={{ height: '10rem' }}>
            <h1 style={{ fontSize: '40px', color: thmem?.ticketColor2 }}>敬请期待</h1>
          </div>
        </div>
      }
      {/*  */}
      {
        (game?.gameTime || gameTime) && JSON.stringify(times) !== '{}' &&
        <div className="countdown gameTime" style={{ color: thmem?.ticketColor1 }} data-widget="countdown" data-timestamp="1647201600000" data-subscribe-elems="[data-widget='fixture-hero']">
          <div style={{ display: 'flex', backgroundImage: `url(${allIcon['Gradient map']})`, paddingLeft: '15px', paddingBottom: '15px' }}>
            <div className="countdown__content countdown__content--no-sponsor" style={{ marginRight: '100px' }}>
              <div className="countdown__title-container" style={{ backgroundColor: '#762647', padding: '0 5px' }}>
                <span className="countdown__title" style={{ fontSize: '1.2rem', margin: 0 }}>距离下一场比赛</span>
              </div>
            </div>
            <div className="countdown__clock1 js-countdown-clock">
              <div className="countdown__clock1-item days is-zero">
                <div className="countdown__count">
                  {/* style={{background: `linear-gradient(90deg,#a71c3f,#712a59)`,WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent',color:'#fff'}} */}
                  <span className="countdown__value1 js-countdown-days" >{times?.day}</span>
                  <span className="countdown__separator">:</span>
                </div>
                <div className="countdown__count-label1" style={{ color: thmem?.ticketColor2, backgroundColor: '#762647', padding: '2px 0' }}>天</div>
              </div>

              <div className="countdown__clock1-item hours">
                <div className="countdown__count">
                  {/* style={{background: `linear-gradient(90deg,#a71c3f,#712a59)`,WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent',color:'#fff'}} */}
                  <span className="countdown__value1 js-countdown-hours"  >{times?.hour}</span>
                  <span className="countdown__separator">:</span>
                </div>
                <div className="countdown__count-label1" style={{ color: thmem?.ticketColor2, backgroundColor: '#762647', padding: '2px 0' }}>时</div>
              </div>

              <div className="countdown__clock1-item minutes">
                <div className="countdown__count">
                  {/* style={{background: `linear-gradient(90deg,#712a59,#3a3872)`,WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent',color:'#fff'}} */}
                  <span className="countdown__value1 js-countdown-minutes">{times?.minute}</span>
                  <span className="countdown__separator">:</span>
                </div>
                <div className="countdown__count-label1" style={{ color: thmem?.ticketColor2, backgroundColor: '#762647', padding: '2px 0' }}>分</div>
              </div>

              <div className="countdown__clock1-item seconds">
                <div className="countdown__count">
                  {/* style={{background: `linear-gradient(90deg,#3a3872,#154284)`,WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent',color:'#fff'}} */}
                  <span className="countdown__value1 js-countdown-seconds" >{times?.second}</span>
                </div>
                <div className="countdown__count-label1" style={{ color: thmem?.ticketColor2, backgroundColor: '#762647', padding: '2px 0' }}>秒</div>
              </div>
            </div>
          </div>

        </div>
      }

      < div className="ticket-top-ticketing">
        <div style={{ flex: 4 }}></div>
        {
          (isButtom && (ticket || game)) &&
          <div className="ticket-cta" onClick={() => goDetail()}>
            购票
          </div>
        }
        <div style={{ flex: 4 }}></div>
      </div>
    </div >
  );
}
