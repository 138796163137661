import { Net } from '../../utils/net';
import { _pageData } from '../common_i';
const domain = localStorage.getItem('DOMAIN') || ''
export interface contentBody {
  avgScore: null;
  background: string;
  configId: number;
  cost: number;
  description: string;
  lastTurn: string;
  logo: string;
  leftBalance: number;
  maxScore: number;
  mine: boolean;
  name: string;
  score: number;
  totalScore: number;
  totalRnk: number;
  exchanges: number;
  props: number;
  current: {
    background: string;
    cost: boolean;
    description: string;
    endTime: string;
    join: boolean;
    startTime: string;
    title: string;
    turnId: number;
    turnName: string;
  };
}
export interface playerBody {
  futures: any;
  jerseyImage: string;
  playerId: number;
  playerName: string;
  portrait: string;
  position: string;
  price: number;
  score: number;
  selectedRatio: number;
  teamLogo: string;
  teamName: string;
  totalIn: number;
  totalOut: number;
  totalScore: number;
}
export class ServiceFantasy {
  public static list(
    params = {} as any // : Promise<_pageData<EnrollBody>>
  ) {
    params.domain = domain
    return Net(`/member/fts/team/query`, null, { params }).then(
      // return Net(`/member/fts/player/listMine`, null, { params }).then(
      res => res.data
    );
  }

  // 范特西首页
  public static configList() {
    return Net(`/member/fts/config/list`, null, {
      params: { domain },
    }).then(res => res.data.list);
  }

  public static configQuery(configId) {
    return Net(`/member/fts/config/query?configId=${configId}`).then(
      res => res.data
    );
  }

  // 参加
  public static configJoin(configId) {
    return Net(
      `/member/fts/config/join?configId=${configId}&domain=${domain}`
    ).then(res => res.data);
  }

  // /member/fts/turn/join
  public static turnJoin(configId, turnId) {
    return Net(
      `/member/fts/turn/join?configId=${configId}&turnId=${turnId}&domain=${domain}`
    ).then(res => res.data);
  }

  // 创建阵容名称
  public static configCreateName(configId, name) {
    return Net(
      `/member/fts/config/createName?configId=${configId}&name=${name}&domain=${domain}`
    ).then(res => res.data);
  }
  // 自动组件
  public static teamAutoReserve(configId) {
    return Net(
      `/member/fts/team/autoReserve?configId=${configId}&domain=${domain}`
    ).then(res => res.data);
  }
  // 提交完成阵容
  public static teamAutoConfirm(configId, turnId, body) {
    return Net(
      `/member/fts/team/autoConfirm?configId=${configId}&turnId=${turnId}&domain=${domain}`,
      body
    ).then(res => res.data);
  }

  // 提交球员交易
  public static playerExchange(pramas, body) {
    return Net(`/member/fts/player/exchange`, body, { params: pramas }).then(
      res => res.data
    );
  }

  // 球星卡 球员卡列表

  public static cardList(configId) {
    return Net(
      `/member/fts/card/list?configId=${configId}&domain=${domain}`
    ).then(res => res.data.list);
  }
  // 我的资产列表 球星卡卡列表

  public static cardListMine() {
    return Net(
      `/member/fts/card/listMine?domain=${domain}`
    ).then(res => res.data.list);
  }

  // 购买球星卡
  public static cardBuy(configId, cardId) {
    return Net(
      `/member/fts/card/buy?configId=${configId}&cardId=${cardId}&domain=${domain}`
    ).then(res => res.data);
  }

  // 道具卡/member/fts/prop/list
  public static propList() {
    return Net(
      `/member/fts/prop/list?domain=${domain}`
    ).then(res => res.data.list);
  }
  // 兑换道具 /member/fts/prop/buy?propId=1&amount=2
  public static propBuy(propId, amount) {
    return Net(
      `/member/fts/prop/buy?propId=${propId}&amount=${amount}&domain=${domain}`
    ).then(res => res.data);
  }

  // 消费记录
  //
  public static ftsMoney() {
    return Net(
      `/member/fts/money/list?domain=${domain}`
    ).then(res => res.data.list);
  }

  // 查询球员库
  /**
   *
   * @param configId configId=3、teamId=34、sort=4、position=门将、name=科、price=20
   * @returns
   */
  public static playerListAll(params = {}) {
    return Net(`/member/fts/player/listAll`, null, {
      params: { ...params },
    }).then(res => res.data.list);
  }

  // 查询球员
  public static playerQuery(configId, playerId) {
    return Net(
      `/member/fts/player/query?configId=${configId}&playerId=${playerId}`
    ).then(res => res.data);
  }
  //这是查询某个球员的得分情况 /member/fts/player/score?configId=3&playerId=34
  public static playerScore(configId, playerId) {
    return Net(
      `/member/fts/player/score?configId=${configId}&playerId=${playerId}`
    ).then(res => res.data.score);
  }
  // 充值
  public static moneyReserve(amount) {
    return Net(`/member/fts/money/reserve?amount=${amount}`).then(
      res => res.data
    );
  }
  // 积分兑换
  public static moneyExchange(amount) {
    return Net(`/member/fts/money/exchange?amount=${amount}`).then(
      res => res.data
    );
  }
  // 球队列表
  public static teamListName(configId) {
    return Net(`/member/fts/team/listName?configId=${configId}`).then(
      res => res.data.list
    );
  }

  // ------------------------- 排行榜 -------------------------

  public static rankTitle(configId) {
    return Net(
      `/member/fts/rank/title?configId=${configId}&domain=${domain}`
    ).then(res => res.data.all);
  }
  // min 按某种类型查看我的所在的榜

  public static rankList(pageNum, configId, level, relatedId, mine?) {
    return Net(
      `/member/fts/rank/list?pageNum=${pageNum}&configId=${configId}&level=${level}&relatedId=${relatedId}&mine=${mine}&domain=${domain}`
    ).then(res => res.data.list);
  }

  // 轮次列表

  public static turnList(configId) {
    return Net(`/member/fts/turn/list?configId=${configId}`).then(
      res => res.data.list
    );
  }
}
