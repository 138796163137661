import { API_BASE_PATH, IS_DEV } from '../configs/index';
import Util from './index';

/**
 * 判断 api 的 请求状态码
 * @param response
 */
function checkApiStatus<T = any>(response: any): Promise<T> {
  if (
    (response.status >= 200 && response.status < 300) ||
    response.status === 304
  ) {
    return Promise.resolve(response);
  }
  return Promise.reject(new Error(`响应码错误: ${response.status}`));
}

/**
 * 这里判断 返回的是否是 json, 需要处理如果 不是 json 的情况
 * @param response
 */
function parseJSON<T = any>(response: any): Promise<T> {
  return response.json().catch(
    (): Promise<Error> => {
      // Util.goLoginConfirm().then((): void =>
      //   Util.jumpPage(Util.createLoginUrlWithRedirection())
      // );
      return Promise.reject({ message: '登录已过期,请重新登录!' });
    }
  );
}

/**
 * 判断后台的返回 状态码
 * @param response
 */
function checkApiResponse<T = any>(response: any): Promise<T> {
  if (response.code === 0) {
    return response;
  }
  return Promise.reject(response);
}

/**
 * 格式化数据到 fetch的body
 * @param data
 * @param bool
 * @constructor
 */
function AppendToFormData(
  data: object | FormData | string,
  bool = false
): string | FormData {
  // /// 如果是 FormData
  if (Util.isFormData(data)) {
    console.log('---------------');

    return data as FormData;
  }
  if ((typeof data) === 'string') {
    //@ts-ignore
    return data
  }
  // //// 如果不是 FormData 就默认序列号为字符串
  if (!bool) {
    return JSON.stringify(data);
  }

  // /// 构建成 FormData
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  return formData;
}

/**
 * 对对象形式的url 参数序列化为 字符串
 * @param params
 * @constructor
 */
function JoinUrl(params: any): string {
  const joins: string[] = [];
  for (const [key, value] of Object.entries(params)) {
    if (!!value) {
      joins.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`
      );
    }
  }
  return joins.join('&');
}

interface OptionsType {
  headers?: object;
  // @ts-ignore
  params?: { [key: string | number]: string };
}

/**
 * 对网络请求的封装
 * @param path
 * @param body
 * @param options
 * @constructor
 */
export const Net = <T = any>(
  path: string,
  body: null | object = {},
  options: OptionsType = {}
): Promise<T> => {
  // const proxyTarget = "http://10.0.1.20:8092"; //测试
  const proxyTarget = ""; //测试

  let url = proxyTarget + API_BASE_PATH + path;
  let headers = {};


  // // 如果数据不是 formData
  if (!Util.isFormData(body)) {
    headers = {
      'Content-Type': 'application/json;charset=utf8',
    };
  }

  // // 如果 有传递 headers
  if (options.headers) {
    headers = Object.assign(headers, options.headers);
  }

  // /// 如果params 不为空
  if (!Util.isObjectEmpty(options.params)) {
    url += `?${JoinUrl(options.params)}`;
  }

  return fetch(url, {
    body: AppendToFormData(body || ''),
    method: 'POST',
    headers,
    credentials: 'include',
  })
    .then(checkApiStatus)
    .then(parseJSON)
    .then(checkApiResponse)
    .catch(
      (err: Error | any): Promise<any> => {
        // 开发模式打印日志
        if (IS_DEV) {
        }

        // 错误上报, TODO:这样的话在调用时的错误就不用提交了??
        if (!IS_DEV) {
          if (err.code && err.code !== 200) {
            // HandlerError.log(
            //   JSON.stringify({
            //     stack: err.message,
            //     url,
            //     body,
            //     options,
            //   }),
            //   ErrorLevel.warning
            // );
          }
        }
        // 登录过期的处理
        if (err.code === 2) {
          Util.removeLoginInfo();
        }

        return Promise.reject(err);
      }
    );
};
