import React from 'react';
import './index.css';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import Icon, { HomeOutlined } from '@ant-design/icons';
interface Props {
  value?: any;
  size?: 'small' | 'middle' | 'big';
  accept?: any;
  onChange?: (file: any, evet: any) => void;
  onDelete?: Function;
  disabled?: boolean;
}

export class ImageComponent extends React.Component<Props, any> {
  constructor(props) {
    super(props);
  }

  beforeUpload = (img) => {
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      if (this.props.onChange) {
        this.props.onChange(reader.result, e)
      }
    });
    reader.readAsDataURL(img);
    return false;
  }

  handleDelete = (event) => {
    const { onDelete } = this.props;
    onDelete && onDelete()
    event.stopPropagation();
  }

  render() {
    const { size, onDelete, value, accept, disabled = false } = this.props;
    const uploadButton = (
      <div>
        <PlusOutlined />
        {/* <div className="ant-upload-text">Upload</div> */}
      </div>
    );

    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className={`${size || 'middle'} my-upload-box`}
        showUploadList={false}
        beforeUpload={this.beforeUpload}
        accept={accept || ".png, .jpg, .jpeg, .gif"}
        disabled={disabled}
      >
        {
          value ?
            <div className='image-content'>
              {
                !onDelete ? null :
                  <Icon onClick={this.handleDelete} type="delete" className="delIcon" />
              }
              <img src={value} alt="avatar" />
            </div>
            :
            uploadButton
        }
      </Upload>
    );
  }
}
