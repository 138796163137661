import { Net } from '../../utils/net';
import { IdAndName, _pageData } from '../common_i';

export interface QuizBody {
  quizId: number;
  name: string;
  logo: string;
  createTime: string;
  startTime: string;
  endTime: string;
  showAmount: true;
  statusTag: string;
  top: boolean;
  touchAmount: number;
  shareAmount: number;
  points: number;
  description: string;
  amount: number;
  joinCount: number;
  backgroundColor: string;
  groups: GroupItem[];
  bet: {
    minimumPoints: number;
    maximumPoints: number;
    rewardRatio: string;
  };
}

interface PeopleInfo {
  name: string;
  createTime: string;
  portraitUrl: string;
  result: string;
}

export interface GroupItem {
  name: string;
  description: string;
  items: {
    id: number;
    name: string;
    result: any;
    itemType: number;
    itemNames: string[];
    goal: string;
    tag: string;
  }[];
  joinLists: PeopleInfo[];
}

export interface RewardItem {
  rewardMode: number;
  title: string;
  description: string;
  button: string;
  lotteryActivityId: number;
  tint: any;
  startTime: string;
  endTime: string;
  lotteryResult: string;
}

export interface LotteryBody {
  finish: any[];
  message: string;
  lottery: {
    background: string;
    backgroundColor: string;
    items: {
      idx: number;
      image: string;
      name: string;
      itemType: number;
      rewardCount: number;
    }[];
    lotteryType: number;
  };
  ready: {
    idx: number;
    lotteryId: number;
  }[];
  myLists: PeopleInfo[];
  joinLists: PeopleInfo[];
}

export interface MineQuizBody {
  id: any;
  name: string;
  quizId: any;
  statusTag: string;
  createTime: string;
  groups: GroupItem[];
  rewards: RewardItem[];
  logo: string;
  backgroundColor: string;
  orgDomain: string;
  orgId: any;
  orgLogo: string;
  orgName: string;
}

interface SubmitBody {
  quizId: any;
  items: IdAndName[];
  points: any;
}

interface Filter {
  pageNum?: number;
  pageSize?: number;
}
const domain = localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]
export class QuizService {
  public static listMine(params: any): Promise<_pageData<MineQuizBody>> {
    return Net('/member/quiz/listMine', null, { params }).then(
      res => res.data.list
    );
  }

  public static queryMine(id): Promise<MineQuizBody> {
    return Net('/member/quiz/queryMine', null, {
      params: { id },
    }).then(res => res.data.query);
  }

  public static query(quizId): Promise<QuizBody> {
    return Net('/member/quiz/query', null, {
      params: { quizId, domain },
    }).then(res => res.data.query);
  }

  //提交竞猜
  public static submit(body: SubmitBody) {
    return Net('/member/quiz/submit', body);
  }

  //查询我的抽奖
  public static queryLottery(id): Promise<LotteryBody> {
    return Net(`/member/lottery/list?activityId=${id}`).then(
      res => res.data.list
    );
  }

  //后台记录访问时间，并完成奖品发放
  public static confirm(lotteryId) {
    return Net(`/member/lottery/confirm?lotteryId=${lotteryId}`).then(
      res => res.data.list
    );
  }

  public static addShare(quizId) {
    return Net('/member/quiz/share', null, { params: { quizId, domain } });
  }
}
