import * as React from 'react';
import './myticket.css'
import Login from '../../loginHome/index'
import Util from '../../../utils';
import VoteDetail from './component/voteDetail'
import { deduplication, subStr, subStrEllipsis } from '../../../utils/tool';
import { VoteService, MineVoteBody } from '../../../service/vote';
import { message, Spin, Modal, Pagination } from 'antd';
import { NoData } from '../../../component/noData';

export default function MyVoteList() {
  const [dataSource, setDataSource] = React.useState<MineVoteBody[]>([])
  const [total, seTtotal] = React.useState<number>(0)
  const [pageNum, setPageNum] = React.useState<number>(1)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [show, setShow] = React.useState<boolean>(false)
  const [type, setType] = React.useState<any>(undefined)
  const [loadding, setLoadding] = React.useState<boolean>(true)
  const [voteId, setVoteId] = React.useState<any>(undefined)
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [isNull, setIsNull] = React.useState<boolean>(true)
  const title = ['投票信息', '抽奖']

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setLoadding(false)
      return
    }
    getData(1, pageSize);
  }, []);

  function getData(pageNum, pageSize) {
    window.scrollTo(0, 0)
    setLoadding(true)
    VoteService.listMine({ pageNum, pageSize })
      .then(res => {
        setDataSource(res.content)
        setPageNum(pageNum)
        setPageSize(pageSize)
        seTtotal(res.totalElements)
        setIsNull(res.totalElements > 0)
      })
      .catch(err => {
        message.error(err.message);
      }).finally(() => {
        setLoadding(false)
      })
  }

  function toSignIn() { }

  function toDetail(id) {
    setVoteId(id)
    setType(0)
    setShow(true)
  }

  function getColor(reimburseTag) {
    return ['报销待提交', '报销待寄送', '报销已打款'].includes(reimburseTag)
      ? '#C31C32'
      : '#B7B4B4';
  }

  function cancel() {
    if (type == 0) {
      setShow(false)
      setVoteId(undefined)
      setType(undefined)
    } else {
      setType(0)
    }
  }



  return (
    <Spin spinning={loadding}>
      {
        isNull ?
          <div className="enroll">
            {dataSource.map(i => (
              <div
                key={i.id}
                className='enroll-item'
                style={{ paddingTop: '20px' }}
              >
                <div className='enroll-content' onClick={() => toDetail(i.id)}>
                  <img src={i.logo} alt="" />
                  <div className='enroll-middle'>
                    <div
                      style={{
                        fontSize: '1rem',
                        height: '100%',
                        marginBottom: '0.5rem',
                      }}
                    >
                      {subStrEllipsis(i.name, 31)}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                      <div>投票时间: {subStr(i.createTime, 0, 16)}</div>
                    </div>
                  </div>
                  {/* <Icon type="right" style={{ height: 80 }} /> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p style={{ flex: 1 }}>{i.orgName}</p>
                  <div
                    className='enroll-status'
                    style={{
                      color: i.statusTag === '待签到' ? '#C31C32' : '#B7B4B4',
                      border: `1px solid ${i.statusTag === '待签到' ? '#C31C32' : '#B7B4B4'
                        }`,
                    }}
                    onClick={() => toSignIn()}
                  >
                    {i.statusTag}
                  </div>
                </div>
              </div>
            ))}
            <div className='goods-pagination'>
              <Pagination
                pageSize={pageSize}
                size="small"
                current={pageNum}
                total={total}
                onChange={(page, pageSize) => { getData(page, pageSize) }}
                onShowSizeChange={(current, size) => { getData(current, size) }}
              />
            </div>

            <Modal
              title={title[type]}
              visible={show}
              footer={null}
              style={{ padding: 'none', overflow: 'unset' }}
              onCancel={() => cancel()}
            >
              <div style={{ width: '100%', backgroundColor: '#f5f5f5' }}>
                {
                  type == 0 &&
                  <VoteDetail cancel={() => { setShow(false) }} id={voteId} />
                  // : type == 1 ?
                  //   <OptionModify id={info.id} itemName={info.itemName} enrollId={info.enrollId} orderId={info.orderId} />
                  //   : type == 2 ?
                  //     <SubmitInfo enrollId={enrollId} />
                  //     : <div></div>
                }
              </div>

            </Modal>
          </div>
          : <NoData show={true} />
      }

      {
        isLogin &&
        <Login onLogin={() => { getData(1, pageSize); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </Spin>
  );
}
