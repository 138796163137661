import * as React from 'react';
import './index.css'
import { Table, Form, Select, Spin } from 'antd';
import { ServiceMutower } from '../../service/mutower';

export function JFLIST() {

  const [dataSource, setDataSource] = React.useState<any>([])
  const [filter, setFilter] = React.useState<any>({ pageSize: 10, pageNum: 1 })
  const [phaseList, setPhaseList] = React.useState<any>([])
  const [scheduleList, setScheduleList] = React.useState<any>([])
  const [turnList, setTurnList] = React.useState<any>([])
  const [groupsList, setGroupsList] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [seasonId, setSeasonId] = React.useState<any>('')
  const columns = [
    {
      title: '名次',
      dataIndex: 'rank',
    },
    {
      title: '球队',
      dataIndex: 'teamName',
      render: (r, row) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <img style={{ width: '20px', height: '20px', marginRight: '10px' }} src={row.logo} alt="" />
          <span>{row.teamName}</span>
        </div>
      )
    },
    {
      title: '轮次',
      dataIndex: 'turn',
    },
    {
      title: '赛',
      dataIndex: 'totalCount',
    },
    {
      title: '胜',
      dataIndex: 'winCount',
    },
    {
      title: '平',
      dataIndex: 'drawCount',
    },
    {
      title: '负',
      dataIndex: 'loseCount',
    },
    {
      title: '进球',
      dataIndex: 'goalCount',
    },
    {
      title: '失球',
      dataIndex: 'failureCount',
    },
    {
      title: '净胜球',
      dataIndex: 'netGoalCount',
    },
    {
      title: '积分',
      dataIndex: 'score',
    },
  ]
  React.useEffect(() => {
    ServiceMutower.mutowerSeason().then(res => {
      setScheduleList(res)
      handleFilterChange('seasonId', res[0].seasonId)
      getPhase(res[0].seasonId)
    })
  }, [])

  function getData(filter) {
    ServiceMutower.mutowerGamesSummary(filter).then(res => {
      setDataSource(res)
    })
  }

  function getPhase(seasonId) {
    ServiceMutower.mutowerDictQuery({ dictType: 100, seasonId: seasonId }).then(res => {
      setPhaseList(res)
      setSeasonId(seasonId)
      handleFilterChange('seasonId', seasonId)
    })
  }

  function getList(phaseId) {
    filter.turnId = undefined
    filter.groupId = undefined
    Promise.all([
      ServiceMutower.mutowerDictQuery({ dictType: 101, seasonId: seasonId, phaseId: phaseId }),
      ServiceMutower.mutowerDictQuery({ dictType: 102, seasonId: seasonId, phaseId: phaseId })
    ]).then(res => {
      res[0].map(el=>el.name = '第'+el.name+'轮')
      
      setTurnList(res[0])
      setGroupsList(res[1])
      setFilter(filter)
    })
  }

  function handleFilterChange(key, value) {
    filter[key] = value
    setFilter(filter)
    getData(filter)
  }

  const formItemLayout = {
    labelCol: { sm: { span: 7 }, },
    wrapperCol: { sm: { span: 13 } }
  }

  return (
    <Spin spinning={loading}>
      <div className='Schedule' style={{ width: '90%', margin: '20px auto', display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
      <Form.Item className='Schedule' style={{ marginTop: '20px', width: '30%' }} label="赛季赛事" {...formItemLayout} >
        <Select defaultValue={filter?.seasonId} value={filter?.seasonId} onChange={(v) => getPhase(v)} placeholder="请选择阶段">
          <Select.Option value={0} key='0'>全部</Select.Option>
          {
            scheduleList?.map(el =>
              <Select.Option value={el.seasonId} key={el.seasonId}>{el.seasonName}</Select.Option>
            )
          }
        </Select>
      </Form.Item>
        <Form.Item className='Schedule' style={{ marginTop: '20px', width: '15%' }} {...formItemLayout} >
          <Select defaultValue={filter?.phaseId} value={filter?.phaseId} onChange={(v) => { getList(v); handleFilterChange('phaseId', v) }} placeholder="请选择阶段">
            <Select.Option value='0' key='0'>全部</Select.Option>
            {
              phaseList?.map(el =>
                <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
              )
            }
          </Select>
        </Form.Item>
        <Form.Item className='Schedule' style={{ marginTop: '20px', width: '10%' }} {...formItemLayout} >
          <Select defaultValue={filter?.turnId} value={filter?.turnId} onChange={(v) => handleFilterChange('turnId', v)} placeholder="请选择轮次">
            <Select.Option value=''>全部</Select.Option>
            {
              turnList?.map(el =>
                <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
              )
            }
          </Select>
        </Form.Item>
        <Form.Item className='Schedule' style={{ marginTop: '20px', width: '15%' }} {...formItemLayout} >
          <Select defaultValue={filter?.groupId} value={filter?.groupId} onChange={(v) => handleFilterChange('groupId', v)} placeholder="请选择分组">
            <Select.Option value=''>全部</Select.Option>
            {
              groupsList?.map(el =>
                <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
              )
            }
          </Select>
        </Form.Item>
      </div>
      <Table
        bordered
        columns={columns}
        pagination={false}
        dataSource={dataSource}
        rowKey={(r, index) => `org_${index}`}
        style={{ width: '95%', margin: '0 auto' }}
      />
    </Spin>
  )

}