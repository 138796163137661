import * as React from 'react';
import Util from '../../../utils';
import LoginHome from '../../loginHome/index'
import { ServiceMember, MemberCardsContent } from '../../../service/member/member'
import { PromotionService } from '../../../service/promotion/index'
import Login from '../../loginHome/index'
import './merchandise.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { message, Modal, Spin } from 'antd';
export default function Merchandise() {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(true);
  const [customerOpen, customerState] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [numerical, numericalState] = React.useState<any>([]);
  const [goods, setGoods] = React.useState<MemberCardsContent>({} as MemberCardsContent);
  const [promotions, setPromotions] = React.useState([]);
  const [showPromo, setShowPromo] = React.useState(false);
  const [promoText, setPromoText] = React.useState('');
  const [num, setNum] = React.useState<number>(1); //存数量
  const [specifications, setSpecifications] = React.useState<any>([]);
  const [isAddCart, setIsAddCart] = React.useState<boolean>(false)
  const [spec1_spec2, set_spec1_spec2] = React.useState<any>([]);
  const [spec2_spec1, set_spec2_spec1] = React.useState<any>([]);
  const [dataSource, setDataSource] = React.useState<any>([]);
  const [$spec, set$Spec] = React.useState<any>({ spec1: '', spec2: '' });
  const [dataInfo, setDataInfo] = React.useState<any>({});
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}');
  const [type, setType] = React.useState<number>(1)
  const state: any = location.state;
  const goodsId = state.goodsId;
  const [isLogin, setIsLogin] = React.useState<boolean>(false);

  React.useEffect(() => {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    sessionStorage.setItem('headerActive', menuArray?.indexOf('/shopping/mall'))
    async function init() {
      sessionStorage.setItem('goodsPlaceOrder', '');
      const goods = await ServiceMember.queryGoods(goodsId);
      const good = goods ? goods : ([] as any);

      let specificationList: any = [];
      let specs1: any = [];
      let specs2: any = [];
      let specification_1 = '';
      let specification_2 = '';

      let spec1_spec2: any = {};
      let spec2_spec1: any = {};
      let dataSource: any = [];

      (good ? good.specifications : []).map((i: any, index) => {
        const {
          specification1,
          specification2,
          referencePrice,
          totalAmount,
          amount,
          price,
        } = i.entity;

        //保存以规格1为key与规格2对应的数据
        if (spec1_spec2.hasOwnProperty(specification1)) {
          spec1_spec2[specification1].push(specification2);
        } else {
          spec1_spec2[`${specification1}`] = [specification2];
        }
        //保存以规格2为key与规格1对应的数据
        if (spec2_spec1.hasOwnProperty(specification2)) {
          spec2_spec1[specification2].push(specification1);
        } else {
          spec2_spec1[`${specification2}`] = [specification1];
        }

        dataSource.push({
          key: `${i.entity.specification1}-${i.entity.specification2}`,
          referencePrice,
          totalAmount,
          amount,
          price,
          image: i.image,
        });

        if (specification1) {
          specs1.push({
            spec: specification1,
            referencePrice,
            totalAmount,
            amount,
            price,
            image: i.image,
            focus: false,
            disabled: false,
            order: 1,
          });
        }
        if (specification2) {
          specs2.push({
            spec: specification2,
            referencePrice,
            totalAmount,
            amount,
            price,
            image: i.image,
            focus: false,
            disabled: false,
            order: 2,
          });
        }
        if (index === 0) {
          specification_1 = i.specification1;
          specification_2 = i.specification2;
        }
      });

      if (specs1.length > 0) {
        let hash = {};
        let arr = specs1.reduce(function (arr, current) {
          if (hash[current.spec]) {
          } else {
            (hash[current.spec] = true && arr.push(current))
          }
          // hash[current.spec]
          //   ? ''
          //   : (hash[current.spec] = true && arr.push(current));
          return arr;
        }, []);
        specificationList.push({
          specification: specification_1,
          specs: arr,
          order: 1,
        });
      }
      if (specs2.length > 0) {
        let hash = {} as any;
        let arr = specs2.reduce(function (arr, current) {
          if (!hash[current.spec]) {
            (hash[current.spec] = true && arr.push(current))
          }
          // hash[current.spec]
          //   ? ''
          //   : (hash[current.spec] = true && arr.push(current));
          return arr;
        }, []);
        specificationList.push({
          specification: specification_2,
          specs: arr,
          order: 2,
        });
      }
      set_spec1_spec2(spec1_spec2);
      set_spec2_spec1(spec2_spec1);
      setDataSource([...dataSource]);
      setSpecifications([...specificationList]);
      setGoods(good);
      setLoading(false)

      // const promotions = await PromotionService.list(goodsId);
      // let promoText = '';
      // promotions.map((i, index) => {
      //   if (index === promotions.length - 1) {
      //     promoText += i.name;
      //   } else {
      //     promoText += i.name + '、';
      //   }
      // });


      // setPromotions(promotions);
      // setPromoText(promoText);
    }
    init()
  }, [])
  let spec1 = '',
    spec2 = '';
  function onChangeColor(i, index1, index2) {
    let enabaleArr = i.order === 1 ? spec1_spec2[i.spec] : spec2_spec1[i.spec];
    // 如果是点击了 选中的项,就取消选中
    if (true === i.focus) {
      specifications[index1].specs.map(item => (item.focus = false));
      specifications.map(a => {
        if (i.order !== a.order) {
          a.specs.map(b => {
            b.disabled = false;
          });
        }
      });
      setSpecifications([...specifications]);
      return;
    }

    specifications[index1].specs.map(item => (item.focus = false));
    // 设置选中的项
    specifications[index1].specs[index2].focus = true;

    // 将选中的项保存起来
    if (i.order === 1) {
      spec1 = i.spec;
      set$Spec({ ...$spec, spec1: i.spec });
    } else if (i.order === 2) {
      spec2 = i.spec;
      set$Spec({ ...$spec, spec2: i.spec });
    }

    specifications.map(a => {
      if (i.order !== a.order) {
        a.specs.map(b => {
          // 判断选中的这项是否存在与对应的另一个规格的项
          if (!enabaleArr.includes(b.spec)) {
            // 不存在,将对应的这一规格中的这一项置灰 则是非选项
            b.disabled = true;
            b.focus = false;
          } else {
            // 存在,然后判断这项的数量是否大于0,<=0 置灰不能选,>0 则是能选项
            b.disabled =
              b.totalAmount - b.amount <= 0 || i.totalAmount - i.amount <= 0
                ? true
                : false;
          }
          if (b.focus) {
            if (b.order === 1) {
              spec1 = b.spec;
              set$Spec({ ...$spec, spec1: b.spec, spec2 });
            } else if (b.order === 2) {
              spec2 = b.spec;
              set$Spec({ ...$spec, spec1, spec2: b.spec });
            }
          }
        });
      }
    });
    setSpecifications([...specifications]);

    i.order === 1 ? (spec1 = i.spec) : (spec2 = i.spec);
    dataSource.map(ds => {
      if (ds.key === `${spec1}-${spec2}`) {
        setDataInfo(ds);
      }
    });
  }
  async function addCart() {
    if (!Util.IsLogin()) {
      setIsLogin(true)
      return;
    }
    if (specifications.length === 2) {
      if ($spec.spec1.length <= 0 || $spec.spec1.length <= 0) {
        message.error('请选择商品规格')
        return
      }
    } else if (specifications.length === 1) {
      if ($spec.spec1.length <= 0) {
        message.error('请选择商品规格')
        return
      }
    }

    let body = {
      entity: {
        goodsId,
        amount: num,
      },
      specification1: $spec.spec1,
      specification2: $spec.spec2,
    }
    try {
      await ServiceMember.addCart(body);
      setIsAddCart(true)
    } catch (e: any) {
      message.error(e.message);
    }
  }

  function buyNow() {
    if (!Util.IsLogin()) {
      setIsLogin(true)
      return;
    }
    if (goods) {
      if (goods.specifications.length === 0) {
        if (goods.totalAmount - goods.amount === 0) {
          message.error('库存不足，正快马加鞭补货')
          return
        }
        sessionStorage.setItem('goodsPlaceOrder',
          JSON.stringify({
            goodsId: goodsId,
            name: goods.name,
            logo: goods.logo,
            specification1: $spec.spec1,
            specification2: $spec.spec2,
            amount: num,
            express: goods.express, //是否有快递
            expressMoney: goods.expressMoney, //快递价格
            delivery: goods.delivery, //是否需要取票
            selfPick: goods.selfPick, //是否自主取货
            minimumPrice: goods.minimumPrice, //商品的价格
          })
        )
        navigate('/goods/placeOrder')
      } else if (!dataInfo.totalAmount) {
        message.error('请选择商品属性')
        return
      } else if (dataInfo.totalAmount - dataInfo.amount === 0) {
        message.error('库存不足，正快马加鞭补货')
      } else {
        sessionStorage.setItem('goodsPlaceOrder',
          JSON.stringify({
            goodsId: goodsId,
            name: goods.name,
            logo: goods.logo,
            specification1: $spec.spec1,
            specification2: $spec.spec2,
            amount: num,
            express: goods.express, //是否有快递
            expressMoney: goods.expressMoney, //快递价格
            delivery: goods.delivery, //是否需要取票
            selfPick: goods.selfPick, //是否自主取货
            minimumPrice: goods.minimumPrice, //商品的价格
          })
        )
        navigate('/goods/placeOrder')
      }
    }
  }

  return (
    <Spin spinning={loading}>
      <div className="container" style={{ width: '100%', minHeight: '100vh', background: thmem?.background, position: 'relative' }}>
        <div className="merchandise">
          <div className="left-detail">
            <div className="active-detail-message">
              <div
                dangerouslySetInnerHTML={{ __html: goods.detail || ' ' }}
              />
            </div>
          </div>
          <div className="right-purchase">
            <div className="purchase-title">{goods.name}</div>
            <div className="purchase-message">
              <div className="message-img">
                <img src={goods.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </div>
              <div className="message-text">
                <p>请选择</p>
                <p className="goods-price" style={{ marginTop: '15px' }}>￥{goods.minimumPrice}</p>
                <p className="goods-amount">库存：{goods.amount}</p>
              </div>
            </div>
            <div className="purchase-button">
              {
                specifications?.map((item: any, index: number) =>
                  <div className="button-item">
                    <p className="button-item-title">{item?.specification}</p>
                    <div className="button-button">
                      {
                        item?.specs?.map((el: any, ind: number) =>
                          <p style={
                            el?.focus
                              ? {
                                backgroundColor: '#c31c32',
                                color: '#fff',
                                cursor: 'pointer'
                              }
                              : { cursor: 'pointer' }
                          } onClick={() => onChangeColor(el, index, ind)}>{el.spec} </p>
                        )
                      }
                    </div>
                  </div>
                )
              }

            </div>
            <div className="purchase-quantity">
              <p className="purchase-quantity-title">购买数量</p>
              <div className="puchase-quantity-number">
                <p onClick={() => setNum(num <= 0 ? 0 : num - 1)}>-</p>
                <input value={num} onChange={(e: any) => setNum(Number(e.target.value) < 0 ? 0 : Number(e.target.value))} type="number" />
                <p onClick={() => setNum(num + 1)}>+</p>
              </div>
            </div>
            <div className="purchase-bottom">
              <div onClick={() => addCart()} className="add-cart">加入购物车</div>
              <div className="buy-now" onClick={() => buyNow()}>立即购买</div>
            </div>
          </div>

        </div>
        {
          isAddCart &&
          // <div style={{ position: 'absolute', top: '10vh', left: '40%', zIndex: 9, backgroundColor: 'white', padding: '30px' }}>
          //   <div className="purchase-title" style={{ textAlign: 'left', marginBottom: '10px' }}>已经添加至您的购物车</div>
          //   <div className="purchase-message">
          //     <div className="message-img">
          //       <img src={goods.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          //     </div>
          //     <div className="message-text">
          //       <p>{goods.name}</p>
          //       <p style={{ marginTop: '15px' }}>规格：{$spec.spec1} {$spec.spec2}</p>
          //       <p >数量：{num}</p>
          //     </div>
          //   </div>
          //   <div className="purchase-bottom" style={{ marginTop: '20px' }}>
          //     <div className="add-cart" onClick={() => { setIsAddCart(false); setNum(1) }}>继续购物</div>
          //     <div className="buy-now" onClick={() => navigate('/shopping/cart')}>立即结算</div>
          //   </div>
          // </div>
          <Modal visible={isAddCart} footer={null} onCancel={() => setIsAddCart(false)}>
            <div className="purchase-title" style={{ textAlign: 'left', marginBottom: '10px' }}>已经添加至您的购物车</div>
            <div className="purchase-message">
              <div className="message-img">
                <img src={goods.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </div>
              <div className="message-text">
                <p>{goods.name}</p>
                <p style={{ marginTop: '15px' }}>规格：{$spec.spec1} {$spec.spec2}</p>
                <p >数量：{num}</p>
              </div>
            </div>
            <div className="purchase-bottom" style={{ marginTop: '20px' }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
                <div className="add-cart" onClick={() => { setIsAddCart(false); setNum(1) }}>继续购物</div>
              </div>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'start' }}>
                <div className="mall-buy-now" onClick={() => navigate('/shopping/cart')}>立即结算</div>
              </div>
            </div>
          </Modal>
        }
        {
          isLogin &&
          <Login onLogin={() => { setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
        }
      </div >
    </Spin>
  );
}
