import * as React from 'react';
import './myticket.css'
import Login from '../../loginHome/index'
import Util from '../../../utils';
import QuizDetail from './component/quizDetail'
import { deduplication, subStr, subStrEllipsis } from '../../../utils/tool';
import { MineQuizBody, QuizService } from '../../../service/quiz';
import { message, Pagination, Spin, Modal } from 'antd';
import { NoData } from '../../../component/noData';

export default function MyQuizList() {
  const [dataSource, setDataSource] = React.useState<MineQuizBody[]>([])
  const [total, seTtotal] = React.useState<number>(0)
  const [show, setShow] = React.useState<boolean>(false)
  const [type, setType] = React.useState<any>(undefined)
  const [pageNum, setPageNum] = React.useState<number>(1)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [loadding, setLoadding] = React.useState<boolean>(true)
  const [isNull, setIsNull] = React.useState<boolean>(true)
  const [quizId, setQuizId] = React.useState<any>(undefined)
  const title = ['竞猜信息', '报名选项', '提交信息']

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      return
    }
    getData(1, pageSize);
  }, []);

  function getData(pageNum, pageSize) {
    setLoadding(true)
    window.scrollTo(0, 0)
    QuizService.listMine({ pageNum, pageSize })
      .then(res => {
        setDataSource(res.content)
        setPageNum(pageNum)
        setPageSize(pageSize)
        seTtotal(res.totalElements)
        setIsNull(res.totalElements > 0)
      })
      .catch(err => {
        message.error(err.message);
      }).finally(() => {
        setLoadding(false)
      })
  }

  function toSignIn() { }

  function toDetail(id) {
    setQuizId(id)
    setType(0)
    setShow(true)
    // Util.jumpPage(ROUTER_NAME.MY_QUIZ_DETAIL.path, { id });
  }
  function cancel() {
    window.location.reload()
    setShow(false)
    setType(undefined)
  }

  return (
    <Spin spinning={loadding}>
      {
        isNull ?
          <div className="enroll">
            {dataSource.map(i =>
              <div
                key={i.id}
                className='enroll-item'
                style={{ paddingTop: '20px' }}
              >
                <div className='enroll-content' onClick={() => toDetail(i.id)}>
                  <img src={i.logo} alt="" />
                  <div className='enroll-middle'>
                    <div
                      style={{
                        fontSize: '1rem',
                        height: '100%',
                        marginBottom: '0.5rem',
                      }}
                    >
                      {subStrEllipsis(i.name, 31)}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                      <div>竞猜时间: {subStr(i.createTime, 0, 16)}</div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p style={{ flex: 1 }}>{i.orgName}</p>
                  <div
                    className='enroll-status'
                    style={{
                      color: i.statusTag === '待签到' ? '#C31C32' : '#B7B4B4',
                      border: `1px solid ${i.statusTag === '待签到' ? '#C31C32' : '#B7B4B4'
                        }`,
                    }}
                    onClick={() => toSignIn()}
                  >
                    {i.statusTag}
                  </div>
                </div>
              </div>
            )}
            <div className='goods-pagination'>
              <Pagination
                pageSize={pageSize}
                size="small"
                current={pageNum}
                total={total}
                onChange={(page, pageSize) => { getData(page, pageSize) }}
                onShowSizeChange={(current, size) => { getData(current, size) }}
              />
            </div>
            {
              isLogin &&
              <Login onLogin={() => { getData(1, pageSize); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
            }
          </div>
          : <NoData show={true} />
      }
      {/* QuizDetail */}
      <Modal
        title={title[type]}
        visible={show}
        footer={null}
        style={{ padding: 'none', overflow: 'unset' }}
        onCancel={() => cancel()}
      >
        <div style={{ width: '100%', backgroundColor: 'white' }}>
          {
            type == 0 ?
              <QuizDetail id={quizId} cancel={() => { setType(0); cancel() }} />
              : <div></div>
          }
        </div>

      </Modal>
    </Spin>
  );
}
