import * as React from 'react';
import './index.css';

const NoDataImage = require('../../assets/image/noData3.jpg');
// const NoDataImage2 = require('../../assets/image/noData4.jpg');
const NoDataImage2 = JSON.parse(localStorage.getItem('icons') as '');

interface Props {
  show: boolean;
  imgStyle?: React.CSSProperties;
  tipStyle?: React.CSSProperties;
  tip?: string;
  children?: React.ReactNode;
  boxStyle?: React.CSSProperties;
}

export function NoData(props: Props) {
  let { show, imgStyle, tipStyle, boxStyle, tip = '什么都没有?' } = props;
  return (
    <div style={{ height: '100%', width: '100%' }}>
      {show ? (
        <div className='no-data-container' style={boxStyle}>
          <img
            className='no-data-image'
            style={imgStyle}
            src={NoDataImage2['Default page']}
          />
        </div>
      ) : (
        props.children
      )}
    </div>
  );
}

export function NotDataView(props: {
  show: boolean;
  style?: React.CSSProperties;
  boxStyle?: React.CSSProperties;
}) {
  const { show, style, boxStyle } = props;
  if (!show) {
    return null;
  }
  return (
    <div className='no-data-container' style={boxStyle}>
      <img
        className='no-data-image'
        style={style}
        src={NoDataImage2['Default page']}
      />
    </div>
  );
}
