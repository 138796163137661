import { Net } from '../../utils/net';
import { _pageData } from '../common_i';

/**
 * @author: hukun
 * @Date: 2019-05-24
 * @Time: 15:29
 * @function
 */
export interface PeopleContent {
  entity: {
    address: string;
    birthday: string;
    gender: number;
    id?: number;
    idCardCode: string;
    issueAuthority: string;
    issueEndDate: string;
    issueStartDate: string;
    name: string;
    auditMessage: string;
  };
  auditStatus: {
    id: number;
    name: string;
  };
  idCardType: string;
  backImage: string;
  faceVideoUrl: string;
  frontImage: string;
  phone: number;
  student: boolean;
  mine: boolean;
}

export class ServicePeoples {
  /**
   * 获取购票人
   */
  public static getAllPeoples(
    audit?: boolean //true: 审核通过/无需审核
  ): Promise<_pageData<PeopleContent>> {
    return Net('/member/contacts/list', null, {
      //@ts-ignore
      params: { pageNum: 1, pageSize: 9999, audit },
    }).then(res => res.data.list);
  }

  /**
   * 查询购票人
   * @param id
   */
  public static queryPeoples(id: string | number): Promise<PeopleContent> {
    //@ts-ignore
    return Net('/member/contacts/query', null, { params: { id } }).then(
      res => res.data.query
    );
  }

  /**
   * 检查库里面是否已经有这个人
   * @param params
   */
  public static queryPeopleByIdCard(
    params: any
  ): Promise<{ check: boolean; contacts: any }> {
    return Net('/member/contacts/queryByIdCard', null, { params }).then(
      res => res.data
    );
  }

  /**
   * 修改购票人
   * @param body
   */
  public static modifyPeoples(body: PeopleContent): Promise<any> {
    return Net('/member/contacts/modify', body).then(res => res.data);
  }

  /**
   * 删除购票人
   * @param id
   */
  public static deletePeoples(id: any): Promise<any> {
    return Net('/member/contacts/delete', null, { params: { id } }).then(
      res => res.data
    );
  }

  /**
   * 新增购票人 获取是否开启OCR、二要素
   */
  public static contactsORC() {
    return Net('/member/contacts/ocr').then(res => res.data);
  }

  /**
   * 实名认证 获取是否开启OCR、二要素
   * @returns
   */
  public static contactsAuth() {
    return Net('/member/auth/ocr').then(res => res.data);
  }

  /**
   * 提交手填页面表单 实名认证
   * @param idCard
   * @param name
   * @returns
   */
  public static authCheckIdCard(idCard: string, name: string) {
    return Net('/member/auth/checkIdCard', { idCard, name }).then(
      res => res.data
    );
  }

  /**
   * 提交手填页面表单 新增购票人
   * @param idCard
   * @param name
   * @returns
   */
  public static contactsCheckIdCard(idCard: string, name: string) {
    return Net('/member/contacts/checkIdCard', { idCard, name }).then(
      res => res.data
    );
  }
}
