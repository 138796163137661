import * as React from 'react';
import { PASSWORD_REGEXP, PHONE_REGEXP } from '../../configs/RegExp';
import { ServiceSystem } from '../../service/system'
import Util from '../../utils'
import Login from './login'
import Register from './register'
import Retrieve from './retrieve'
import RealName from './realName'
import './login.css'
interface LoginHomeProp {
  // type: number
  onLogin: () => void
  onCancel: () => void
}
export default function LoginHome(prop: LoginHomeProp) {
  let { onLogin, onCancel } = prop
  const [type, setType] = React.useState<number>(1)
  const [nickName, setNickName] = React.useState<string>('')
  return (
    type === 1
      ?
      <Login onLogin={() => { onLogin(); setType(1) }} onRetrieve={() => setType(3)} onCancel={() => onCancel()} onRejister={() => setType(2)} />
      : type === 2
        ?
        <Register onLogin={(nickName) => { setNickName(nickName); onLogin() }} onCancel={() => onCancel()} />
        : type === 3
          ?
          <Retrieve onLogin={() => setType(1)} onCancel={() => onCancel()} onRejister={() => setType(2)} />
          : type === 4
            ?
            <RealName nickName={nickName} onLogin={() => { onLogin(); setType(1) }} onCancel={() => onCancel()} />
            : <div></div>
  );
}