import * as React from 'react';
import './index.css'

interface sponsorsProp {
  sponsors: any[]
}

export function Sponsors(prop: sponsorsProp) {
  let { sponsors } = prop
  let thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  let backgroundImage = ''
  thmem?.images?.map((el: any) => {
    if (el.imageType === 4) {
      backgroundImage = el.imageUrl
    }
  })
  return (
    sponsors?.length > 0 ?
      <div className="sponsorAll" style={{ backgroundImage: `url(${backgroundImage})` }}>
        {
          sponsors.map((el: any, index: number) =>
            <div key={index + 'el'} className="sponsor-one">
              <span className="sponsor-title">{el.levelName}</span>
              <div className="sponsor-img-list">
                {el.sponsors?.map((item: any, index1: number) =>
                  <div key={index1 + 'el' + index} className="sponsor-img">
                    <img src={item.image} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </div>
                )
                }
              </div>
            </div>
          )
        }
      </div>
      :
      <div></div>
  );
}
