import * as React from 'react';
import './index.css'
import { ServiceHomePage } from '../../service/homePage/index';
import { ServiceArticle } from '../../service/article/index';
import { Honr } from '../../component/honr/index';
import { Sponsors } from '../../component/sponsors/index'
import { PictureBackground } from '../../component/PictureBackground/index'
import { ContentMessage } from '../../component/ContentMessage/index'
import { TitleList } from '../../component/TitleList/index'
import { message, Spin } from 'antd';
import { NoData } from '../../component/noData';

class New extends React.Component<any, any> {
  state = {
    sponsors: [] as any,
    honors: [] as any,
    articleList: [] as any,
    active: 0,
    isDetail: false,
    chooseInfo: {} as any,
    titleList: [{ id: 0, name: '最新' }, { id: 1, name: '报名' }, { id: 5, name: '投票' }, { id: 4, name: '竞猜' }],
    thmem: JSON.parse(sessionStorage.getItem('thmem') || '{}'),
    newActive: sessionStorage.getItem('newActive') || -1,
    filter: {
      tagType: '1,4,5',
      pageNum: 1,
      pageSize: 10,
    } as any,
    total: 1,
    Topping: [] as any,
    loading: true,
    hasMore: true, //是否显示'查看更多'
  };

  componentDidMount() {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    sessionStorage.setItem('headerActive', menuArray?.indexOf('/activity'))
    let headerActive = Number(sessionStorage.getItem('headerActive'))
    let menuList = JSON.parse(sessionStorage.getItem('menuList') || '{}')
    this.setState({ TitleList: menuList[headerActive]?.children })
    Promise.all([
      ServiceHomePage.getSponsors(),
      ServiceHomePage.getHonors(),
    ]).then(res => {
      this.setState({
        sponsors: res[0],
        honors: res[1],
      })
    }).catch(err => {
      message.error(err.message)
    })
    if (this.state.newActive >= 0) {
      this.onChange(Number(this.state.newActive))
    } else {
      this.getData(this.state.filter, true)
    }
  }

  getData(filter = this.state.filter, flag = false) {
    let { Topping } = this.state
    ServiceArticle.list(filter).then(res => {
      let { articleList } = this.state
      if (flag) {
        articleList = []
        Topping = res?.content[0] ? [res?.content[0]] : []
      }
      let newData = [] as any
      let newData1 = [] as any
      let date = res?.content[0]?.createTime?.split(' ')[0]
      res?.content?.map((el: any) => {
        if (el.top && flag) {
          Topping.push(el)
        }
        if (date == (el.createTime?.split(' ')[0])) {
          newData1.push(el)
        } else {
          newData.push({
            time: date,
            list: newData1
          })
          date = el.createTime?.split(' ')[0]
          newData1 = [el]
        }
      })
      if (newData1.length > 0) {
        newData.push({
          time: date,
          list: newData1,
        })
      }
      articleList = [...articleList, ...newData]

      this.setState({
        articleList: [...articleList],
        filter,
        Topping,
        total: res.totalElements,
        hasMore: !res.last //这个字段应该是表示查询到的是否是最后的数据,不确定
      })
    }).catch(err => {
      message.error(err.message)
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  onChange(key: number) {
    let { filter } = this.state
    filter.pageNum = 1

    if (key === 0) {
      filter.tagType = '1,4,5'
    } else {
      filter.tagType = key
    }

    this.setState({
      active: key,
      isDetail: false,
      loading: true,
      total: 1
    })
    this.getData(filter, true)
  }

  handle() {
    let { filter, hasMore } = this.state
    if (hasMore) {
      filter.pageNum = Number(filter.pageNum) + 1
      this.getData(filter)
    } else {
      message.warn('亲，没有更多内容啦')
    }
  }

  render() {
    let { sponsors, total, honors, loading, thmem, active, titleList, articleList, Topping, isDetail, chooseInfo } = this.state
    return (
      <div className="container " style={{ width: '100%', minHeight: '90vh', background: thmem?.background }}>
        <Spin spinning={loading}>
          {/* 标题列表 */}
          <TitleList active={active} titleList={titleList} onChange={(e) => this.onChange(e)} />
          <div className='container1'></div>
          {/* 最新大图 */}
          {
            Topping?.length > 0 &&
            <PictureBackground Topping={Topping[Topping?.length - 1]} />
          }
          {/* 内容 */}
          {
            total > 0 ?
              <ContentMessage
                articleList={articleList}
                type={1}
                onClick={() => this.handle()}
              />
              : <NoData show={true} />
          }
          {/* 荣誉图片列表 */}
          <Honr honors={honors} />
          {/* 赞助商列表 */}
          <Sponsors sponsors={sponsors} />
          <div
            style={{ padding: '10px 0' }}
            dangerouslySetInnerHTML={{ __html: thmem?.bottomHtml || ' ' }}
          />
        </Spin>
      </div >
    );
  }
}
export default New