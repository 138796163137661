import * as React from 'react';
import './personalIndex.css'
import Integral from './integral/index'
import Coupon from './coupon/index'
import Bank from './bank/index'
import MyEnrollList from './myActivity/myEnrollList'
import MyQuizList from './myActivity/myQuizList'
import Collection from './MyCollection/collection'
import MyVoteList from './myActivity/myVoteList'
import Fantasy from './fantasy/index'
import Order from './order/list'
import AfterSaleList from './order/list/afterSales/afterSales'
import ClubBenfits from './clubBenfits/index'
import VipCenter from './vipCenter/index'
import AccountData from './accountData/index'
import { Center } from './center'
import LastMyTicket from './myTicket/index'
import { ServiceAccount } from '../../service/account/index'
import { ServiceEnroll } from '../../service/enroll/index'
import { message, Menu, Spin } from 'antd';
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";//引入routerdom

const { SubMenu } = Menu;
function PersonalCenter() {
  const tipObj = React.useRef(null)
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const icons = JSON.parse(localStorage.getItem('icons') || '{}')
  const [enrollItem, setEnrollItem] = React.useState<any>([])
  const [data, setData] = React.useState<any>({})
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [loadding, setLoadding] = React.useState<boolean>(false)
  const [selectedKeys, setSelectedKeys] = React.useState<any>(['center1'])
  const [openKeys, setOpenKeys] = React.useState<any>(['center1', 'sub1', 'sub2', 'sub3', 'sub4'])

  React.useEffect(() => {
    if (window.location.href.indexOf('/personal/center/my/account') === -1) {
      //@ts-ignore
      tipObj?.current?.style?.setProperty("--menu-background-color", thmem?.background3);
      //@ts-ignore
      tipObj?.current?.style?.setProperty("--menu-color", thmem?.fontColor3);
      setOpenKeys([...openKeys, getOpenKeys(window.location.href.split('szfc/w')[1])])
      setSelectedKeys([getSelectedKeys(window.location.href.split('szfc/w')[1])])
    }
  }, [])

  function getData() {
    Promise.all([
      ServiceAccount.getAccountDetail(),
      ServiceEnroll.listMine({ pageNum: 1, pageSize: 1 }),
    ]).then(res => {
      setData(res[0])
      setEnrollItem(res[1]?.content)
      localStorage.setItem(
        'USER_INFO',
        JSON.stringify(res[0])
      );
      sessionStorage.setItem(
        'myMemberCardName',
        res[0].currentMemberCard ? res[0].currentMemberCard.name : ''
      );
    }).catch(err => {
      message.error(err.message)
    }).finally(() => {
      setLoadding(false)
    })
  }
  function getOpenKeys(value) {
    let key = ''
    switch (value) {
      case '/personal/center/vip/center': key = 'center1'; break;
      case '/personal/center/my/vip/center':
      case '/personal/center/my/club/benfits': key = 'sub1'; break;
      case '/personal/center/my/collection':
      case '/personal/center/my/fantasy':
      case '/personal/center/my/ticket':
      case '/personal/center/my/integral':
      case '/personal/center/my/coupon':
      case '/personal/center/my/bank': key = 'sub2'; break;
      case '/personal/center/my/vote':
      case '/personal/center/my/quiz':
      case '/personal/center/my/enroll': key = 'sub3'; break;
      case '/personal/center/my/order/0':
      case '/personal/center/my/order/1':
      case '/personal/center/my/order/2':
      case '/personal/center/my/order/3':
      case '/personal/center/my/order/4':
      case '/personal/center/after/sale': key = 'sub4'; break;
    }
    return key
  }
  function getSelectedKeys(value) {
    let key = ''
    switch (value) {
      case '/personal/center/vip/center': key = 'center1'; break;
      case '/personal/center/my/vip/center': key = '1'; break;
      case '/personal/center/my/club/benfits': key = '2'; break;
      case '/personal/center/my/collection': key = '3'; break;
      case '/personal/center/my/fantasy': key = '4'; break;
      case '/personal/center/my/ticket': key = '5'; break;
      case '/personal/center/my/integral': key = '6'; break;
      case '/personal/center/my/coupon': key = '7'; break;
      case '/personal/center/my/bank': key = '8'; break;
      case '/personal/center/my/vote': key = '9'; break;
      case '/personal/center/my/quiz': key = '10'; break;
      case '/personal/center/my/enroll': key = '11'; break;
      case '/personal/center/my/order/0': key = '12'; break;
      case '/personal/center/my/order/1': key = '12'; break;
      case '/personal/center/my/order/2': key = '12'; break;
      case '/personal/center/my/order/3': key = '12'; break;
      case '/personal/center/my/order/4': key = '12'; break;
      case '/personal/center/after/sale': key = '13'; break;
    }
    return key
  }

  return (
    <Spin spinning={loadding}>
      {
        window.location.href.indexOf('/personal/center/my/account') >= 0
          ?
          <Routes>
            <Route path="/my/account" element={<AccountData />} />
          </Routes>
          :
          <section className='personal-container'>
            <aside ref={tipObj} >
              <Menu
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                mode="inline"
                theme="dark"
                onSelect={(e) => setSelectedKeys([...e.selectedKeys])}
              >
                <Menu.Item key="center1" >
                  <Link to="/personal/center/vip/center">用户中心首页</Link>
                </Menu.Item>
                <SubMenu key="sub1" title="权益中心">
                  <Menu.Item key="center">
                    <Link to="/personal/center/my/vip/center">会员权益</Link>
                  </Menu.Item>
                  <Menu.Item key="benfits">
                    <Link to="/personal/center/my/club/benfits">合作伙伴福利</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu key="sub2" title="资产中心">
                  <Menu.Item key="collection"><Link to="/personal/center/my/collection">数字藏品</Link></Menu.Item>
                  <Menu.Item key="fantasy">
                    <Link to="/personal/center/my/fantasy">范特西</Link>
                  </Menu.Item>
                  <Menu.Item key="ticket"><Link to="/personal/center/my/ticket">电子球票</Link></Menu.Item>
                  <Menu.Item key="integral"><Link to="/personal/center/my/integral">积分</Link></Menu.Item>
                  <Menu.Item key="coupon"><Link to="/personal/center/my/coupon">优惠券</Link></Menu.Item>
                  <Menu.Item key="bank"><Link to="/personal/center/my/bank">银行卡</Link></Menu.Item>
                </SubMenu>
                <SubMenu key="sub3" title="活动中心">
                  <Menu.Item key="enroll"><Link to="/personal/center/my/enroll">报名</Link></Menu.Item>
                  <Menu.Item key="vote"><Link to="/personal/center/my/vote">投票</Link></Menu.Item>
                  <Menu.Item key="quiz"><Link to="/personal/center/my/quiz">竞猜</Link></Menu.Item>
                </SubMenu>
                <SubMenu key="sub4" title="订单中心">
                  <Menu.Item key="order"><Link to="/personal/center/my/order/0">我的订单</Link></Menu.Item>
                  <Menu.Item key="sale"><Link to="/personal/center/after/sale">我的售后</Link></Menu.Item>
                </SubMenu>
              </Menu>
              <div style={{ width: '100%', height: '60px' }}></div>
            </aside>
            <main style={{ marginLeft: '200px', height: '100%' }}>
              <div style={{ width: '100%', position: 'relative' }}>
                <Routes>
                  <Route path="/" element={<Center />} />
                  <Route path="/vip/center" element={<Center />} />
                  <Route path="/my/bank" element={<Bank />} />
                  <Route path="/my/integral" element={<Integral />} />
                  <Route path="/my/order/:key" element={<Order />} />
                  <Route path="/my/coupon" element={<Coupon />} />
                  <Route path="/my/fantasy" element={<Fantasy />} />
                  <Route path="/my/vip/center" element={<VipCenter />} />
                  <Route path='/my/ticket' element={<LastMyTicket />} />
                  <Route path="/my/quiz" element={<MyQuizList />} />
                  <Route path="/my/vote" element={<MyVoteList />} />
                  <Route path="/my/collection" element={<Collection />} />
                  <Route path="/my/club/benfits" element={<ClubBenfits />} />
                  <Route path="/accountData" element={<AccountData />} />
                  <Route path="/my/enroll" element={<MyEnrollList />} />
                  <Route path="/after/sale" element={<AfterSaleList />} />
                </Routes>
              </div>

            </main>
          </section>
      }
    </Spin>
  );
}
export default PersonalCenter