import * as React from 'react';
import './index.css'

interface TitleList {
  active: number
  titleList: any[]
  onChange: (key: number, index?: number) => void
}

export function TitleList(prop: TitleList) {
  let { active = -1, titleList, onChange } = prop
  const tipObj = React.useRef(null)
  let thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')

  React.useEffect(() => {
    function init() {
      //@ts-ignore
      tipObj?.current.style.setProperty("--drop-down-color", thmem?.fontColor4);
    }
    init();
  }, []);

  return (
    <div className="new-top_title" ref={tipObj} style={{ backgroundColor: thmem?.background2, left: window.location.href.indexOf("/personal/center") != -1 ? '200px' : 0 }}>
      <ul className="new-ul" style={{ color: thmem?.fontColor2 }}>
        {
          titleList?.map((el: any, index: number) =>
            <li className={active === el.id ? "active new-li" : "new-li"} style={{ borderColor: active === el.id ? '' : thmem?.background2 }} onClick={() => onChange(el.id, index)}>
              <p className="li-title" style={{ color: thmem?.fontColor2 }}>{el.name}</p>
            </li>
          )
        }
      </ul>
    </div>
  );
}
