import { message } from 'antd';
import { info } from 'console';
import * as React from 'react';
import './gift.css'
interface giftProp {
  goods: any
  chooseGift: (item: any) => void
}
export default function Gift(prop: giftProp) {
  let { goods, chooseGift } = prop
  const [num, setNum] = React.useState<number>(1); //存数量
  const [specifications, setSpecifications] = React.useState<any>([]);
  const [isAddCart, setIsAddCart] = React.useState<boolean>(false)
  const [spec1_spec2, set_spec1_spec2] = React.useState<any>([]);
  const [spec2_spec1, set_spec2_spec1] = React.useState<any>([]);
  const [dataSource, setDataSource] = React.useState<any>([]);
  const [$spec, set$Spec] = React.useState<any>({ spec1: '', spec2: '' });
  const [dataInfo, setDataInfo] = React.useState<any>({ spec1: '', spec2: '' });
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}');

  React.useEffect(() => {
    async function init() {
      const good = goods ? goods : ([] as any);
      let specificationList: any = [];
      let specs1: any = [];
      let specs2: any = [];
      let specification_1 = '';
      let specification_2 = '';

      let spec1_spec2: any = {};
      let spec2_spec1: any = {};
      let dataSource: any = [];

      (good ? good.specifications : []).map((i: any, index) => {
        const {
          specification1,
          specification2,
          referencePrice,
          totalAmount,
          amount,
          price,
        } = i;

        //保存以规格1为key与规格2对应的数据
        if (spec1_spec2.hasOwnProperty(specification1)) {
          spec1_spec2[specification1].push(specification2);
        } else {
          spec1_spec2[`${specification1}`] = [specification2];
        }
        //保存以规格2为key与规格1对应的数据
        if (spec2_spec1.hasOwnProperty(specification2)) {
          spec2_spec1[specification2].push(specification1);
        } else {
          spec2_spec1[`${specification2}`] = [specification1];
        }

        dataSource.push({
          key: `${i.specification1}-${i.specification2}`,
          referencePrice,
          totalAmount,
          amount,
          price,
          //   image: i.image,
        });

        if (specification1) {
          specs1.push({
            spec: specification1,
            referencePrice,
            totalAmount,
            amount,
            price,
            // image: i.image,
            focus: false,
            disabled: false,
            order: 1,
          });
        }
        if (specification2) {
          specs2.push({
            spec: specification2,
            referencePrice,
            totalAmount,
            amount,
            price,
            image: i.image,
            focus: false,
            disabled: false,
            order: 2,
          });
        }
        if (index === 0) {
          specification_1 = i.specification1;
          specification_2 = i.specification2;
        }
      });

      if (specs1.length > 0) {
        let hash = {};
        let arr = specs1.reduce(function (arr, current) {
          if (hash[current.spec]) {
          } else {
            (hash[current.spec] = true && arr.push(current))
          }
          // hash[current.spec]
          //   ? ''
          //   : (hash[current.spec] = true && arr.push(current));
          return arr;
        }, []);
        specificationList.push({
          specification: goods.specification1,
          specs: arr,
          order: 1,
        });
      }
      if (specs2.length > 0) {
        let hash = {} as any;
        let arr = specs2.reduce(function (arr, current) {
          if (!hash[current.spec]) {
            (hash[current.spec] = true && arr.push(current))
          }
          // hash[current.spec]
          //   ? ''
          //   : (hash[current.spec] = true && arr.push(current));
          return arr;
        }, []);
        specificationList.push({
          specification: goods.specification2,
          specs: arr,
          order: 2,
        });
      }
      set_spec1_spec2(spec1_spec2);
      set_spec2_spec1(spec2_spec1);
      setDataSource([...dataSource]);
      setSpecifications([...specificationList]);
    }
    init()
  }, [])
  let spec1 = '',
    spec2 = '';
  function onChangeColor(i, index1, index2) {
    let enabaleArr = i.order === 1 ? spec1_spec2[i.spec] : spec2_spec1[i.spec];
    // 如果是点击了 选中的项,就取消选中
    if (true === i.focus) {
      specifications[index1].specs.map(item => (item.focus = false));
      specifications.map(a => {
        if (i.order !== a.order) {
          a.specs.map(b => {
            b.disabled = false;
          });
        }
      });
      setSpecifications([...specifications]);
      return;
    }

    specifications[index1].specs.map(item => (item.focus = false));
    // 设置选中的项
    specifications[index1].specs[index2].focus = true;

    // 将选中的项保存起来
    if (i.order === 1) {
      spec1 = i.spec;
      set$Spec({ ...$spec, spec1: i.spec });
    } else if (i.order === 2) {
      spec2 = i.spec;
      set$Spec({ ...$spec, spec2: i.spec });
    }

    specifications.map(a => {
      if (i.order !== a.order) {
        a.specs.map(b => {
          // 判断选中的这项是否存在与对应的另一个规格的项
          if (!enabaleArr.includes(b.spec)) {
            // 不存在,将对应的这一规格中的这一项置灰 则是非选项
            b.disabled = true;
            b.focus = false;
          } else {
            // 存在,然后判断这项的数量是否大于0,<=0 置灰不能选,>0 则是能选项
            b.disabled =
              b.totalAmount - b.amount <= 0 || i.totalAmount - i.amount <= 0
                ? true
                : false;
          }
          if (b.focus) {
            if (b.order === 1) {
              spec1 = b.spec;
              set$Spec({ ...$spec, spec1: b.spec, spec2 });
            } else if (b.order === 2) {
              spec2 = b.spec;
              set$Spec({ ...$spec, spec1, spec2: b.spec });
            }
          }
        });
      }
    });
    setSpecifications([...specifications]);

    i.order === 1 ? (spec1 = i.spec) : (spec2 = i.spec);

    dataSource.map(ds => {
      if (ds.key === `${spec1}-${spec2}`) {
        setDataInfo(ds);

      }
    });
  }
  function sureChoose() {
    if (specifications.length > 1) {
      if (!($spec.spec1 && $spec.spec2)) {
        message.error('请完善赠品规格')
        return
      }
    } else if (specifications.length > 0) {
      if (!($spec.spec1 || $spec.spec2)) {
        message.error('请完善赠品规格')
        return
      }
    }
    const gifts = {
      specification2Key: goods.specification2,
      specification1Key: goods.specification1,
      specification2: $spec.spec2,
      specification1: $spec.spec1,
      goodsId: goods.id,
      logo: goods.logo,
      name: goods.name,
    }
    chooseGift(gifts)
  }

  return (
    <div className="container" style={{ width: '100%', background: thmem?.background, position: 'relative' }}>
      <div className="merchandise">
        <div className="left-detail">
          <div className="active-detail-message">
            <div
              dangerouslySetInnerHTML={{ __html: goods?.detail || ' ' }}
            />
          </div>
        </div>
        <div className="right-purchase">
          <div className="purchase-title">{goods.name}</div>
          <div className="purchase-message">
            <div className="message-img">
              <img src={goods.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <div className="message-text">
              <p>请选择</p>
              <p className="goods-price" style={{ marginTop: '15px' }}>￥{goods.defaultPrice}</p>
              {/* <p className="goods-amount">库存：{goods.amount}</p> */}
            </div>
          </div>
          <div className="purchase-button">
            {
              specifications?.map((item: any, index: number) =>
                <div className="button-item">
                  <p className="button-item-title">{item?.specification}</p>
                  <div className="button-button">
                    {
                      item?.specs?.map((el: any, ind: number) =>
                        <p style={
                          el?.focus
                            ? {
                              backgroundColor: '#c31c32',
                              color: '#fff',
                              cursor: 'pointer'
                            }
                            : { cursor: 'pointer' }
                        } onClick={() => onChangeColor(el, index, ind)}>{el.spec} </p>
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
          <div className="purchase-bottom">
            {/* <div onClick={()=>{}} className="add-cart">加入购物车</div> */}
            <div onClick={() => { sureChoose() }} className="buy-now">确认选择</div>
          </div>
        </div>
      </div>
    </div >
  );
}
