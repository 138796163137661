import * as React from 'react';
import './index.css'
import { Recommend } from './recommend'
import SeripheralGoods from './seripheralGoods'

import { TitleList } from '../../component/TitleList/index'
import { message } from 'antd';
class ShoppingMall extends React.Component<any, any> {
  state = {
    active: 2,
    // { id: 1, name: '推荐' },
    titleList: [{ id: 2, name: '数字藏品' }, { id: 3, name: '周边商品' }],
    thmem: JSON.parse(sessionStorage.getItem('thmem') || '{}')
  };
  componentDidMount() {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    console.log('--------');

    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    console.log(menuArray?.indexOf('/shopping/mall'));

    sessionStorage.setItem('headerActive', menuArray?.indexOf('/shopping/mall'))
  }
  onChange(key: number) {
    this.setState({ active: key });

  }

  render() {
    let { thmem, active, titleList } = this.state
    return (
      <div style={{
        marginTop:titleList?.length>0 ?'55px':'35px',
        width: '100%', minHeight: '100vh', background: thmem?.background
      }
      }>
        <div className="container1"></div>
        {/* 标题列表 */}
        <TitleList active={active} titleList={titleList} onChange={(e) => this.onChange(e)} />
        {
          active === 1 &&
          <Recommend onChange={(key) => this.setState({ active: key })} />
        }
        {
          active === 3 &&
          <SeripheralGoods active={active} />
        }
        {
          active === 2 &&
          <SeripheralGoods active={active} />
        }
      </div >
    );
  }
}
export default ShoppingMall