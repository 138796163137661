import * as React from 'react';
import './index.css'
import { Table, Form, Select, Spin, Button } from 'antd';
import { ServiceMutower } from '../../service/mutower';
import Checkbox from 'antd/lib/checkbox/Checkbox';

export function ScheduleList() {
  const [dataSource, setDataSource] = React.useState<any>([])
  let [filter, setFilter] = React.useState<any>({ pageSize: 10, pageNum: 1 })
  const [phaseList, setPhaseList] = React.useState<any>([])
  const [scheduleList, setScheduleList] = React.useState<any>([])
  const [turnList, setTurnList] = React.useState<any>([])
  const [groupsList, setGroupsList] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [seasonId, setSeasonId] = React.useState<any>('')
  const [mutowerMyTeams, setMutowerMyTeams] = React.useState<any>([])
  const [teamsId, setTeamsId] = React.useState<any>('')
  const [onlySee, setOnlySee] = React.useState<boolean>(false)
  const [turnIndex, setTurnIndex] = React.useState<number>(-1)

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      render: (t, row, index) => index + 1
    },
    {
      title: '轮次',
      dataIndex: 'turn',
    },
    {
      title: '分组',
      dataIndex: 'group',
    },
    {
      title: '比赛日期',
      dataIndex: 'gameTime',
      render: (r, row) => (
        <span>{row.gameTime?.substr(0, 10)}</span>
      )
    },
    {
      title: '比赛时间',
      dataIndex: 'gameTime',
      render: (r, row) => (
        <span>{row.gameTime?.substr(11)}</span>
      )
    },
    {
      title: '主队',
      dataIndex: 'hostTeam',
      render: (r, row) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <img style={{ width: '20px', height: '20px', marginRight: '10px' }} src={row.hostLogo} alt="" />
          <span>{row.hostTeam}</span>
        </div>
      )
    },
    {
      title: '客队',
      dataIndex: 'clientTeam',
      render: (r, row) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <img style={{ width: '20px', height: '20px', marginRight: '10px' }} src={row.clientLogo} alt="" />
          <span>{row.clientTeam}</span>
        </div>
      )
    },
    {
      title: '比赛场地',
      dataIndex: 'address',
    },
    {
      title: '比赛结果',
      dataIndex: 'result',
    },
    {
      title: '比赛状态',
      dataIndex: 'status',
    }
  ]
  React.useEffect(() => {

    ServiceMutower.mutowerSeason().then(async res => {
      setScheduleList(res)
      let seasonId = res[0].seasonId
      let { phase, turn } = await ServiceMutower.getCurrentTurn(res[0].seasonId)
      let phaseId = (phase || {}).id
      let turnId = (turn || {}).id
      filter = { ...filter, phaseId, turnId, seasonId }
      getPhase(seasonId)
      setFilter(filter)
      getTurnList(phaseId)
      getData(filter)
      getMutowerMyTeams(seasonId)
    })

  }, [])

  function getMutowerMyTeams(seasonId){
    ServiceMutower.mutowerMyTeams({seasonId,domain:'szfc'}).then(res=>{
      setMutowerMyTeams(res)
      if(res.length === 1){
        setTeamsId(res[0].teamId)
      }
    })
  }

  function getData(value) {
    ServiceMutower.mutowerGames(value).then(res => {
      setDataSource(res)
    })
  }

  function getPhase(seasonId) {
    ServiceMutower.mutowerDictQuery({ dictType: 100, seasonId: seasonId }).then(res => {
      setPhaseList(res)
      setSeasonId(seasonId)
    })
  }

  function getList(phaseId) {
    filter.turnId = undefined
    filter.groupId = undefined
    Promise.all([
      ServiceMutower.mutowerDictQuery({ dictType: 101, seasonId: seasonId, phaseId: phaseId }),
      ServiceMutower.mutowerDictQuery({ dictType: 102, seasonId: seasonId, phaseId: phaseId })
    ]).then(res => {
      setTurnList(res[0])
      setGroupsList(res[1])
      setFilter(filter)
    })
  }

  function getTurnList(phaseId) {
    ServiceMutower.mutowerDictQuery({ dictType: 101, seasonId: seasonId, phaseId: phaseId })
      .then(res => {
         res.map((el,index)=>{
          el.name = '第'+el.name+'轮'
          if(filter.turnId === el.id){
            setTurnIndex(index)
          }
         })
         
        setTurnList(res)
      })
  }

  function handleFilterChange(key, value) {
    filter[key] = value
    setFilter(filter)
    getData(filter)
  }

  function handleFilterChange1(key, value) {
    setTeamsId(value)
    if(onlySee){
      filter[key] = value.join(',')
      setFilter(filter)
      getData(filter)
    }
    
  }

  function handleFilterChange2(value) {
    setOnlySee(value.target.checked)
    if(teamsId){
      filter['teamIds'] = value.target.checked? teamsId :undefined
      setFilter(filter)
      getData(filter)
    }
    
  }
  function handleFilterChange3(key,value) {
    turnList.map((el,index)=>{
      if(el.id === value){
        setTurnIndex(index)
      }
    })
    filter[key] = value
    setFilter(filter)
    getData(filter)
  }


  function onSeasonChange(seasonId) {
    getPhase(seasonId)
    setFilter({seasonId,pageSize: 10, pageNum: 1})
    getData({seasonId,pageSize: 10, pageNum: 1})
    getMutowerMyTeams(seasonId)
    setOnlySee(false)
  }

  const formItemLayout = {
    labelCol: { sm: { span: 7 }, },
    wrapperCol: { sm: { span: 13 } }
  }
console.log(filter);

  return (
    <Spin spinning={loading}>
      <div className='Schedule' style={{ width: '90%', margin: '20px auto', display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
        <Form.Item className='Schedule' style={{ marginTop: '20px', width: '30%' }} label="赛季赛事" {...formItemLayout} >
          <Select
            defaultValue={filter?.seasonId}
            value={filter?.seasonId}
            onChange={onSeasonChange}
            placeholder="请选择阶段"
          >
            <Select.Option value='' key=''>全部</Select.Option>
            {
              scheduleList?.map(el =>
                <Select.Option value={el.seasonId} key={el.seasonId}>{el.seasonName}</Select.Option>
              )
            }
          </Select>
        </Form.Item>
      {/* </div>
      <div  style={{ width: '90%', margin: '20px auto', display: 'flex', alignItems: 'center', justifyContent: 'start' }}> */}
      <Form.Item className='Schedule' style={{ marginTop: '20px', width: '15%' }} {...formItemLayout} >
          <Select defaultValue={filter?.phaseId} value={filter?.phaseId} onChange={(v) => { getList(v); handleFilterChange('phaseId', v) }} placeholder="请选择阶段">
            <Select.Option value='' key=''>全部</Select.Option>
            {
              phaseList?.map(el =>
                <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
              )
            }
          </Select>
      </Form.Item>
      <Form.Item className='Schedule' style={{ marginTop: '20px', width: '15%' }} {...formItemLayout} >
        <Select defaultValue={filter?.groupId} value={filter?.groupId} onChange={(v) => handleFilterChange('groupId', v)} placeholder="请选择分组">
          <Select.Option value=''>全部</Select.Option>
          {
            groupsList?.map(el =>
              <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
            )
          }
        </Select>
      </Form.Item>
      <Form.Item className='Schedule' style={{ marginTop: '20px', width: '10%' }} {...formItemLayout} >
        <Select defaultValue={filter?.turnId} value={filter?.turnId} onChange={(v,Option) => handleFilterChange3('turnId', v)} placeholder="请选择轮次">
          <Select.Option value=''>全部</Select.Option>
          {
            turnList?.map((el) =>
              <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
            )
          }
        </Select>
      </Form.Item>
      <span style={{margin:'0 10px',color:turnIndex<=0?'#ccc':'#082ef3',cursor:'pointer'}}  onClick={turnIndex<=0?()=>{}:()=>{handleFilterChange('turnId', turnList[turnIndex-1]?.id);setTurnIndex(turnIndex-1)}} >上一轮</span>
      <span style={{margin:'0 10px',color:turnIndex>=turnList.length-1?'#ccc':'#082ef3',cursor:'pointer'}} onClick={
        turnIndex<turnList.length-1?
        ()=>{handleFilterChange('turnId', turnList[turnIndex+1]?.id);setTurnIndex(turnIndex+1)}
        :()=>{}}>下一轮</span>
      {/* <Button type={turnIndex<=0?'default':'primary'} disabled={turnIndex<=0} onClick={()=>{handleFilterChange('turnId', turnList[turnIndex-1]?.id);setTurnIndex(turnIndex-1)}} style={{margin:'0 10px'}}>上一轮</Button>
      <Button type={turnIndex>=turnList.length-1?'default':'primary'} disabled={turnIndex>=turnList.length-1} onClick={()=>{handleFilterChange('turnId', turnList[turnIndex+1]?.id);setTurnIndex(turnIndex+1)}}  style={{margin:'0 10px'}}>下一轮</Button> */}
      {
        mutowerMyTeams?.length>0 &&
      <div style={{display:'flex',alignItems:'center'}}>
          <Checkbox checked={onlySee} onChange={(e)=>handleFilterChange2(e)}>
            只看
          </Checkbox>
          {
              mutowerMyTeams?.length ===1 ?
                <div style={{display:'flex',alignItems:'center'}}>
                  <img src={mutowerMyTeams[0]?.teamLogo} alt="" style={{width:'20px',height:'20px'}}/>
                 <span>{mutowerMyTeams[0]?.teamName}</span>
                </div>
              :
              <Select style={{width:'120'}} defaultValue={teamsId}  mode='multiple'  onChange={(v) => handleFilterChange1('teamIds', v)} placeholder="请选择分组">
              {
                mutowerMyTeams?.map(el =>
                  <Select.Option value={el.teamId} key={el.teamId}>{el.teamName}</Select.Option>
                )
              }
            </Select>

            }
        </div>
      }
      </div>
      <Table
        style={{ width: '95%', margin: '0 auto' }}
        bordered
        rowKey={(r, index) => `org_${index}`}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <div style={{ width: '100%', height: '20px' }}></div>
    </Spin>
  )

}