import React, { useLayoutEffect } from 'react';
import { ServiceCommon } from './service/common/index'
import { ServiceSystem } from './service/system/index'
import './App.css';
import Header from './page/header';
import Home from './page/home';
import New from './page/new'
import { NewDetail } from './page/new/detail'
import PersonalCenter from './page/personal-center'
import Activity from './page/activity'
import EnrollDetail from './page/activity/detail/enroll'
import VoteDetail from './page/activity/detail/vote'
import QuizDetail from './page/activity/detail/quiz'
import Ticket from './page/ticket';
import ShoppingMall from './page/shoppingMall';
import GoodsPlaceOrder from './page/shoppingMall/merchandise/placeOrder/index';
import Merchandise from './page/shoppingMall/merchandise/index';
import { Club } from './page/club';
import DigitalCollection from './page/shoppingMall/digitalCollection/index'
import CollectionPlaceOrder from './page/shoppingMall/digitalCollection/placeOrder/index'
import TicketPlaceOrder from './page/ticket/placeOrder/index'
import ShoppingCart from './page/shoppingCart/cart';
import ChooseTicketList from './page/ticket/chooseTicketlist/index'
import Goods from './page/goods/index'
import Collection from './page/collection/index'
import PurchaseTickets from './page/ticket/chooseTicketlist/purchaseTickets'
import { Routes, Route, Navigate } from "react-router-dom";//引入routerdom
import { localStorageEnum, sessionStorageEnum } from './enum';
import { CustomRouter, history } from './CustomRouter';
import { useWindowSize } from './utils/useWindowSize';

function App() {
  const [width, height] = useWindowSize();

  // 获取背景色和字体颜色
  React.useEffect(() => {
    async function init() {
      localStorage.setItem('DOMAIN', window.location.pathname.split('/')[1]);
      ServiceCommon.getOrgInfo().then(res => {
        document.title = res?.clientTitle || '球迷服务平台';
        localStorage.setItem('orgName', res?.orgName);
      }).catch(error => {
        console.log(error);
      });

      if (localStorage.getItem(localStorageEnum.TOKEN)) {
        ServiceSystem.tokenLogin(
          localStorage.getItem(localStorageEnum.TOKEN) || '',
          localStorage.getItem(localStorageEnum.DOMAIN) || ''
        ).then(res => {
          if (res.token) {
            localStorage.setItem(localStorageEnum.TOKEN, res.token);
            sessionStorage.setItem(sessionStorageEnum.IS_LOGIN_, 'TRUE');
            sessionStorage.setItem('orgName', res.orgName);
            localStorage.setItem('homeDomain', res?.domain);
          }
        }).catch(err => {
          sessionStorage.removeItem(sessionStorageEnum.IS_LOGIN_);
        });
      }
    }
    init()
  }, [])

  return (
    <div className="App">
      <CustomRouter history={history}>
        {/* <BrowserRouter > */}
        <Header ></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/shopping/cart" element={<ShoppingCart />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/enroll/detail" element={<EnrollDetail />} />
          <Route path="/enroll/detail/:id/:tagType" element={<EnrollDetail />} />
          <Route path="/vote/detail" element={<VoteDetail />} />
          <Route path="/vote/detail/:id/:tagType" element={<VoteDetail />} />
          <Route path="/quiz/detail" element={<QuizDetail />} />
          <Route path="/quiz/detail/:id/:tagType" element={<QuizDetail />} />
          <Route path="/new" element={<New />} />
          <Route path="/new/detail" element={<NewDetail />} />
          <Route path="/new/detail/:id/:tagType" element={<NewDetail />} />
          <Route path="/club" element={<Club />} />
          <Route path="/shopping/mall" element={<ShoppingMall />} />
          <Route path="/goods" element={<Goods />} />
          <Route path="/collection" element={<Collection />} />
          <Route path="/shopping/merchandise" element={<Merchandise />} />
          <Route path='/goods/placeOrder' element={<GoodsPlaceOrder />} />
          <Route path='/goods/digital/collection' element={<DigitalCollection />} />
          <Route path='/collection/placeOrder' element={<CollectionPlaceOrder />} />

          <Route path="/ticket" element={<Ticket />} />
          <Route path='/ticket/placeOrder' element={<TicketPlaceOrder />} />
          <Route path="/choose/ticket/list" element={<ChooseTicketList />} />
          <Route path="/choose/ticket/list/:gameId" element={<ChooseTicketList />} />
          <Route path="/choose/ticket/purchase" element={<PurchaseTickets />} />
          <Route path="/personal/center/*" element={<PersonalCenter />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
        {/* </BrowserRouter> */}
      </CustomRouter>
    </div>

  );
}

export default App;
