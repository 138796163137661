import * as React from 'react';
import './integral.css'
import Login from '../../loginHome/index'
import Util from '../../../utils';
import {
  IntegralContent,
  ServiceMember,
} from '../../../service/member/member';
import { message, Spin, Card, Table } from 'antd';
import { _pageData } from '../../../service/common_i';
/**
 * 积分
 * @returns 
 */
function Integral() {
  const [list, setList] = React.useState<any>([])
  const [totalElements, setTotalElements] = React.useState<number>(0)
  const [pageNum, setPageNum] = React.useState<number>(1)
  const [loadding, setLoadding] = React.useState<boolean>(true)
  const [isLogin, setIsLogin] = React.useState<boolean>(false)

  async function getData() {
    const inter = await ServiceMember.queryMemberPoints();
    console.log(inter?.content);
    
    setList(inter?.content)
    setTotalElements(inter?.totalElements)
    setLoadding(false)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setLoadding(false)
      return
    }
    getData()
  }, []);

  const columns = [
    {
      title: '序号',
      render: (t, r, index) => (pageNum - 1) * 10 + index + 1
    },
    {
      title: '积分',
      dataIndex: 'entity.points',
      render: (t, r, index) => 
      <span>{r.entity.points}</span>
    },
    {
      title: '明细',
      dataIndex: 'title',
    },
    {
      title: '时间',
      dataIndex: 'entity.createTime',
      render: (t, r, index) => 
      <span>{r.entity.createTime}</span>
    },
    {
      title: '交易组织',
      dataIndex: 'address',
      render: (t, r, index) => 
      <span>{r.org.orgName}</span>
    },
    {
      title: '剩余',
      dataIndex: 'address',
      render: (t, r, index) => 
      <span>{r.entity.lefted}</span>
    },
  ];
  console.log(list);
  
  return (
    <Spin spinning={loadding}>
      <div className='integral-collection'>
        <p className="totalIntgral">总积分<span>{list[0]?.entity.totalPoints}</span></p>
        {/* <p className="availableIntgral">当前可用<span>322223</span></p> */}
      </div>
      <Card title="积分收支明细" extra={''}>
        <Table
          columns={columns}
          dataSource={list}

          pagination={{
            current: pageNum,
            pageSize: 10,
            total: totalElements,
            showTotal: total => `共 ${total} 条记录，每页显示 10 条记录`,
            onChange: pageNum => { }
            // this.getData(pageNum)
          }}
        />
      </Card>
      {
        isLogin &&
        <Login onLogin={() => { getData(); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </Spin>
  );
}
export default Integral