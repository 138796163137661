import * as React from 'react';
// import './myticket.css'
import Login from '../../../loginHome/index'
import Util from '../../../../utils';
import { RenderOrderItem } from './orderItem'
import OrderDetails from './orderDetail'
import NftDetails from './nftDetail'
import InvoiceAdd from '../../invoice/add'
import AfterSaleDetails from './afterSales/afterSaleDetails'
import { DissHistory } from './dissHistory/index'
import { PayMent } from '../payment/index'
import CollectionDetail from '../../MyCollection/collectionDetail' //数字藏品详情
import { TitleList } from '../../../../component/TitleList/index'
import { ServiceOrder, OrderContent } from '../../../../service/order';
import { Pagination, message, Spin, Modal, Divider, } from 'antd';
import { deduplication, subStr, subStrEllipsis } from '../../../../utils/tool';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NoData } from '../../../../component/noData';
/**
 * 
 * @returns 
 */
function OrderList() {
  const params = useParams()
  const [active, setActive] = React.useState<number>(Number(params?.key))
  const [totalElements, setTotalElements] = React.useState<number>(1)
  const [pageNum, setPageNum] = React.useState<number>(1)
  const [pageSize, setPageSize] = React.useState<number>(20)
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [list, setList] = React.useState<any[]>([])
  const [show, setShow] = React.useState<boolean>(false)
  const [type, setType] = React.useState<any>(-1)
  const [orderId, setOrderId] = React.useState<any>(undefined)
  const [loadding, setLoadding] = React.useState<boolean>(true)
  const [receiving, setReceiving] = React.useState<boolean>(false)
  const [id, setId] = React.useState<any>()
  const allIcon = JSON.parse(localStorage.getItem('icons') || '{}')
  const titleList = [
    { id: 0, name: '全部' },
    { id: 1, name: '待付款' },
    { id: 2, name: '待发货' },
    { id: 3, name: '待收/取货' },
    { id: 4, name: '已完成' }
  ]
  const title = ['订单详情', '订单详情', '提交信息', '开具发票', '售后申请', '售后详情', '订单支付']
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  function getOrderList(pageNum, pageSize) {
    setLoadding(true)
    window.scrollTo(0, 0)
    ServiceOrder.getAllOrder(pageNum, pageSize, active)
      .then(res => {
        setList([...res.content])
        setPageNum(pageNum)
        setPageSize(pageSize)
        setTotalElements(res.totalElements)
      })
      .catch(e => {
        message.error(e.message);
      })
      .finally(() => {
        setLoadding(false);
      });
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setLoadding(false)
      return
    }
    getOrderList(1, pageSize)
  }, [active]);

  function onChange(key: number) {
    setActive(key)
  }

  // 取消票的订单
  function cancelOrder(id) {
    ServiceOrder.cancelOrder(id)
      .then(res => {
        message.success('删除成功')
        getOrderList(pageNum, pageSize);
      })
      .catch(err => {
        message.error(err.message);
      });
  }

  function clickOrderStatus(status, id): void {
    switch (status) {
      case 1:
        setOrderId(id)
        setType(6)
        setShow(true)
        // 去支付
        // Util.jumpPage(ROUTER_NAME.GO_PAY.path, {
        //   status: active,
        //   orderId: id,
        // });
        break;
      case 2:
        orderTap(id);
        // 我要催单 弹框提示
        break;
      case 3:
        // 确认取货，弹框提示 确认收货后调用接口 刷新
        openPickUpAlert(id);
        break;
      case 4:
        // 查看物流，跳转物流详情
        // Util.jumpPage(ROUTER_NAME.LOGISTICS_DETAIL.path, {
        //   status: currentTab,
        //   id: id,
        //   orderList: 1,
        // });
        break;
      case 10:
        // 申请开票
        setType(3); setOrderId(id); setShow(true)
        // Util.jumpPage(ROUTER_NAME.INVOICE.path, { status: currentTab, id });
        break;
      case 11:
      // 发票详情
      case 12:
      case 13:
      case 16:
        // 发票详情
        // Util.jumpPage(ROUTER_NAME.INVOICE_INFO.path, {
        //   status: currentTab,
        //   id,
        // });
        break;

      default:
        break;
    }
  }

  function orderTap(id) {
    ServiceOrder.orderTap(id)
      .then(res => {
        message.success(res.data.message)
      })
      .catch(err => {
        message.error(err.message);
      });
  }

  function onDelete(orderId) {
    Modal.confirm({
      title: '提示',
      content: '删除订单之后不可恢复，需要重新下单哦',
      okText: '确认',
      cancelText: '取消',
      bodyStyle: { margin: '10px' },
      onOk: () => {
        cancelOrder(orderId)
      }
    })
    // openDeleteAlert(() => cancelOrder(orderId));
  }
  function clickOrderItem(item: OrderContent): void {
    setOrderId(item.orderId)
    setShow(true)
    setType(item.itemType === 6 ? 1 : 0)
    //藏品订单详情与周边商品订单详情不一样
    // const path =
    //   item.itemType === 6
    //     ? ROUTER_NAME.COLLECTION_ORDER_DETAIL.path
    //     : ROUTER_NAME.ORDER_INFO.path;
    // Util.jumpPage(path, { status: currentTab, id: item.orderId });
  }
  function cancel() {
    if (type == 0 || type == 1 || type == 3) {
      setShow(false)
      setOrderId(undefined)
      setType(undefined)
    } else {
      setType(0)
    }
  }
  function openPickUpAlert(id) {
    Modal.confirm({
      title: '提示',
      content: '请到取货地址当面确认取货，订单将不能再次取货，请确认',
      okText: '确认',
      cancelText: '取消',
      bodyStyle: { margin: '10px' },
      onOk: () => {
        pickUp(id)
      }
    })
  }
  function pickUp(id) {
    ServiceOrder.pinckUp(id)
      .then(res => {
        getOrderList(pageNum, pageSize)
      })
      .catch(err => {
        message.error(err.message)
      });
  }

  return (
    <Spin spinning={loadding}>
      {/* <div className="container" style={{ width: '100%', background: thmem?.background, minHeight: '90vh' }}> */}
      <div className="container1"></div>
      <TitleList active={active} titleList={titleList} onChange={(e) => onChange(e)} />
      {
        totalElements > 0 ?
          <div style={{ width: '100%' }}>
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center' }}>
              {
                list?.map((item, index) =>
                  <RenderOrderItem
                    key={index + item.orderId + 'order'}
                    onDelete={(id) => onDelete(id)}
                    onClick={(item) => clickOrderItem(item)}
                    onClickStatus={(status, id) => clickOrderStatus(status, id)}
                    item={item}
                  />
                )
              }

            </div>
            <div className='goods-pagination'>
              <Pagination
                pageSize={pageSize}
                size="small"
                current={pageNum}
                total={totalElements}
                onChange={(page, pageSize) => { getOrderList(page, pageSize) }}
                onShowSizeChange={(current, size) => { getOrderList(current, size) }}
              />
            </div>
          </div>
          :
          <NoData show={true} />
      }
      <Modal
        title={title[type]}
        visible={show}
        footer={null}
        style={{ padding: 'none', overflow: 'unset' }}
        onCancel={() => cancel()}
      >
        {
          type == 0 ?
            <OrderDetails afterSale={(id, key) => { setOrderId(id); setType(key) }} orderId={orderId} />
            : type == 1 ?
              <NftDetails orderId={orderId} onChange={() => setType(2)} />
              : type == 2 ?
                <CollectionDetail orderId={orderId} />
                : type == 3 ?
                  <InvoiceAdd id={orderId} onCancel={() => setType(-1)} />
                  : type == 4 ?
                    <AfterSaleDetails consult={() => setType(5)} orderId={orderId} />

                    : type == 5 ?
                      <DissHistory orderId={orderId} />
                      : type == 6 ?
                        <PayMent onCancel={() => setType(-1)} orderId={orderId} />
                        : <div></div>
        }
      </Modal>
      {
        isLogin &&
        <Login onLogin={() => { getOrderList(1, pageSize); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </Spin>
  );
}
export default OrderList