import { message, Modal, Input } from 'antd';
import * as React from 'react';
import { PASSWORD_REGEXP, PHONE_REGEXP, NICKNAME_REGEXP } from '../../configs/RegExp';
import { ServiceSystem } from '../../service/system'
import { ServiceAccount } from '../../service/account'
import Util from '../../utils'
import './login.css'
import { sessionStorageEnum } from '../../enum';
import { useDownTime } from '../../hooks/useDownTime';
interface LoginHomeProp {
  onCancel: () => void
  onLogin: (nickName: string) => void
}
export default function Register(prop: LoginHomeProp) {
  let { onCancel, onLogin } = prop
  const [phone, setPhone] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [code, setCode] = React.useState<string>('')
  const [newPassword, setNewPassword] = React.useState<string>('')
  const [surePassword, setSurePassword] = React.useState<string>('')
  const [nickName, setNickName] = React.useState<string>('')

  const downTime = useDownTime()

  async function submitForm() {
    try {
      if (!phone || !phone.match(PHONE_REGEXP)) {
        return message.error('请输入正确的手机号!');
      }
      if (!code) {
        return message.error('请输入验证码!');
      }
      if (!password || !password.match(PASSWORD_REGEXP)) {
        return message.error('请输入正确的密码!');
      }
      if (!NICKNAME_REGEXP.test(nickName.toString())) {
        return message.error('请填写正确的昵称!');
      }

      const res = await ServiceSystem.register(
        { phone, nickName, code, password: Util.hashPassword(password) },
      );

      const detail = await ServiceAccount.getAccountDetail()
      localStorage.setItem(sessionStorageEnum.USER_INFO, JSON.stringify(detail))
      Util.setLoginInfo(res.token);
      message.success('注册成功!');
      onLogin(nickName)
      setPhone('')
      setCode('')
      setPassword('')
      setNewPassword('')
      setNickName('')
      await Util.sleep(500);
    } catch (err: any) {
      message.error(err.message);
    }
  }

  async function getVerifyCode(): Promise<any> {
    try {
      if (downTime.time > 0) {
        return
      }
      if (!phone || !phone.match(PHONE_REGEXP)) {
        message.error('请输入正确的手机号!');
        return Promise.reject(new Error('0'));
      }
      await ServiceSystem.getVerifyCode(phone, phone, '1');
      message.info('验证码已发送!');
      downTime.start(120)
      return Promise.resolve(1);
    } catch (err: any) {
      message.error(err.message);
      return Promise.reject(err);
    }
  }

  return (
    <Modal
      visible={true}
      footer={null}
      onCancel={() => onCancel()}
    >
      <div style={{ width: '100%', backgroundColor: '#fff' }}>
        <div className='login-box'>
          <div className="login-title">欢迎注册，成为我们的一员</div>
          <div className="phone">
            <div className="phone-title" >手机号码</div>
            <div className='yanzhengma' style={{ width: '100%', position: 'relative' }}>
              <Input value={phone} onChange={(e: any) => setPhone(e.target.value)} />
              <span
                onClick={() => getVerifyCode()}
                className='register-code'
                style={{}}
              >
                {downTime.time > 0 ? downTime.time + 's' : '发送验证'}
              </span>
            </div>

            <div className="phone-title ">验证码</div>
            <Input value={code} onChange={(e: any) => setCode(e.target.value)} />
            <div className="phone-title">密码</div>
            <Input.Password
              style={{ width: 226 }}
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </div>
          <div className='prompt-information'>6~20位字符，包含字母、数字和特殊字符的<strong style={{ color: 'black' }}>两种</strong></div>
          <div className="phone">
            <div className="phone-title">确认新密码</div>
            <Input.Password
              style={{ width: 226 }}
              value={surePassword}
              onChange={(e: any) => setSurePassword(e.target.value)}
            />
            <div className="phone-title">昵称</div>
            <Input value={nickName} onChange={(e: any) => setNickName(e.target.value)} />
          </div>
          <div className="login-button" onClick={() => submitForm()}>注册</div>
        </div>
        <div className='completed' onClick={() => onLogin(nickName)}>
          已经注册
        </div>
      </div>
    </Modal>
  );
}