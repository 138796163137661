import * as React from 'react';
import './bank.css'
import Login from '../../loginHome/index'
import Util from '../../../utils';
import { BankAccountService, AccountBody } from '../../../service/bankAccount';
import { Popconfirm, message, Spin, Modal, Button, Form, Input, } from 'antd';
const giftCardImage = require('../../../assets/image/giftCard.png');
/**
 * 数字藏品
 * @returns 
 */
function Bank() {
  const [list, setList] = React.useState<AccountBody[]>([])
  const [show, setShow] = React.useState<boolean>(false)
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [loadding, setLoadding] = React.useState<boolean>(true)
  const [bankName, setBankName] = React.useState<string>('')
  const [bankNumber, setBankNumber] = React.useState<string>('')
  const [accountName, setAccountName] = React.useState<string>('')
  const allIcon = JSON.parse(localStorage.getItem('icons') || '{}')
  function getData() {
    BankAccountService.list().then(res => {
      setList(res.content)
    }).finally(() => {
      setLoadding(false)
    })
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      return
    }
    getData()
  }, []);

  async function showCode() {
    setShow(true)
  }
  function deleteConfirm(id) {
    BankAccountService.delete(id)
      .then(res => {
        message.success('删除成功')
        getData();
      })
      .catch(err => {
        message.error(err.message);
      });
  }
  function submit() {
    if (bankName && accountName && bankNumber) {
      let body = {
        entity: {
          bankName: bankName,
          accountName: accountName,
          bankNumber: bankNumber,
        },
      };
      BankAccountService.add(body)
        .then(res => {
          message.success('新增成功')
          getData()
        })
        .catch(err => {
          message.error(err.message);
        }).finally(() => {
          setShow(false)
        })
    } else {
      message.error('请完善银行卡信息')
    }

  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  return (
    <Spin spinning={loadding}>
      <div className="voucher">
        {
          list?.map(i =>
            <div className='bank-item'>
              <div style={{ display: 'flex' }}>
                <h3>{i.entity.accountName}</h3>
                <div className='bank-middle'>{i.entity.bankNumber}</div>
              </div>
              <div style={{ color: '#888888', fontSize: '14px', textAlign: 'left' }}>
                {i.entity.bankName && i.entity.bankName.length > 20
                  ? i.entity.bankName + '...'
                  : i.entity.bankName}
              </div>
              <Popconfirm
                title="是否删除？"
                okText="确认"
                cancelText="取消"
                onConfirm={() => deleteConfirm(i.entity.id)}
              >
                <Button size='small' style={{ position: 'absolute', top: '5px', right: '5px' }}>
                  删除
                </Button>
              </Popconfirm>

            </div>
          )
        }
        <Button onClick={() => showCode()} style={{ marginTop: '20px', marginLeft: '1.6%' }} type="primary" size={'large'}>
          新增银行卡
        </Button>
      </div>
      <Modal
        title="新增银行卡"
        visible={show}
        footer={null}
        onCancel={() => { setShow(false) }}
      >
        <div style={{ width: '100%', background: '#fff', padding: '20px 0' }}>
          <Form {...formItemLayout} >
            <Form.Item required label="收款银行">
              <Input onChange={v => setBankName(v.target.value)} />
            </Form.Item>
            <Form.Item required label="银行卡号">
              <Input onChange={v => setBankNumber(v.target.value)} />
            </Form.Item>
            <Form.Item required label="姓名">
              <Input onChange={v => setAccountName(v.target.value)} />
            </Form.Item>
          </Form>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button onClick={() => submit()} style={{ margin: '0 16%' }} type="primary" >
              确认
            </Button>
            <Button onClick={() => { setShow(false) }} type="primary" >
              取消
            </Button>
          </div>
        </div>
      </Modal>
      {
        isLogin &&
        <Login onLogin={() => { getData(); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </Spin>
  );
}
export default Bank