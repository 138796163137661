import * as React from 'react';
import './peopleList.css'
import { Service_Geo } from '../../../service/geo'
import { ServiceAddress } from '../../../service/address'
import { Checkbox, Cascader, Spin, List, message, Input, InputNumber, Radio, Button } from 'antd'
interface AddressEntity {
  address: string;
  cityId: number;
  first: boolean;
  fullAddress: string;
  id?: number;
  name: string;
  phone: string;
  provinceId: number;
  regionId: number;
}
interface AddressProp {
  isChoose?: boolean
  onChoose?: (item: any) => void
  address?: AddressEntity
}

function Address(props: AddressProp) {
  let { isChoose = false, onChoose, address } = props
  const [loading, setLoading] = React.useState<boolean>(true)
  const [addressList, setAddressList] = React.useState<any>([])
  const [addressEntity, setAddressEntity] = React.useState<AddressEntity>({} as AddressEntity)
  const [provinces, setProvinces] = React.useState<any>()
  const [options, setOptions] = React.useState<any[]>([])
  const [chooseOption, setChooseOptions] = React.useState<AddressEntity>({} as AddressEntity)
  const [isEdit, setEdit] = React.useState<boolean>(false)

  React.useEffect(() => {
    getFarstData();
    getData()
  }, [])

  React.useEffect(() => {
    setChooseOptions(address as AddressEntity)
    setOptions([])
    setAddressEntity({} as AddressEntity)
  }, [address])

  async function getFarstData() {
    try {
      const allAddress = await ServiceAddress.getAddressList(0, 99);
      console.log(allAddress.content);
      setAddressList(allAddress.content)
      setLoading(false)
    } catch (e: any) {
      message.error(e.message)
    }
  }
  function checkAddressForm(data: AddressEntity) {
    if (!addressEntity) {
      message.error('请先填写表单!');
      return false;
    }
    const { name, address } = addressEntity;
    if (!name || (name.length && name.length < 2)) {
      message.error('收货人姓名长度需要在2-25个字符');
      return false;
    }

    if (!options) {
      message.error('请选择你的收货区域!');
      return false;
    }

    if (!address || (address && (address.length < 4 || address.length > 200))) {
      message.error('详细地址为4-200个字符!');
      return false;
    }
    return true;
  }
  function onSubmit() {
    if (JSON.stringify(chooseOption) === '{}') {
      message.error('请选择地址')
    } else {
      onChoose && onChoose(chooseOption)
    }
  }
  async function onSave() {
    try {

      if (!checkAddressForm(addressEntity)) {
        return;
      }
      addressEntity.provinceId = options[0]
      addressEntity.cityId = options[1]
      addressEntity.regionId = options[2]

      if (addressEntity.id) {
        await ServiceAddress.modifyAddress(addressEntity);
        getData()
        setOptions([])
        getFarstData()
        setAddressEntity({} as AddressEntity)
        message.success('修改成功!');
      } else {
        await ServiceAddress.addAddress(addressEntity);
        getData()
        setOptions([])
        getFarstData()
        setAddressEntity({} as AddressEntity)
        message.success('新增成功!');
      }
      setEdit(false)
    } catch (e: any) {
      message.error(e.message);
    }
  }

  function handleChoose(key, value) {
    addressEntity[key] = value
    setAddressEntity({ ...addressEntity })
  }

  // 加载地址国家数据
  async function getData() {
    //省
    const province = await Service_Geo.getGeoAll();
    setProvinces(province)
  }

  function onChange(value) {
    setOptions(value)
  }

  function onEdit(item) {
    setEdit(true)
    setOptions([item.entity.provinceId, item.entity.cityId, item.entity.regionId]);
    setAddressEntity({ ...item.entity, fullAddress: item.address })
  }

  return (
    <Spin spinning={loading}>
      <div className='people-box'>
        <List style={{ width: '95%' }}>
          {
            addressList?.map((item, index) =>
              <List.Item key={item?.entity.id}>
                <List.Item.Meta
                  title={
                    <div style={{ cursor: 'pointer' }} onClick={() => { setChooseOptions({ ...item?.entity, address: item?.address + item?.entity?.address }) }}>
                      <span>{item.entity.name}</span>
                      <span style={{ marginLeft: '20px', color: '#00000073' }}>{item.entity.phone}</span>
                    </div>
                  }
                  description={
                    <div style={{ cursor: 'pointer' }} onClick={() => { setChooseOptions({ ...item?.entity, address: item?.address + item?.entity?.address }) }}>
                      {
                        item.entity.first &&
                        <span className='location-item-default'>默认</span>
                      }
                      {item.address}
                    </div>}
                />
                <a style={{ marginRight: '10px' }} onClick={() => onEdit(item)}>编辑</a>
                {
                  isChoose &&
                  <Radio checked={item?.entity?.id == chooseOption?.id} onChange={() => { setChooseOptions({ ...item?.entity, address: item?.address + item?.entity?.address }) }}></Radio>
                }
              </List.Item>
            )}
        </List>

      </div>
      {
        isChoose &&
        <div className="people-button" onClick={() => onSubmit()}>提交</div>
      }
      <div style={{ height: '1px', width: '100%', backgroundColor: '#ccc' }}></div>

      {isEdit ?
        <div className="add-people">
          <div className="add-people-box">
            <div className="label">姓名</div>
            <div className="input-value">
              <Input style={{ width: 220 }} value={addressEntity?.name} onChange={(e) => handleChoose('name', e.target.value)} type="text" />
            </div>
          </div>
          <div className="add-people-box">
            <div className="label">手机号码</div>
            <InputNumber style={{ width: 220 }} controls={false} value={addressEntity?.phone} type="text" onChange={(e) => handleChoose('phone', e)} />
          </div>
          <div className="add-people-box">
            <div className="label">地区信息</div>
            <div className="input-value">
              <Cascader
                value={options}
                options={provinces}
                style={{ width: 320 }}
                fieldNames={{ label: "name", value: "id", children: "children" }}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="add-people-box">
            <div className="label">详细地址</div>
            <div className="input-value">
              <Input style={{ width: 342 }} placeholder="请填写详细地址,不少于4个字" value={addressEntity?.address} onChange={(e) => handleChoose('address', e.target.value)} type="text" />
            </div>
          </div>
          <div style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
            <Checkbox checked={addressEntity?.first} onChange={(e) => handleChoose('first', e.target.checked)} >设置为默认收货地址</Checkbox>
          </div>
          <div className="people-button" onClick={() => onSave()}>保存</div>
        </div>
        :
        <div
          className="people-button"
          style={{ width: 200 }}
          onClick={() => setEdit(true)}>
          新增地址
        </div>
      }
    </Spin>
  );
}
export default Address