import * as React from 'react';
import './index.css'
import { ServiceHomePage, GamesBody, GameTopBody } from '../../service/homePage/index';
import { Honr } from '../../component/honr/index';
import { Sponsors } from '../../component/sponsors/index'
import { TicketTop } from './top'
import { TicketList } from './ticketlist'
import { Spin } from 'antd'
import { stat } from 'fs';

class Ticket extends React.Component<any, any> {
  state = {
    sponsors: [] as any,
    honors: [] as any,
    gameList: [] as GamesBody[],
    top: {} as GameTopBody,
    thmem: JSON.parse(sessionStorage.getItem('thmem') || '{}'),
    loading: true,
    domain: localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]
  };
  componentDidMount() {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    sessionStorage.setItem('headerActive', menuArray?.indexOf('/ticket'))
    window.scrollTo(0, 0)
    Promise.all([
      ServiceHomePage.getSponsors(),
      ServiceHomePage.getHonors(),
    ]).then(res => {
      this.setState({
        sponsors: res[0],
        honors: res[1],
      })
    }).catch(err => {
    }).finally(() => {
      this.setState({ loading: false })
    })
    this.getData()
    this.getGameTop()
  }
  getGameTop() {
    ServiceHomePage.getGameTop({ domain: this.state.domain }).then(res => {
      this.setState({ top: res })
    })
  }
  getData() {
    ServiceHomePage.getGameList(2).then(res => {
      this.setState({
        gameList: res.games
      })

    })
  }

  render() {
    let { sponsors, honors, thmem, gameList, top, loading } = this.state
    let backgroundImage = ''
    thmem?.images?.map((el: any) => {
      if (el.imageType === 7) {
        backgroundImage = el.imageUrl
      }
    })
    return (
      <div style={{ position: 'relative', marginTop: '35px', width: '100%', minHeight: '92vh', background: thmem?.background }}>
        <Spin spinning={loading}>
          {/* 最新大图 */}
          {/* 内容 */}
          {
            JSON.stringify(top) !== '{}' &&
            <TicketTop isButtom={true} top={top} />
          }
          {/* 推荐图片 */}
          {
            backgroundImage &&
            <div style={{ width: '100%' }}>
              <img src={backgroundImage} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
          }
          {
            gameList?.length > 0 &&
            <TicketList gameList={gameList} />
          }
          {/* 荣誉图片列表 */}
          <Honr honors={honors} />
          {/* 赞助商列表 */}
          <Sponsors sponsors={sponsors} />
        </Spin>
        <div style={{ height: '100px', width: '100%' }}>
        </div>
        <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
          <div
            style={{ padding: '10px 0', margin: '0 auto' }}
            dangerouslySetInnerHTML={{ __html: thmem?.bottomHtml || ' ' }}
          />
        </div>

      </div >
    );
  }
}
export default Ticket