import * as React from 'react';
import { Select, DatePicker, TimePicker, Input, Upload } from 'antd';
import './ticketComponent.css'
import {
  ServiceEnroll,
  MineBody,
  SubmitInfoItem,
} from '../../../../service/enroll';
import Util from '../../../../utils';
const { Option } = Select
const { TextArea } = Input
interface submitProp {
  enrollId: string
}
export default function SubmitInfo(prop: submitProp) {
  let { enrollId } = prop
  const [fields, setFields] = React.useState<any[]>([]);
  const [info, setInfo] = React.useState<MineBody>({} as MineBody);

  React.useEffect(() => {
    ServiceEnroll.queryMine(enrollId).then(res => {
      setInfo(res);
      setFields(res.submitContent);
    });
  }, []);

  function toModify() {
    // Util.jumpPage(ROUTER_NAME.ENROLL_SUBMIT_INFO.path, {
    //   enrollId: enrollId || '',
    //   id: info.enrollmentId,
    //   itemName: info.itemName,
    //   modify: 'true',
    // });
  }


  let signInEndTime;
  let nowTime = new Date().getTime();
  try {
    signInEndTime = new Date(info.signInEndTime).getTime();
  } catch (error) {
    console.log(error);
  }

  return (
    <div style={{ backgroundColor: 'white', width: '95%', margin: '0 auto' }}>
      {
        // fields?.map((el, index) =>
        //   (el.type === 1 || el.type === 2 || el.type === 3 || el.type === 4) ?
        //     <div className="info-option">
        //       <div className="option-name">{el.name}</div>
        //       {
        //         el.type === 1 ?
        //           <Select defaultValue=" " style={{ width: 120 }} onChange={(e) => { fields[index].inputValue = e; setFields([...fields]) }} >
        //             {
        //               el.values?.map((item, index) =>
        //                 <Option value={item}>{item}</Option>
        //               )
        //             }
        //           </Select>
        //           : el.type === 2 ?
        //             <div>
        //               <DatePicker mode="date" placeholder='选择日期' format="YYYY-MM-DD" onChange={(e, date) => { fields[index].inputValue = date; setFields([...fields]) }} />
        //             </div>
        //             : el.type === 3 ?
        //               <div>
        //                 <TimePicker placeholder='选择时间' onChange={(e, time) => { fields[index].inputValue = time; setFields([...fields]) }} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
        //               </div>
        //               : el.type === 4 ?
        //                 <div>
        //                   <DatePicker placeholder='选择日期' format="YYYY-MM-DD HH:mm:ss" onChange={(e, date) => { fields[index].inputValue = date; setFields([...fields]) }} />
        //                 </div>
        //                 : ''
        //       }
        //     </div>
        //     :
        //     <div className="info-option1">
        //       <div className="option-name">{el.name}</div>
        //       {
        //         el.type === 5 ?
        //           <TextArea rows={2} onChange={(e) => {
        //             fields[index].inputValue = e.target.value; setFields([...fields])
        //           }} />
        //           : el.type === 6 ?
        //             <div style={{ textAlign: 'left' }}>
        //               <Upload
        //                 listType="picture-card"
        //                 fileList={fileList}
        //                 showUploadList={{ showPreviewIcon: false }}
        //                 onChange={({ fileList }) => setFileList(fileList)}
        //               >
        //                 {fileList.length >= el.maxLength ? null : uploadButton}
        //               </Upload>
        //             </div>
        //             : ''
        //       }

        //     </div>

        // )
        fields?.map((el, index) =>
          (el.type === 1 || el.type === 2 || el.type === 3 || el.type === 4)
            ?
            <div className="info-option">
              <div className="option-name">{el.title}</div>
              <div className='option-content'>{el.content[0]}</div>
            </div>
            :
            <div className="info-option1">
              <div className="option-name">{el.title}</div>
              {
                el.type === 5 ?
                  <div className='option-content'>{el.content[0]}</div>
                  : el.type === 6 &&
                  <div style={{ textAlign: 'left' }}>
                    <Upload
                      listType="picture-card"
                      fileList={el.content.map((i, index) => ({
                        id: index,
                        url: i,
                      }))}
                      showUploadList={{ showPreviewIcon: false, showRemoveIcon: false }}
                    >
                    </Upload>
                  </div>
              }
            </div>
        )}
      <div
        className="bottom-btn"
        onClick={toModify}
        style={{
          display: signInEndTime && signInEndTime > nowTime ? 'block' : 'none',
        }}
      >
        修改
      </div>
    </div>
  );
}
