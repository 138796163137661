import * as React from 'react';
import './cart.css'
import Util from '../../utils/'
import Login from '../loginHome/index'
// import Rejister from '../loginHome/register'
// import Retrieve from '../loginHome/retrieve'
import { ServiceMember, CardListContent } from '../../service/member/member'
import { Checkbox, message, Button } from 'antd'
import { useNavigate } from 'react-router-dom';
import { sessionStorageEnum } from '../../enum';

export default function ShoppingCart() {
  const allIcon = JSON.parse(localStorage.getItem('icons') || '{}')
  let thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const [isLogin, setIsLogin] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<Boolean>();
  const [shopsList, setShopsList] = React.useState<CardListContent[]>([]);
  const [totalPrice, setTotalPrice] = React.useState<number>(0);
  const [chooseNum, setChooseNum] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      // setType(1)
      setIsLogin(true)
      setIsLogin(true)
      return;
    }
    getGoodsList();
  }, [refresh]);

  async function getGoodsList() {
    // setType(0)
    setIsLogin(false)
    setLoading(true)
    try {
      const cartContent = await ServiceMember.queryCartData(1, 999);
      let datas = cartContent.content;
      let ids: number[] = [];

      if (shopsList.length > 0) {
        ids = shopsList.filter(i => i.selected).map(p => p.entity.id);
      }
      datas.map(item => {
        if (ids.includes(item.entity.id)) {
          item.selected = true;
        }
        return item;
      });
      setShopsList(datas);
      setLoading(false)

      countingTotalPrice(datas);
    } catch (e: any) {
      message.error(e.message)
    }
  }

  function countingTotalPrice(datasource?) {
    let totalPrice = 0;
    let chooseNum = 0
    let data = datasource || shopsList;
    data.map(goods => {
      if (goods.selected) {
        chooseNum += 1
        totalPrice +=
          (goods.memberPrice ? goods.memberPrice : goods.price) *
          goods.entity.amount;
      }
    });
    setChooseNum(chooseNum)
    setTotalPrice(totalPrice);
  }

  function checkboxChange(id, index, e) {
    shopsList.map((i, ind) => {
      if (index === ind) {
        i.selected = !i.selected;
      }
    });
    countingTotalPrice();
    setShopsList([...shopsList]);
  }

  function Invalid() {
    alert('此商品已经下架');
  }

  function changeNum(item, type) {
    let amount;
    if (type === 'plus') {
      amount = item.entity.amount + 1;
    } else {
      amount = item.entity.amount - 1;
    }
    const body = {
      entity: {
        ...item.entity,
        amount,
      },
      specification1: item.specification1, //规格1的内容，如红色
      specification2: item.specification2, //规格2的内容，如小码
    };

    ServiceMember.modifyCart(body)
      .then(res => {
        setRefresh(!refresh)
        countingTotalPrice();
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  }

  function onDelete(item) {
    ServiceMember.delectCartData([item.entity.id]).then(res => {
      message.success('删除成功')
      getGoodsList();
    });
  }

  let arr = [],
    amount = 0;
  function handlerSubmit() {
    let isok = true;
    let selectedGoods = shopsList.filter(i => i.selected);

    selectedGoods.map(i => {
      if (i.status !== 2) {
        isok = false;
      }
    });
    if (!isok) {
      message.error('选择的商品中存在已失效商品');
      return;
    }
    selectedGoods.map(item => {
      let cart = {
        logo: item.logo,
        name: item.name,
        rawCost: item.price, //价格
        amount: item.entity.amount, //数量
        goodsId: item.entity.goodsId,
        specification1: item.specification1,
        specification2: item.specification2,
      };
      amount += item.entity.amount;
      arr.push(cart as never);
      //SET数据到本地的key
    });
    sessionStorage.setItem(
      'goodsPlaceOrder',
      JSON.stringify({ arr, express: true, expressMoney: 12, amount })
    );
    navigate('/goods/placeOrder')
  }

  return (
    <div className='container2' style={{ width: '100%', minHeight: '100vh', backgroundColor: thmem?.background }} >
      <div style={{ width: '85%', minHeight: '80vh', backgroundColor: '#fff', margin: '0 auto', display: 'flex', justifyContent: 'left', position: 'relative' }} >
        <div className="cartLeft">
          <h1 className='cart-name'>购物车</h1>
          {
            shopsList?.map((el, index) =>
              <div className="cart-info">
                <div className="checked">
                  <Checkbox checked={el?.selected} onChange={(e) => checkboxChange(el.entity.id, index, e)} />
                </div>
                <div className="cart-message">
                  <div className="goods-logo">
                    <img src={el.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </div>
                  <div className="goods-text">
                    <p>{el.name}</p>
                    <p>规格：{el.specification1} {el.specification2}</p>
                  </div>
                </div>
                <div className="cart-num">
                  <p onClick={
                    el.status === 2 ? () => changeNum(el, 'minus') : Invalid
                  }>-</p>
                  <input value={el.entity.amount} type="text" />
                  <p onClick={
                    el.status === 2 ? () => changeNum(el, 'plus') : Invalid
                  }>+</p>
                </div>
                <div className="operation">
                  <Button type="primary" danger onClick={() => onDelete(el)} >删除</Button>
                </div>
                <div className="cart-price">￥{el.price * el.entity.amount}</div>
              </div>
            )
          }

        </div>
        <div className="cartRigth">
          <div className="orderTitle">订单内容</div>
          <div className="order-info">
            <div className="order-info-choose-num">
              <p className="choose-num-title">已选</p>
              <p className="choose-num-option">{chooseNum} 项</p>
            </div>
            <div className="order-info-choose-num">
              <p className="choose-num-title">小计</p>
              <p className="choose-num-option">￥ {totalPrice}</p>
            </div>
          </div>
          {/* /shopping/settlement */}
          <div onClick={() => handlerSubmit()} className="start-calculation">
            开始结算
          </div>
        </div>
        {
          isLogin &&
          <Login onLogin={() => getGoodsList()} onCancel={() => setIsLogin(false)} />
        }
      </div>
    </div>
  );
}
