import * as React from 'react';
import './myticket.css'
import Login from '../../loginHome/index'
import Util from '../../../utils';
import EnrollDetail from './component/enrollDetail'
import OptionModify from './component/optionModify'
import SubmitInfo from './component/SubmitInfo'
import { MineBody, ServiceEnroll } from '../../../service/enroll';
import { Pagination, message, Spin, Modal, } from 'antd';
import { deduplication, subStr, subStrEllipsis } from '../../../utils/tool';
import { NoData } from '../../../component/noData';
/**
 * 数字藏品
 * @returns 
 */
function MyEnrollList() {
  const [list, setList] = React.useState<MineBody[]>([])
  const [info, setInfo] = React.useState<any>({})
  const [total, setTotal] = React.useState<number>(0)
  const [pageNum, setPageNum] = React.useState<number>(1)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [show, setShow] = React.useState<boolean>(false)
  const [type, setType] = React.useState<any>(undefined)
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [loadding, setLoadding] = React.useState<boolean>(true)
  const [isNull, setIsNull] = React.useState<boolean>(true)
  const [enrollId, setEnrollId] = React.useState<any>(undefined)
  const allIcon = JSON.parse(localStorage.getItem('icons') || '{}')
  const title = ['报名信息', '报名选项', '提交信息']
  function getData(pageNum, pageSize) {
    setLoadding(true)
    ServiceEnroll.listMine({ pageNum, pageSize })
      .then(res => {
        setPageNum(pageNum)
        setPageSize(pageSize)
        setList(res.content)
        setTotal(res.totalElements)
        setIsNull(res.totalElements > 0)
      })
      .catch(err => {
        message.error(err.message);
      }).finally(() => {
        setLoadding(false)
      })
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setLoadding(false)
      return
    }
    getData(1, pageSize)
  }, []);

  function toDetail(id) {
    setEnrollId(id)
    setType(0)
    setShow(true)
  }
  function getColor(reimburseTag) {
    return ['报销待提交', '报销待寄送', '报销已打款'].includes(reimburseTag)
      ? '#C31C32'
      : '#B7B4B4';
  }
  async function toSignIn(tag, code) {
    if (tag === '待签到') {
      // let qrcode = await QRCode.toDataURL(code);
      // Modal.alert('', <img width={400} src={qrcode} />);
    }
  }
  function toReimburse(item) {
    // switch (item.reimburseTag) {
    //   case '报销待提交':
    //     Util.jumpPage(ROUTER_NAME.SUBMIT_REIMBURSE_INFO.path, {
    //       id: item.enrollmentId,
    //     });
    //     break;
    //   default:
    //     Util.jumpPage(ROUTER_NAME.REIMBURSE_PROGRESS.path, {
    //       id: item.enrollmentId,
    //       enrollId: item.id,
    //     });
    //     break;
    // }
  }
  function toOption(info) {
    if (info.itemApplyToSign) {
      setInfo({
        id: info.enrollmentId,
        itemName: info.itemName,
        enrollId: info.id,
        orderId: info.orderId,
      })
      setType(1)
    }
  }
  function toSumitInfo(info) {
    if (info.hasSubmitContent && enrollId) {
      setType(2)
      // Util.jumpPage(ROUTER_NAME.SUBMIT_INFO.path, { enrollId: info.id });
    }
  }
  function cancel() {
    if (type == 0) {
      setShow(false)
      setEnrollId(undefined)
      setType(undefined)
    } else {
      setType(0)
    }
  }

  return (
    <Spin spinning={loadding}>
      {
        isNull ?
          <div className="enroll">
            {list.map(i => (
              <div key={i.id} className='enroll-item'>
                <span style={{ color: '#C31C32', fontSize: '1.2rem' }}>
                  {i.main.name}
                </span>
                <div className='enroll-content' onClick={() => toDetail(i.id)}>
                  <img src={i.logo} alt="" />
                  <div className='enroll-middle'>
                    <div
                      style={{
                        fontSize: '1.4rem',
                        height: '50px',
                        marginBottom: '0.5rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {subStrEllipsis(i.name, 31)}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                      <div>活动时间: </div>
                      <div>{`${subStr(i.activityStartTime, 0, 16)} ~ ${subStr(
                        i.activityEndTime,
                        0,
                        16
                      )}`}</div>
                    </div>
                  </div>
                  {/* <Icon type="right" style={{ height: 80 }} /> */}
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p style={{ flex: 1 }}>{i.orgName}</p>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div
                      className='enroll-status'
                      style={{
                        color: getColor(i.reimburseTag),
                        display: i.reimburseTag ? 'block' : 'none',
                        marginRight: '20px',
                        border: `1px solid ${getColor(i.reimburseTag)}`,
                      }}
                      onClick={() => toReimburse(i)}
                    >
                      {i.reimburseTag}
                    </div>
                    <div
                      className='enroll-status'
                      style={{
                        color: i.tag === '待签到' ? '#C31C32' : '#B7B4B4',
                        border: `1px solid ${i.tag === '待签到' ? '#C31C32' : '#B7B4B4'
                          }`,
                      }}
                      onClick={() => toSignIn(i.tag, i.code)}
                    >
                      {i.audit && i.tag === '已报名'
                        ? i.audit.name.substring(5)
                        : i.tag}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className='goods-pagination'>
              <Pagination
                pageSize={pageSize}
                size="small"
                current={pageNum}
                total={total}
                onChange={(page, pageSize) => { getData(page, pageSize) }}
                onShowSizeChange={(current, size) => { getData(current, size) }}
              />
            </div>
            <Modal
              title={title[type]}
              visible={show}
              footer={null}
              style={{ padding: 'none', overflow: 'unset' }}
              onCancel={() => cancel()}
            >
              <div style={{ width: '100%', backgroundColor: 'white' }}>
                {
                  type == 0 ?
                    <EnrollDetail cancel={() => { setType(0); cancel() }} toOption={(item) => toOption(item)} toSumitInfo={(item) => toSumitInfo(item)} id={enrollId} />
                    : type == 1 ?
                      <OptionModify id={info.id} itemName={info.itemName} enrollId={info.enrollId} orderId={info.orderId} />
                      : type == 2 ?
                        <SubmitInfo enrollId={enrollId} />
                        : <div></div>
                }
              </div>

            </Modal>
          </div>
          : <NoData show={true} />
      }
      {
        isLogin &&
        <Login onLogin={() => { getData(1, pageSize); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </Spin>
  );
}
export default MyEnrollList