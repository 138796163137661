import * as React from 'react';
import './orderDetail.css'
import utils from '../../../../utils';
import { Countdown } from '../../../../component/Countdown'
import { QueryOrderInfo, ServiceOrder, express } from '../../../../service/order';
import {
  DictContent,
  DictionaryEnum,
  ServiceDictionary,
} from '../../../../service/dictionary';
import { RenderCell, getTime, toFixed, getStatusName } from '../component/index'
import { message, Spin, Button, List } from 'antd';
/**
 * 数字藏品
 * @returns 
 */
interface OrderDetailsState {
  orderId: string
  afterSale: (id: any, key: any) => void
}
function OrderDetails(prop: OrderDetailsState) {
  let { orderId, afterSale } = prop
  const [active, setActive] = React.useState<number>(0)
  const [loadding, setLoadding] = React.useState<boolean>(false)
  const [info, setInfo] = React.useState<QueryOrderInfo>()
  const [express, setExpress] = React.useState<express>()
  const [discount, setDiscount] = React.useState<DictContent[]>([])
  const [delivery, setDelivery] = React.useState<any[]>([])
  const [error, setError] = React.useState<boolean>(false)
  const [errMsg, setMsg] = React.useState<string>('')

  async function getData() {
    try {
      const [info, express, delivery, discount] = await Promise.all([
        ServiceOrder.queryGoods(orderId), //商品订单的这个接口  不知道票品的能不能查的到数据，会员的可以查到
        ServiceOrder.queryGoodss(orderId),
        ServiceDictionary.getDictionary(DictionaryEnum.配送方式 + ''),
        ServiceOrder.queryGoods2(orderId),
      ]);
      setInfo(info)
      setExpress(express[0])
      setDelivery(delivery)
      setDiscount(discount)
      setLoadding(false)

    } catch (err: any) {
      message.error(err.message);
      setLoadding(false)
      setError(true)
      setMsg(err.message)
    }
  }
  function logisticsDetail() {
    // utils.jumpPage(ROUTER_NAME.LOGISTICS_DETAIL.path, {
    //   id: utils.getUrlParams().get('id') as string,
    //   status: utils.getUrlParams().get('status') as string,
    // });
  }
  //点击售后状态按钮进行跳转
  function onClickStatus(e, i) {
    const textStatus = e.target.innerText;
    if (textStatus == '申请售后') {
      afterSale(info?.orderId, 3)
    } else {
      afterSale(i.item.pss.id, 1)

    }
  }
  React.useEffect(() => {
    getData();
  }, [orderId]);

  if (!info) {
    return null;
  }
  const allSeatMenoy = toFixed(
    info.seats.reduce((start: number, cu) => (start += cu.price), 0)
  );
  let aa = console.log();

  let expireTime = new Date();
  if (info.expireTime) {
    expireTime = new Date(Date.parse(info.expireTime.replace(/-/g, '/')));
  }
  const find = delivery.find(val => val.dictKey === info.deliveryType);
  const deliveryName = find ? find.dictValue : '无';
  return (
    <Spin spinning={loadding}>
      <div
        className='nfr-content'
        style={{ background: '#f5f5f5', paddingBottom: info.status === 1 ? '40px' : '10px' }}
      >
        <div className='tickets-details'>
          {info.itemType === 2 ? (
            info.items.map((i, index) => {
              return (
                <div
                  key={index}
                  style={{
                    paddingBottom: '3px',
                    borderBottom: '1px solid #e7e7e7',
                    marginBottom: '10px',
                  }}
                >
                  <img
                    src={i.item.itemLogo}
                    className='tickets-pic'
                  />
                  <div
                    className='Order-box'
                    style={{ verticalAlign: 'top' }}
                  >
                    <p className='tickets-title'>{i.item.itemName}</p>
                    <p className='goods-title'>
                      {i.specification1 == null || i.specification2 == null
                        ? ''
                        : i.specification1 + ';' + i.specification2}
                      &nbsp;
                      <p>
                        价格:¥{i.item.actualCost}.00
                        <span style={{ color: '#101010' }}>
                          X{i.item.amount}
                        </span>
                      </p>
                    </p>
                  </div>
                  {/*售后按钮*/}

                  <div className='order-item-f'>
                    {info.payoffTime === null ? (
                      ''
                    ) : (
                      <div
                        className='order-item-fs'
                        style={{ display: 'block' }}
                        onClick={e => onClickStatus(e, i)}
                      >
                        {i.item.pss === null
                          ? '申请售后'
                          : i.item.pss.status === 1
                            ? '待审核'
                            : i.item.pss.status === 2
                              ? '等待买家退货'
                              : i.item.pss.status === 3
                                ? '已退货'
                                : i.item.pss.status === 4
                                  ? '退款成功'
                                  : i.item.pss.status === 5
                                    ? '已拒绝'
                                    : i.item.pss.status === 6
                                      ? '已取消'
                                      : i.item.pss.status === 7
                                        ? ' 已完成'
                                        : ''}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ display: 'flex' }}>
              <img src={info.itemLogo} className='tickets-pic' />
              <div className='Order-box'>
                <p className='tickets-title-two'>{info.itemName}</p>
                <p className='tickets-title-two'>
                  {info.seats[0]?.seatName}
                </p>
                {/* <span> {info.seats[0].seatName} </span> */}
              </div>
            </div>
          )}
        </div>

        {info.gifts.map(item => {
          return (
            <div className='step2-title'>
              <img
                className='step2-title-pic'
                src={item.logo}
                alt=""
              />
              <div className='step2-title-text'>
                <p>{item.name}</p>
                <p style={{ marginTop: '10px' }}>尺寸：{item.specification1}</p>
                <p>颜色分类： {item.specification2}</p>
                <div className='gift-number'>
                  <p>赠品</p>
                  <span>x {item.count}</span>
                </div>
              </div>
            </div>
          );
        })}
        {/* 物流信息 */}
        {express && express.expressCode ? (
          <div
            className='card'
            style={{ paddingTop: '10px' }}
            onClick={() => logisticsDetail()}
          >
            <div style={{ display: 'flex', position: 'relative' }}>
              <div style={{ flex: 1 }}>物流详情</div>
              <div style={{ margin: 'auto 0' }}>
                {/* <Icon type="right" /> */}
              </div>
            </div>
          </div>
        ) : (
          <div className='card' style={{ paddingTop: '10px' }}>
            暂无物流
          </div>
        )}

        {/*收货人运费等等*/}

        {info.mobileRecipientAddress ? (
          <div className='card'>
            <div className='card-item'>
              <div className='card-item-left'>
                收货人: <span>{info.mobileRecipientAddress.name}</span>
              </div>
              <div className='card-item-right'>
                {info.mobileRecipientAddress.phone}
              </div>
            </div>
            <div className='address-row'>
              <span className='address-left'>收货地址：</span>
              <span className='address-right'>
                {info.mobileRecipientAddress.fullAddress}
              </span>
            </div>
          </div>
        ) : (
          info.selfPickAddressName && (
            <div className='card'>
              <div className='address-row'>
                <span className='address-left'>取票地址：</span>
                <span className='address-right'>
                  {info.selfPickAddressName}
                </span>
              </div>
            </div>
          )
        )}
        {
          info.seats.length > 0 &&
          <List
            dataSource={info.seats}
            style={{ background: '#fff', margin: '5px 0' }}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={<span>共{info.seats.length}件</span>}
                />
                <div>{`￥${allSeatMenoy}`}</div>
              </List.Item>
            )}
          >
          </List>
        }
        <div className='card'>
          <RenderCell
            header="运费"
            red
            footer={`+ ￥${toFixed(info.deliveryPrice)}`}
          />
          <RenderCell
            header="优惠"
            red
            footer={`- ￥${toFixed(info.rawCost - info.actualCost)}`}
          />
          <List
            dataSource={discount}
            style={{ background: '#fff', margin: '5px 0', display: discount.length > 0 ? 'block' : 'none' }}
            renderItem={(item: any, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={item?.title}
                />
                <div>-￥ {toFixed(item?.money)}</div>
              </List.Item>
            )}
          >
          </List>
          <RenderCell header="订单总价" footer={`￥${toFixed(info.rawCost)}`} />
        </div>
        <div className='card'>
          <div className='cell'>
            <span>{info.status === 1 ? '待付款' : '实付款'}</span>
            <span className='cell-b' />
            <span className='color-red'>
              ￥{toFixed(info.actualCost)}
            </span>
          </div>
        </div>
        <div className='card'>
          <RenderCell header="订单编号" footer={info.orderId} />
          <RenderCell header="配送方式" footer={deliveryName} />
          <RenderCell header="订单状态" footer={getStatusName(info.status)} />
          <RenderCell header="用户备注" footer={info.userMemo || '无'} />
          <RenderCell header="下单时间" footer={info.createTime || '无'} />
          {info.status !== 1 && (
            <>
              <RenderCell
                header="支付渠道"
                footer={info.ttpOrderType || '无'}
              />
              <RenderCell
                header="渠道订单号"
                footer={info.ttpOrderId || '无'}
              />
              <RenderCell header="支付时间" footer={info.payoffTime || '无'} />
            </>
          )}
        </div>
      </div>
      {info.status === 1 && (
        <div className='footer'>
          <span className='money'>￥{toFixed(info.actualCost)}</span>
          <span className='time'>
            <span>剩余</span>
            <Countdown time={getTime(new Date(), expireTime)} />
          </span>
          <Button
            style={{ width: 'auto' }}
            className='payment'
            onClick={() => { }}
          >
            立即付款
          </Button>
        </div>
      )}
    </Spin>
  );
}
export default OrderDetails