import { Net } from '../../utils/net';
import { GoodsItemType } from '../../enum';
import { _pageData } from '../common_i';

// 会员卡信息
export interface MemberCardsContent {
  itemType: GoodsItemType.member;
  id: number;
  abbrName: string;
  accountMaxAmount: number;
  accountMinAmount: number;
  anotherAcquireType: number;
  backImage: string;
  contentImages: string[];
  delivery: boolean;
  description: string;
  detail: string;
  express: boolean;
  expressMoney: number;
  frontImage: string;
  goodsId: number; //
  goods: string[]; //周边商品购物车list
  shops: string[]; //周边商品
  image: string; //规格里面的图片
  amount: number; //库存
  speciName: any[]; //尺码
  specifications: []; //颜色
  content: any;
  leftAmount: number;
  level: number;
  logo: string;
  maxPromPrice: number;
  maximumAmount: number;
  minimumPrice: number; // 当前价格
  mimPromPrice: number;
  minimumAmount: number;
  maximumPrice: number; // 划掉的价格
  memberDiscountType: number | null; //会员优惠类型
  memberDiscountValue: number | null; //会员价/或者折扣
  name: string;
  notes: string;
  orderMaxAmount: number;
  orderMinAmount: number;
  reserveNotes: string;
  selfPick: boolean;
  selfPickAddresses: any[];
  status: number;
  statusStr: string;
  payable: boolean; // 是否可以买
  possessed: boolean; // 是否已拥有
  leftDays: number; // 剩余天数
  renewalCardId: number | null;
  renewalPrice: number | null; //续费价格
  upgradeCardId: number | null;
  upgradePrice: number | null; //升级价格
  topImages: string[];
  totalAmount: number;
  forEach?: () => void;
  map?: () => void;
  rights: any[];
  card: {
    backImage: string;
    logo: string;
    id: number;
  };
  mobileMemberCard: {
    endTime: string;
  };
  orgInfo: {
    orgId: number
    orgName: string
    orgLogo: string
    orgDomain: string
    clientTitle: string
  }
}

// 会员赠品信息
export interface MemberGiftsContent {
  id: number;
}
export interface CardListContent {
  logo: string;
  name: string;
  amount: number;
  specification1: string;
  specification2: string;
  price: number;
  memberPrice: number;
  status: any;
  entity: {
    goodsId: number;
    amount: number;
    id: number;
  };
  content: [
    {
      entity: {
        goodsId: number;
        amount: number;
      };
      createTime: string;
      itemType: number;
      logo: string;
      name: string;
      price: number;
      specification1: string;
      specification2: string;
      status: number;
    }
  ];
  selected?: any;
}

export interface PreReserveMoneyContent {
  actualCost: number;
  deliveryPrice: number;
  discount: number;
  rawCost: number;
  goodsPrice: number;
  totalPoints: number;
  pointsStep: number;
  points: number;
}
// 票品
interface SubmitOrderParams {
  goodsId: string;
  voucherIds: any[];
  deliveryType: number | undefined;
  receiptAddressId: number | undefined;
  selfPickAddressId: string | undefined;
  userMemo: string;
  gifts: any[];
  anotherAcquireType?: number;
}

//周边商品下订单
interface SubmitShopParams {
  goodsId: number | undefined;
  specification1: string | undefined;
  specification2: string | undefined;
  amount: number | undefined;
  deliveryType: number | undefined;
}

//周边商品加入购物车
interface cartDateType {
  entity: {
    goodsId: string | undefined;
    amount: number | undefined;
  };
  specification1: string | undefined;
  specification2: string | undefined;
}

export interface ReserveResult {
  actualCost: number;
  createTime: string;
  deliveryPrice: number;
  deliveryType: number;
  expireTime: string;
  gifts: any[];
  itemCount: number;
  itemDescription: string;
  itemId: number;
  itemLogo: string;
  itemName: string;
  itemType: number;
  mobileRecipientAddress: null;
  orderId: string;
  payAccount: string;
  payoffTime: null;
  payoffType: number;
  rawCost: number;
  seats: [];
  selfPickAddressId: number;
  selfPickAddressName: null;
  status: number;
  ttpOrderId: string;
  ttpOrderType: string;
  userMemo: string;
}

export interface MyMemberRightsContent {
  description: string;
  logo: string;
  eec: string;
  name: string;
  param: number;
  rightsId: number;
  unit: string;
}

export interface MyMemberContent {
  rights: MyMemberRightsContent[];
  description: string;
  level: number;
  logo: string;
  name: string;
  status: number;
  score: number;
  endTime: number;
  leftDays: number;
}

// 积分详情
export interface IntegralContent {
  entity: {
    accountId: number;
    createTime: string;
    points: number;
    reference: string;
    ruleId: number;
    totalPoints: number;
  };
  name: string;
  nickName: string;
  phone: string;
  title: string;
}

const domain = localStorage.getItem('DOMAIN') || '';
/**
 * 调用api数据
 */
export class ServiceMember {
  /**
   * 查询会员列表
   */
  public static listAllMemberCard(): Promise<MemberCardsContent[]> {
    return Net('/member/card/listAll').then(res => res.data.list);
  }

  /**
   * 查询周边商品列表
   * 已经正式用了周边商品的api
   */
  public static shopAllList(params: any): Promise<any> {
    params.domain = domain
    return Net('/member/goods/list', null, { params }).then(
      res => res.data.list
    );
  }
  /**
   * 查询周边商品类别
   */
  public static getGoodsClazz(params?): Promise<any> {
    // return Net('/member/goods/listClazz').then(res => res.data.list);
    return Net('/api/w/goods/listClazz', null, { params }).then(res => res.data.list);
  }

  /**
   * 查询周边商品的详细数据
   * @param goodsId
   */
  public static queryGoods(
    goodsId: string
  ): Promise<MemberCardsContent> {
    // let domain = localStorage.getItem('DOMAIN');
    return Net('/member/goods/query', null, { params: { goodsId, domain } }).then(
      res => res.data.query
    );
  }

  /**
   * 周边商品下单
   */
  public static goodsReserve(
    body: SubmitShopParams
  ): Promise<MemberCardsContent> {
    return Net('/member/goods/order/reserve', body).then(res => res.data);
  }

  /**
   * 周边商品加入购物车
   */
  public static addCart(body: cartDateType): Promise<MemberCardsContent> {
    return Net('/member/cart/add', body).then(res => res.data);
  }

  // public static queryCartData( id: string | number): Promise<MemberCardsContent> {
  //     return Net('/member/cart/list', null, { params: { id } }).then(
  //         res => res.data.list
  //     );
  // }
  /**
   * 删除购物车
   * @param ids
   */
  public static delectCartData(
    ids: any
  ): Promise<MemberCardsContent> {
    return Net('/member/cart/batchDelete', null, { params: { ids } }).then(
      res => res.data.list
    );
  }
  /**
   * 查询购物车数据list
   * @param goodsId
   */
  public static queryCartData(
    pageNum: any,
    pageSize: any
  ): Promise<_pageData<CardListContent>> {
    return Net('/member/cart/list', null, {
      params: { pageNum, pageSize },
    }).then(res => res.data.list);
  }

  /**
   *  修改 购物车 颜色规格
   **/
  public static modifyCart(body: any): Promise<MemberCardsContent> {
    return Net('/member/cart/modify', body).then(res => res.data);
  }

  /**
   * 查询会员卡详情
   * @param goodsId
   */
  public static queryMemberCard(goodsId: any): Promise<MemberCardsContent> {
    return Net('/member/card/query', null, { params: { goodsId } }).then(
      res => res.data.query
    );
  }

  /**
   * 会员卡询价
   */
  public static preReserveGetMoney(
    body: SubmitOrderParams
  ): Promise<PreReserveMoneyContent> {
    return Net('/member/card/order/preReserve', body).then(res => res.data);
  }

  /**
   * 会员卡下单
   */
  public static reserve(body: SubmitOrderParams): Promise<ReserveResult> {
    return Net('/member/card/order/reserve', body).then(res => res.data.order);
  }

  /**
   * 会员卡支付  后台说三个支付的接口合并了
   * @param orderId
   * /member/card/order/payoff
   */
  public static payoff(orderId: string) {
    return Net('/order/payoff', null, { params: { orderId } }).then(
      res => res.data
    );
  }

  /**
   * 查看我的会员
   */
  public static queryMyMemberListInfo(): Promise<MyMemberContent[]> {
    return Net('/member/card/listMine').then(res => res.data.list);
  }

  /**
   * 查看我的会员卡详情
   */
  public static queryMyMemberInfo(level: any): Promise<MyMemberContent> {
    return Net('/member/card/queryMine', null, { params: { level } }).then(
      res => res.data.query
    );
  }

  //查询会员卡详情
  public static queryCard(cardId: any): Promise<MyMemberContent> {
    return Net('/member/card/queryCard', null, { params: { cardId } }).then(
      res => res.data.card
    );
  }

  /**
   * 查看我的积分详情
   */
  public static queryMemberPoints(
    pageNum: any = 1,
    pageSize: any = 999
  ): Promise<_pageData<IntegralContent>> {
    return Net('/member/points/list', null, {
      params: { pageNum, pageSize },
    }).then(res => res.data.list);
  }

  /**
   * 会员是否有赠品
   * @param goodsId
   *
   */
  public static queryGiftByGoods(goodsId: string): Promise<any> {
    return Net(`/member/goods/queryGiftByGoods?goodsId=${goodsId}`).then(
      res => res.data.goods
    );
  }
}
