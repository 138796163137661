import * as React from 'react';
import './group.css'
import { ID_NUMBER_REGEXP, PHONE_REGEXP } from '../../../../configs/RegExp';
import { GroupService, GroupDetail } from '../../../../service/group'
import { ServiceAccount, AccountDetailInfo } from '../../../../service/account'
import { deduplication } from '../../../../utils/tool';
import { ImageComponent } from '../../../../component/imageComponet/ImageComponent';
import { Table, Select, Radio, List, Spin, Space, message, Input, InputNumber, Button } from 'antd'
import Util from '../../../../utils';
import { getEnvironmentData } from 'worker_threads';
const { Option } = Select;
const { Search } = Input;
interface GroupProp {
  onCancel: () => void
}
function Group(prop: GroupProp) {
  let { onCancel } = prop
  const icons = JSON.parse(localStorage.getItem('icons') || '{}')
  const [type, setType] = React.useState<number>(0)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [userInfo, setUserInfo] = React.useState<AccountDetailInfo>();
  const [groups, setGroups] = React.useState<any[]>([]);
  const [yearData, setYearData] = React.useState<any[]>([]);

  const [year, setYear] = React.useState<string>('');
  const [name, setName] = React.useState<string>('');
  const [members, setMembers] = React.useState<any>();
  const [numberOfElements, setNumberOfElements] = React.useState<any>();
  const [pageNum, setPageNum] = React.useState<any>()
  const [flag, setFlag] = React.useState<boolean>(false)
  const [histories, setHistories] = React.useState<any[]>([]);

  const [groupInfo, setGroupInfo] = React.useState<GroupDetail>({} as GroupDetail)
  const [show, setShow] = React.useState<boolean>(false)
  const [groupId, setGroupId] = React.useState<any>()
  const [data, setData] = React.useState<any>([])

  React.useEffect((): void => {
    // 设置年份
    ServiceAccount.getAccountDetail().then(res => {
      setUserInfo(res);
      const groupId = res.group ? res.group.entity.id : undefined;
      setGroupId(groupId)
      if (res.group && res.group.groupType.id !== 1) {
        getMyGroup()
        setType(2)
      } else {
        getData(year, name)
        setType(1)
      }
    })
  }, [flag]);
  function getData(years, name) {
    // 设置年份
    let date = new Date()
    let year = date.getFullYear()
    let data = [{ label: year + '', value: year + '' }]
    setYearData(data);
    setYear((year || years) + '')
    Promise.all([
      GroupService.getAllGroup()
    ]).then(datas => {
      setGroups([...datas[0]]);
      GroupService.getGroupFans({
        year: years,
        name: name,
        groupId,
      })
        .then(res => {
          setMembers(res.content || [])
          setNumberOfElements(res.numberOfElements)
          setPageNum(res.number)
          setLoading(false)
          setYear(years)
        })
        .catch(e => {
          setLoading(false)
          setShow(true)
          // message.error(e.message)
        }).finally(() => {
          setLoading(false)
        })
    })
      .catch(e => {
        setLoading(false)
        // message.error(e.message)
      });
  }
  function getMyGroup() {
    setGroupId(undefined)
    GroupService.getMyGroup()
      .then(res => {
        setGroupInfo(res || {})
      })
      .catch(e => {
        message.error(e.message);
      }).finally(() => {
        setLoading(false)
      })
  }
  function joinGroup() {
    GroupService.joinGroup(groupId)
      .then(res => {
        // location.reload();
        setFlag(true)
        setShow(false)
      })
      .catch(err => {
        message.error(err.message)
      });
  }
  function quitGroup() {
    GroupService.quitGroup()
      .then(res => {

        onCancel()
        setFlag(false)
      })
      .catch(e => {
        message.error(e.message);
      });
  }
  function getHistory() {
    const id =
      userInfo && userInfo.group ? userInfo.group.entity.id : undefined;
    if (id) {
      GroupService.getHistory()
        .then(res => {
          setHistories([...res]);
          setType(3)
        })
        .catch(e => {
          message.error(e.message);
        });
    }

  }

  const columns = [
    {
      title: '成员姓名',
      dataIndex: 'name',
    },
    {
      title: '套票座位',
      dataIndex: 'seatName',
      render: (r, row) => (
        <span>{r || '-'}</span>
      )
    },
    {
      title: '主场出勤',
      dataIndex: 'hostCount',
      defaultSortOrder: 'descend' as "descend",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: '客场出勤',
      dataIndex: 'clientCount',
      defaultSortOrder: 'descend' as "descend",
      sorter: (a, b) => a.age - b.age,
    },
  ];
  const group = groupInfo;
  const item = document.getElementById('desc');
  item &&
    (item.innerHTML = group.entity
      ? group.entity.description.replace(/\n/g, '<br/>')
      : '');
  return (
    <Spin spinning={loading}>
      {
        type === 1 &&
        <div style={{ display: userInfo && userInfo.group ? 'block' : 'none' }}>
          <div className='top' onClick={() => { }}>
            <img
              style={{ height: '90px', width: 'auto' }}
              src={userInfo && userInfo.group ? userInfo.group.logo : ''}
            />
            <div style={{ width: '50%', padding: '10px' }}>
              <h3>
                {userInfo && userInfo.group ? userInfo.group.entity.name : ''}
              </h3>
              <h4 style={{ lineHeight: '15px' }}>
                {numberOfElements || ''}人
              </h4>
            </div>
          </div>

          <div style={{ display: 'flex', marginBottom: '20px' }}>
            <Input.Group compact>
              <Select defaultValue={year} value={year} style={{ width: 80 }} onChange={(e) => getData(e, name)}>
                {
                  yearData?.map(el =>
                    <Option value={el.value}>{el.label}</Option>
                  )
                }
              </Select>
              <Search onSearch={(e) => getData(year, e)} style={{ width: '70%', height: '34px', marginLeft: '24px' }} placeholder="搜索" />
              {/* <Input style={{ width: '70%', height: '34px', marginLeft: '24px' }} defaultValue="input content" /> */}
            </Input.Group>
          </div>
          <Table columns={columns} dataSource={members} />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button onClick={() => getHistory()} type='primary' style={{ margin: '0 20px' }}>历史</Button>
            <Button onClick={() => { getMyGroup(); setType(2) }} type='primary' style={{ margin: '0 20px' }}>详情</Button>
          </div>
        </div>
      }
      {
        type === 2 &&
        <div>
          <div
            className='top'
            style={{ display: groupId ? 'none' : 'flex' }}
          >
            <img
              style={{ height: '90px', width: 'auto' }}
              src={group.entity ? group.logo : ''}
            />
            <div style={{ width: '50%' }}>
              <h3>{group.entity ? group.entity.name : ''}</h3>
              <p style={{ lineHeight: '20px' }}>{group.fullAddress}</p>
            </div>
          </div>
          <p className='longtxt' id="desc" />
          <div className='longtxt'>
            联系人 :{' '}
            {`${group.entity ? group.entity.contactName : ''} ${group.entity ? group.entity.contactPhone : ''
              }`}
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button onClick={() => setType(1)} type='primary' style={{ margin: '0 20px' }}>返回</Button>
            <Button onClick={() => quitGroup()} type='primary' style={{ margin: '0 20px' }}>退出</Button>
          </div>
        </div>
      }
      {
        type == 3 &&
        <div>
          <h2>组织历史</h2>
          <List>
            {
              histories.map((i, index) =>
                <List.Item key={`card_${index}`} >
                  <List.Item.Meta title={i}
                  />
                </List.Item>
              )
            }
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button onClick={() => setType(1)} type='primary' style={{ margin: '0 20px' }}>返回</Button>
            </div>
          </List>
        </div>
      }
      {
        !show || groups.length === 0 ? null :
          <div>
            <h2 style={{ marginBottom: '10px' }}>请选择加入组织</h2>
            <Radio.Group onChange={(e) => setGroupId(e.target.value)} value={groupId}>
              <Space direction="vertical">
                {
                  groups?.map(el =>
                    <Radio value={el.id}>{el.name}</Radio>
                  )
                }
              </Space>
            </Radio.Group>
            <List>
              <List.Item >
                <List.Item.Meta title='提示'
                  description='加入的组织不同，享受的权益可能不一样哦'
                />
              </List.Item>
            </List>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button onClick={() => joinGroup()} className='group-btn'>
                确认加入
              </Button>
            </div>

          </div>

      }

    </Spin>
  );
}
export default Group