/**
 * @author: hukun
 * @Date: 2019-05-16
 * @Time: 14:21
 * @function
 */
import { Net } from '../../utils/net';
// import { RealNameInfo } from '../member/realName';
// import { GroupDetail } from '../group';

export interface AccountDetailInfo {
  detail: {
    account: string;
    accountRevisable: boolean;
    admin: boolean;
    birthday: any;
    gender: string;
    id: number;
    inspector: boolean;
    nickName: string;
    phone: string;
    portraitUrl: string;
    status: number;
  };
  currentMemberCard: {
    name: string;
  };
  token: string;
  auth: {
    id: any;
    name: string;
  };
  roles: number[]; //1 检票, 2 报名签到, 3 核销
  code: string;
  group: any; //GroupDetail;
  wallet: {
    ftsMoney: number;
    props: number;
    playerCards: number;
    money: number;
    points: number;
    tickets: number;
    vouchers: number;
  };
  topImage: string;
}

export interface GroupProps {
  content: any[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  sort: null;
  totalElements: number;
  totalPages: number;
}

export class ServiceAccount {
  /**
   * 获取账号信息
   */
  public static getAccountDetail(): Promise<AccountDetailInfo> {
    return Net('/home/detail').then(res => res.data);
  }

  /**
   * 修改账号信息
   * @param account
   */
  public static modifyAccount(account: string): Promise<any> {
    return Net('/home/setAccount', null, { params: { account } });
  }

  /**
   * 修改用户名
   * @param nickName
   */
  public static modifyNickName(nickName: string): Promise<any> {
    return Net('/home/setNickName', null, { params: { nickName } });
  }

  /**
   * 修改用户密码
   * @param newPassword
   * @param oldPassword
   */
  public static modifyPassword(newPassword: any, oldPassword: any): Promise<any> {
    return Net('/home/changePassword', null, {
      params: { newPassword, oldPassword },
    });
  }

  /**
   * 修改用户手机
   * @param phone
   * @param code
   */
  public static modifyPhone(phone: any, code: any): Promise<any> {
    return Net('/home/setPhone', null, { params: { phone, code } }).then(
      res => res.data
    );
  }

  /**
   * 修改用户头像
   * @param body
   */
  public static modifyPortrait(body: FormData): Promise<any> {
    return Net('/home/uploadPortrait', body).then(res => res.data);
  }

  /**
   * 实名信息
   */
  public static myAuthInfo(): Promise<any> {
    return Net('/member/auth/myAuth').then(res => res.data.auth);
  }
}
