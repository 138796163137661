import * as React from 'react';
import { OrderStatusEnum, ServiceOrder } from '../../../../../service/order';
import { AfterContent } from '../../../../../service/order';
import Login from '../../../../loginHome/index'
import utils from '../../../../../utils';
import './afterSales.css';
import { deduplication } from '../../../../../utils/tool';
import { message, Modal, Spin, Pagination, Button } from 'antd';
import OrderDetails from '../orderDetail'
import AfterSaleDetails from './afterSaleDetails'
import { DissHistory } from '../dissHistory/index'
import { ApplicationAfter } from '../afterSales/applicationAfter'
import { NoData } from '../../../../../component/noData';
function AfterSaleList() {
  const [list, setList] = React.useState<any>([])
  const [orderId, setOrderId] = React.useState<any>(undefined)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(1)
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [totalElements, setTotalElements] = React.useState<number>(1)
  const [type, setType] = React.useState<any>(undefined)
  const [show, setShow] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const title = ['订单详情', '申请售后', '协商历史', '申请售后']
  function jumpToMy(): void {
  }

  function clickAfter(id) {
    setOrderId(id)
    setType(1)
    setShow(true)
    // utils.jumpPage(ROUTER_NAME.APPLY_AFTER_STATUS.path, { pssId: id });
  }

  function clickOrderItem(orderId) {
    setOrderId(orderId)
    setType(0)
    setShow(true)
  }

  async function getOrderList(pageNum, pageSize) {
    window.scrollTo(0, 0)
    setLoading(true)
    ServiceOrder.getAllAfterSale(pageNum, pageSize)
      .then(res => {
        setList([...res.content])
        setPageSize(pageSize)
        setPageNum(pageNum)
        setTotalElements(res.totalElements)
      })
      .catch(e => {
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false)
      });
  }

  // 取消商品的订单
  async function cancelOrder(id) {
    try {
      await ServiceOrder.cancelOrder(id); //取消商品的订单
      await getOrderList(pageNum, pageSize);
    } catch (e: any) {
      message.error(e.message);
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!utils.IsLogin()) {
      setIsLogin(true)
      setLoading(false)
      return
    }
    getOrderList(1, pageSize);
  }, []);

  console.log(list);

  return (
    // <Page top={} footer="">
    <Spin spinning={loading}>
      {
        totalElements > 0 ?
          <div className='container2' style={{ height: '100%', width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
            {
              list?.map((item, index) =>
                <AfterSaleItem
                  key={index + item.orderId + 'sales'}
                  onDelete={(id) => cancelOrder(id)}
                  onClick1={(id) => clickOrderItem(id)}
                  clickAfter={id => clickAfter(id)}
                  item={item}
                />
              )
            }
            <div className='goods-pagination'>
              <Pagination
                pageSize={pageSize}
                size="small"
                current={pageNum}
                total={totalElements}
                onChange={(page, pageSize) => { getOrderList(page, pageSize) }}
                onShowSizeChange={(current, size) => { getOrderList(current, size) }}
              />
            </div>
            <Modal
              title={title[type]}
              visible={show}
              footer={null}
              style={{ padding: 'none', overflow: 'unset' }}
              onCancel={() => { setShow(false); setType(-1) }}
            >
              {
                type == 0 &&
                <OrderDetails afterSale={(id, key) => { setOrderId(id); setType(key) }} orderId={orderId} />
              }
              {
                type == 1 &&
                <AfterSaleDetails consult={() => setType(2)} orderId={orderId} />
              }
              {
                type == 2 &&
                <DissHistory orderId={orderId} />
              }
              {
                type == 3 &&
                <ApplicationAfter cancel={() => setShow(false)} type='' orderId={orderId} />
              }
            </Modal>
            {
              isLogin &&
              <Login onLogin={() => { getOrderList(1, pageSize); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
            }
          </div>
          :
          <NoData show={true} />
      }

    </Spin>

    // </Page>
  );
}

// 售后管理
interface AfterSaleItemProps {
  item: AfterContent;
  onDelete: (id: string) => void;
  onClick1: (id: string) => void;
  clickAfter: (id: string) => void;
}

function AfterSaleItem(props: AfterSaleItemProps) {
  const { item, onDelete, clickAfter, onClick1 } = props;
  return (
    <div className='order-item' key={item.orderId}>
      <div className='order-item-hf'>
        {/*//小垃圾桶*/}
        {item.entity.statusName === '待付款' &&
          <Button size='small' onClick={() => onDelete(item.orderId)}>删除</Button>
          // <div onClick={() => onDelete(item.orderId)} >
          //   删除
          // </div>
        }
        {/* <IsRender visibility={item.entity.statusName === '待付款'}>
          <div
            className={`icon-trash $'order-item-hr'`}
            onClick={() => onDelete(item.orderId)}
          />
        </IsRender> */}
      </div>
      <div
        className='order-item-c'
        onClick={() => onClick1(item.orderId)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={item.item.itemLogo} className='shop-img' alt="" />
          <div style={{ flex: 1, padding: '0.725vw' }}>
            <div className='shop-titles'>
              <p className='shop-name'>{item.item.itemName}</p>
              <span className='shop-size'>
                {item.item.specification || ''}
              </span>
            </div>
            <div className='order-item-cf'>
              {`共${item.item.amount}件`}&nbsp; 价格
              <span className='bold'>{`￥${item.item.actualCost}`}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='order-item-f'>
        <div>
          {item?.org.orgName}
        </div>
        <div
          className='order-item-fs'
          onClick={() => clickAfter(item.entity.id)}
        >
          {item.entity.statusName}
        </div>
      </div>

    </div>
  );
}

export default AfterSaleList
