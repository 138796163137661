// 首页商品分类的type 同时也是 接口返回的tktClazz字段的值 TODO: 目前
export enum goodsTktClazzEnum {
  package = 1, //TODO:套票
  session = 2, //TODO:场次票
  member = 3, //TODO: 会员权益
  goodsList = 4, // TODO: 周边商品
}
// 用来区分商品,会员,球票
export enum GoodsItemType {
  gift = 3,
  ticket = 1, //票品
  goods = 2, //商品
  delivery = 4, //是否要取票
  member = 5,
}
// sessionStorage 中存储数据的key
export enum sessionStorageEnum {
  // 提交订单
  SUBMIT_ORDER = 'SUBMITORDER',
  // 选择位置
  CHOOSE_SEAT = 'CHOOSESEAT',
  // 选择的tabbar
  SELECT_NAME = 'selectName',
  // 实名认证
  REAL_NAME_AUTH = 'REAL_NAME_AUTH_',
  // 是否是微信扫码激活
  GIFT_CARD_ACTIVATION = 'GIFT_CARD_ACTIVATION_',
  // 标记登录重定向地址
  LOGIN_REDIRECT = 'LOGIN_REDIRECT_',
  // 用户协议
  PROTOCOL = 'PROTOCOL',
  // 是否已经登录
  IS_LOGIN_ = 'IS_LOGIN',
  // 标记是否曾经断线
  offline = 'offline',
  // 活动扫描签到二维码的数据
  SIGN_IN_QR_CODE = 'SIGN_IN_QR_CODE',
  // 活动签到的选项
  SIGN_IN_OPTION = 'SIGN_IN_INFO',
  // 缓存/home/detail接口的返回数据
  USER_INFO = 'USER_INFO',

  // 客户平台设置名称
  ORG_NAME = 'ORG_NAME',
}

// localStorageEnum 中存储数据的key
export enum localStorageEnum {
  // token
  TOKEN = 'MUFANS_TOKEN',
  // 支付订单号
  PAY_ORDER_ID = 'payOrderId',
  //当前的客户子域名
  DOMAIN = 'DOMAIN',
}

// 实名认证的证件类型
export enum RealNameCardTypeEnum {
  id = 1, //身份证
  passport = 2, //护照
  home = 3, //回乡证
  taiwan = 4, //台湾证
  ocr2 = 5,
}

// 性别
export enum genderEnum {
  man = '1',
  woman = '2',
}

// 是否登录
export enum loginStatusEnum {
  yes = 'TRUE',
  no = 'FALSE',
}
/**
 *  2 快递
 *  3 自主
 *  1 无需
 */
export enum ReceivingWayId {
  express = 2,
  selfPick = 3,
  delivery = 1,
}
export enum ReceivingWayText {
  express = '快递¥12元',
  selfPick = '纸质票 (前往取票点取票)',
  delivery = '电子票 (无需取票凭电子票入场)',
}

/**
 * 返回证件类型字符串
 * @param type
 */
export function getCardName(type: RealNameCardTypeEnum) {
  switch (type) {
    case RealNameCardTypeEnum.home:
      return '回乡证';
    case RealNameCardTypeEnum.id:
      return '身份证';
    case RealNameCardTypeEnum.passport:
      return '护照';
    case RealNameCardTypeEnum.taiwan:
      return '台胞证';
    default:
      return '';
  }
}

/**
 * 返回性别 字符串
 * @param type
 */
export function getGender(type: genderEnum) {
  switch (type) {
    case genderEnum.man:
      return '男';
    case genderEnum.woman:
      return '女';
    default:
      return '无';
  }
}
