import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css'
import { ServiceHomePage, MenuBody } from '../../service/homePage/index'
import { Link } from "react-router-dom";//引入routerdom
import { sessionStorageEnum } from '../../enum';
import Login from '../loginHome/index'
import { Modal } from 'antd'
/**
 * 一级菜单中会有 会员中心 这个踢出单独处理 不放到左边的菜单列表中 
 */
function Header() {
  const [menuList, setMenuList] = React.useState<MenuBody[]>([]);
  const [active, setActive] = React.useState<any>(sessionStorage.getItem('headerActive') || '0');
  const [hoverA, setHoverA] = React.useState<any>(active);
  const [userInfo, setUserInfo] = React.useState<any>(JSON.parse(localStorage.getItem('USER_INFO') || '{}'));
  const [orgName, setOrgName] = React.useState<any>(localStorage.getItem('orgName') || '');
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [urlShow, setUrlShow] = React.useState<boolean>(false)
  const [url, setUrl] = React.useState<string>('')
  const [links, setLinks] = React.useState<any>([])
  const tipObj = React.useRef(null)
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const icons = JSON.parse(localStorage.getItem('icons') || '{}')
  const navigate = useNavigate();
  React.useEffect(() => {
    function init() {
      //@ts-ignore
      tipObj?.current.style.setProperty("--drop-downColor", thmem?.fontColor4);
      console.log('------thmem------', tipObj?.current);


      Promise.all([
        ServiceHomePage.getMenus(),
        ServiceHomePage.links()
      ]).then(res => {
        setMenuList(res[0])
        console.log(res[1])
        setLinks(res[1])
        sessionStorage.setItem('menuList', JSON.stringify(res))
        sessionStorage.setItem('menuArray', JSON.stringify(res[0].map(el => el.url)))
      })
    }
    init();
  }, []);

  React.useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('USER_INFO') || '{}'))
  }, [localStorage.getItem(sessionStorageEnum.IS_LOGIN_)]);

  React.useEffect(() => {
    setOrgName(localStorage.getItem('orgName') || '')
  }, [localStorage.getItem('orgName')]);

  React.useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('USER_INFO') || '{}'))
    setActive(sessionStorage.getItem('headerActive'))
  }, [sessionStorage.getItem('headerActive')])

  function goTab(item: any, key: number) {
    setActive(key)
    sessionStorage.setItem('headerActive', key + '')
    navigate(item.url)
  }

  function goChildrenTab(item: any, key: number, index: number) {
    setActive(index)
    sessionStorage.setItem('headerActive', index + '')
    // @ts-ignore
    sessionStorage.setItem('newActive', key + 1)
    navigate(item.url, { state: { name: item.name } })
  }

  function heederClass(index: number) {
    let className = active == index ? "one-li active" : 'one-li'
    if (hoverA != index && hoverA != active && hoverA != "-1") {
      className += " fontA"
    }

    return className;
  }
  function onMouseEnter(index: Number) {
    setHoverA(index);
  }
  function onMouseLeave() {
    setHoverA("-1");

  }
  function showMnue(index) {
    if (index == hoverA) {
      return "block"
    } else {
      return "none"
    }
  }
  function chooseLink(item) {
    console.log(item);
    if (item.type === 1) {
      window.open(item.url)
    } else {
      setUrl(item.url)
      setUrlShow(true)
    }

  }

  function goDetail() {
    if (userInfo?.detail?.nickName) {
      window.open(`/${localStorage.getItem('DOMAIN') || ''}/w/personal/center/my/account`, '_blank');
    } else {
      setIsLogin(true)
    }
  }

  let isAuth = userInfo.auth && ['无需审核', '审核通过'].includes(userInfo.auth.name);

  const MenuStyles = {
    backgroundColor: thmem?.background1,
    color: thmem?.fontColor1
  }

  const isPersonanlCenter = window.location.href.indexOf("/personal/center") !== -1

  return (
    <div className="container headerContainer" ref={tipObj} style={{ backgroundColor: thmem?.background }}>
      <div className="headerBox" style={{ backgroundColor: thmem?.background1 }}>
        <div className="leftLogo" 
        // onClick={() => {
        //   if (!isPersonanlCenter) {
        //     sessionStorage.setItem('headerActive', 0 + '')
        //     navigate('/home')
        //   } else {
        //     window.open(`/${localStorage.getItem('DOMAIN') || ''}/w/home`, '_blank')
        //   }
        // }}
        >
          <div className='header-logo'>
            <img src={thmem?.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ backgroundColor: thmem?.background, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {
              !isPersonanlCenter &&
              <div style={{ flex: 1, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                {
                  links?.map(el =>
                    <img onClick={() => chooseLink(el)} src={el.icon} alt="" style={{ width: '28px', height: '28px', marginLeft: '5px', cursor: 'pointer' }} />
                  )
                }
              </div>
            }
            <div>
              {
                !isPersonanlCenter ?
                  <div className="rightVip">
                    <img style={{ cursor: 'pointer', width: '25px' }} onClick={() => navigate('/shopping/cart')} src={icons['Shopping']} alt=""
                    />
                    <div className="vip"
                    >
                      <div onClick={() => goDetail()} style={{ width: '25px', height: '25px',marginRight:'10px' }}>
                        <img src={userInfo?.detail?.portraitUrl || icons['Default Avatar']} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />
                      </div>
                      <span style={{fontSize:'12px'}} onClick={() => goDetail()}>{userInfo?.detail?.nickName || '登录注册'}</span>
                      <p onClick={() => { window.open(`/${localStorage.getItem('DOMAIN') || ''}/w/personal/center`, '_blank') }} style={{ backgroundColor: 'rgb(250, 172, 3)',fontSize:'12px', color: thmem?.fontColor1, padding: '5px 10px', marginLeft: '15px', borderRadius: '5px' }}>用户中心</p>
                    </div>
                  </div>
                  :
                  <div className="rightVip">
                    <div className="vip" onClick={() => {
                      if (userInfo?.detail?.nickName) {
                        window.open(`/${localStorage.getItem('DOMAIN') || ''}/w/personal/center/my/account`, '_blank');
                      } else {
                        setIsLogin(true)
                      }
                    }} >
                      <div style={{ width: '25px', height: '25px', marginRight: '10px' }}>
                        <img className="vip-img" src={userInfo?.detail?.portraitUrl || icons['Default Avatar']} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '0' }} />
                      </div>
                      <span style={{fontSize:'12px'}}>{userInfo?.detail?.nickName || '登录注册'}</span>
                    </div>

                    {
                      isAuth &&
                      <div style={{ display: 'flex', margin: '5px 10px', alignItems: 'self-start', background: '#fff' }}>
                        <img
                          src={icons['real name']}
                          className='icon-gross'
                          alt=""
                          style={{ width: '12px', height: '12px' }}
                        />
                        <div style={{fontSize:'12px'}} className='portraitBar-auth-text'>已实名</div>
                      </div>
                    }
                  </div>
              }
            </div>
          </div>
          {
            !isPersonanlCenter &&
            <div style={{ height: '50px' }}>
              <div className="titleList">
                <ul className="one-ul" style={MenuStyles}>
                  {
                    menuList?.map((el: any, index: number) =>
                      el.name != '会员中心' &&
                      <li
                        className={heederClass(index)}
                        onClick={() => goTab(el, index)}
                        onMouseEnter={() => onMouseEnter(index)}
                        onMouseLeave={() => onMouseLeave()}
                      >
                        {el.name}
                      </li>
                    )
                  }
                </ul>
              </div>
            </div>
          }
        </div>
      </div>

      {
        isLogin &&
        <Login onLogin={() => { setUserInfo(JSON.parse(localStorage.getItem('USER_INFO') || '{}')); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }

      <Modal
        visible={urlShow}
        footer={null}
        onCancel={() => setUrlShow(false)}
      >
        <img src={url} alt="" style={{ width: '100%', height: '100%' }} />
      </Modal>
    </div >
  );
}
export default Header
