import * as React from 'react';
import './index.css'
import Util from '../../../utils/'
import { TicketBody } from '../../../service/homePage/index'
import { useNavigate, useSearchParams } from 'react-router-dom';

interface TicketListProp {
  gameList: TicketBody[]
}

export function GameList(prop: TicketListProp) {
  let { gameList } = prop
  console.log(gameList);
  const navigate = useNavigate();

  return (
    <div className="game-ticket-list">
      {
        gameList?.map(el =>
          <div className="ticket-list-one">
            <div className="ticket-logo">
              <img src={el.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </div>
            <div className="ticket-name">
              {el.name}
            </div>
            <div className="ticket-info"></div>
            <div className="ticket-button" onClick={() => navigate('/choose/ticket/purchase', { state: { id: el.ticketId } })}>购票</div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
              <img src={el.orgInfo.orgLogo} alt="" style={{ width: '15px', height: '15px', margin: '0 5px' }} />
              <p style={{ color: '#d8d8d8', fontSize: '14px' }}>{el.orgInfo.orgName}</p>
            </div>
          </div>
        )
      }

    </div>
  );
}
