import * as React from 'react';
import './index.css';
import { ServiceOrder } from '../../../../../service/order';
import utils from '../../../../../utils';
interface DissHistoryState {
  orderId: string
}
export function DissHistory(prop: DissHistoryState) {
  let { orderId } = prop
  const [histories, setHistories] = React.useState<any[]>();
  async function historyList() {
    try {
      const datas = await ServiceOrder.afterCalcDateil(orderId);
      setHistories(datas.histories);
    } catch (e) { }
  }
  React.useEffect(() => {
    historyList();
  }, []);
  return (
    <div style={{ backgroundColor: '#fff' }}>
      {histories === undefined
        ? ''
        : histories.reverse().map((item, index) => {
          return (
            <div key={index} className='diss-box'>
              <p>
                {item.createTime} {item.statusName}
              </p>
              <div className='diss-list'>
                {/*<p>{pss === undefined ? '': pss.pssType === 1 ? '售后类型：仅退款':pss.pssType ===  2 ? '售后类型:退货退款': ''} </p>*/}
                <p>
                  {item.price == null ? '' : '申请金额(元)：' + item.price}
                </p>
                <p>
                  {item.descTitle + ':'} {item.description}
                </p>
                {item.images.map((i, index) => {
                  return (
                    <img
                      className='user-img'
                      src={item.images[index]}
                      key={index}
                      alt=""
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
}