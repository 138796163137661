import { Net } from '../../utils/net';

export interface wxPayResult {
  appId: string;
  nonceStr: string;
  package: string;
  sign: string;
  signType: string;
  timeStamp: string;
  code_url:string;
}
export class ServicePay {
  public static wechatPay(
    orderId: string
  ): Promise<{
    url: string;
    orderString: wxPayResult;
  }> {
    return Net('/order/createOrderWechat', null, {
      params: { orderId },
    }).then(res => res.data);
  }

  /**
   * 微信支付成功回调
   * @param orderId
   */
  public static wechatDone(orderId: string) {
    return Net('/order/payoffWechat', null, { params: { orderId } }).then(
      res => res
    );
  }

  /**
   * 微信浏览器内 使用支付宝
   */
  public static aliPayByWechatBrowser(
    orderId: string
  ): Promise<{
    resultObj: any;
    url: string;
    status: boolean;
  }> {
    return Net('/order/createOrderAlipayForWechat', null, {
      params: { orderId },
    }).then(res => res.data);
  }

  /**
   * 其他浏览器内 使用支付宝
   */
  public static aliPayByOtherBrowser(orderId: string) {
    return Net('/order/createOrderAlipay', null, {
      params: { orderId },
    }).then(res => res.data);
  }

  public static getPayStatus(orderId: string): Promise<boolean> {
    return Net('/order/loopOrder', null, { params: { orderId } }).then(
      res => res.data.status
    );
  }
}
