import * as React from 'react';
import './index.css'
import { DatePicker, Input, Select, TimePicker, Upload, message, Form, List, Radio, Modal, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import moment from 'moment';
import Util from '../../../utils';
import { ServiceEnroll, QueryBody } from '../../../service/enroll/index'
import { subStr } from '../../../utils/tool';
import Login from '../../loginHome/index'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ServicePay } from '../../../service/pay';
import QRCode from 'qrcode';
import { ServiceOrder } from '../../../service/order';

const { TextArea } = Input;
const { Option } = Select;
/**
 * 报名
 */
export default function Enroll(prop: any) {
  let location = useLocation()
  const navigate = useNavigate()
  const [id, setId] = React.useState<any>('')
  const Icons = JSON.parse(localStorage.getItem('icons') || '{}')
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const [info, setInfo] = React.useState<QueryBody>({} as QueryBody);
  const [isLogin, setIsLogin] = React.useState<boolean>(false);
  const [indexKey, setIndexKey] = React.useState<number>(0);
  const [items, setItems] = React.useState<any>([]);
  const [fields, setFields] = React.useState<any>([]);
  const [data, setData] = React.useState<any[]>([
    '报名选项', '报名信息', '支付押金', '完成报名'
  ]);

  const params = useParams()
  const [optionName, setOptionName] = React.useState<any>('');
  const [fileList, setFileList] = React.useState<any>([]);
  const [payType, setPayType] = React.useState<PayWayEnum>(1);
  const [orderId, setOrderId] = React.useState<any>();
  const [times, setTimes] = React.useState<any>({} as any);
  const [qrcode, setQrCode] = React.useState<string>('');
  const [showCode, setShowCode] = React.useState<boolean>(false);
  const [actualCost, setActualCost] = React.useState<number>();

  //定时器
  let count_down, get_status;

  //tagType：1、报名；2、咨询
  React.useEffect(() => {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    sessionStorage.setItem('headerActive', menuArray?.indexOf('/activity'))
    let state: any = location.state
    function init() {
      getData(state?.id || params?.id)
    }
    init()
    document.body.style.backgroundColor = 'white';

    return () => {
      clearInterval(count_down)
      clearInterval(get_status)
    }
  }, []);

  function getData(id) {
    setIsLogin(false)
    ServiceEnroll.query(id)
      .then(res => {
        setInfo(res || {});
        let { items, fields, points } = res
        setItems(items)
        setFields(fields)
        setId(id)
        if (res.buttonTxt === '您已报名') {
          setIndexKey(3)
        } else {
          setIndexKey(items.length > 0 ? 0 : fields?.length > 0 ? 1 : points ? 2 : 3)
          //返回null说明没登录
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  }

  function optionChange(row, index) {
    if (row.amount !== row.totalAmount) {
      items.map(i => (i.check = false));
      items[index].check = true;
      setOptionName(items[index].name);
      setItems([...items])
    }
  }

  async function nextStep() {
    if (indexKey === 0) {
      submit()
    } else if (indexKey === 1) {
      if (!Util.IsLogin()) {
        // 未登录
        setIsLogin(true)
      } else {
        // 登录后判断 填入的数据是否合理 且是否有收费
        submit1()
      }
    } else if (indexKey === 2) {
      pay()
    }
  }

  async function pay() {
    try {
      const { expireTime, actualCost } = await ServiceOrder.queryOrder(orderId)
      if (payType === 1) {
        const res = await ServicePay.wechatPay(orderId)
        const qrcode = await QRCode.toDataURL(res?.orderString?.code_url);
        setCountDown(expireTime)
        setShowCode(true)
        setQrCode(qrcode)
        setActualCost(actualCost)
        getPayStatus(orderId)
      } else if (payType == 2) {
        const { orderString } = await ServicePay.aliPayByOtherBrowser(orderId)
        let ht = document.getElementById('form') as any
        ht.innerHTML = orderString;
        document.forms[0].submit()
      }
    } catch (e) {
      console.log(e)
    }
  }

  function setCountDown(time: string) {
    setInterval(() => {
      setTimes(Util.showtime(time))
    }, 1000)
  }

  // 轮询订单的支付状态,支付成功后跳转页面
  async function getPayStatus(orderId) {
    try {
      let temp = setInterval(() => {
        ServicePay.getPayStatus(orderId).then(status => {
          if (status) {
            // 轮询结果,在页面跳转的时候停止轮询
            clearInterval(temp)
            setIndexKey(3)
            setShowCode(false)
          }
        })
      }, 1000)
    } catch (e) {
      console.log(e)
    }
  }

  function submitEnroll(body) {
    ServiceEnroll.submit(body).then(res => {
      if (res.data.orderId) {
        setIndexKey(2)
        setOrderId(res.data.orderId)
        return
      }
      message.success('提交成功')
      navigate(-1)
    }).catch(err => {
      message.error(err.message);
    })
  }

  function submit1() {
    let forms = fields?.map(i => {
      if (i.type === 6) {
        return;
      } else {
        return { id: i.id, name: i.inputValue };
      }
    });
    const images = fields.filter(i => i.type === 6);

    fileList?.map(el => {
      forms.push({
        id: images[0].id,
        name: el.thumbUrl,
      });
    });
    forms = forms.filter(i => i);

    let unInputFields = forms.filter((i: any) => !i.name);
    let minLength = images[0] ? images[0].minLength : 0

    if (unInputFields.length > 0 || !id || (fileList.length < minLength)) {
      message.error('请完善报名信息');
      return;
    } else {
      let body = {
        enrollmentId: parseInt(id),
        itemName: optionName,
        forms,
      };
      // 如果有收费 就去到支付页面 没有就到提交页面
      if (info.points) {
        setIndexKey(2)
      } else {
        submitEnroll(body)
      }
    }
  }

  function submit() {
    if (!optionName) {
      message.error('请选择一个报名选项');
      return;
    }
    //修改时产品设计是分开修改的所以不需要跳转
    if (info.useForm && info.fields && info.fields.length > 0 && id) {
      setIndexKey(1)
    } else {
      let body = {
        enrollmentId: id,
        itemName: optionName,
      }
      if (!Util.IsLogin()) {
        // 未登录
        // setType(1)
        setIsLogin(true)
      } else {
        // 登录后,根据提交接口返回是否有orderId判断是否有收费
        submitEnroll(body)
      }
    }
  }

  function cancelEnroll() {
    ServiceEnroll.cancel(info.enrollmentId)
      .then(res => {
        navigate(-1)
      })
      .catch(err => {
        message.error(err.message);
      });
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  );

  let color = ['我要报名', '您已报名'].includes(info.buttonTxt)
    ? true
    : false;

  //type 可以定义一个枚举类型,用文字描述代替id,方便理清逻辑
  function renderItem(item, index) {
    switch (item.type) {
      case 1:
        return (
          <Select
            defaultValue=" "
            style={{ width: 220 }}
            onChange={(e) => {
              fields[index].inputValue = e;
              setFields([...fields])
            }}
          >
            {item.values?.map((item, idx) =>
              <Option key={idx} value={item}>{item}</Option>
            )}
          </Select>
        )
      case 2:
        return (
          <DatePicker
            mode="date"
            placeholder='选择日期'
            format="YYYY-MM-DD"
            style={{ width: 220 }}
            onChange={(e, date) => {
              fields[index].inputValue = date;
              setFields([...fields])
            }}
          />
        )
      case 3:
        return (
          <TimePicker
            placeholder='选择时间'
            style={{ width: 220 }}
            onChange={(e, time) => {
              fields[index].inputValue = time;
              setFields([...fields])
            }}
            defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
          />
        )
      case 4:
        return (
          <DatePicker
            placeholder='选择日期'
            style={{ width: 220 }}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={(e, date) => {
              fields[index].inputValue = date;
              setFields([...fields])
            }}
          />
        )
      case 5:
        return (
          <TextArea
            rows={2}
            style={{ width: 220 }}
            onChange={(e) => {
              fields[index].inputValue = e.target.value;
              setFields([...fields])
            }}
          />
        )
      case 6:
        return (
          <Upload
            listType="picture-card"
            fileList={fileList}
            showUploadList={{ showPreviewIcon: false }}
            onChange={({ fileList }) => { console.log(fileList); setFileList(fileList) }}
          >
            {fileList.length >= item.maxLength ? null : uploadButton}
          </Upload>
        )
    }
  }

  return (
    <div style={{ width: '100%', background: thmem?.background, minHeight: '100vh', marginTop: '45px', }}>
      <div className="activity">
        <div className="activity-left-info">
          <div className="info-top">
            <h2 className="top-title">{info.name}</h2>
            <p>报名时间：{` ${subStr(info.signInStartTime, 0, 16)} ~ ${subStr(
              info.signInEndTime,
              0,
              16)}`}</p>
            <div className="info-bottom">
              <p>活动时间：{` ${subStr(info.activityStartTime, 0, 16)} ~ ${subStr(
                info.activityEndTime,
                0,
                16
              )}`}</p>
              <p>
                <span>阅读 {info.touchAmount}</span>
                <span>分享 {info.shareAmount}</span>
              </p>
            </div>
          </div>
          <div className="active-detail-message">
            <div
              dangerouslySetInnerHTML={{ __html: info.description }}
            />
          </div>
        </div>
        <div className="right-operation">
          <div className="signup-option">
            {
              data?.map((item, index) =>
                <div className="name-option" style={{ backgroundColor: index === indexKey ? '#efefef' : '' }}>{item} </div>
              )}
          </div>
          {
            indexKey === 0 &&
            <div className="signup-info">
              <div className="info-title">
                {info.itemName}
              </div>
              {
                items.map((el, index) =>
                  <div className="info-option">
                    <div className="option-name">
                      {el.name}
                      {info.showAmount
                        ? el.totalAmount
                          ? `(${el.amount}/${el.totalAmount})`
                          : `(${el.amount})`
                        : ''}
                    </div>
                    <div
                      onClick={() => optionChange(el, index)}
                      className="option-choose-button"
                      style={{
                        backgroundColor: el.amount === el.totalAmount || el.check ? '#B7B4B4' : '#C32D32',
                        cursor: 'pointer'
                      }}
                    >
                      {el.check ? '已选择' : '选择'}
                    </div>
                  </div>
                )
              }
            </div>
          }
          {
            indexKey === 1 &&
            <div className="signup-info" style={{ textAlign: 'left' }}>
              {/* app.css 中定义了全局的的text-align: center; 需要去掉 */}
              <div className="info-title">请完善报名信息</div>
              <Form layout='vertical'>
                {fields?.map((el, index) =>
                  <Form.Item label={el.name} key={index} >
                    {renderItem(el, index)}
                  </Form.Item>
                )}
              </Form>
            </div>
          }
          {
            indexKey === 2 &&
            <div className="signup-info">
              <div className="info-title">
                您需要支付 ¥{actualCost}
                <PayWaySelect
                  way={payType}
                  onChange={setPayType}
                />
              </div>
            </div>
          }
          {
            indexKey === 3 &&
            <div className="signup-info">
              <div className="info-title">
                {color ? '您已经提交报名' : info.buttonTxt}
              </div>
              <Link to="/personal/center/my/enroll">
                <Button style={{ background: '#40a9ff', color: 'white' }}>
                  查看报名
                </Button>
              </Link>
            </div>
          }
          {
            indexKey !== 3 && (
              <div onClick={() => nextStep()} className="quiz-button" style={{ borderRadius: 5 }}>
                {indexKey === 2 ? '立即支付' : '下一步'}
              </div>
            )
            // color &&
            // <div style={{ width: '100%', display: 'flex', marginTop: '40px', justifyContent: 'center', alignItems: 'center' }}>
            //   <div className="quiz-button" onClick={() => cancelEnroll()}>取消报名</div>
            //   <div className="quiz-button" onClick={() => setIndexKey(1)} style={{ backgroundColor: 'blue', color: 'white' }}>修改报名</div>
            // </div>
          }


        </div>
        <div className='close' onClick={() => navigate('/activity')}>
          {/* 关闭 X */}
          关闭<img src={Icons['close']} alt="" style={{width:'25px',height:'25px'}} />
        </div>
      </div>
      {
        isLogin &&
        <Login onLogin={() => setIsLogin(false)} onCancel={() => setIsLogin(false)} />
      }

      <Modal
        title={"微信支付 " + `${times && '支付时间还剩余' + `${times?.minute || ''}:${times?.second || ''}`}`}
        visible={showCode}
        footer={null}
        onCancel={() => setShowCode(false)}
      >
        <div style={{ width: '100%', margin: '30px auto', textAlign: 'center' }}>
          <img src={qrcode} alt="" />
        </div>
      </Modal>

      <div id="form" style={{ opacity: 0 }} />
    </div >
  );
}

const wechatImg = require('../../../assets/image/wechat.png');
const alipayImg = require('../../../assets/image/ai_pay.png');

enum PayWayEnum {
  wechat = 1,
  ali = 2,
  wallet = 3,
  none = 0,
}

interface PayWaySelectProps {
  way: PayWayEnum;
  onChange: (way: PayWayEnum) => void;
}

function PayWaySelect(props: PayWaySelectProps) {
  const { way, onChange } = props;
  const imageStyle = {
    width: '35px !important',
    height: '35px !important',
    marginRight: 20,
  };
  return (
    <List style={{ paddingLeft: '10px', backgroundColor: '#fff' }}>
      <List.Item >
        <List.Item.Meta
          style={{ width: '100%' }}
          title={
            <div onClick={() => onChange(PayWayEnum.wechat)} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Radio checked={way === PayWayEnum.wechat} />
              <img style={imageStyle} src={wechatImg} />
              <div>微信支付</div>
            </div>
          }
        />
      </List.Item>
      <List.Item >
        <List.Item.Meta
          style={{ width: '100%' }}
          title={
            <div onClick={() => onChange(PayWayEnum.ali)} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Radio checked={way === PayWayEnum.ali} />
              <img style={imageStyle} src={alipayImg} />
              <div>支付宝</div>
            </div>
          }
        />
      </List.Item>
    </List>
  );
}