import * as React from 'react';
import './index.css'
import { useNavigate } from 'react-router-dom';

interface PictureBackgroundProp {
  Topping: any
}
const url = {
  '5': '/vote/detail', //投票
  '4': '/quiz/detail', //竞猜
  '3': '/new/detail', //视频
  '2': '/new/detail', //咨询
  '1': '/enroll/detail', //报名
}
export function PictureBackground(prop: PictureBackgroundProp) {
  let { Topping } = prop
  const colors = {
    报名: '#F4AE42',
    竞猜: '#3697DB',
    投票: '#61BB5F',
    视频: '#c31c32',
  } as any
  const icons = JSON.parse(localStorage.getItem('icons') || '{}')
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const navigate = useNavigate();

  function goDetail() {
    navigate(url[Topping.tagType], { state: { id: Topping.id, tagType: Topping.tagType } })

  }
  return (
    <div style={{ width: '100%', }} onClick={() => goDetail()}>
      <div className="new-exhibition" >
        <div style={{ width: '100%', height: '100%', backgroundImage: `url(${Topping?.logo})`, backgroundSize: '100% 100%', filter: 'blur(4px)', backgroundRepeat: 'no-repeat', position: 'absolute', top: 0, left: 0 }}></div>
        <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div className="exhibition-img">
            <img src={Topping?.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          </div>
          <p style={{ width: '100%', position: 'absolute', color: '#fff', left: 0, bottom: 0, zIndex: 3, textAlign: 'center', fontSize: '22px', fontWeight: 'bold' }}>{Topping?.name}</p>
        </div>
      </div>
      <div className="bottom-info">
        <div className="info-box">
          <div className="info-left" style={{color:thmem?.fontColor5}}>
            <span className='left-name' style={{ backgroundColor: thmem?.fontColor5 }}></span>
            <span >{Topping?.tag}</span>
          </div>
          <div className="info-righ" style={{color:thmem?.fontColor5}}>
            <div className="time-img">
              <img src={icons['time']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </div>
            <p>{Topping?.createTime?.split(' ')[0]}</p>
          </div>
        </div>
      </div>
    </div>

  );
}
