import * as React from 'react';
import './accountData.css'
import md5 from 'md5';
import { ServicePeoples } from '../../../service/peoples/index'
import { ServiceAddress } from '../../../service/address/index'
import { ServiceAccount } from '../../../service/account/index'
import { ServiceSystem } from '../../../service/system/index'
import { localStorageEnum } from '../../../enum';
import { Button, Descriptions, Form, Spin, Upload, Modal, Input, message } from 'antd';
import { ACCOUNT_REGEXP, NICKNAME_REGEXP, PASSWORD_REGEXP } from '../../../configs/RegExp';
import Login from '../../loginHome/index'
import Util from '../../../utils';
import RealNameAuthInfo from './realName'
import PeopleList from './peopleList'
import Address from './address'
import Group from './group'
import { Link } from "react-router-dom";//引入routerdom
import { Net } from '../../../utils/net';

function PersonalCenter() {
  const domain = localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]
  const [icons, setIcons] = React.useState<any>({})
  const [data, setData] = React.useState<any>()
  const [loadding, setLoadding] = React.useState<boolean>(false)
  const [type, setType] = React.useState<string>('')
  const [nickName, setNickName] = React.useState<string>('')
  const [account, setAccount] = React.useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [code, setCode] = React.useState<string>('')
  const [oldPassword, setOldPassword] = React.useState<string>('')
  const [newPassword, setNewPassword] = React.useState<string>('')
  const [surePassword, setSurePassword] = React.useState<string>('')
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [authInfo, setAuthInfo] = React.useState<any>()
  const [people, setPeople] = React.useState<any>()
  const [address, setAddress] = React.useState<any>()

  async function getAccountDetailInfo() {
    try {
      Promise.all([
        ServiceAccount.myAuthInfo(),
        ServiceAccount.getAccountDetail(),
        ServicePeoples.getAllPeoples(true),
        ServiceAddress.getAddressList(0, 1)
      ]).then(res => {
        setAuthInfo(res[0])
        setPeople({ ...res[2]?.content[0] })
        setAddress({ ...res[3]?.content[0] })
        // 因为后面后台在没有登录后返回  detail=null
        if (res[1]?.detail) {
          localStorage.setItem('USER_INFO', JSON.stringify(res[1]))
          Util.setLoginInfo(res[1]?.token);
          setData(res[1]);
        }
      })
    } catch (e: any) {
      message.error(e.message)
    }
  }
  function getIcons() {
    Net(`/home/getAllIcon?domain=${domain}`)
      .then(res => {
        setIcons(res.data.list)
      })
      .catch(err => {
      });
  }
  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      return
    }
    getIcons()
    getAccountDetailInfo()
  }, [])
  React.useEffect(() => {
    if (!Util.IsLogin()) {
      setIsLogin(true);
    }
    if (type === '') {
      setNickName('')
      setAccount('')
      setPhone('')
      setCode('')
      setOldPassword('')
      setNewPassword('')
      setSurePassword('')
    }
  }, [type])

  function handleChange(info) {
    if (info.file.status === 'uploading') {
      setLoadding(true)
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        console.log(imageUrl)
        const param = new FormData(); // 创建form对象
        const photos = Util.dataURLtoFile(imageUrl, '头像');
        param.append('file', photos);
        ServiceAccount.modifyPortrait(param).then(res => {
          message.success('上传成功')
        }).catch(err => {
          message.error(err.message)
        })
          .finally(() => {
            setLoadding(false)
          })
      }
      );
    }
  };
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  async function modifyNickName() {
    try {
      if (!nickName || !NICKNAME_REGEXP.test(nickName.toString())) {
        message.error('请填写正确的昵称!');
        return;
      }
      await ServiceAccount.modifyNickName(nickName);
      message.success('修改成功!');
      getAccountDetailInfo()
      setType('')

    } catch (e: any) {
      message.error(e.message)
    }
  }
  async function modifyAccount() {
    try {
      if (!ACCOUNT_REGEXP.test(account.toString())) {
        return message.error('请填写正确的账号!');
      }
      await ServiceAccount.modifyAccount(account);
      message.success('修改成功!');
      getAccountDetailInfo()
      setType('')
      // await Util.sleep(1000);
      // Util.back();
    } catch (e: any) {
      message.error(e.message);
    }
  }
  async function modifyPhone() {
    try {

      if (!code || code.length === 0) {
        message.error('请输入验证码!');
        return;
      }
      const res = await ServiceAccount.modifyPhone(phone, code);
      localStorage.setItem(localStorageEnum.TOKEN, res.token);
      message.success('修改成功!');
      getAccountDetailInfo()
      setType('')
      // await Util.sleep(1000);
      // Util.back();
    } catch (e: any) {
      message.error(e.message);
    }
  }
  async function getCode() {
    try {
      await ServiceSystem.getVerifyCode(phone, phone, 3 + '');
      message.success('验证码已发送!');
    } catch (e: any) {
      message.error(e.message);
    }
  }
  async function modifyPW() {
    if (!oldPassword) {
      return message.error('请输入旧的密码!');
    }
    if (!newPassword) {
      return message.error('请输入新密码!');
    }
    if (!newPassword.match(PASSWORD_REGEXP)) {
      return message.error('新密码格式不正确!');
    }
    if (!surePassword) {
      return message.error('请输入确认密码!');
    }
    if (!surePassword.match(PASSWORD_REGEXP)) {
      return message.error('确认密码格式不正确!');
    }
    if (newPassword !== surePassword) {
      return message.error('两次输入密码不一致!');
    }
    try {
      const res = await ServiceAccount.modifyPassword(
        md5(window.btoa(newPassword)),
        md5(window.btoa(oldPassword))
      );
      localStorage.setItem(localStorageEnum.TOKEN, res.token);
      message.success('修改成功!');
      getAccountDetailInfo()
      setType('')
    } catch (e: any) {
      message.error(e.message);
    }
  }
  async function logout() {
    try {
      await ServiceSystem.logout();
      Util.removeLoginInfo();
      localStorage.setItem('USER_INFO', '{}')
      message.success('退出登录成功!');
      setType('')
      window.location.reload()
    } catch (error) {
    }
  }
  function onChange() {
    if (data && data.auth) {
      setType('group')
      // if (data.group && data.group.groupType.id !== 1) {

      // }else{

      // }
    } else {
      message.info('请先实名')
    }
  }

  return (
    <Spin spinning={loadding}>
      <div className="accountHeader" style={{ marginTop: '35px' }}>
        <h1>账号资料</h1>
        {/* <Link to='/personal/center/vip/center'>
          <Button onClick={() => { window.location.reload() }} type="primary" style={{ marginLeft: '30px' }}>
            返回会员中心
          </Button>
        </Link> */}
      </div>
      <div className="accound-top">
        <Descriptions column={1} >
          <Descriptions.Item label="头像">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={data?.detail.portraitUrl} alt="" style={{ width: '40px', height: '40px', margin: '0 10px' }} />
              <Upload
                showUploadList={false}
                onChange={(info) => handleChange(info)}>
                <Button size='small'>上传</Button>
              </Upload>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="昵称">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ margin: '0 10px' }}>{data?.detail?.nickName}</p>
              <a onClick={() => { setType('nickName'); setNickName(data?.detail?.nickName || '') }} >编辑</a>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="账号">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ margin: '0 10px' }}>{data?.detail.account}</p>
              {
                data && (data.detail.accountRevisable) &&
                <a onClick={() => setType('account')}>还有一次修改机会</a>
              }
            </div>
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="data-list">
        <div className="list-item">
          <img src={icons['phone']} alt="" />
          <p className="list-title">手机号码</p>
          <p className="list-info">{data?.detail?.phone}</p>
          <Button onClick={() => setType('phone')}>修改手机号码</Button>
        </div>
        <div className="list-item">
          <img src={icons['computer']} alt="" />
          <p className="list-title">登录密码</p>
          <p className="list-info">定期更换密码有助于账号安全</p>
          <Button onClick={() => setType('password')} >修改登录密码</Button>
        </div>
        <div className="list-item">
          <img src={icons['auth']} alt="" />
          <p className="list-title">实名认证</p>
          <p className="list-info">{authInfo?.mobileAccountAuth?.name || '暂未认证'} {authInfo?.mobileAccountAuth?.idCardCode && Util.formatIdNumber(authInfo?.mobileAccountAuth?.idCardCode)}</p>
          <Button onClick={() => setType('realName')}>{authInfo?.mobileAccountAuth?.name ? '查看实名信息' : '实名认证'}</Button>
        </div>
        <div className="list-item">
          <img src={icons['phone']} alt="" />
          <p className="list-title">我的组织</p>
          <p className="list-info">{data && data.auth ? data?.group?.entity?.name || '暂无组织' : '请先实名认证'}</p>
          <Button onClick={() => onChange()}>查看我的组织</Button>
        </div>
        <div className="list-item">
          <img src={icons['phone']} alt="" />
          <p className="list-title">常用购票人</p>
          <p className="list-info">{people?.entity?.name || '暂无常用购票人'} {people?.entity?.idCardCode && Util.formatIdNumber(people?.entity?.idCardCode)}</p>
          <Button onClick={() => setType('pepoleList')}>查看常用购票人</Button>
        </div>
        <div className="list-item">
          <img src={icons['phone']} alt="" />
          <p className="list-title">收货地址</p>
          <p className="list-info">{address?.address || '暂无收货地址'}{address?.entity?.address}</p>
          <Button onClick={() => setType('address')}>查看收货地址</Button>
        </div>
        <div className="list-item">
          <img src={icons['out']} alt="" style={{ height: '96px' }} />
          <p className="list-info"></p>
          <Button onClick={() => logout()} style={{ fontSize: '20px' }}>退出登录</Button>
        </div>
      </div>
      {/* 修改昵称 */}
      <Modal
        title="修改昵称"
        visible={type == 'nickName'}
        onOk={() => modifyNickName()}
        onCancel={() => setType('')}
        cancelText='取消'
        okText="确认"
      >
        <Form
        >
          <Form.Item
            label="昵称"
            required
          >
            <Input value={nickName} onChange={e => setNickName(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
      {/* 修改账号 */}
      <Modal
        title="修改账号"
        visible={type == 'account'}
        onOk={() => modifyAccount()}
        onCancel={() => setType('')}
        cancelText='取消'
        okText="确认"
      >
        <Form
        >
          <Form.Item
            label="账号"
            required
          >
            <Input value={account} onChange={e => setAccount(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
      {/* 修改手机号 */}
      <Modal
        title="修改手机号"
        visible={type == 'phone'}
        onOk={() => modifyPhone()}
        onCancel={() => setType('')}
        cancelText='取消'
        okText="确认"
      >
        <Form
        >
          <Form.Item
            label="手机号码"
          >
            <p>{data?.detail?.phone}</p>
          </Form.Item>
          <Form.Item
            label="新手机号码"
            required
          >
            <Input value={phone} onChange={e => setPhone(e.target.value)} />
          </Form.Item>
          <Form.Item
            label="验证码"
            required
          >
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Input value={code} onChange={e => setCode(e.target.value)} style={{ width: '60%', marginRight: '10px' }} />
              <Button onClick={() => getCode()} size='small' type='primary'>获取</Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      {/* 修改登录密码 */}
      <Modal
        title="修改登录密码"
        visible={type == 'password'}
        onOk={() => modifyPW()}
        onCancel={() => setType('')}
        cancelText='取消'
        okText="确认"
      >
        <Form
        >
          <Form.Item
            label="旧密码"
            required
          >
            <Input.Password value={oldPassword} onChange={e => setOldPassword(e.target.value)} style={{ width: '70%' }} />
          </Form.Item>
          <Form.Item
            label="新密码"
            required
          >
            <Input.Password value={newPassword} onChange={e => setNewPassword(e.target.value)} style={{ width: '70%' }} />
          </Form.Item>
          <Form.Item
            label="确认新密码"
            required
          >
            <Input.Password value={surePassword} onChange={e => setSurePassword(e.target.value)} style={{ width: '70%' }} />
          </Form.Item>
        </Form>
      </Modal>
      {/* 实名信息 */}
      <Modal
        title={authInfo?.mobileAccountAuth?.name ? "实名信息" : "实名认证"}
        visible={type == 'realName'}
        onCancel={() => setType('')}
        footer={null}
      >
        <RealNameAuthInfo onComplete={() => { setType(''); getAccountDetailInfo() }} isReal={authInfo?.mobileAccountAuth?.name ? true : false} />
      </Modal>
      {/* 常用购票人 */}
      <Modal
        title="常用购票人"
        visible={type == 'pepoleList'}
        onCancel={() => setType('')}
        footer={null}
      >
        <PeopleList />
      </Modal>
      {/* 常用地址 */}
      <Modal
        title="常用地址"
        visible={type == 'address'}
        onCancel={() => setType('')}
        footer={null}
      >
        <Address />
      </Modal>

      {/* 常用地址 */}

      <Modal
        title="我的组织"
        visible={type == 'group'}
        onCancel={() => setType('')}
        footer={null}
      >
        <Group onCancel={() => setType('')} />
      </Modal>
      {
        isLogin &&
        <Login onLogin={() => { getAccountDetailInfo(); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </Spin >
  );
}
export default PersonalCenter