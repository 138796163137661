import * as React from 'react';
import { OrderStatusEnum, ServiceOrder } from '../../../../../service/order';
import utils from '../../../../../utils';
import './applicationAfter.css';
import { PlusOutlined } from '@ant-design/icons'
import { message, Upload, Spin, Pagination, Button, Input } from 'antd';
import { ImageComponent } from '../../../../../component/imageComponet/ImageComponent';
interface ApplicationAfterProp {
  orderId: any
  type: string
  cancel: () => void
}
const defaultPic = require('../../../../../assets/image/money.png');
const tuiPic = require('../../../../../assets/image/tui.png');
const cameraPic = require('../../../../../assets/image/upload.svg');
const data = [];
export function ApplicationAfter(prop: ApplicationAfterProp) {
  let { orderId, type, cancel } = prop
  const [games, updateGames] = React.useState<any>({
    item: {},
    specification1: '',
    specification2: '',
  });
  const [money, setMoney] = React.useState<any>([]);
  const [textarea, setTextarea] = React.useState<any[]>([]);
  const [types, setType] = React.useState<number>();
  const [selectedImage, setSelectedImage] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  async function getGoodsDate() {
    try {
      const data = JSON.parse(sessionStorage.getItem('after') as string);
      if (data) {
        updateGames(data);
        let pic = data.item.actualCost * data.item.amount;
        setMoney(pic);
      }
    } catch (e: any) {
      message.error(e.message);
    }
  }
  function refund(e) {
    if (e.target.innerText === '仅退款') {
      setType(1);
    }
    if (e.target.innerText === '退货退款') {
      setType(2);
    }
    const she = document.getElementById('all');
    const box = document.getElementById('voucher');
    if (she !== null && box !== null) {
      she.style.display = 'none';
      box.style.display = 'block';
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    getGoodsDate();
  }, []);

  function moneyChange(e) {
    if (Number(e.target.value) <= Number(games?.item.actualCost * games?.item.amount)) {
      setMoney(e.target.value);
    }
  }

  function textareaChange(e) {
    setTextarea(e.target.value);
  }

  //提交售后
  async function submitSale() {
    const this_type = type as any;
    const body = {
      orderId: orderId as string, // 订单编号
      pssType: types, // 售后类型， 1 仅退款；2、退货退款
      itemId: games.item.id, //订单中的物品id
      amount: games.item.amount, // 退货数量
      price: money, // 价格
      description: textarea,
      images: selectedImage.map(el => el.thumbUrl), //图片 base64图片的数组  state.selectedImage[0].url
    };
    const body_this = {
      orderId: orderId as string, // 订单编号
      itemId: games.item.id, //订单中的物品id
      amount: games.item.amount, // 退货数量
      price: money, // 价格
      description: textarea,
      images: selectedImage.map(el => el.thumbUrl), //图片 base64图片的数组  state.selectedImage[0].url
    };

    try {
      if (money.length == 0) {
        message.error('您还没有填写退款金额');
      } else if (money > games.item.actualCost * games.item.amount) {
        message.error('退款金额不正确，请修改');
      } else if (textarea.length == 0) {
        message.error('您还没有填写退款原因');
      } else {
        //今天晚上问一下朱总这个接口是干什么的？？？？？？？？？
        this_type == 2
          ? await ServiceOrder.alignPutCalc(body_this)
          : await ServiceOrder.putCalc(body);

        message.success('提交成功');
        cancel()
        // utils.jumpPage(ROUTER_NAME.ORDER_INFO.path, {
        //   id: query.get('orderId') as string,
        // }); //调到售后订单详情
      }
    } catch (e: any) {
      message.error(e.message);
    }
  }
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  );
  return (
    <Spin spinning={loading}>
      <div style={{ backgroundColor: '#F2F2F2', height: '100%' }}>
        <div className='apply-title'>
          <div className='tickets-details'>
            <img
              className='apply-img'
              src={games.item.itemLogo}
              alt=""
            />
            <div className='Order-box'>
              <p className='tickets-title'>{games.item.itemName}</p>
              <p className='goods-title'>
                {games.specification1 == null ? '' : games.specification1 + ';'}
                {games.specification2 == null ? '' : games.specification2}
              </p>
              <p className='goods-title'>
                价格:¥{games.item.actualCost}.00
                <span style={{ color: '#101010' }}>X{games.item.amount}</span>
              </p>
            </div>
          </div>
        </div>
        <div className='apply-logistics' id="all">
          <div className='refund' onClick={e => refund(e)}>
            <img src={defaultPic} alt="" />
            <span>仅退款</span>
            <i
              className="icon-right"
              style={{ color: '#C7C7CC', float: 'right' }}
            >
              {' '}
            </i>
          </div>

          <div className='refund-tui' onClick={refund}>
            <img src={tuiPic} alt="" />
            <span>退货退款</span>
            <i
              className="icon-right"
              style={{ color: '#C7C7CC', float: 'right' }}
            >
              {' '}
            </i>
          </div>
        </div>

        <div className='refund-details' id="voucher">
          <p>
            退款金额：
            <Input
              type="text"
              value={money}
              onChange={value => moneyChange(value)}
              style={{ color: '#C31C32' }}
            />
          </p>
          <p>最多可退¥{games?.item.actualCost * games?.item.amount}</p>
          <Input.TextArea
            title="退款原因："
            placeholder="请填写"
            rows={4}
            value={textarea}
            onChange={(e) => textareaChange(e)}
          >
          </Input.TextArea>

          <div className='voucher'>
            <p>上传凭证：</p>
            {
              <Upload
                listType="picture-card"
                fileList={selectedImage}
                showUploadList={{ showPreviewIcon: false }}
                onChange={({ fileList }) => { console.log(fileList); setSelectedImage(fileList) }}
              >
                {selectedImage.length >= 4 ? null : uploadButton}
              </Upload>
            }
            <div
              className='voucher-img'
              style={{ position: 'relative' }}
            >
            </div>
          </div>
          <Button
            className='Submission'
            onClick={() => submitSale()}
          >
            提交
          </Button>
        </div>
      </div>
    </Spin >
  );
}
// export default ApplicationAfter
