import * as React from 'react';
import { PASSWORD_REGEXP, PHONE_REGEXP } from '../../configs/RegExp';
import { ServicePeoples } from '../../service/peoples'
import { ServiceOrder } from '../../service/order'
import { deduplication } from '../../utils/tool';
import { ImageComponent } from '../../component/imageComponet/ImageComponent';
import { Checkbox, Select, message, Spin, Modal } from 'antd'
import Util from '../../utils'
import './index.css'
const { Option } = Select;

interface ticketBuyerProp {
  people: any[]
  ticketId: number
  count: number
  choosePeople: (item: any[]) => void
  onCancel: () => void
}
export default function TicketBuyer(prop: ticketBuyerProp) {
  let { people, ticketId, count, choosePeople, onCancel } = prop
  const [type, setType] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [data, setData] = React.useState<any>([])
  const [peoples, setPeoples] = React.useState<any>([])
  const [arry, setArry] = React.useState<any>([])
  const [name, setName] = React.useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [cardType, setCardType] = React.useState<string>('')
  const [cardNum, setCardNum] = React.useState<string>('')
  const [pacePic, setPacePic] = React.useState<string>('')
  React.useEffect(() => {
    ServicePeoples.getAllPeoples(true)
      .then(res => {
        let newData = deduplication(
          [...data, ...res.content],
          ['entity', 'id']
        );
        newData?.map(el => {
          if (people.indexOf(el.entity.id) != -1) {
            el.selected = true
            arry.push(el)
          }
        })
        setArry(arry)
        setPeoples(newData)
        setData(newData)
        setLoading(false)
      })
      .catch(e => {
        message.error(e.message);
      })
  }, [])
  async function submitForm() {
    try {
      for (const item of arry) {
        if (people.indexOf(item.entity.id) === -1) {
          const ok = await ServiceOrder.isCanBuyIt(
            item.entity.id,
            ticketId as number
          );
          if (!ok) {


          } else {
            message.warn('同一购票人只能购买一张同类型票!');
            return;
          }
        }
      }
      choosePeople(arry)
    } catch (e: any) {
      message.error(e.message);
    }
  }

  function checkboxChange(id, index, el, e) {
    let countNum: number = 0
    peoples.map((i, ind) => {
      if (i.selected) {
        countNum += 1
      }
    });
    if (countNum >= count && !(el.selected)) {
      message.warn(`最多可选择${count}个购票人`)
      return
    }

    if (!e.target.checked) {
      let key = -1
      arry.map((el, index) => { if (el.entity.id === id) { key = index } })
      arry.splice(key, 1)
    } else {
      arry.push(el)
    }
    setArry(arry)

    peoples[index].selected = !peoples[index].selected
    setPeoples([...peoples]);
  }

  return (
    <Modal wrapClassName="ticketBuyerModal" visible={true} footer={null} onCancel={() => type ? setType(false) : onCancel()}>
      <Spin spinning={loading}>
        {/* <div className="people-container"> */}
        {
          type ?
            <div className="add-people">
              <p>新增购票人</p>
              <div className="add-people-box">
                <div className="label">姓名</div>
                <div className="input-value">
                  <input value={name} onChange={(e) => setName(e.target.value)} type="text" />
                </div>
              </div>
              <div className="add-people-box">
                <div className="label">手机号码</div>
                <div className="input-value">
                  <input value={phone} type="text" onChange={(e) => setPhone(e.target.value)} />
                </div>
              </div>
              <div className="add-people-box">
                <div className="label">证件类型</div>
                <div className="input-value">
                  <Select defaultValue="" value={cardType} style={{ width: 120 }} onChange={(e) => setCardType(e)} >
                    <Option value={1}>身份证</Option>
                    <Option value={2}>护照</Option>
                  </Select>
                </div>
              </div>
              <div className="add-people-box">
                <div className="label">证件号码</div>
                <div className="input-value">
                  <input value={cardNum} onChange={(e) => setCardNum(e.target.value)} type="text" />
                </div>
              </div>
              <div className="add-people-box">
                <div className="label">人脸照片</div>
                <div className="input-value">
                  <ImageComponent value={pacePic} onChange={(data) => setPacePic(data)} />
                </div>
              </div>
              <div className="people-button" onClick={() => submitForm()}>提交</div>
            </div>
            :
            <div className='people-box'>
              <div className="people-title">我的购票人</div>
              {
                peoples?.map((el, index) =>
                  <div className="people-list">
                    <Checkbox checked={el?.selected} onChange={(e) => checkboxChange(el.entity.id, index, el, e)} />
                    <div className="people-log">
                      <img src={el.faceVideoUrl} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    </div>
                    <div className="people-info">
                      <p>{el.entity.name}</p>
                      <p>{el.entity.idCardCode}</p>
                    </div>
                  </div>
                )
              }
              <span onClick={() => setType(true)} style={{ display: 'block', margin: '20px 0', color: 'blue', cursor: 'pointer' }}>新增购票人</span>
              <div className="people-button" onClick={() => submitForm()}>提交</div>
            </div>
        }
      </Spin>
    </Modal>
  );
}