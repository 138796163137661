/**
 * @author: hukun
 * @Date: 2019-05-23
 * @Time: 18:04
 * @function
 */

import { Net } from '../../utils/net';
import { _pageData } from '../common_i';

/**
 * 收货地址数据
 */
export interface AddressEntity {
  address: string;
  cityId: number;
  first: boolean;
  fullAddress: string;
  id?: number;
  name: string;
  phone: string;
  provinceId: number;
  regionId: number;
}

/**
 * 一条收货地址
 */
export interface AddressContent {
  entity: AddressEntity;
  address: string;
}

export class ServiceAddress {
  /**
   * 获取收货地址列表
   * @param pageNum
   * @param pageSize
   */
  public static getAddressList(
    pageNum: number = 1,
    pageSize: number = 999
  ): Promise<_pageData<AddressContent>> {
    return Net('/member/address/list', null, {
      //@ts-ignore
      params: { pageNum, pageSize },
    }).then(res => res.data.list);
  }

  /**
   * 查询某一条收货地址
   * @param id
   */
  public static queryAddress(id: number | string): Promise<AddressContent> {
    //@ts-ignore
    return Net('/member/address/query', null, { params: { id } }).then(
      res => res.data.query
    );
  }

  /**
   * 新增地址
   * @param body
   */
  public static addAddress(body: AddressEntity): Promise<{ id: number }> {
    return Net('/member/address/add', { entity: body }).then(res => res.data);
  }

  /**
   * 修改地址
   * @param body
   */
  public static modifyAddress(body: AddressEntity): Promise<any> {
    return Net('/member/address/modify', { entity: body }).then(
      res => res.data
    );
  }

  /**
   * 删除地址
   * @param id
   */
  public static deleteAddress(id: number): Promise<any> {
    //@ts-ignore
    return Net('/member/address/delete', null, { params: { id } }).then(
      res => res.data
    );
  }
}
