import * as React from 'react';
import './index.css'
import { ServiceHomePage } from '../../service/homePage/index';
import { ServiceArticle } from '../../service/article/index';
import { Honr } from '../../component/honr/index';
import { Sponsors } from '../../component/sponsors/index'
import { PictureBackground } from '../../component/PictureBackground/index'
import { ContentMessage } from '../../component/ContentMessage/index'
import { TitleList } from '../../component/TitleList/index'
import { message, Spin } from 'antd';
import { NoData } from '../../component/noData';
class New extends React.Component<any, any> {
  state = {
    sponsors: [] as any,
    honors: [] as any,
    articleList: [] as any,
    active: 1,
    loading: true,
    totalElement: 1,
    titleList: [{ id: 1, name: '最新' }, { id: 2, name: '资讯' }, { id: 3, name: '视频' }],
    thmem: JSON.parse(sessionStorage.getItem('thmem') || '{}'),
    newActive: sessionStorage.getItem('newActive') || -1,
    filter: {
      tagType: '3,2',
      pageNum: 1,
      pageSize: 10,
    } as any,
    Topping: [] as any,
    hasMore: true
  };

  componentDidMount() {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)

    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    sessionStorage.setItem('headerActive', menuArray?.indexOf('/new'))
    let { newActive } = this.state
    Promise.all([
      ServiceHomePage.getSponsors(),
      ServiceHomePage.getHonors(),
    ]).then(res => {
      this.setState({
        sponsors: res[0],
        honors: res[1],
      })
    }).catch(err => {
      message.error(err.message)
    }).finally(() => {
      this.setState({ loading: false })
    })
    if (newActive >= 0) {
      this.onChange(Number(newActive))
    } else {
      this.getData(this.state.filter, true)
    }
  }

  getData(filter = this.state.filter, flag = false) {
    let { Topping, articleList } = this.state
    ServiceArticle.list(filter).then(res => {
      if (flag) {
        articleList = []
        Topping = res?.content[0] ? [res?.content[0]] : []
      }
      let newData = [] as any
      let newData1 = [] as any
      let date = res?.content[0]?.createTime?.split(' ')[0]
      res?.content?.map((el: any) => {
        if (el.top && flag) {
          Topping.push(el)
        }
        if (date == (el.createTime?.split(' ')[0])) {
          newData1.push(el)
        } else {
          newData.push({
            time: date,
            list: newData1
          })
          date = el.createTime?.split(' ')[0]
          newData1 = [el]
        }
      })
      if (newData1.length > 0) {
        newData.push({
          time: date,
          list: newData1,
        })
      }
      this.setState({
        articleList: [...articleList, ...newData],
        filter,
        Topping,
        totalElement: res?.totalElements,
        hasMore: !res.last,
      })
    }).catch(err => {
      message.error(err.message)
    }).finally(() => {
      this.setState({ loading: false })
    })
  }


  onChange(key: number) {
    let { filter } = this.state
    filter.pageNum = 1
    if (key === 1) {
      filter.tagType = '3,2'
    } else {
      filter.tagType = key
    }
    this.setState({
      active: key,
      loading: true,
      totalElement: 1
    })
    this.getData(filter, true)
  }

  handle() {
    let { filter, hasMore } = this.state
    //totalElement > articleList.length 无法判断是否还有数据
    if (hasMore) {
      filter.pageNum = Number(filter.pageNum) + 1
      this.getData(filter)
    } else {
      message.warn('亲，没有更多内容啦')
    }

  }

  render() {
    let { sponsors, totalElement, honors, thmem, active, titleList, articleList, Topping, loading } = this.state
    return (
      <Spin spinning={loading}>
        <div className="container" style={{ width: '100%', background: thmem?.background, minHeight: '90vh' }}>
          <div className="container1"></div>
          <TitleList active={active} titleList={titleList} onChange={(e) => this.onChange(e)} />

          {/* 最新大图 */}
          {
            active === 1 && Topping.length > 0 &&
            <PictureBackground Topping={Topping[Topping?.length - 1]} />
          }
          {/* 内容 */}
          {
            totalElement > 0 ?
              <ContentMessage
                articleList={articleList}
                type={2}
                onClick={() => this.handle()}
              />
              :
              <NoData show={true} />
          }
          {/* 荣誉图片列表 */}
          <Honr honors={honors} />
          {/* 赞助商列表 */}
          <Sponsors sponsors={sponsors} />
          <div
            style={{ padding: '10px 0' }}
            dangerouslySetInnerHTML={{ __html: thmem?.bottomHtml || ' ' }}
          />
        </div >
      </Spin>
    );
  }
}
export default New