// 验证手机号码
export const PHONE_REGEXP = /^1\d{10}$/;

// 验证证件号码
export const ID_NUMBER_REGEXP = /^[a-zA-Z0-9]+$/;

export const NICKNAME_REGEXP = /^.{1,20}$/;

export const ACCOUNT_REGEXP = /^[a-zA-Z0-9_.]{3,16}$/;

// 强密码 字母+数字+特殊字符
//  /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*]+$)(?![\d!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/

// 中：字母+数字，字母+特殊字符，数字+特殊字符
export const PASSWORD_REGEXP = /^(?![a-zA-Z]+$)(?![\W_]+$)(?![0-9]+$)[a-zA-Z0-9\W_]{6,20}$/;

export const REGEXP = {
  phone: PHONE_REGEXP,
  pass: PASSWORD_REGEXP,
  nickName: NICKNAME_REGEXP,
  accountName: ACCOUNT_REGEXP,
  idCard: ID_NUMBER_REGEXP,
  http: /(http|https):\/\/([\w.]+\/?)\S*/,
  email: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
};
