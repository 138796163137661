import * as React from 'react';
import './ticketComponent.css';
import { Button, message, Modal } from 'antd';
import { ServiceEnroll, QueryBody } from '../../../../service/enroll';
import Util from '../../../../utils';
import { HandlerError } from '../../../../utils/HandlerError';

interface optionsProp {
  itemName: string
  id: string
  enrollId: string
  orderId: string
}
export default function Options(prop: optionsProp) {
  let { id, itemName, enrollId, orderId } = prop
  const [options, setOptions] = React.useState<any>([]);
  const [optionName, setOptionName] = React.useState('');
  const [reset, setReset] = React.useState(false);
  const [info, setInfo] = React.useState<QueryBody>({} as QueryBody);
  React.useEffect(() => {
    ServiceEnroll.query(id)
      .then(res => {
        let options = res.items;
        options.map((i: any) => {
          if (i.name === itemName) {
            i.check = true;
          }
        });
        setOptions(options);
        setInfo(res);
      })
      .catch(err => {
        message.error(err.message);
      });
  }, []);

  function optionChange(row, index) {
    if (row.amount !== row.totalAmount && reset) {
      options.map(i => (i.check = false));
      options[index].check = true;
      setOptions([...options]);
      setOptionName(options[index].name);
    }
  }

  function submit() {
    if (!optionName) {
      message.warn('请选择一个报名选项');
      return;
    }
    let body = {
      enrollmentId: id,
      itemName: optionName,
    };

    ServiceEnroll.modifyOption(body)
      .then(res => {
        message.success('提交成功');
        // setTimeout(() => {
        //   Util.jumpPage(ROUTER_NAME.MY_ENROLL_DETAIL.path, { id: enrollId });
        // }, 1000);
      })
      .catch(err => {
        message.error(err.message);
      });
  }

  function onClick() {
    if (!reset) {
      setReset(true);
    } else {
      submit();
    }
  }

  let signInEndTime;
  let nowTime = new Date().getTime();
  try {
    signInEndTime = new Date(info.signInEndTime.replace(/-/g, '/')).getTime();
  } catch (error) {
    console.log(error);
  }

  return (
    <div style={{ height: '100%', width: '95%', margin: '0 auto', backgroundColor: 'white' }}>
      <div className='option-main'>
        <div style={{ marginBottom: '10px' }}>{info.itemName}</div>
        {options.map((i, index) => (
          <div className='option-item' key={index}>
            <div style={{ flex: 1 }}>
              {i.name}
              {info.showAmount
                ? i.totalAmount
                  ? `(${i.amount}/${i.totalAmount})`
                  : `(${i.amount})`
                : ''}
            </div>
            <Button
              style={{
                display: i.check || reset ? 'block' : 'none',
                color: '#fff',
                cursor: 'pointer',
                backgroundColor:
                  i.amount === i.totalAmount || i.check ? '#B7B4B4' : '#C32D32',
              }}
              size="small"
              onClick={() => optionChange(i, index)}
            >
              {i.check ? '已选择' : '选择'}
            </Button>
          </div>
        ))}
      </div>
      <Button
        className='btn'
        onClick={onClick}
        style={{
          display:
            !(orderId?.length > 0) && signInEndTime && signInEndTime > nowTime
              ? 'block'
              : 'none',
          backgroundColor: !reset && optionName ? '#B7B4B4' : '#C32D32',
        }}
      >
        {reset ? '确认选择' : '重新选择'}
      </Button>
    </div>
  );
}
