import * as React from 'react';
import './index.css'
import { ServiceHomePage, GamesBody, GameTopBody, TicketBody } from '../../../service/homePage/index';
import { Honr } from '../../../component/honr/index';
import { Sponsors } from '../../../component/sponsors/index'
import { TicketTop } from '../top'
import { GameList } from './gameList'
import { useLocation, useParams } from 'react-router-dom'
import { json } from 'stream/consumers';
import { message, Spin } from 'antd';
function Ticket() {

  const [sponsors, setSponsors] = React.useState<any[]>([])
  const [honors, setHonors] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [gameList, setGameList] = React.useState<TicketBody[]>([])
  const [gameId, setGameId] = React.useState<number>()
  const [top, setTop] = React.useState<GameTopBody>({} as GameTopBody)
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const location = useLocation()
  const domain = localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]
  const state: any = location.state;
  // const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams()
  React.useEffect(() => {
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    sessionStorage.setItem('headerActive', menuArray?.indexOf('/ticket'))
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    function init() {
      Promise.all([
        ServiceHomePage.getSponsors(),
        ServiceHomePage.getHonors(),
      ]).then(res => {
        setSponsors(res[0])
        setHonors(res[1])
      }).catch(err => {
        message.error(err.message)
      }).finally(() => {
        setLoading(false)
      })
      getGameTop()
      getData(state?.gameId || params?.gameId)

    }
    init()
  }, [])
  function getData(gameId) {
    ServiceHomePage.getGameList2(gameId).then(res => {
      setGameList(res)

    })
  }
  function getGameTop() {
    ServiceHomePage.getGameTop({ domain }).then(res => {
      setTop(res)
    })
  }

  let backgroundImage = ''
  thmem?.images?.map((el: any) => {
    if (el.imageType === 7) {
      backgroundImage = el.imageUrl
    }
  })

  return (
    <Spin spinning={loading}>
      <div style={{ marginTop: '35px', width: '100%', minHeight: '100vh', background: thmem?.background }}>
        {/* 最新大图 */}
        {/* 内容 */}
        {
          JSON.stringify(top) !== '{}' &&
          <TicketTop isButtom={false} top={top} />
        }
        {/* 推荐图片 */}
        {
          backgroundImage &&
          <div style={{ width: '100%' }}>
            <img src={backgroundImage} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
        }
        {
          gameList?.length > 0 &&
          <GameList gameList={gameList} />
        }
        {/* 荣誉图片列表 */}
        <Honr honors={honors} />
        {/* 赞助商列表 */}
        <Sponsors sponsors={sponsors} />
      </div >
    </Spin>
  );
}
export default Ticket