
import * as React from 'react';
import './index.css'
import { ReactDOM } from 'react';
import { ServiceHomePage } from '../../service/homePage'
import { NavLink as Link } from 'react-router-dom'
const url = {
  '5': '/vote/detail', //投票
  '4': '/quiz/detail', //竞猜
  '3': '/new/detail', //视频
  '2': '/new/detail', //咨询
  '1': '/enroll/detail', //报名
}
class CarouselHero extends React.Component<any, any> {

  constructor(prop: any) {
    super(prop)
    this.state = {
      thmem: JSON.parse(sessionStorage.getItem('thmem') || '{}'),
      icons: JSON.parse(localStorage.getItem('icons') || '{}'),
      topImg: [] as any
    }
  }

  componentDidMount() {
    ServiceHomePage.getHomeTop().then(res => {
      this.setState({ topImg: res }, () => {
        this.click2()
      })
    })
  }
  click(index: number) {
    if (index === 0) {
      this.click1()
    }
    if (index === 1) {
      this.click2()
    }
    if (index === 2) {
      this.click3()
    }
  }
  click1() {
    let tipObj1 = this.refs.tip1 as HTMLElement
    let tipObj2 = this.refs.tip2 as HTMLElement
    let tipObj3 = this.refs.tip3 as HTMLElement
    setTimeout(() => {
      tipObj1.classList.remove("un-select")
      tipObj1.classList.add("on-select")
      tipObj2.classList.add("un-select")
      tipObj2.classList.remove("on-select")
      tipObj3.classList.add("un-select")
      tipObj3.classList.remove("on-select")
    }, 50);
  }

  click2() {
    let tipObj1 = this.refs.tip1 as HTMLElement
    let tipObj2 = this.refs.tip2 as HTMLElement
    let tipObj3 = this.refs.tip3 as HTMLElement
    setTimeout(() => {
      tipObj2.classList.add("on-select")
      tipObj2.classList.remove("un-select")

      tipObj1.classList.add("un-select")
      tipObj1.classList.remove("on-select")

      tipObj3.classList.add("un-select")
      tipObj3.classList.remove("on-select")
    }, 50);

  }

  click3() {
    let tipObj1 = this.refs.tip1 as HTMLElement
    let tipObj2 = this.refs.tip2 as HTMLElement
    let tipObj3 = this.refs.tip3 as HTMLElement
    setTimeout(() => {
      tipObj3.classList.add("on-select")
      tipObj3.classList.remove("un-select")

      tipObj2.classList.add("un-select")
      tipObj2.classList.remove("on-select")

      tipObj1.classList.add("un-select")
      tipObj1.classList.remove("on-select")
    }, 50);

  }

  setActiveKey(item) {
    console.log(item);
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    console.log(menuArray);

    if (item.tagType == 1) {
      sessionStorage.setItem('headerActive', menuArray?.indexOf('/activity'))
    } else {
      sessionStorage.setItem('headerActive', menuArray?.indexOf('/new'))
    }
    return
  }

  render() {
    let { thmem, icons, topImg } = this.state
    return (
      <div className="big-img-box">
        {
          topImg?.map((el: any, index: number) =>
            <div onClick={() => this.setActiveKey(el)} key={index} className="big-img-one" ref={'tip' + (index + 1)} onMouseEnter={() => this.click(index)}>
              <Link
                to={
                  { pathname: url[el.tagType] + '/' + el.id + '/' + el.tagType }
                } >
                <div className="top-img">
                  <img src={el.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  <p className='big-img-one-title'>{el.name}</p>
                </div>
                <div className="bottom-info" style={{ background: thmem?.background }}>
                  <div className="info-box"  >
                    <div className="info-left" style={{ color: thmem?.fontColor5 }}>
                      <span></span>
                      <span>{el.subType}</span>
                    </div>
                    <div className="info-righ" style={{ color: thmem?.fontColor5 }}>
                      <div className="time-img">
                        <img src={icons['time']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                      </div>
                      <p>{el.createTime?.split(' ')[0]}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )
        }
      </div>
    );
  }
}
export default CarouselHero