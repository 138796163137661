/**
 * @author: hukun
 * @Date: 2019-06-05 10:53
 * @description
 */
import { Net } from "../../utils/net";

export enum DictionaryEnum {
  配送方式 = 1206,
  证件类型 = 2001,
  发票类型 = 400,
  发票抬头 = 401,
  资讯类别 = 10000,
  视频类别 = 10001
}

export interface DictContent {
  dictKey: number;
  dictValue: string;
}

const cache = new Map();

export class ServiceDictionary {

  public static getDictionary(dictionaryId): Promise<DictContent[]> {
    // /// 对字典做个缓存
    if (cache.has(dictionaryId)) {
      return Promise.resolve(cache.get(dictionaryId));
    }
    return Net("/common/getDictionary", null, {
      params: { dictionaryId }
    }).then(res => {
      cache.set(dictionaryId, res.data.dictionary);
      return res.data.dictionary;
    });
  }
}
