import * as React from 'react';

export function useDownTime(cb?: () => void) {
  const [_, update] = React.useState(0);
  const time = React.useRef(-1);
  const status = React.useRef(0);
  const timer = React.useRef<any>(null);
  const stop = function stop() {
    status.current = 0;
    clearInterval(timer.current as any);
    time.current = -1;
    cb && cb();
  };
  const start = React.useMemo(
    () =>
      function s(count: number) {
        if (status.current === 1) {
          return;
        }
        time.current = count;
        status.current = 1;
        // @ts-ignore
        timer.current = setInterval(() => {
          if (time.current !== 0) {
            time.current -= 1;
            update(time.current);
          } else {
            stop();
          }
        }, 1000);
      },
    []
  );
  return {
    start,
    stop,
    time: time.current,
  };
}
