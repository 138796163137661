import { Net } from '../../utils/net';
import { _pageData } from '../common_i';

export interface VideoBody {
  createTime: string;
  id: number;
  name: string;
  shareAmount: number;
  touchAmount: number;
  url: string;
  logo: string;
}
export interface ArticleBody {
  createTime: string;
  id: number;
  name: string;
  shareAmount: number;
  touchAmount: number;
  description: string;
  logo: string;
}
export interface queryBody {
  createTime: string
  description: string
  id: number
  logo: string
  name: string
  shareAmount: number
  touchAmount: number
}

interface Filter {
  pageNum?: number;
  pageSize?: number;
  tagType: number | string;
  subType?: number | string;
  status?: number | string; //2、未开始，3、进行中，4、已结束; 其他值无效,
  domain?: any;
  clientType?: string;
}

export class ServiceArticle {
  public static list(params: any): Promise<_pageData<ArticleBody>> {
    params.domain = localStorage.getItem('DOMAIN') || '';
    params.clientType = '8'
    return Net('/member/article/list', null, { params }).then(
      res => res.data.list
    );
  }

  public static query(articleId: any): Promise<queryBody> {
    let domain = localStorage.getItem('DOMAIN') || '';
    return Net('/member/article/query', null, {
      params: { articleId, domain },
    }).then(res => res.data.query);
  }

  public static addShare(articleId: any) {
    let domain = localStorage.getItem('DOMAIN') || '';
    return Net('/member/article/share', null, {
      params: { articleId, domain },
    });
  }

  public static videoQuery(videoId): Promise<VideoBody> {
    let domain = localStorage.getItem('DOMAIN') || '';
    return Net('/member/video/query', null, {
      params: { videoId, domain },
    }).then(res => res.data.query);
  }
}

// video

