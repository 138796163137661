import * as React from 'react';
import './invoice.css'
import { ServiceInvoice } from '../../../service/invoice'
import { IdAndName } from '../../../service/common_i';
import { message, Input, Radio, Menu, List, Form, Button } from 'antd';
const { SubMenu } = Menu;
interface InvoiceProp {
  id: any,
  onCancel: () => void
}
function InvoiceAdd(prop: InvoiceProp) {
  let { id, onCancel } = prop
  console.log(id);

  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const icons = JSON.parse(localStorage.getItem('icons') || '{}')
  const [enrollItem, setEnrollItem] = React.useState<any>([])
  const [look, setLook] = React.useState<any>('')
  const [company, setCompany] = React.useState<any>('')
  const [identify, setIdentify] = React.useState<any>('')
  const [email, setEmail] = React.useState<any>('')
  const formData = {
    company: '',
    identify: '',
    receiver: '',
    phone: '',
    address: '',
    provinceId: -1,
    cityId: -1,
    regionId: -1,
    fullAddr: '',
    email: '',
  }
  const formError = {
    company: false,
    identify: false,
    receiver: false,
    phone: false,
    address: false,
    email: false,
  };
  React.useEffect(() => {
  }, [])

  async function handlerSubmit() {
    try {
      if (look == 1) {
        if (!company) {
          message.error('请输入公司名称')
          return
        }
        if (!identify) {
          message.error('请输入纳税人识别号')
          return
        }
      }
      if (!email) {
        message.error('请输入电子邮箱')
        return
      }

      const body = {
        entity: {
          name: look == 1 ? company : '',
          taxNumber: look == 1 ? identify : '',
          email: email,
          phone: '',
          content: '商品明细',
          receiverName: formData.receiver,
          receiverPhone: formData.phone.replace(/\s/g, ''),
          receiverAddress: formData.address,
          regionId: formData.regionId,
          provinceId: formData.provinceId,
          cityId: formData.cityId,
        },
        tax: '普通发票',
        type: '电子',
        head: look == 1 ? '企业' : '个人',
        orderIds: [id],
      }

      console.log(body);
      return
      await ServiceInvoice.submitInvoiceForm(body as any);
      message.success('成功')
      onCancel()
    } catch (error: any) {
      message.error(error.message)
    }
  }

  return (
    <div style={{ background: '#f5f5f5' }}>
      <div className='invoice-title'>发票类型</div>
      <List style={{ paddingLeft: '10px', backgroundColor: '#fff' }}>
        <List.Item >
          <List.Item.Meta
            title={
              <Radio checked={true}>电子发票</Radio>
            }
            description={
              <div style={{ marginLeft: '25px' }}>电子发票与纸质发票具有同等的法律效应，可支持报销入账</div>
            }
          />
        </List.Item>
      </List>
      <div className='invoice-title'>发票抬头</div>
      <List style={{ paddingLeft: '10px', backgroundColor: '#fff' }}>
        <List.Item >
          <List.Item.Meta
            style={{ width: '100%' }}
            title={
              <Radio checked={look == 2} value={2} onChange={() => setLook(2)}>个人</Radio>
            }
          />
          <div onClick={() => setLook(2)} style={{ width: '83%', height: '28px' }}></div>
        </List.Item>
        <List.Item >
          <List.Item.Meta
            title={
              <Radio checked={look == 1} value={1} onChange={() => setLook(1)}>公司</Radio>
            }
          />
          <div onClick={() => setLook(1)} style={{ width: '83%', height: '28px' }}></div>
        </List.Item>
      </List>
      {
        look == 1 &&
        <Form
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12 }}
          style={{ backgroundColor: '#fff', paddingBottom: '20px' }}
        >
          <Form.Item
            label="公司全称"
          >
            <Input value={company} onChange={(e) => setCompany(e.target.value)} style={{ border: 'none' }} placeholder='请输入' />
          </Form.Item>
          <Form.Item
            label="纳税人识别号"
          >
            <Input value={identify} onChange={(e) => setIdentify(e.target.value)} style={{ border: 'none' }} placeholder='请输入' />
          </Form.Item>
        </Form>
      }

      <div className='invoice-title'>发票内容</div>
      <List style={{ paddingLeft: '10px', backgroundColor: '#fff' }}>
        <List.Item >
          <List.Item.Meta
            title={
              <Radio checked={true}>商品明细</Radio>
            }
            description={
              <div style={{ marginLeft: '25px' }}>根据国家相关规定，发票的开票内容需与购买商品一致，不支持手动选择开票内容。</div>
            }
          />
        </List.Item>
      </List>
      <div className='invoice-title'>接收地址</div>
      <Form
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 12 }}
        style={{ backgroundColor: '#fff', padding: '20px 0 10px' }}
      >
        <Form.Item
          label="电子邮箱"
        >
          <Input value={email} onChange={(e) => setEmail(e.target.value)} style={{ border: 'none' }} placeholder='请输入' />
        </Form.Item>
      </Form>
      <div style={{ width: '100%', paddingTop: '10px', textAlign: 'center', background: '#fff' }}>
        <Button onClick={() => handlerSubmit()} style={{ width: '80%' }} danger type="primary" >
          提交
        </Button>
      </div>
    </div>
  );
}
export default InvoiceAdd