import { Net } from '../../utils/net';

export interface GroupDetail {
  entity: {
    id: number;
    name: string;
    description: string;
    provinceId: number;
    cityId: number;
    regionId: number;
    address: string | null;
    contactName: string;
    contactPhone: string;
  };
  fullAddress: string;
  groupType: {
    id: number;
    name: string;
  };
  logo: string;
  fansNbr: number;
}

export class GroupService {
  public static list(params) {
    return Net('/member/group/list', null, { params }).then(
      res => res.data.content
    );
  }
  public static add(id) {
    return Net('/group/add', null, { params: { id } });
  }
  public static modify(id) {
    return Net('/group/modify', null, { params: { id } });
  }
  public static query(id) {
    return Net('/group/query', null, { params: { id } }).then(
      res => res.data.query
    );
  }
  //查询可加入的团体列表
  public static getAllGroup() {
    return Net('/member/group/listAllGroups').then(res => res.data.list);
  }
  //加入团体
  public static joinGroup(groupId) {
    return Net('/member/group/joinGroup', null, { params: { groupId } });
  }
  //查询已加入团体详情
  public static getMyGroup(): Promise<GroupDetail> {
    return Net('/member/group/myGroup').then(res => res.data.detail);
  }
  //查询团体下的成员列表
  public static getGroupFans(params): Promise<any> {
    return Net('/member/group/listGroupMember', null, { params }).then(
      res => res.data.list
    );
  }
  //退出团体
  public static quitGroup(): Promise<any> {
    return Net('/member/group/quitGroup');
  }
  //查询历史
  public static getHistory(): Promise<any> {
    return Net('/member/group/listHistory').then(res => res.data.list.content);
  }
}
