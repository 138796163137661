import React from 'react';
import logo from './logo.svg';
import { Net } from '../utils/net'
import { ServiceHomePage } from './homePage/index';
import { json } from 'stream/consumers';
const domain = localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]
export function GetTheme() {
  ServiceHomePage.getTheme().then(res => {
    sessionStorage.setItem('thmem', JSON.stringify(res))
  })
}
export function initGetTheme() {
  ServiceHomePage.getTheme().then(res => {
    sessionStorage.setItem('thmem', JSON.stringify(res))
    return res
  })
}

export function getIcons() {
  Net(`/home/getAllIcon?domain=${domain}`)
    .then(res => {
      localStorage.setItem('icons', JSON.stringify(res.data.list));
      //设置浏览器中所显示的图标
      let titleIcon = document.getElementsByTagName('link')[0];
      // 这里替换http为https 
      titleIcon.href = res.data.list['Default Avatar']?.replace('http', 'https') || `/favicon.ico`;
    })
    .catch(err => {
    });
}

