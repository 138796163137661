import * as React from 'react';
import { PASSWORD_REGEXP, PHONE_REGEXP } from '../../configs/RegExp';
import { ServiceSystem } from '../../service/system'
import { message, Modal, Input } from 'antd';
import Util from '../../utils'
import './login.css'
import { useDownTime } from '../../hooks/useDownTime';

interface LoginHomeProp {
  onCancel: () => void
  onLogin: () => void
  onRejister: () => void
}
export default function Retreve(prop: LoginHomeProp) {
  let { onCancel, onLogin, onRejister } = prop
  const [phone, setPhone] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [code, setCode] = React.useState<string>('')
  const [surePassword, setSurePassword] = React.useState<string>('')

  const downTime = useDownTime()

  async function submitForm() {

    try {
      if (!phone || !phone.match(PHONE_REGEXP)) {
        message.error('请输入正确的手机号!');
        return;
      }
      if (!code) {
        message.error('请输入验证码!');
        return;
      }
      if (!password || !password.match(PASSWORD_REGEXP)) {
        message.error('请输入正确的密码!');
        return;
      }
      if (password !== surePassword) {
        message.error('输入的两次密码不相同')
      }
      await forgetModifyPassword(phone, password, code);
    } catch (err: any) {
      message.error(err.message);
    }
  }
  async function forgetModifyPassword(phone, password, code): Promise<void> {
    try {
      await ServiceSystem.forgetModifyPassword(
        phone,
        code,
        Util.hashPassword(password)
      );
      await login(phone, password);
    } catch (err: any) {
      message.error(err.message);
    }
  }

  async function login(phone, pwd): Promise<void> {

    try {
      const res = await ServiceSystem.login(phone, Util.hashPassword(pwd));
      localStorage.setItem('homeDomain', res?.domain);
      Util.setLoginInfo(res.token)
    } catch (err: any) {
      message.error(err.message)
    }
  }

  async function getVerifyCode(): Promise<any> {
    try {
      if (downTime.time > 0) {
        return
      }
      if (!phone || !phone.match(PHONE_REGEXP)) {
        message.error('请输入正确的手机号!');
        return Promise.reject(new Error('0'));
      }
      await ServiceSystem.getVerifyCode(phone, phone, '1');
      message.info('验证码已发送!');
      downTime.start(120)
      return Promise.resolve(1);
    } catch (err: any) {
      message.error(err.message);
      return Promise.reject(err);
    }
  }

  return (
    // <div className="login-container">
    <Modal visible={true} footer={null} onCancel={() => onCancel()}>
      <div style={{ width: '100%', backgroundColor: '#fff' }}>
        <div className='login-box'>
          <div className="login-title">我们来帮助您重置密码</div>
          <div className="phone">
            <div className="phone-title">手机号码</div>
            <div style={{ width: '100%', position: 'relative' }}>
              <Input value={phone} onChange={(e: any) => setPhone(e.target.value)} />
              <span
                onClick={() => getVerifyCode()}
                className='register-code'
                style={{}}
              >
                {downTime.time > 0 ? downTime.time + 's' : '发送验证'}
              </span>
            </div>

            <div className="phone-title">验证码</div>
            <Input value={code} onChange={(e: any) => setCode(e.target.value)} />
            <div className="phone-title">密码</div>
            <Input.Password
              style={{ width: 226 }}
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />

          </div>
          <div className='prompt-information'>6~20位字符，包含字母、数字和特殊字符的<strong style={{ color: 'black' }}>两种</strong></div>
          <div className="phone">
            <div className="phone-title">确认新密码</div>
            <Input.Password
              style={{ width: 226 }}
              value={surePassword}
              onChange={(e: any) => setSurePassword(e.target.value)}
            />
          </div>
          <div className="login-button" onClick={() => submitForm()}>确认重置密码</div>
          <p style={{ margin: '10px 0', color: '#1890ff', cursor: 'pointer' }} onClick={() => onRejister()} >还没有注册？让我们带您开启大门！</p>
        </div>
        <div className='completed' onClick={() => onLogin()}>
          返回登录
        </div>
      </div>
    </Modal>
  );
}