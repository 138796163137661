import * as React from 'react';
import './index.css'
import { useNavigate } from 'react-router-dom';
interface GoodsProp {
  goodsList: any[]
  onChange?: () => void
}

export function HomeGoods(prop: GoodsProp) {
  const { goodsList, onChange } = prop
  const navigate = useNavigate();

  return (
    <div className="goodsBox1">
      {
        goodsList?.map((el: any) =>
          <div className="goods-one" onClick={() => {
            onChange && onChange()
            navigate('/shopping/merchandise', { state: { goodsId: el.goodsId } })
          }
          }
          >
            <div className="goods-image">
              <img src={el.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <p className="goods-title">{el.name}</p>
            <p className="godds-price">￥39</p>
          </div>
        )
      }

      {/* <div className="goods-one">
        <div className="goods-image">
          <img src="" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </div>
        <p className="goods-title">深圳佳兆业官方助威围巾</p>
        <p className="godds-price">￥39</p>
      </div>
      <div className="goods-one">
        <div className="goods-image">
          <img src="" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </div>
        <p className="goods-title">深圳佳兆业官方助威围巾</p>
        <p className="godds-price">￥39</p>
      </div>
      <div className="goods-one">
        <div className="goods-image">
          <img src="" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </div>
        <p className="goods-title">深圳佳兆业官方助威围巾</p>
        <p className="godds-price">￥39</p>
      </div>
      <div className="goods-one">
        <div className="goods-image">
          <img src="" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </div>
        <p className="goods-title">深圳佳兆业官方助威围巾</p>
        <p className="godds-price">￥39</p>
      </div>
      <div className="goods-one">
        <div className="goods-image">
          <img src="" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </div>
        <p className="goods-title">深圳佳兆业官方助威围巾</p>
        <p className="godds-price">￥39</p>
      </div> */}
    </div>
  );
}
