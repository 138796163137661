import { useLayoutEffect, useState } from "react"
import { Router } from "react-router-dom"
import * as His from 'history';

export const history = His.createBrowserHistory();

export const BASE_PATH = `/${window.location.pathname.split('/')[1]}/w`

export function jumpPage(path) {
  console.log(BASE_PATH + path)
  history.push(BASE_PATH + path)
}

//为了能在类组件中使用路由跳转(尽量使用函数组件)
export const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
      basename={BASE_PATH}
    />
  )
}

