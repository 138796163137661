import { Net } from '../../utils/net';
import { _pageData } from '../common_i';

export interface AccountBody {
  entity: {
    id?: any;
    accountName: string;
    bankName: string;
    bankNumber: string;
  };
}

export class BankAccountService {
  public static list(): Promise<_pageData<AccountBody>> {
    return Net('/member/bank/list').then(res => res.data.list);
  }

  public static query(id: string): Promise<AccountBody> {
    return Net('/member/bank/query', null, { params: { id } }).then(res => res.data.query);
  }

  public static add(body: AccountBody): Promise<any> {
    return Net('/member/bank/add', body);
  }

  public static modify(body: AccountBody): Promise<any> {
    return Net('/member/bank/modify', body);
  }

  public static delete(id: any): Promise<any> {
    return Net('/member/bank/delete', null, { params: { id } });
  }
}
