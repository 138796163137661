import * as React from 'react';
import './quizDetail.css'
import Util from '../../../../utils/index'
import { QuizService, MineQuizBody, RewardItem } from '../../../../service/quiz/index'
import { ServiceAccount } from '../../../../service/account';
import { message } from 'antd';
import Login from '../../../loginHome/index'
/**
 * 竞猜
 */
interface QuizProp {
  id: number | string
  cancel: () => void
}
let logo = {
  1: require('../../../../assets/image/all.png'),
  2: require('../../../../assets/image/group.png'),
  3: require('../../../../assets/image/part.png'),
  4: require('../../../../assets/image/present.png'),
};
export default function QuizDetail(prop: QuizProp) {
  const { id, cancel } = prop
  let [canvas2, setCanvas2] = React.useState<any>();
  let [canvas1, setCanvas1] = React.useState<any>();
  const [info, setInfo] = React.useState<MineQuizBody>({} as MineQuizBody);
  const [scoreNum, setScoreNum] = React.useState('');
  const [lineup, setLineup] = React.useState([] as any);
  const [imgUrl, setImgUrl] = React.useState<any>(undefined);
  const allIcon = JSON.parse(localStorage.getItem('icons') || '');
  const [USER_INFO, setUSER_INFO] = React.useState(
    JSON.parse(localStorage.getItem('USER_INFO') || '{}')
  ); //detail.portraitUrl - 微信头像 detail.nickName - 姓名（头像下方展示）
  const [isLoad, setIsLoad] = React.useState(false);
  React.useEffect(() => {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    function init() {
      QuizService.queryMine(id)
        .then(async res => {
          setInfo(res || {});
          const info = await ServiceAccount.getAccountDetail();
          setUSER_INFO(info);
          await res.groups[0].items.forEach(el => {
            if (el.itemType === 4) {
              setLineup(el?.result?.split('、'));
            }
            if (el.itemType === 3) {
              setScoreNum(el.result);
            }
          });
          //
        })
        .catch(err => {
          message.error(err.message);
        });
    }
    init();
  }, [id]);
  // 单个数字
  React.useEffect(() => {
    if (canvas2) {
      setCanvasOne();
    }
  }, [lineup]);
  React.useEffect(() => {
    if (isLoad) {
      exportImg();
    }
  }, [isLoad]);
  function exportImg() {
    setImgUrl(canvas2.toDataURL('image/png'));
  }
  function setCanvasOne() {
    let imgUrl = new Image();
    imgUrl.src = info.logo; //allIcon['quiz']
    const ctx = canvas2.getContext('2d');
    ctx?.clearRect(0, 0, 0, 0)

    imgUrl.onload = () => {
      // 背景大图片
      canvas2.width = imgUrl.width;
      canvas2.height = imgUrl.height;
      ctx.clearRect(0, 0, canvas2.width, canvas2.height);
      ctx.drawImage(imgUrl, 0, 0);
      // 文本
      ctx.font = '70px 宋体';
      ctx.textBaseline = 'bottom';
      ctx.textAlign = 'center';
      ctx.fillStyle = '#ffffff';
      ctx.fillText(scoreNum?.split(':')[0] || '', 470, 560);
      ctx.fillText(scoreNum?.split(':')[1] || '', 620, 560);
      ctx.font = '40px 宋体';
      ctx.textBaseline = 'bottom';
      ctx.fillText(lineup[0] || '', 375, 785);
      ctx.fillText(lineup[1] || '', 375, 870);
      ctx.fillText(lineup[2] || '', 375, 955);
      ctx.fillText(lineup[3] || '', 375, 1035);
      ctx.fillText(lineup[4] || '', 375, 1120);
      ctx.fillText(lineup[5] || '', 375, 1205);
      ctx.fillText(lineup[6] || '', 740, 785);
      ctx.fillText(lineup[7] || '', 740, 870);
      ctx.fillText(lineup[8] || '', 740, 960);
      ctx.fillText(lineup[9] || '', 740, 1040);
      ctx.fillText(lineup[10] || '', 740, 1125);
      ctx.fillText(info.createTime || '', 580, 1342);
      ctx.fillText(`这是${USER_INFO.detail.nickName}的竞猜内容`, 545, 1660);
      // 头像
      let portraitUrl = new Image();
      portraitUrl.src = USER_INFO?.detail?.portraitUrl;
      portraitUrl.onload = () => {
        ctx.drawImage(portraitUrl, 420, 1368, 240, 240);
      };
    };
  }
  // 判断多选的是否猜中
  function setJjudge(item) {
    let index = true;
    let arry = item.result.split('、');
    let tag = item.tag.split('、');
    if (arry.length === tag.length) {
      arry.forEach(el => {
        if (item.tag.indexOf(el) === -1) {
          index = false;
        }
      });
      return index;
    } else {
      return false;
    }
  }
  //rewardMode: 1.数字 2.多选 3.比分
  function switchRewardMode(item: RewardItem) {
    return (
      <div
        key={item.lotteryActivityId}
        className='quiz-item'
        style={{ display: 'flex' }}
      >
        <img src={logo[item.rewardMode]} />
        <div className='quiz-info'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{item.title}</span>
            {item.button && (
              <div
                className='quiz-btn'
              // onClick={() => goLottery(item.lotteryActivityId)}
              >
                {item.button}
              </div>
            )}
          </div>
          <span>{item.description || item.tint}</span>
        </div>
      </div>
    );
  }
  return (
    <div style={{ width: '100%', backgroundColor: info.backgroundColor, }}>
      {info.rewards && info.rewards.length > 0 && (
        <div className='quiz-item-detail'>
          {(info.rewards || []).map(p => switchRewardMode(p))}
        </div>
      )}

      {imgUrl ? (
        <img src={imgUrl} alt="" />
      ) : (
        <canvas id="views" style={{ width: '100%' }} ref={r => setCanvas2(r)} />
      )}
      {(info.groups || []).map(
        (i, index1) =>
          i.name !== '隐藏结果' && (
            <div
              key={`group_${index1}`}
              className='quiz-item-content'
              style={{ paddingBottom: '20px' }}
            >
              <div
                style={{ padding: '10px 0' }}
                dangerouslySetInnerHTML={{ __html: i.description || ' ' }}
              />
              {/* <div
                key={`group_${index1}`}
                ref={ins => {
                  if (!ins) return;
                  ins.innerHTML = htmlDecodeByRegExp(i.description || '');
                }}
              /> */}
              <div className='quiz-item-detail'>
                {
                  i.items.map((el, index2) =>
                    el.itemType === 1
                      ?
                      <div className="input-number" style={{ display: 'block' }}>
                        <p style={{ padding: '10px 0' }}>
                          {`${el.name} `}
                          <span style={{ color: '#c31c32' }}>
                            {el.tag &&
                              `(${el.tag === el.result ? '已猜中' : el.tag})`}
                          </span>
                        </p>
                        <div className="input">
                          {/* <span
                          onClick={() => SingleSubtraction(index, index1)}
                          >-</span> */}
                          <input
                            disabled
                            // onChange={(e) => SingInput(index, index1, e.target.value)}
                            value={el.result} type="number" />
                          {/* <span
                          onClick={() => SingleAddition(index, index1)}
                          >+</span> */}
                        </div>
                      </div>
                      : el.itemType === 2 ?
                        <div className="line-up-list" style={{ display: 'block' }}>
                          <p style={{ padding: '10px 0' }}>
                            {`${el.name} `}
                            <span style={{ color: '#c31c32' }}>
                              {el.tag &&
                                `(${el.tag === el.result ? '已猜中' : el.tag})`}
                            </span>
                          </p>
                          {
                            el.itemNames?.map((i: any) =>
                              <p
                                // onClick={() => SingleChoice(index, index1, i)} 
                                style={i == el.result ? {
                                  backgroundColor: '#ec5435',
                                  color: '#fff',
                                  border: '1px solid #ec5435'
                                } : {}} className="line-up-name">{i}</p>
                            )
                          }
                        </div>
                        : el.itemType === 3 ?
                          <div className="input-number" style={{ display: 'block' }}>
                            <p style={{ padding: '10px 0' }}>
                              {`${el.name} `}
                              <span style={{ color: '#c31c32' }}>
                                {el.tag &&
                                  `(${el.tag === el.result ? '已猜中' : el.tag})`}
                              </span>
                            </p>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <div className="input">
                                {/* <span>-</span> */}

                                <input disabled value={el.result?.split(':')[0]} type="number" />
                                {/* <span > +</span> */}
                              </div>
                              <span>:</span>
                              <div className="input">
                                {/* <span>-</span> */}
                                <input disabled value={el.result?.split(':')[1]} type="number" />
                                {/* <span >+</span> */}
                              </div>
                            </div>
                          </div>
                          : el.itemType === 4 ?
                            <div className="line-up-list" style={{ display: 'block' }}>
                              <div style={{ margin: '10px auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p>{`${el.name} `}</p>
                                <p style={{ color: '#c31c32' }}>
                                  {el.tag && `(${setJjudge(el) ? '已猜中' : el.tag})`}
                                </p>
                              </div>
                              {
                                el.itemNames?.map((i: any) =>
                                  <p style={el.result?.split('、')?.indexOf(i) !== -1 && el.result?.indexOf(i) != undefined ? {
                                    backgroundColor: '#ec5435',
                                    color: '#fff',
                                    border: '1px solid #ec5435'
                                  } : {}} className="line-up-name">{i}</p>
                                )
                              }
                            </div>
                            : ''
                  )
                }
              </div>
            </div>
          )
      )}
      {/* {
        isLogin &&
        <Login onLogin={() => setIsLogin(false)} onCancel={() => setIsLogin(false)} />
      } */}
    </div >
  );
}