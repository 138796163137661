import { message, Modal, Input } from 'antd';
import * as React from 'react';
import { PASSWORD_REGEXP, PHONE_REGEXP } from '../../configs/RegExp';
import { ServiceSystem } from '../../service/system'
import { ServiceAccount } from '../../service/account'
import Util from '../../utils'
import './login.css'
interface LoginHomeProp {
  type?: number
  //   Refresh:()=>void
  onCancel: () => void
  onRejister: () => void
  onRetrieve: () => void
  onLogin: () => void
}
export default function Login(prop: LoginHomeProp) {
  let { onCancel, onRejister, onRetrieve, onLogin } = prop
  const [phone, setPhone] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')

  async function submitForm() {
    try {
      if (!phone || !phone.match(PHONE_REGEXP)) {
        message.error('请输入正确的手机号!');
        return;
      }
      if (!password || !password.match(PASSWORD_REGEXP)) {
        message.error('请输入正确的密码!');
        return;
      }
      await login(phone, password)
    } catch (err: any) {
      message.error(err.message);
    }
  }

  async function login(phone, pwd): Promise<void> {
    try {
      const res = await ServiceSystem.login(phone, Util.hashPassword(pwd));
      const info = await ServiceAccount.getAccountDetail();
      localStorage.setItem('USER_INFO', JSON.stringify(info))
      Util.setLoginInfo(res.token)
      localStorage.setItem('homeDomain', res?.domain);
      message.success('登录成功')
      window.location.reload()
      onLogin()

    } catch (err: any) {
      message.error(err.message)
    }
  }

  return (
    <Modal visible={true} footer={null} onCancel={() => onCancel()}>
      <div style={{ width: '100%', backgroundColor: '#fff' }}>
        <div className='login-box'>
          <div className="login-title">欢迎回家,请登录您的账号</div>
          <div className="phone">
            <div className="phone-title">手机号码</div>
            <Input defaultValue="" value={phone} onChange={(e: any) => setPhone(e.target.value)} />
            <div className="phone-title">密码</div>
            <Input.Password
              style={{ width: 226 }}
              defaultValue=""
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </div>
          <div className='prompt-information'>6~20位字符，包含字母、数字和特殊字符的<strong style={{ color: 'black' }}>两种</strong></div>
          <p style={{ margin: '10px 0', color: '#1890ff', cursor: 'pointer' }} onClick={() => onRetrieve()} >忘记密码了？</p>
          <div className="login-button" onClick={() => submitForm()}>登录</div>
        </div>
        <p style={{ margin: '10px 0', color: '#1890ff', cursor: 'pointer', textAlign: 'center' }} onClick={() => onRejister()}>还没有注册？让我们带您开启大门！</p>
      </div>
    </Modal>
  );
}