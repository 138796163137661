import * as React from 'react';
import { CollectionService, CollectionContent } from '../../../service/member/collection'
import Login from '../../loginHome/index'
import { useLocation, useNavigate } from 'react-router-dom'
import { message, Spin } from 'antd';
import Util from '../../../utils';

//导入css要使用这种方式,可以避免class样式冲突(因为发现很多同名class),其他直接引入css的页面都需要修改
//[https://create-react-app.bootcss.com/docs/adding-a-css-modules-stylesheet]
import styles from './digitalCollection.module.css'


export default function Merchandise() {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(true);
  const [isLogin, setIsLogin] = React.useState<boolean>(false);
  const [infoData, setInfoData] = React.useState<CollectionContent>({} as CollectionContent);
  const [isCanBuy, setCanBuy] = React.useState<Boolean>(false);
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const state: any = location.state;

  //定时器
  let SaleTimer;

  React.useEffect(() => {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setIsLogin(true)
      return;
    }
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    function init() {
      sessionStorage.setItem('goodsPlaceOrder', '');
      getData()
    }
    init()
    //清楚定时器
    return () => clearInterval(SaleTimer)
  }, [])

  function getData() {
    const goodsId = state.goodsId;
    CollectionService.query(goodsId).then(res => {
      setInfoData(res);
      getRTime(res.nfr.releaseTime);
    }).catch(err => {
      message.error(err.message)
    }).finally(() => {
      setLoading(false)
    })
  }

  function buyNow() {
    const { nfr } = infoData || {};
    const { releaseCount = 0, soldCount = 0 } = nfr || {};
    const {
      goodsId,
      logo,
      express,
      expressMoney,
      delivery,
      minimumPrice = 0,
      selfPick,
    } = query || {};
    if (releaseCount - soldCount === 0) {
      message.info('您来晚啦,数字藏品已售尽哦')
      return
    }
    sessionStorage.setItem(
      'collectionPlaceOrder', //SET数据到本地
      JSON.stringify({
        goodsId: goodsId,
        name: name,
        logo: logo,
        amount: 1,
        express: express, //是否有快递
        expressMoney: expressMoney, //快递价格
        delivery: delivery, //是否需要取票
        selfPick: selfPick, //是否自主取货
        minimumPrice: minimumPrice, //商品的价格
      })
    );
    navigate('/collection/placeOrder', { state: { goodsId } })
  }

  // 倒计时
  function getRTime(timeStr: string) {
    SaleTimer = setInterval(function () {
      let endTime = new Date(timeStr.replace(/-/g, '/')).getTime();
      let NowTime = new Date();
      let t = endTime - NowTime.getTime();

      if (t > 0) {
        t /= 1000; //总秒数
        let d = Math.floor(t / 60 / 60 / 24) as any;
        let h = Math.floor((t / 60 / 60) % 24) as any;
        let m = Math.floor((t / 60) % 60) as any;
        let s = Math.floor(t % 60) as any;

        if (d < 10) d = '0' + d;
        if (h < 10) h = '0' + h;
        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;

        let dEle = document.getElementById('t_d');
        let hEle = document.getElementById('t_h');
        let mEle = document.getElementById('t_m');
        let sEle = document.getElementById('t_s');

        dEle && (dEle.innerHTML = '即将发售 ' + d + '天');
        hEle && (hEle.innerHTML = h + ':');
        mEle && (mEle.innerHTML = m + ':');
        sEle && (sEle.innerHTML = s);
        setCanBuy(false);
      } else {
        let time = document.getElementById('time');
        time && (time.innerHTML = '立即购买');
        clearInterval(SaleTimer);
        SaleTimer = null
        setCanBuy(true);
      }
    }, 1000);
  }

  let { query, nfr } = infoData || {};
  const {
    name,
    logo,
    description,
    detail,
    topImages = [],
    memberDiscountValue,
    minimumPrice = 0,
    maximumPrice = 0,
    memberDiscountType,
  } = query || {};
  const {
    creator,
    name: nfrName,
    publisher,
    releaseCount = 0,
    soldCount = 0,
    version,
  } = nfr || {};

  // 按照是否有会员优惠计算展示价格
  let price = minimumPrice;
  let fakePrice = maximumPrice || minimumPrice;
  let showMemberPrice = memberDiscountType !== 0;
  //如果有会员优惠
  if (memberDiscountType && memberDiscountValue) {
    if (memberDiscountType === 1) {
      // return;
    } else if (memberDiscountType === 3) {
      price = memberDiscountValue;
    } else {
      price = price * memberDiscountValue * 0.1;
    }
    fakePrice = minimumPrice;
  }

  return (
    <Spin spinning={loading}>
      <div className={styles["container"]} style={{ background: thmem?.background }}>
        <div className={styles["merchandise"]}>
          <div className={styles["left-detail"]}>
            <div className={styles["active-detail-message"]}>
              <div
                dangerouslySetInnerHTML={{ __html: description || ' ' }}
              />
              <div
                dangerouslySetInnerHTML={{ __html: detail || ' ' }}
              />
            </div>
          </div>
          <div className={styles["right-purchase"]}>
            <div className={styles["purchase-title"]}>{name}</div>
            <div className={styles["purchase-message1"]}>
              <div className={styles["message-img"]}>
                <img src={logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </div>
              <div style={{ fontSize: '16px', margin: '10px 0', width: '100%', display: 'flex', alignItems: 'center' }}>
                <p>价格</p>
                <p style={{ fontSize: '22px', margin: "0 20px", color: '#c31c32', }}>{price == 0 ? '限时免费' : `¥${Util.toFixed(price, 2)}`}</p>
                <p style={{ color: '#cccccc', textDecoration: showMemberPrice ? 'unset' : 'line-through' }}>{fakePrice == price ? '' : `¥${Util.toFixed(fakePrice, 2)}`}</p>
              </div>
              <div className={styles['collection-info']}>
                <div><span>藏品名称：</span>{nfrName}</div>
                <div><span>版本号：</span>{version} 余{releaseCount - soldCount}/{releaseCount}</div>
                <div><span>发行数量：</span>{releaseCount}</div>
                <div><span>创作者：</span> <img src={creator} alt="" /></div>
                <div><span>发行方：</span><img src={publisher} alt="" /></div>
              </div>
            </div>
            <div className={styles["purchase-bottom"]}>
              {isCanBuy ?
                <div className={styles["buy-btn"]} onClick={() => buyNow()}>
                  立即购买
                </div>
                :
                <span id="time" className={styles.sale_time_down}>
                  <span id="t_d" />
                  <span id="t_h" />
                  <span id="t_m" />
                  <span id="t_s" />
                </span>
              }
            </div>
          </div>
        </div>

        {
          isLogin &&
          <Login onLogin={() => { setIsLogin(false); getData() }} onCancel={() => setIsLogin(false)} />
        }
      </div >
    </Spin >
  );
}
