import { Net } from '../../utils/net';
interface OrgContent {
  orgId: number;
  orgLogo: string;
  orgName: string;
  orgDomain: string;
  clientTitle: string;
}

export class ServiceCommon {
  /**
   * 用户协议
   */
  public static getProtocol(): Promise<string> {
    return Net('/common/protocol?domain=' + localStorage.getItem('DOMAIN') || '').then(res => res.data.text);
  }
  /**
   * 获取字典
   * @param dictType 10000:资讯类型 10001:视频类型
   */
  public static getListOrgType(dictType) {
    let domain = localStorage.getItem('DOMAIN') || '';
    return Net('/home/listOrgType', null, {
      params: { dictType, domain },
    }).then(res => res.data.list);
  }
  /**
   * 获取首页动画URL
   * @param domain
   */
  public static getLoginFlash() {
    let domain = localStorage.getItem('DOMAIN') || '';
    return Net('/home/getHomeFlash', null, { params: { domain } }).then(
      res => res.data.url
    );
  }

  /**
   * 获取组织页面标题
   */
  public static getOrgInfo(): Promise<OrgContent> {
    let domain = window.location.pathname.split('/')[1];
    let params = { domain };
    return Net('/api/w/home/getOrgInfo', null, { params }).then(res => res.data.org);
  }
}
