import * as React from 'react';
import './placeOrder.css'
import { ServiceVoucher, VoucherBody } from '../../../service/voucher'
import { ServicePay } from '../../../service/pay'
import { ServiceRealName } from '../../../service/member/realName'
import { ServiceOrder } from '../../../service/order'
import { ServiceAddress } from '../../../service/address'
import { ServiceTicket } from '../../../service/ticket'
import { message, Radio, Checkbox, Spin, Modal, Button } from 'antd';
import Util from '../../../utils';
import { NavLink as Link } from 'react-router-dom';
import QRCode from 'qrcode';
import Address from '../../personal-center/accountData/address'
import { jumpPage } from '../../../CustomRouter';

const giftCardImage = require('../../../assets/image/giftCard.png');
class TicketPlaceOrder extends React.Component<any, any>  {

  state = {
    ticketData: {} as any,
    distribution: [] as any,
    // 1无需，2快递，3自取
    pickAddress: [] as any,
    pickAddressType: [] as any,
    address: {} as any,
    addressType: '' as any,
    selectPickAddress: {} as any,
    selectedVouchers: [] as any,
    ticketItems: 0,
    memoInputValue: '' as any,
    vouchers: {} as VoucherBody,
    money: {
      actualCost: 0,
      deliveryPrice: 0,
      discount: 0,
      rawCost: 0,
    } as any,
    //   积分抵扣
    pointDeduction: 0 as number,
    thmem: JSON.parse(sessionStorage.getItem('thmem') || '{}'),
    body: JSON.parse(sessionStorage.getItem('placeOrder') || '{}') as any,
    loading: true,
    url: '',
    IsSuccess: false,
    showAddress: false,
    allIcon: JSON.parse(localStorage.getItem('icons') || '{}'),
    isDone: false,
    // 提交订单后的数据
    submitAfterData: {} as any,
    qrcode: '' as string,
    showCode: false as boolean,
    times: {} as any
  }


  componentDidMount() {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    this.getData()
  }

  async getData() {
    let { body } = this.state

    const [ticket] = await Promise.all([
      ServiceTicket.getTicketInfo(body.ticketId),
    ]);
    let ticketItems = body.selectable
      ? body.ticketForms[0]?.ticketForm || undefined
      : 2;
    this.setState({ ticketData: ticket.ticket, ticketItems, loading: false }, () => {
      if (body.ticketForms?.length <= 0) {
        return;
      }
      this.setDistributions(ticketItems);
    })


  }
  // 取票方式
  async setDistributions(ticketItems) {
    let { body } = this.state
    const { express, expressMoney, selfPick, delivery, selfPickAddresses } =
      body.ticketForms?.length > 1
        ? body.ticketForms[ticketItems - 1]
        : body.ticketForms[0];
    const list: any[] = [];
    /**
     *  2 快递
     *  3 自主
     *  1 无需
     */
    if (express) {
      list.push({
        id: 2,
        text: '快递',
        money: expressMoney,
      });
    }
    if (selfPick) {
      list.push({
        id: 3,
        text: '纸质票 (前往取票点取票)',
      });
      //   setPickAddress([...selfPickAddresses])
      //   setSelectPickAddress(selfPickAddresses[0] || {})
      this.setState({ pickAddress: [...selfPickAddresses], SelectPickAddress: selfPickAddresses[0] || {} })
    }

    if (delivery) {
      list.push({
        id: 1,
        text: '电子票 (无需取票凭电子票入场)',
      });
    }

    this.setState({ distribution: list })
    if (list?.length === 1) {
      this.setState({ addressType: list[0].id })
    }
    // 查看是否有默认收货地址, 然后计算价格
    await this.getAddress();
  }
  async getAddress() {
    try {
      const res = await ServiceAddress.getAddressList(0, 999);
      let first: any = null;
      for (const item of res.content) {
        if (item.entity.first) {
          first = item;
        }
      }
      // 如果有默认的收货地址就直接请求 价格
      // 如果没有默认收货地址,就自己计算一个
      if (first) {
        first.entity.address = first?.address + first?.entity?.address
        this.setState({ address: first.entity })
      }

      await this.getMoney();
    } catch (err: any) {
      message.error(err.message);
      // 报错了也需要自己计算价格
      // 在用户信息不全,如没有收货地址,没有收货方式等,后台就会返回错误
      this.setMoneyInit();
    }
  }
  async getMoney() {
    try {
      // setMoney(await ServiceOrder.preReserve(this.formatSubmitParams()))
      let money = await ServiceOrder.preReserve(this.formatSubmitParams()); //询价接口
      this.setState({ money })
      let param = this.formatSubmitParams();
      let voucherParam = {
        items: [
          {
            itemId: param.ticketId,
            itemType: 1,
            amount: 1,
          },
        ],
        totalPrice: money.rawCost - money.deliveryPrice,
        voucherIds: param.voucherIds,
        delivery: param.deliveryType === 2, //快递
      };

      const [voucher] = await Promise.all([
        ServiceVoucher.findVoucherByOrder(voucherParam),
      ]);
      this.setState({ vouchers: voucher })
    } catch (e: any) {
      message.error(e.message)
    }
  };

  setMoneyInit() {
    let momey = 0;
    let { body, ticketData, distribution } = this.state
    // 判断是否有赠品,
    if (body.isGift) {
      momey = body.locations.reduce((s, c) => (s += c.money), 0);
    } else {
      momey = body.peoples.reduce((s, c) => {
        if (c.student) {
          return (s += c.location.stuPrice);
        }
        return (s += c.location.money);
      }, 0);
    }
    // 快递费
    const em = ticketData.expressMoney || 0;

    let moneyBody: any = {};
    if (distribution?.length === 1) {
      // 大概是快递的id为 2吧
      const isKuaidi = !!distribution.find(v => v.id === 2);
      moneyBody = {
        // actualCost: money + (isKuaidi ? em : 0),
        actualCost: '请选择取票方式',
        deliveryPrice: isKuaidi ? em : 0,
        discount: '0.00',
        rawCost: momey + (isKuaidi ? em : 0),
      };
    } else {
      moneyBody = {
        // actualCost: money,
        actualCost: '请选择取票方式',
        deliveryPrice: '0.00',
        discount: '0.00',
        rawCost: momey,
      };
    }
    this.setState({ money: moneyBody })
  }

  formatSubmitParams() {
    let { body, ticketData, selectedVouchers, addressType, address, selectPickAddress, memoInputValue, ticketItems } = this.state
    let params: any = {};
    params = {
      ticketId: body.ticketId,
      voucherIds: selectedVouchers.map(v => v.entity.id),
      deliveryType: addressType,
      receiptAddressId: address.id,
      selfPickAddressId: selectPickAddress.id || '',
      userMemo: memoInputValue,
      ticketForm: ticketItems,
      gifts: [],
    };
    if (!ticketData.useSvg) {
      params.seats = body?.peoples?.map(item => {
        return {
          contactsId: item?.entity?.id,
          customSectionId: body?.customSectionId,
        };
      });
    } else {
      // 如果有赠品
      if (body.isGift) {
        params.seats = body.peoples.map(item => {
          return {
            contactsId: '',
            sectionSvgId: body.sectionId,
            seatSvgId: item.id,
          };
        });
      } else {
        params.seats = body.peoples.map(item => {
          return {
            contactsId: item.entity.id,
            sectionSvgId: body.sectionId,
            seatSvgId: item.id,
          };
        });
        if (body.gifts) {
          params.gifts = body.gifts
            .filter(v => !v.isRemove)
            .map(value => {
              return {
                goodsId: value.goodsId,
                specificationId: value.id,
                count: 1,
              };
            });
        }
      }
    }
    return params;
  }
  // 清除选择的优惠券
  clearVouchers() {
    let { body } = this.state
    // setSelectPickAddress([])
    this.setState({ selectPickAddress: [] })
    const arr = body.gifts;
    for (const [k, v] of Object.entries(body.gifts)) {
      arr[+k].isRemove = false;
    }
    body.gifts = arr;
    this.setState({ body })
    // sessionStorage.setItem(
    //   sessionStorageEnum.SUBMIT_ORDER,
    //   JSON.stringify(this.data)
    // );
  }
  // 选择优惠券
  saveSelectedVouchers(value) {
    let { selectedVouchers } = this.state
    let selectedIds = selectedVouchers.map(i => i.entity.id);
    if (selectedIds.includes(value.entity.id)) {
      //如果选择的是已经选中的
      selectedVouchers.map((i, index) => {
        //去掉selectedVouchers中保存的这个数据
        if (i.entity.id === value.entity.id) {
          selectedVouchers.splice(index, 1);
        }
      });
      this.updateVouchers();
    } else if (value.selectable) {
      //如果选择的是没选过的,并且可以选择的
      selectedVouchers.push(value);
      this.updateVouchers();
    }
    this.setState({ selectedVouchers })

  }
  // 当优惠券选择更改时调用,更新可用优惠券
  updateVouchers() {
    let { money } = this.state
    let param = this.formatSubmitParams();
    let voucherParam = {
      items: [
        {
          itemId: param.ticketId,
          itemType: 1,
          amount: 1,
        },
      ],
      totalPrice: money.actualCost,
      voucherIds: param.voucherIds,
      delivery: param.deliveryType === 2, //快递
    };
    this.getMoney()
    ServiceVoucher.findVoucherByOrder(voucherParam)
      .then(res => {
        //   setVouchers(res)
        // this.vouchers = res;
        this.setState({ vouchers: res })
      })
      .catch(err => {
        message.error(err.message);
      });
  }


  // 选择收货方式
  changeSelect(key) {
    this.setState({ addressType: key })
    if (key === 2) {
      this.getMoney();
    } else {
      this.getMoney();
    }
  }
  async submitOrders() {
    let { addressType } = this.state
    try {
      if (typeof addressType !== 'number') {
        return message.error('请选择取票方式!');
      }
      const res = await ServiceOrder.submitOrder(this.formatSubmitParams());

      let url = ''
      // 暂时回到票品首页
      if (res.actualCost === 0) {
        await ServiceRealName.activeTicketByOrderId(res.orderId);
        this.setState({ url: '/ticket', IsSuccess: true })
      } else {
        this.setState({ submitAfterData: res })
      }
    } catch (err: any) {
      message.error(err.messge)
    }
  }
  async wxPay() {
    let { submitAfterData } = this.state
    const data = await ServicePay.wechatPay(submitAfterData?.orderId); //现网环境的微信付款
    if (data) {
      let qrcode = await QRCode.toDataURL(data?.orderString?.code_url);
      this.setState({
        qrcode,
        showCode: true
      }, () => {
        this.getPayStatus(submitAfterData?.orderId)
        this.setCountDown(submitAfterData?.expireTime)
      })
    }
  }
  async aliPay() {
    let { submitAfterData } = this.state
    try {
      const res = await ServicePay.aliPayByOtherBrowser(submitAfterData?.orderId);

      let from = res?.orderString as string
      let ht = this.refs.from as HTMLElement
      ht.innerHTML = from;
      document.forms[0].submit()
    } catch (e: any) {
      message.error(e.message);
    }
  }
  setCountDown(time: string) {
    const times1 = setInterval(() => {
      this.setState({ times: Util.showtime(time) })
    }, 1000)
  }
  // 轮询订单的支付状态,支付成功后跳转页面
  // let isDone = false;
  async getPayStatus(orderId) {
    let { isDone } = this.state
    try {
      const status = await ServicePay.getPayStatus(orderId);

      if (status) {
        jumpPage('/w/personal/center/my/order/0')
      } else {
        await Util.sleep(1000);
        // 轮询结果,在页面跳转的时候停止轮询
        if (!isDone) {
          await this.getPayStatus(orderId);
        }
        return;
      }
      this.setState({ isDone: true, showCode: false })

    } catch (e) {
    }
  }



  render() {
    let { body, loading, ticketData, thmem, ticketItems, selectedVouchers, vouchers, distribution, address, addressType, money, pointDeduction,
      submitAfterData,
      qrcode,
      showCode,
      allIcon,
      times } = this.state
    const vounchers =
      selectedVouchers?.length > 0
        ? `使用${selectedVouchers?.length}张`
        : vouchers?.valid?.length > 0
          ? `可用${vouchers?.valid?.length}张`
          : '暂无可用';

    const getTicketWay =
      distribution.find(v => v.id === addressType) &&
      distribution.find(v => v.id === addressType).id;
    const selectedIds = selectedVouchers.map(i => i.entity.id)

    let maxJF = Math.min(
      Math.floor(money.actualCost / 2) * money.pointsStep,
      Math.floor(money.totalPoints / money.pointsStep) * money.pointsStep
    ) || 0

    let bool = false;
    let text = money.actualCost;
    if (money?.actualCost?.toString().includes('请选择')) {
      bool = true;
      if (addressType) {
        text = '请选择收货地址';
      }
    }
    return (
      <Spin spinning={loading}>
        <div className="container" style={{ width: '100%', minHeight: '100vh', position: 'relative', background: thmem?.background }}>
          <div className="purchase-box">
            <div className="purchase-box-left">
              <div className="item-box">
                <div className="item-box-titel">球票形式</div>
                <div className="item-box-list">
                  <Radio.Group onChange={(e) => this.setState({ ticketItems: e.target.value })} value={ticketItems}>
                    {
                      body?.ticketForms?.map(el =>
                        <Radio key={el.ticketForm} value={el.ticketForm}>{el.ticketForm == 1 ? '电子票' : '实体票'}</Radio>
                      )
                    }
                  </Radio.Group>
                </div>
              </div>
              <div className="item-box">
                <div className="item-box-titel">配送方式</div>
                <div className="item-box-list">
                  <Radio.Group onChange={(e) => this.changeSelect(e.target.value)} value={getTicketWay}>
                    {distribution.map((item, index) => (
                      <Radio
                        key={item.id}
                        value={item.id}
                      >
                        {item.text}
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
              </div>
              <div className="item-box">
                <div className="item-box-titel">收货地址</div>
                <div className="item-box-list">
                  {
                    JSON.stringify(address) === '{}' ?
                      <p>请选择收货地址</p>
                      :
                      <p>{address.name} {address.phone} {address.address}</p>
                  }
                </div>
                <div className="address">
                  <Button onClick={() => this.setState({ showAddress: true })}>切换地址</Button>
                </div>
              </div>
              <div className="item-box">
                <div className="item-box-titel">优惠券 <span>{vounchers}张可用</span></div>
                <div className="item-box-list">
                  {
                    vouchers.valid?.map(el => {
                      const { voucher, selectable, entity = {} as any } = el;
                      return (
                        //@ts-ignore
                        <Checkbox checked={selectedIds.includes(entity.id)} onChange={() => this.saveSelectedVouchers(el)}>
                          <div className={selectable || selectedIds.includes(entity?.id) ? 'coupon-valid' : 'coupon-invalid'} >
                            <img src={el.voucher.image || giftCardImage} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                          </div>
                        </Checkbox>
                      )
                    }
                    )
                  }

                </div>
              </div>
              <div className="item-box">
                <div className="item-box-titel">积分 <span>共{money.totalPoints || 0} 可用{maxJF || 0}</span></div>
                <div className="item-box-list">
                  <input disabled={maxJF <= 0} value={pointDeduction} min={0} max={maxJF} onChange={(e: any) => this.setState({ pointDeduction: (Number(e.target.value) > maxJF ? maxJF : e.target.value) })} type="number" />
                  <p style={{ lineHeight: '30px', marginLeft: '30px' }}>抵扣 {pointDeduction}元</p>
                </div>
              </div>
            </div>
            <div className="purchase-box-right">
              <div className="purchase-right-title"></div>
              <div className="content-list">
                {body?.peoples &&
                  body?.peoples?.map(el =>
                    <div className="content-list-one">
                      <div className="content-img">
                        <img src={ticketData?.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                      </div>
                      <div className="content-info">
                        <p>{ticketData?.name}</p>
                        <p>{el.location?.split('区')[0]}区</p>
                        <p>{el.location?.split('区')[1]}</p>
                      </div>
                      <div className="content-price">
                        ￥{el.money}
                      </div>
                    </div>
                  )
                }
                {body?.gifts &&
                  body?.gifts?.map(item =>
                    <div className="content-list-one">
                      <div className="content-img">
                        <img src={item?.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                      </div>
                      <div className="content-info">
                        <p>{item?.name}</p>
                        <p>{item.specification1Key}:{item.specification1}</p>
                        <p>{item.specification2Key}:{item.specification2}</p>
                      </div>
                      <div className="content-price" style={{ color: "#c31c32" }}>
                        赠品
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="sub-total">
                <div className="sub-total-list">
                  <p className="total-title">小计</p>
                  <p className="total-price">￥{Util.toFixed(money.rawCost - money.deliveryPrice)}</p>
                </div>
                {addressType === 2 &&
                  <div className="sub-total-list">
                    <p className="total-title" style={{ fontSize: '13px' }}>快递费用</p>
                    <p className="total-price" style={{ fontSize: '13px' }}>+ ￥ {Util.toFixed(money.rawDeliveryPrice)}</p>
                  </div>
                }
                <div className="sub-total-list">
                  <p className="total-title" style={{ fontSize: '13px' }}>优惠金额</p>
                  <p className="total-price" style={{ fontSize: '13px' }}>￥{money.discount}</p>
                </div>
                {/* <div className="sub-total-list">
                          <p className="total-title">积分抵扣</p>
                          <p className="total-price">￥ 0</p>
                      </div>
                      <div className="sub-total-list">
                          <p className="total-title">运费</p>
                          <p className="total-price">+￥ {Util.toFixed(money.deliveryPrice)}</p>
                      </div> */}
              </div>
              <div className="sub-total">
                <div className="sub-total-list">
                  <p className="total-title">小计</p>
                  <p className="total-price">{bool ? text : `￥ ${Util.toFixed(money.actualCost)}`}</p>
                </div>
                {
                  JSON.stringify(submitAfterData) === '{}' ?
                    <div className="pay-now" onClick={() => this.submitOrders()}>提交</div>
                    :
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                      <div onClick={() => this.wxPay()} style={{ width: '30%', height: '50px', margin: '0 10px', border: '1px solid #ccc', cursor: 'pointer' }}>
                        <img src={allIcon['wpc']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                      </div>
                      <div onClick={() => this.aliPay()} style={{ width: '30%', height: '50px', margin: '0 10px', border: '1px solid #ccc', cursor: 'pointer' }}>
                        <img src={allIcon['zpc']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />

                      </div>
                      <div ref="from" style={{ opacity: 0 }}></div>
                    </div>
                }
                {/* <div className="pay-now" onClick={() => this.submitOrders()}>提交</div> */}
              </div>
            </div>
          </div>

        </div >
        <Modal title="提交成功" closable={false} visible={this.state.IsSuccess} footer={null}>
          <Button type="primary">
            <Link to={{ pathname: this.state.url }}>确认</Link>
          </Button>
        </Modal>
        <Modal
          title="常用地址"
          visible={this.state.showAddress}
          onCancel={() => this.setState({ showAddress: false })}
          footer={null}
        >
          <Address address={address} isChoose={true} onChoose={(e) => { this.setState({ address: { ...e }, showAddress: false }) }} />
        </Modal>
        <Modal
          title={"微信支付 " + `${times && '支付时间还剩余' + `${times?.minute || ''}:${times?.second || ''}`}`}
          visible={showCode}
          footer={null}
          onCancel={() => { this.setState({ showCode: false }) }}
        >
          <div style={{ width: '100%', margin: '30px auto', textAlign: 'center' }}>
            <img src={qrcode} alt="" />
          </div>
        </Modal>
      </Spin>
    );
  }
}
export default TicketPlaceOrder