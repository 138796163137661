import * as React from 'react';
import './index.css'
import { ActiveBody } from '../../service/homePage/index'
import { useNavigate } from 'react-router-dom';

interface activeProp {
  activity: ActiveBody,
  type: number,
  onChange?: () => void
}
const url = {
  '5': '/vote/detail', //投票
  '4': '/quiz/detail', //竞猜
  '3': '/new/detail', //视频
  '2': '/new/detail', //咨询
  '1': '/enroll/detail', //报名
}

export function HomeActive(prop: activeProp) {
  let { activity, type, onChange } = prop
  const navigate = useNavigate();
  const colors = {
    报名: '#F4AE42',
    竞猜: '#3697DB',
    投票: '#61BB5F',
    视频: '#c31c32',
  } as any
  const statusColor = {
    '进行中': '#0000FF',
    '未开始': '#FDC52C',
    '已结束': '#555555'
  } as any
  const icons = JSON.parse(localStorage.getItem('icons') || '{}')
  function GoDetail(item: any) {
    onChange && onChange()
    navigate(url[activity.tagType], { state: { id: activity.id, tagType: activity.tagType } })
  }
  return (
    <div className="activeBox1" key={activity.tagType + '_' + activity.id} onClick={() => GoDetail(activity)} style={{ borderTop: '0.1rem solid #efefef' }}>
      <div className="active-img-left">
        <img src={activity.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
      <div className="active-content-right">
        <div className="active-content-title">
          {activity.name}
        </div>
        {
          type === 1 &&
          <div className="active-content-state">
            <span style={{ color: statusColor[activity.statusTag] }} >{activity.statusTag}</span><span>{activity.startTime} ~ {activity.endTime}</span>
          </div>
        }
        <div className="active-content-type" style={{ color: colors[activity.tag] }}><span style={{ backgroundColor: colors[activity.tag] }} className='active-content-type-span'></span>{activity.tag}</div>
        <div className="active-content-bottom">
          <div className="active-content-bottom-browse">
            <div style={{ flex: 1 }}></div>
            <div className="browse-left">
              <img src={icons['watchwatch - grey']} alt="" style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
              <span>{activity.touchAmount}</span>
            </div>
            <div className="browse-right">
              <img src={icons['share - grey']} alt="" style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
              <span>{activity.shareAmount}</span>
            </div>
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={activity.orgInfo.orgLogo} alt="" style={{ width: '15px', height: '15px', margin: '0 5px' }} />
          <p style={{ color: '#d8d8d8', fontSize: '14px' }}>{activity.orgInfo.orgName}</p>
        </div>
      </div>
    </div>
  );
}
