import { Net } from '../../utils/net';
import { genderEnum, RealNameCardTypeEnum } from '../../enum';

export interface RealNameInfo {
  backImage: string;
  frontImage: string;
  faceVideoUrl: string;
  mobileAccountAuth: {
    address: string;
    birthday: string;
    gender: genderEnum;
    id: number;
    idCardCode: string;
    idCardType: RealNameCardTypeEnum;
    issueAuthority: string;
    issueEndDate: string;
    issueStartDate: string;
    name: string;
  };
  auditStatus: {
    name: string;
  };
  mobileContacts: any;
  mobileMemberCards: any;
}

/**
 * 会员相关接口
 */
export class ServiceRealName {
  /**
   * 是否已经实名
   */
  public static isAuth(): Promise<boolean> {
    return Net('/member/auth/isAuth').then(res => res.data.auth);
  }

  /**
   * 获取实名信息
   */
  public static getMeyAuthInfo() {
    return Net('/member/auth/myauth').then(res => res.data);
  }

  /**
   * 上传护照
   * @param base64
   * @param type
   * @param cardType
   */
  public static uploadPassport(
    base64: string,
    type: string,
    cardType: any
  ): Promise<RealNameInfo> {
    return Net(
      '/member/auth/uploadFrontImageBase64',
      { base64, type },
      { params: { cardType } }
    );
  }
  /**
   * 上传正面
   * @param base64
   * @param type
   * @param cardType
   */
  public static checkFront(
    base64: string,
    type: string,
    cardType: any
  ): Promise<RealNameInfo> {
    return Net(
      '/member/auth/checkFrontImageBase64',
      {
        base64,
        type,
      },
      { params: { cardType } }
    ).then(res => res.data.auth);
  }

  /**
   * 上传背面
   * @param base64
   * @param type
   * @param cardType
   */
  public static checkBack(
    base64: string,
    type: string,
    cardType: string
  ): Promise<RealNameInfo> {
    return Net(
      '/member/auth/checkBackImageBase64',
      {
        base64,
        type,
      },
      { params: { cardType } }
    ).then(res => res.data.auth);
  }

  /**
   * 检查人脸图片
   * @param base64
   * @param type
   * @param cardType
   */
  public static checkFace(
    base64: string,
    type: string,
    cardType: any
  ): Promise<[number, number, number, number]> {
    return Net(
      '/member/auth/checkFaceBase64',
      {
        base64,
        type,
      },
      { params: { cardType } }
    ).then(res => res.data.faceRect);
  }

  /**
   * 人脸识别 实名认证
   * @param base64
   * @param type
   * @param cardType
   */
  public static uploadFace(
    base64: string,
    type: string,
    cardType: any
  ): Promise<RealNameInfo> {
    return Net(
      '/member/auth/uploadFaceBase64',
      {
        base64,
        type,
      },
      { params: { cardType } }
    ).then(res => res.data.auth);
  }

  /**
   * 最后提交
   * @param name
   * @param code
   */
  public static submitRealName(
    name: string,
    code: string
  ) {
    return Net('/member/auth/submit', null, { params: { name, code } });
  }

  /**********************新增购票人**************************/
  /**
   * 人脸识别
   */
  public static checkPeopleFace(
    base64: string,
    type: string
  ): Promise<[number, number, number, number]> {
    return Net('/member/contacts/checkFaceBase64', {
      base64,
      type,
    }).then(res => res.data.faceRect);
  }

  /**
   * 其他证件 最后上传
   * @param params
   * @param body 里面包含三种图片, 如果库中已经存在这个人 就不用上传
   */
  public static addPeoplesByOther(
    params: any,
    body?: FormData
  ): Promise<{ id: number }> {
    return Net('/member/contacts/add', body, { params }).then(res => res.data);
  }

  /**
   * 身份证 最后上传
   * @param phone
   */
  public static addPeopleById(phone: string) {
    return Net('/member/contacts/submitContacts', null, { params: { phone } });
  }

  /**
   * 上传人脸
   */
  public static uplandPeopleFace(base64: string, type: string) {
    return Net('/member/contacts/uploadFaceBase64', { base64, type }).then(
      res => res.data
    );
  }

  /**
   * 购票人:上传证件正面
   * @param base64
   * @param type
   */
  public static checkPeopleFront(
    base64: string,
    type: string
  ): Promise<{
    contacts: any;
    check: boolean;
  }> {
    return Net('/member/contacts/checkFrontImageBase64', { base64, type }).then(
      res => res.data
    );
  }

  /**
   * 上传证件反面
   * @param base64
   * @param type
   */
  public static checkPeopleBack(
    base64: string,
    type: string
  ): Promise<{
    contacts: any;
    check: boolean;
  }> {
    return Net('/member/contacts/checkBackImageBase64', { base64, type }).then(
      res => res.data
    );
  }

  /**
   * 上传护照验证
   * @param base64
   * @param type
   */
  public static checkPassport(
    base64: string,
    type: string
  ): Promise<{ contacts: any; check: boolean }> {
    return Net('/member/contacts/checkPassportImageBase64', {
      base64,
      type,
    }).then(res => res.data);
  }

  /*******激活球票********/
  /**
   * 场次票 激活
   */
  public static activeTicket(contactsId: any) {
    return Net('/member/ticket/activeTicket', null, {
      params: { contactsId },
    }).then(res => res.data);
  }
  // 扫描提交
  public static validateQR(
    params: any
  ): Promise<{
    seatName: string;
    validate: boolean;
  }> {
    return Net('/member/ticket/validateQR', null, { params }).then(
      res => res.data
    );
  }
  /**
   * 提交 激活
   */
  public static activeTicketByOrderId(orderId: string) {
    return Net('/order/payoff', null, { params: { orderId } }).then(
      res => res.data
    );
  }
}
