import * as React from 'react';
import './peopleList.css'
import { ID_NUMBER_REGEXP, PHONE_REGEXP } from '../../../configs/RegExp';
import { ServicePeoples } from '../../../service/peoples'
import { ServiceRealName } from '../../../service/member/realName'
import { deduplication } from '../../../utils/tool';
import { ImageComponent } from '../../../component/imageComponet/ImageComponent';
import { Checkbox, Select, message, Spin, Modal } from 'antd'
import Util from '../../../utils';
import { getEnvironmentData } from 'worker_threads';
const { Option } = Select;

function PeopleList() {
  const icons = JSON.parse(localStorage.getItem('icons') || '{}')
  const [type, setType] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [data, setData] = React.useState<any>([])
  const [peoples, setPeoples] = React.useState<any>([])
  const [arry, setArry] = React.useState<any>([])
  const [name, setName] = React.useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [cardType, setCardType] = React.useState<number>()
  const [cardNum, setCardNum] = React.useState<string>('')
  const [pacePic, setPacePic] = React.useState<string>('')
  const [codePic, setCodePic] = React.useState<string>('')

  React.useEffect(() => {
    getData()
  }, [])
  function getData() {
    window.scrollTo(0, 0)
    ServicePeoples.getAllPeoples(true)
      .then(res => {
        let newData = deduplication(
          [...data, ...res.content],
          ['entity', 'id']
        );
        setPeoples(newData)
        setData(newData)
        setLoading(false)
      })
      .catch(e => {
        message.error(e.message);
      })
  }

  async function submitForm() {
    try {
      if (!phone) {
        return message.error('请输入您手机号码!');
      }
      if (!phone.match(PHONE_REGEXP)) {
        return message.error('请输入正确的手机号码!');
      }
      if (!cardType) {
        return message.error('请选择您的证件类型!');
      }
      switch (cardType) {
        case 1:
          // 判断ORC是否开启
          ServicePeoples.contactsORC().then(res => {
            if (res.ocr) {
              // utils.replaceToPage(ROUTER_NAME.NEWCONTACTSSTEP_ID.path);
            } else {
              submit()
            }
          });
          break;
        case 2:
          post()
          // utils.replaceToPage(ROUTER_NAME.NEWCONTACTSSTEP_OTHER.path);
          break;
        default:
          break;
      }

    } catch (e: any) {
      message.error(e.message);
    }
  }
  async function submit() {
    try {
      if (!name) {
        return message.error('请输入您的姓名!');
      }
      if (!cardNum || !ID_NUMBER_REGEXP.test(cardNum.toString())) {
        return message.error('请填写正确的证件号码!');
      }
      const res = await ServicePeoples.contactsCheckIdCard(cardNum, name);
      if (res?.check) {
        // 证件信息之前已经上传了 直接到最后一步提交手机号
        ServiceRealName.addPeopleById(phone).then(res => {
          message.success('新增成功')
          getData()
        }).catch(err => {
          message.error(err.message)
        })
        // utils.replaceToPage(ROUTER_NAME.ID_CARD_INFORCONFIRM.path);
        return;
      }
      if (res.success) {
        // 证件信息上传验证信息正确 去到上传证件照页面
        ServiceRealName.addPeopleById(phone).then(res => {
          getData()
        }).catch(err => {
          message.error(err.message)
        })
      } else {
        // 证件信息上传验证信息错误
        message.error('请检查证件的信息是否填写正确 确认正确的情况下可联系客服处理')
      }
    } catch (e: any) {
      message.error(e.message)
    }
  }
  async function post() {
    try {
      if (!name) {
        return message.error('请输入您的姓名!');
      }
      if (!cardNum || !ID_NUMBER_REGEXP.test(cardNum.toString())) {
        return message.error('请填写正确的证件号码!');
      }
      const res = await ServicePeoples.queryPeopleByIdCard({
        idCardType: cardType,
        idCardCode: cardNum,
        name: name,
      });
      const params = {
        name: name,
        phone: phone,
        idCardType: cardType,
        idCardCode: cardNum,
      };
      if (res.check) {
        await ServiceRealName.addPeoplesByOther(params);
        message.success('新增成功')
        getData()
      } else {
        let body = new FormData();
        body.append('face', pacePic as any);
        body.append('front', codePic as any);
        await ServiceRealName.addPeoplesByOther(params, body);
        message.success('提交成功,请耐心等待审核结果')
        getData()
        // utils.replaceToPage(ROUTER_NAME.NEWCONTACTSSTEP_OTHER_NEXT.path);
      }
    } catch (e) {
      // utils.replaceToPage(ROUTER_NAME.NEWCONTACTSSTEP_OTHER_NEXT.path);
    }
  }


  function getImage(data) {
    setLoading(true)
    const imageFIle = Util.dataURLtoFile(data, '图片名称');
    ServiceRealName.checkPeopleFace(
      Util.getBase64Body(data),
      Util.getFileType(imageFIle)
    )
      .then(faceRect => {
        setPacePic(data)
      })
      .catch(err => {
        message.error(err.message)
      }).finally(() => {
        setLoading(false)
      })

  }
  async function checkPassport(base64) {
    ServiceRealName.checkPassport(
      Util.getBase64Body(base64),
      'png')
      .then(res => {
        setCodePic(base64)
      })
      .catch(err => {
        message.error(err.message);
      });
  }
  return (
    <Spin spinning={loading}>
      <div className='people-box'>
        <div className="people-title">我的购票人</div>
        {
          peoples?.map((el, index) =>
            <div className="people-list">
              <div className="people-log">
                <img src={el.faceVideoUrl} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
              </div>
              <div className="people-info">
                <p>{el.entity.name}</p>
                <p>{Util.formatIdNumber(el.entity.idCardCode)}</p>
              </div>
            </div>
          )
        }
      </div>
      <div className="add-people">
        <p>新增购票人</p>
        <div className="add-people-box">
          <div className="label">姓名</div>
          <div className="input-value">
            <input value={name} onChange={(e) => setName(e.target.value)} type="text" />
          </div>
        </div>
        <div className="add-people-box">
          <div className="label">手机号码</div>
          <div className="input-value">
            <input value={phone} type="text" onChange={(e) => setPhone(e.target.value)} />
          </div>
        </div>
        <div className="add-people-box">
          <div className="label">证件类型</div>
          <div className="input-value">
            <Select value={cardType} style={{ width: 120 }} onChange={(e) => setCardType(e)} >
              <Option value={1}>身份证</Option>
              <Option value={2}>护照</Option>
            </Select>
          </div>
        </div>
        <div className="add-people-box">
          <div className="label">证件号码</div>
          <div className="input-value">
            <input value={cardNum} onChange={(e) => setCardNum(e.target.value)} type="text" />
          </div>
        </div>
        {
          cardType === 2 &&
          <div className="add-people-box">
            <div className="label">证件照片</div>
            <div className="input-value">
              <ImageComponent value={codePic} onChange={(data) => checkPassport(data)} />
            </div>
          </div>
        }
        <div className="add-people-box">
          <div className="label">人脸照片</div>
          <div className="input-value">
            <ImageComponent value={pacePic} onChange={(data) => getImage(data)} />
          </div>
        </div>
        <div className="people-button" onClick={() => submitForm()}>提交</div>
      </div>
    </Spin>
  );
}
export default PeopleList