import { Net } from '../../utils/net';
import { IdAndName, _pageData } from '../common_i';

export interface OrderContent {
  actualCost: number;
  invoiceStatus: number;
  invoiceStatusName: string;
  itemCount: number;
  itemDescription: string;
  itemId: number;
  itemLogo: string;
  itemName: string;
  itemType: number;
  orderId: string;
  rawCost: number;
  status: number;
  statusName: string;
  userMemo: string;
  amount: number;
  orgName: string;
  orgLogo: string;
  orgDomain: string;
  orgId: number;
  items: [
    {
      specification1: string;
      specification2: string;

      item: {
        itemLogo: string;
        itemName: string;
        itemAbbrName: string;
        amount: number;
        actualCost: number;
        // map:()=>void;
      };
    }
  ];
}
export interface express {
  expressName: string;
  expressCode: string;
  itemType: string;
  items: [];
  routes: [
    {
      createTime: string;
      location: string;
      info: string;
    }
  ];
  status: number;
  title: string;
}
export interface AfterContent {
  item: any;
  id: any;
  org: {
    clientTitle: string;
    orgDomain: string;
    orgId: number
    orgLogo: string;
    orgName: string;
  }
  entity: {
    statusName: string;
    orderId: string;
    id: any;
  };
  orderId: string;
  specification1: string;
  specification2: string;
}


interface OrderResponse {
  express: express;
  detail: QueryOrderInfo;
  discount: any;
  nfr?: NFRContent;
}

export interface NFRContent {
  accountName: string;
  contractAddress: string;
  createTime: string;
  creatorLogo: string;
  creatorName: '深圳足球俱乐部';
  fileUrl: string;
  hash: string;
  itemId: number;
  name: string;
  nfrIssuer: IdAndName;
  nfrProtocol: IdAndName;
  publisherLogo: string;
  publisherName: string;
  releaseCount: number;
  releaseTime: string;
  serialId: string;
  transactionHash: string;
}

export interface QueryOrderInfo {
  actualCost: number;
  createTime: string;
  deliveryPrice: number;
  deliveryType: number;
  expireTime: string;
  gifts: {
    count: number;
    goodsId: number;
    id: number;
    logo?: string;
    name: string;
    orderId: string;
    price: number;
    specification1: string;
    specification2: string;
    specificationId: number;
    specificationTitle1: string;
    specificationTitle2: string;
  }[];
  itemCount: number;
  itemDescription: string;
  itemId: number;
  itemLogo: string;
  itemName: string;
  itemType: number;
  mobileRecipientAddress: {
    address: string;
    cityId: number;
    first: boolean;
    fullAddress: string;
    id: number;
    name: string;
    phone: string;
    provinceId: number;
    regionId: number;
  };
  orderId: string;
  payAccount: string;
  payoffTime: string;
  payoffType: number;
  rawCost: number;
  seats: OrderSeatInfo[];
  selfPickAddressId: number;
  selfPickAddressName: string;
  status: number;
  ttpOrderId: number;
  ttpOrderType: string;
  userMemo: string;
  items: [
    {
      specification1: string;
      specification2: string;
      item: {
        itemLogo: string;
        itemName: string;
        itemAbbrName: string;
        actualCost: number;
        amount: number;
        pss: {
          id: number;
          itemId: number;
          status: number;
          statusName: string;
        };
      };
    }
  ];
}

export interface OrderSeatInfo {
  price: number;
  seatName: string;
  rawPrice: number; // 原价
}

export enum OrderStatusEnum {
  // 售后申请 = 4,
  全部 = 0,
  待付款 = 1,
  待发货 = 2,
  待收货 = 3,
  已完成 = 4,
}

export class ServiceOrder {
  /**
   * 获取所有订单
   * @param pageNum
   * @param pageSize
   * @param type
   */
  public static getAllOrder(
    pageNum: number,
    pageSize: number,
    type?: OrderStatusEnum
  ): Promise<_pageData<OrderContent>> {
    return Net('/order/list', null, {
      //@ts-ignore
      params: { pageNum, pageSize, type },
    }).then(res => res.data.list);
  }

  /**
   * 获取我所有的售后订单
   */
  public static getAllAfterSale(
    pageNum: number,
    pageSize: number
  ): Promise<_pageData<AfterContent>> {
    return Net('/order/pss/list', null, {
      //@ts-ignore
      params: { pageNum, pageSize },
    }).then(res => res.data.list);
  }

  /**
   * 是否可以购买球票
   * @param contactsId
   * @param ticketId
   */
  public static isCanBuyIt(
    contactsId: number,
    ticketId: number
  ): Promise<boolean> {
    return Net('/order/chooseContacts', null, {
      //@ts-ignore
      params: { contactsId, ticketId },
    }).then(res => res.data.possessed);
  }

  /**
   * 删除订单
   * @param orderId
   */
  public static deleteOrder(orderId: string): Promise<any> {
    return Net('/order/delete', null, { params: { orderId } }).then(
      res => res.data
    );
  }

  /**
   * 取消所有的订单
   * @param orderId
   */
  public static cancelOrder(orderId: number | string): Promise<any> {
    //@ts-ignore
    return Net('/order/cancel', null, { params: { orderId } }).then(
      res => res.data
    );
  }

  /**
   * 查询订单
   * @param orderId
   */
  public static orderQuery(orderId): Promise<any> {
    let params = { orderId }
    return Net('/order/query', null, { params }).then(
      res => res.data
    );
  }

  public static queryOrder(orderId: string): Promise<QueryOrderInfo> {
    return Net('/order/query', null, { params: { orderId } }).then(
      res => res.data.detail
    );
  }
  /**
   * 查询所有的订单详情
   */
  public static queryGoods(orderId: string): Promise<QueryOrderInfo> {
    return Net('/order/query', null, { params: { orderId } }).then(
      res => res.data.detail
    );
  }
  public static queryGoods1(orderId: string): Promise<any> {
    return Net('/order/query', null, { params: { orderId } }).then(
      res => res.data.detail.mobileRecipientAddress
    );
  }
  public static queryGoods2(orderId: string): Promise<any> {
    return Net('/order/query', null, { params: { orderId } }).then(
      res => res.data.discount
    );
  }
  public static queryGoodss(orderId: string): Promise<express> {
    return Net('/order/query', null, { params: { orderId } }).then(
      res => res.data.express
    );
  }
  public static queryGoodsData(orderId: string) {
    return Net('/order/query', null, { params: { orderId } }).then(
      res => res.data.detail
    );
  }

  /**
   * 提交售后
   */
  public static putCalc(body: any): Promise<any> {
    return Net('/order/pss/create', body).then(res => res.data.pssId);
  }

  /**
   * 再次修改售后申请，提交售后
   */
  public static alignPutCalc(body: any): Promise<any> {
    return Net('/order/pss/append', body).then(res => res.data.pssId);
  }

  /**
   * 售后详情
   */
  public static afterCalcDateil(id: string): Promise<any> {
    return Net('/order/pss/query', null, { params: { id } }).then(
      res => res.data.query
    );
  }

  /**
   * 提交物流公司售后
   */
  public static afterSaleLogistics(body: any): Promise<any> {
    return Net('/order/pss/delivery', body).then(res => res.data);
  }

  /**
   * 提交物流公司售后
   */
  public static afterSaleCancel(pssId: string): Promise<any> {
    return Net('/order/pss/cancel', null, { params: { pssId } }).then(
      res => res.data
    );
  }

  /**
   * 售后的服务评价
   */
  public static serviceRating(body: any): Promise<any> {
    return Net('/order/pss/finish', body).then(res => res.data);
  }
  /**
   * 询价
   * @param body
   */
  public static preReserve(body: any) {
    return Net('/order/preReserve', body).then(res => res.data);
  }

  /**
   * 周边商品询价
   * @param body
   */
  public static goodsPreReserve(body: any) {
    return Net('/member/goods/order/preReserve', body).then(res => res.data);
  }

  /**
   * 周边商品下单
   */
  public static submitGoodsOrder(body: any): Promise<any> {
    return Net('/member/goods/order/reserve', body).then(res => res.data.order);
  }

  /**
   * 提交订单
   */
  public static submitOrder(body: any): Promise<any> {
    return Net('/order/reserve', body).then(res => res.data.order);
  }

  /**
   * 确认收货
   */
  public static pinckUp(id) {
    return Net(`/order/confirm?orderId=${id}`);
  }

  /**
   * 催单
   * /order/tap?orderId=d202010200000000002
   */
  public static orderTap(id) {
    return Net(`/order/tap?orderId=${id}`);
  }

  /**
   * 查询自定义分区
   * @param ticketId
   */
  public static getCustomSections(ticketId) {
    return Net(`/ticket/listCustomSections?ticketId=${ticketId}`).then(
      res => res.data
    );
  }
}
