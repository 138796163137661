/**
 * @author: hukun
 * @Date: 2019-05-20
 * @Time: 10:38
 * @function
 */
import { Net } from '../../utils/net';
import { _pageData, IdAndName } from '../common_i';
import { GoodsItemType, goodsTktClazzEnum } from '../../enum';

/**
 * 票品list里面的详情
 */
export interface Ticket {
  then: () => void;
  id: number;
  itemType: GoodsItemType;
  abbrName: string;
  goodsId: any;
  afterSaleType: number;
  backImage: string;
  childAllowed: false;
  childHeight: number;
  contentImages: string[];
  delivery: boolean;
  description: string;
  detail: string;
  forms: formsProps[];
  selectable: boolean;
  express: boolean; //快递
  expressMoney: number; //快递的钱
  frontImage: string;
  invoiceSupply: boolean;
  invoiceType: number;
  leagues: {
    description: string;
    endTime: string;
    hostNumber: number;
    leagueId: number;
    leagueType: number;
    name: string;
    startTime: string;
    status: number;
    teamId: number;
  }[];
  logo: string;
  maximumPrice: number;
  minimumAmount: number;
  minimumPrice: number;
  maximumAmount: number;
  maxPromPrice: number;
  mimPromPrice: number;
  name: string;
  notes: string;
  reserveNotes: string;
  restrictMount: number;
  restricted: boolean;
  saleType: number;
  seatchoosable: boolean;
  sections: {
    sectionId: number;
    name: string;
    price: number;
    stuPrice: number;
  }[];
  selfPick: boolean;
  selfPickAddresses: any[];
  stadiumId: number;
  stadiumSvgUrl: any;
  status: number;
  statusStr: string;
  ticketId: number;
  tktClazz: number;
  topImages: string[];
  accountMaxAmount: number | null;
  totalAmount: number | null;
  leftAmount: number | null;
  useSvg: boolean;
  restrictAccount: boolean; //是否限制本人购买
}

/**
 * 票品详情类型
 */
interface TicketDetail {
  section: {
    count: string;
    name: string;
    svgId: string;
  }[];
  name: string;
  logo: string;
  ticket: Ticket;

  goods: {
    express: boolean; //快递
    expressMoney: number; //快递的钱
    selfPick: boolean;
    selfPickAddresses: any[];
    delivery: boolean;
  };
  express: boolean; //快递
  expressMoney: number; //快递的钱
  selfPick: boolean;
  selfPickAddresses: any[];
  delivery: boolean;
}

export interface formsProps {
  delivery: boolean;
  express: boolean;
  expressMoney: number;
  selfPick: boolean;
  selfPickAddresses: any[];
  ticketForm: number;
}

interface goodsDetail {
  section: {
    count: string;
    name: string;
    svgId: string;
  }[];
  goods: Ticket;
  express: boolean;
  expressMoney: number;
}

/**
 * 我的球票
 */
export interface MyTicket {
  description: string;
  faceUrl: string;
  frontImage: string;
  gameInfo: string;
  gameTime: string;
  itemLogo: string;
  mobileOrderSeat: {
    accountId: number;
    channel: number;
    checkStatus: number;
    code: string;
    colId: number;
    contactsId: number;
    contactsIdCardCode: string;
    contactsIdCardType: number;
    contactsName: string;
    contactsPhone: string;
    id: number;
    invoiceStatus: number;
    orderId: string;
    price: number;
    printStatus: number;
    rowId: number;
    seatName: string;
    tccSectionId: number;
    tcuSectionId: number;
    ticketId: number;
    voucherId: number;
  };
  name: string;
  qrCode: string;
  stadium: IdAndName;
  ticketId: number;
  tktClazz: number;
  // 自己添加的数据
  qr?: string;
}
interface CheckAccount {
  data: any;
  message: string;
  code: string;
}
const domain = localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]

export class ServiceTicket {
  /**
   * 票品列表,免登陆接口统一使用domain
   */
  public static getTicketList(): Promise<Ticket[]> {
    return Net('/ticket/list', null, { params: { domain } }).then(
      res => res.data.list
    );
  }

  /**
   * 票品详情
   * @param ticketId
   */
  public static getTicketInfo(ticketId: string): Promise<TicketDetail> {
    return Net('/ticket/query', null, { params: { ticketId } }).then(
      res => res.data
    );
  }

  /**
   * 周边商品query
   * @param  goodsId
   */
  public static queryShopInfo(goodsId: string): Promise<TicketDetail> {
    return Net('/member/goods/query', null, {
      params: { goodsId, domain },
    }).then(res => res.data.query);
  }

  /**
   * 首页轮播图
   * @param itemType 1:票品轮播图, 2:商品轮播图
   */
  public static getHomeSlicer(itemType: any): Promise<string[]> {
    return Net('/ticket/listSlider', null, {
      params: { domain, itemType },
    }).then(res => res.data.slider);
  }

  /**
   * 获取我的礼品球票列表
   */
  public static getMyGiftCardList() {
    return Net('/member/ticket/listCard').then(res => res.data);
  }

  /**
   * 电子票v2
   */
  public static getTicketListLastV2(
    tktType: any // TicketTypeEnum
    // mine: string = 'true'
  ): Promise<string[]> {
    return Net('/member/ticket/listV2', null, {
      params: { tktType },
    }).then(res => res.data.list);
  }

  /**
   * 电子票最新
   */
  public static getTicketListLast(
    mine: string = 'true',
    pageNum: any = 1,
    pageSize: any = 5
  ) {
    // return Net('/member/ticket/listV3', null, {
    return Net('/member/ticket/listV4', null, {
      params: { mine, pageNum, pageSize },
    }).then(res => res.data.list);
  }

  /**
   * 赠品信息
   * @param ticketId
   * @param svgId
   */
  public static queryGiftBySection(
    ticketId: number,
    svgId: string | number
  ): Promise<any[]> {
    return Net('/ticket/queryGiftBySection', {}).then(res => res.data.goods);
  }

  /**
   * 会员是否有赠品
   * @param goodsId
   *
   */
  public static queryGiftByGoods(goodsId: string | number) {
    return Net(`/member/goods/queryGiftByGoods?goodsId=${goodsId}`).then(
      res => res.data.goods
    );
  }

  /**
   * 通过场次信息查 赠品信息
   * @param tcuSectionId
   */
  public static queryGiftBySectionId(
    tcuSectionId: any
  ): Promise<any> {
    return Net('/ticket/queryGiftBySectionId', null, {
      params: { tcuSectionId },
    }).then(res => res.data.goods);
  }

  /**
   * 获取场馆svg
   * @param ticketId
   * @param svgId
   */
  public static querySectionSvg(ticketId: string, svgId: string) {
    return Net('/ticket/getSectionSvg', null, {
      params: { ticketId, svgId },
    }).then(res => res.data);
  }

  public static getDomStr(url: any): Promise<string> {
    return Net('/common/svg', url, { headers: { 'Content-Type': 'application/text' } }).then(res => res.data.svg)
    // return fetch(decodeURIComponent(url), {
    //   method: 'GET',
    //   mode: 'cors',
    // }).then(
    //   (response: Response): Promise<string> => {
    //     try {
    //       return response.text();
    //     } catch (e) {
    //       return Promise.reject(
    //         new Error(JSON.stringify({ message: '获取数据错误!' }))
    //       );
    //     }
    //   }
    // );
  }
}
export enum TicketTypeEnum {
  package = 1,
  gift = 2,
  session = 3,
}
