import { Net } from '../../utils/net';

/**
 * @author: hukun
 * @Date: 2019-05-24
 * @Time: 15:14
 * @function
 */

/**
 * 国家
 */
interface countryContent {
  id: number;
  name: string;
  code: number;
  continent: string;
  icon: string;
}

/**
 * 省份
 */
interface provinceContent {
  id: number;
  name: string;
  type: number;
}

/**
 * 城市
 */
interface cityContent {
  id: number;
  name: string;
}

/**
 * 区
 */
interface regionContent {
  id: number;
  name: string;
}

export class Service_Geo {
  /**
   * 获取所有国家
   */
  // public static getAllGeo(): Promise<countryContent[]> {
  //   return Net('/common/geo/listAllCountry').then(res => res.data.country);
  // }
  /**
   * 获取所有国家   listAllProvince
   */
  public static getAllGeo(): Promise<countryContent[]> {
    return Net('/common/geo/listAllProvince').then(res => res.data.province);
  }
  // 所有数据
  public static getGeoAll(): Promise<countryContent[]> {
    return Net('/common/geo/all').then(res => res.data.all);
  }

  /**
   * 省份
   * @param countryId
   */
  public static getAllProvinceByCountryId(
    countryId: any = 40
  ): Promise<provinceContent[]> {
    return Net('/common/geo/listAllProvince', null, {
      params: { countryId },
    }).then(res => res.data.province);
  }

  /**
   * 城市
   * @param provinceId
   */
  public static getAllCityBYProvinceId(
    provinceId: any
  ): Promise<cityContent[]> {
    return Net('/common/geo/listAllCity', null, {
      params: { provinceId },
    }).then(res => res.data.city);
  }

  /**
   * 区县
   * @param cityId
   */
  public static getAllRegionByCityId(cityId: any): Promise<regionContent[]> {
    return Net('/common/geo/listAllRegion', null, { params: { cityId } }).then(
      res => res.data.region
    );
  }
}
