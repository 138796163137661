import { Net } from '../../utils/net';
import { _pageData } from '../common_i';

interface Voucher {
  accountId: number;
  entity: {
    id: number;
    voucherId: number;
    phone: string;
    receiveTime: string;
    payoffTime: string | null;
    expireTime: string;
    orderId: number | null;
    accountId: number;
    entityStatus: number;
    recovery: any;
    orgId: number;
  };
  accountName: string;
  portraitUrl: string;
  items: any[];
  voucher: {
    image: string;
    itemType: string;
    balance: number;
    voucherType: string;
    name: string;
    description: string;
    discount: number;
    items: any[];
  };
  selectable: boolean;
}

export interface VoucherBody {
  valid: Voucher[];
  invalid: Voucher[];
}

export class ServiceVoucher {
  // 查询优惠券
  public static findAllByTicket(ticketId: number) {
    //@ts-ignore
    return Net('/voucher/findAllByTicket', null, { params: { ticketId } }).then(
      res => res.data.vouchers.content
    );
  }

  //查询优惠劵(是否可用)
  public static findVoucherByOrder(body): Promise<VoucherBody> {
    return Net('/voucher/findAllByGoods', body).then(res => res.data.vouchers);
  }
  // 查询积分
  public static findAllByPoints() {
    return Net('/member/points/list', null, {}).then(res => res.data.list);
  }

  // 查询票是否有赠品
  public static queryGiftBySection(ticketId: number, svgId: string) {
    return Net('/ticket/queryGiftBySection', null, {
      //@ts-ignore
      params: { ticketId, svgId },
    }).then(res => res.data.goods);
  }

  // 验证激活扫描的密码
  public static activeQR(params: any) {
    return Net('/member/ticket/activeQR', null, { params }).then(
      res => res.data
    );
  }

  public static getMyVouchers(params): Promise<_pageData<any>> {
    return Net('/voucher/listV3', null, { params }).then(
      res => res.data.vouchers
    );
  }
}
