import * as React from 'react';
import './fantasy.css'
import Login from '../../loginHome/index'
import Util from '../../../utils';
import { NoData } from '../../../component/noData';
import { TitleList } from '../../../component/TitleList/index'
import { ServiceAccount } from '../../../service/account';
import { ServiceFantasy } from '../../../service/fantasy'
import { Popconfirm, message, Spin, Modal, Input, InputNumber } from 'antd';
import { deduplication, subStr, subStrEllipsis } from '../../../utils/tool';
import { toJS } from 'mobx';
/**
 * 数字藏品
 * @returns 
 */
function Fantasy() {
  const [active, setActive] = React.useState<number>(0)
  const [integral, setIntegral] = React.useState<any>([]);
  const [propsList, setPropsList] = React.useState<any>([]);
  const [starCardList, setStarCardList] = React.useState<any>([]);
  const [isLogin, setIsLogin] = React.useState<boolean>(false)

  const [show, setShow] = React.useState<boolean>(false)
  const [type, setType] = React.useState<any>(undefined)

  const [visible, setVisible] = React.useState<boolean>(false)
  const [propInfo, setPropInfo] = React.useState<any>()
  const [propNum, setPropNum] = React.useState<number>(0)

  const [loadding, setLoadding] = React.useState<boolean>(true)
  const allIcon = JSON.parse(localStorage.getItem('icons') || '{}')

  const [chooseImg, setChooseImg] = React.useState<any>()
  const [starCard, setStarCard] = React.useState<boolean>(false)
  const [visible1, setVisible1] = React.useState<boolean>(false)
  const [ftd, setFtd] = React.useState<boolean>(false)
  const [fantedou, setFanteDou] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [RechargeList, setRechargeList] = React.useState([
    {
      fantedou: 100,
      rmb: 1,
      isClick: false,
    },
    {
      fantedou: 2000,
      rmb: 20,
      isClick: false,
    },
    {
      fantedou: 5000,
      rmb: 50,
      isClick: false,
    },
    {
      fantedou: 10000,
      rmb: 100,
      isClick: false,
    },
    {
      fantedou: 20000,
      rmb: 200,
      isClick: false,
    },
    {
      fantedou: 50000,
      rmb: 500,
      isClick: false,
    },
  ]);
  const titleList = [
    { id: 0, name: '范特豆' },
    { id: 1, name: '道具卡' },
    { id: 2, name: '球星卡' }
  ]
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  async function getData() {
    try {
      const theyData = await ServiceAccount.getAccountDetail();
      const fen = toJS(theyData).wallet;
      setIntegral(fen);
      setLoadding(false)
    } catch (e: any) {
      message.error(e.message)
      setLoadding(false)
    }
  }

  function getPropList() {
    ServiceFantasy.propList()
      .then(res => {
        setPropsList(res);
      })
  }

  function getStarCardList() {
    ServiceFantasy.cardListMine()
      .then(res => {
        setStarCardList(res);
      })
  }

  function propBuy() {
    if (integral.ftsMoney < propInfo.price * propNum) {
      message.warn('范特豆剩余必须大于消耗数量');
      return;
    }
    ServiceFantasy.propBuy(propInfo.propId, propNum)
      .then(res => {
        message.success('兑换成功');
        getPropList();
      })
      .catch(e => {
        message.error(e.message);
      })
      .finally(() => {
        setPropInfo({});
        setPropNum(0);
        setVisible(false);
      });
  }
  function MoneyExchange() {
    if (integral.points < fantedou) {
      message.error('范特豆剩余必须大于消耗数量');
      return;
    }
    ServiceFantasy.moneyExchange(fantedou)
      .then(res => {
        message.success('兑换成功');
        getData();

      })
      .catch(e => {
        message.error(e.message);
      })
      .finally(() => {
        setFanteDou(0);
        setVisible1(false);
      });
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setLoadding(false)
      return
    }
    getData()
    if (active == 1) {
      getPropList();
      return;
    }
    if (active == 2) {
      getStarCardList();
      return
    }

  }, [active]);

  function setClick(index) {
    RechargeList.map(el => {
      el.isClick = false;
    });
    RechargeList[index].isClick = true;
    setAmount(RechargeList[index].fantedou);
    setRechargeList([...RechargeList]);
  }
  function reserve() {
    ServiceFantasy.moneyReserve(amount)
      .then(res => {
        message.success('下单成功')
        // Util.jumpPage(ROUTER_NAME.GO_PAY.path, {
        //   orderId: res.order.orderId,
        // });
        getData()
      })
      .catch(e => {
        message.error(e.message);
      }).finally(() => {
        setFtd(false)
      })

  }
  function onChange(key: number) {
    setActive(key)
  }

  function cancel() {
    if (type == 0 || type == 1) {
      setShow(false)
      setType(undefined)
    } else {
      setType(0)
    }
  }

  return (
    <Spin spinning={loadding}>
      <div className="container" style={{ width: '100%', minHeight: '90vh', backgroundColor: '#fff' }}>
        <div className="container1"></div>
        <TitleList active={active} titleList={titleList} onChange={(e) => onChange(e)} />
        {active == 0 && (
          <div style={{ width: '100%', backgroundColor: 'white' }}>
            <div style={{ width: '100%', height: '20px' }}></div>
            <div
              style={{
                padding: '20px',
                backgroundColor: '#f2f2f2',
                width: '30%',
                marginLeft: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{ flex: 1, display: 'flex', alignItems: 'center' }}
              >
                <img
                  src={allIcon['ftd']}
                  alt=""
                  style={{
                    width: '50px',
                    height: '50px',
                    margin: '0 10px',
                  }}
                />
                <p>范特豆</p>
              </div>
              <div style={{ flex: 1 }}>
                剩余：{integral.ftsMoney || 0}
              </div>
            </div>
            <div style={{ padding: '20px', width: '30%' }}>
              &emsp;&emsp;
              范特豆可用于购买球星卡和道具卡。1元人民币可充值100范特豆。参加梦幻阵容游戏也可以获得大量范特豆哦，快来参加吧。
            </div>
            <div className='goods-footer' style={{ width: '30%', margin: '20px' }}>
              <div
                role="button"
                className='goods-footer-cart'
                onClick={() => setVisible1(true)}
              >
                兑换
              </div>
              <div
                role="button"
                className='goods-footer-buy'
                onClick={() => setFtd(true)}
              >
                充值
              </div>
            </div>
          </div>
        )}
        {active == 1 && (
          propsList?.length > 0 ?
            <div >
              <div
                style={{
                  width: '100%',
                  height: '3px',
                  backgroundColor: 'white',
                }}
              ></div>
              <div
                style={{
                  width: '30%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  paddingBottom: '10px',
                  background: '#f5f5f5'
                }}
              >
                {propsList?.map(el => (
                  <div
                    onClick={() => {
                      setPropInfo(el); setVisible(true);
                    }}
                    style={{
                      width: '40%',
                      backgroundColor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                      margin: '10px 2% 0 2%',
                      cursor: 'pointer'
                    }}
                  >
                    <img
                      src={el?.image}
                      alt=""
                      style={{
                        width: '40px',
                        height: '40px',
                        marginRight: '5px',
                      }}
                    />
                    <div
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <p style={{ color: '' }}>{el?.propName}</p>
                      <p>数量：{el?.amount}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  width: '100%',
                  height: '3px',
                  backgroundColor: 'white',
                }}
              ></div>
            </div>
            :
            <NoData show={true} />
        )}
        {active == 2 && (
          starCardList?.length > 0 ?
            <div
              style={{
                width: '99%',
                margin: '15px auto',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {starCardList?.map(el => (
                <div
                  style={{
                    width: '8%',
                    backgroundColor: 'white',
                    marginRight: '1%',
                    marginBottom: '5px',
                  }}
                  onClick={() => {
                    setChooseImg(el); setStarCard(true);
                  }}
                >
                  <div
                    style={{
                      width: '85%',
                      margin: '15px auto',
                    }}
                  >
                    <img
                      src={el.image}
                      alt=""
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            :
            <NoData show={true} />
        )}
        <Modal
          visible={starCard}
          footer={null}
          style={{ padding: 'none', overflow: 'unset' }}
          onCancel={() => { setChooseImg({}); setStarCard(false) }}
        >
          <div style={{ width: '100%', backgroundColor: '#fff' }}>

            <img src={chooseImg?.image} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
        </Modal>
      </div>
      <Modal
        title="范特豆充值"
        visible={ftd}
        footer={null}
        onCancel={() => { setFtd(false) }}
      >
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
          <div
            className='shopping-details'
            style={{ background: 'none' }}
          >
            <div
              style={{
                width: '95%',
                display: 'flex',
                alignItems: 'center',
                margin: '0 auto 10px',
              }}
            >
              <img
                src={allIcon['ftd']}
                alt=""
                style={{
                  width: '79px',
                  height: '83px',
                  margin: '0 10px',
                }}
              />
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                }}
              >
                {/* <p>我的范特豆：{integral.ftsMoney || 0}</p> */}
                <p>范特豆充值</p>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                padding: '10px 0',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {RechargeList.map((el, index) => (
                <div
                  onClick={() => setClick(index)}
                  key={index}
                  style={{
                    textAlign: 'center',
                    width: '25%',
                    margin: '0 3.3% 10px 0',
                    padding: '5px 0',
                    background: '#f9f9f9',
                    borderRadius: '5px',
                    border: el.isClick
                      ? '1px solid #c31c32'
                      : '1px solid #f9f9f9',
                    cursor: 'pointer'
                  }}
                >
                  <p style={{ color: el.isClick ? '#c31c32' : '#101010' }}>
                    <span style={{ fontSize: '20px' }}>{el.fantedou}</span>豆
                  </p>
                  <p style={{ color: el.isClick ? '#c31c32' : '#919191' }}>
                    {el.rmb}元
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div
            onClick={() => reserve()}
            role="button"
            className='shop-ok'
          >
            确认
          </div>
        </div>
      </Modal>
      <Modal
        title="积分兑换"
        visible={visible1}
        onOk={() => MoneyExchange()}
        onCancel={() => { setVisible1(false); setPropNum(0); setPropInfo({}) }}
        cancelText='取消'
        okText="确认"
      >
        <div style={{ width: '95%', backgroundColor: '#fff', paddingLeft: '5%' }}>

          <p style={{ marginBottom: '10px' }}>范特豆兑换<span style={{ color: '#c31c32' }}>*</span>:</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputNumber value={fantedou} min={0} max={integral?.points || 0} onChange={(e) => setFanteDou(e)} style={{ width: '100px', marginRight: '10px' }} />
            范特豆
          </div>
          <p style={{ margin: '10px 0' }} >可用积分：{integral?.points || 0}</p>
          <p style={{ margin: '10px 0' }} >消耗积分：{fantedou || 0}</p>
          <p style={{ margin: '10px 0' }} >1积分可兑换1个范特豆</p>
        </div>
      </Modal>
      <Modal
        title="兑换道具卡"
        visible={visible}
        onOk={() => propBuy()}
        onCancel={() => { setVisible(false); setPropNum(0); setPropInfo({}) }}
        cancelText='取消'
        okText="确认"
      >
        <div style={{ width: '95%', backgroundColor: '#fff', paddingLeft: '5%' }}>
          <p style={{ marginBottom: '10px' }}>兑换数量<span style={{ color: '#c31c32' }}>*</span>:</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputNumber value={propNum} min={0} max={(integral?.ftsMoney || 0) / (propInfo?.price)} onChange={(e) => setPropNum(e)} style={{ width: '100px', marginRight: '10px' }} />
            {propInfo?.propName}
          </div>
          <p style={{ margin: '10px 0' }} >消耗范特豆：{propInfo?.price * propNum}</p>
          <p style={{ margin: '10px 0' }} >范特豆剩余：{integral?.ftsMoney || 0}</p>
        </div>
      </Modal>
      {
        isLogin &&
        <Login onLogin={() => { getData(); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </Spin>
  );
}
export default Fantasy