/**
 * @author: hukun
 * @Date: 2019-05-16
 * @Time: 14:21
 * @function
 */
import { Net } from '../../utils/net';
const domain = localStorage.getItem('DOMAIN') || ''
export class ServiceMutower {

  public static mutowerGames(filter: any): Promise<any> {
    return Net('/ttp/mutower/games', null, { params: { ...filter } }).then(res => res.data?.games);
  }
  public static mutowerMyTeams(filter: any): Promise<any> {
    return Net('/ttp/mutower/myTeams', null, { params: { ...filter } }).then(res => res.data.list);
  }

  public static mutowerDictQuery(filter: any): Promise<any> {
    return Net('/ttp/mutower/dict/query', null, { params: { ...filter } }).then(res => res.data.list);
  }

  public static mutowerGamesSummary(filter: any): Promise<any> {
    return Net('/ttp/mutower/games/summary', null, { params: { ...filter } }).then(res => res.data.summary);
  }

  // 俱乐部下的赛程与积分接口
  public static mutowerSeason(): Promise<any> {
    return Net('/ttp/mutower/seasons?domain=' + domain).then(res => res.data.list.content);
  }

  // 获取赛事最近的阶段和轮次
  public static getCurrentTurn(seasonId): Promise<any> {
    return Net('/ttp/mutower/getCurrentTurn?seasonId=' + seasonId).then(res => res.data);
  }

}
