import * as React from 'react';
import './afterSaleDetails.css';
import { ServiceOrder } from '../../../../../service/order';
import utils from '../../../../../utils';
import { MemberCardsContent } from '../../../../../service/member/member';
import { List, message, Modal, Steps } from 'antd';
const { Step } = Steps
interface AfterSaleDetailsState {
  orderId: string
  consult: () => void
}
export function AfterSaleDetails(prop: AfterSaleDetailsState) {
  let { orderId, consult } = prop
  const query = utils.getUrlParams();
  const [detail, setDetail] = React.useState<any>();
  const [goods, setGoods] = React.useState<MemberCardsContent>();

  async function init() {
    try {
      const datas = await ServiceOrder.afterCalcDateil(
        orderId
      );
      setDetail(datas);
    } catch (e: any) {
      message.error(e.message);
    }
  }

  React.useEffect(() => {
    init();
  }, []);

  //到协商历史去吧
  // function consult() {
  // utils.jumpPage(ROUTER_NAME.DISS_HISTORY.path, {
  //   pssId: query.get('pssId') as string,
  // });
  // }
  function orderNo() {
    // utils.jumpPage(ROUTER_NAME.PUST_ORDERNO.path, {
    //   pssId: query.get('pssId') as string,
    //   time: detail.updateTime,
    // });
  }
  function editApply() {
    // utils.jumpPage(ROUTER_NAME.APPLY_AFTER.path, {
    //   orderId: detail.orderId,
    //   type: 2,
    // });
  }

  // 取消申请
  function afterCancel() {
    Modal.confirm({
      title: '取消申请售后',
      content: '取消之后，可以在订单详情再次申请',
      okText: '确认',
      cancelText: '取消',
      bodyStyle: { margin: '10px' },
      onOk: () => {
        ServiceOrder.afterSaleCancel(orderId).then(res => {
          message.success('撤销成功')
          init()
        }).catch(e => {
          message.error(e.message)
        })

      }
    })

  }
  //服务评价
  function ServiceEvaluation() {
    // utils.jumpPage(ROUTER_NAME.SEVERS_SHOPS.path, {
    //   orderId: detail.orderId,
    //   pssId: query.get('pssId') as string,
    // });
  }
  let arry: any = []
  detail?.histories &&
    detail?.histories?.map(el => {
      arry.unshift(el)
    })

  return (
    <div style={{ backgroundColor: '#F2F2F2', height: '100%' }}>
      <div className='sale-box'>
        <div className='sale-title'>
          <h2 style={{ fontSize: '18px' }}>
            {detail == undefined ? '' : detail.entity.statusName}
          </h2>
          <p>{detail == undefined ? '' : detail.updateTime}</p>
        </div>
        <div className='sale-list'>
          <p>
            售后类型：
            {detail == undefined
              ? ''
              : detail.entity.pssType === 2
                ? '退货退款'
                : '仅退款'}
          </p>
          <p>订单号：{detail == undefined ? '' : detail.orderId} </p>
          <p>申请时间：{detail == undefined ? '' : detail.entity.createTime}</p>
          <p>申请人：{detail == undefined ? '' : detail.accountName} </p>
          <p>联系电话：{detail == undefined ? '' : detail.phone}</p>

          {detail == undefined ? (
            ''
          ) : detail.entity.status == 4 ? (
            <div
              className='sale-btn'
              style={{ marginLeft: '72%' }}
              onClick={ServiceEvaluation}
            >
              服务评价
            </div>
          ) : (
            ''
          )}
          {detail == undefined ? (
            ''
          ) : detail.entity.status == 2 ? (
            <span>
              <div className='sale-btn' onClick={orderNo}>
                填写退货单号
              </div>
              <div
                className='sale-btn-canlc'
                onClick={() => afterCancel()}
              >
                撤销申请
              </div>
              {/*<div className='sale-btn' onClick={editApply}>*/}
              {/*  修改申请*/}
              {/*</div>*/}
            </span>
          ) : (
            ''
          )}

          {detail == undefined ? (
            ''
          ) : detail.entity.status == 3 ||
            detail.entity.status == 5 ||
            detail.entity.status == 1 ? (
            <span>
              <div
                className='sale-btn-canlc'
                onClick={() => afterCancel()}
              //   alert('取消申请售后', '取消之后，可以在订单详情再次申请', [
              //     { text: '取消', onPress: () => console.log('cancel') },
              //     {
              //       text: '确定',
              //       onPress: () => afterCancel(),
              //     },
              //   ])
              // }
              >
                撤销申请
              </div>
              {/*<div className='sale-btn' style={{borderColor:'#888888',color:'#888888'}}>撤销申请</div>*/}
              {/*<div className='sale-btn' onClick={editApply}>*/}
              {/*  修改申请*/}
              {/*</div>*/}
              {/*修改申请暂时隐藏了*/}
            </span>
          ) : (
            ''
          )}
        </div>
        {/*协商历史*/}
        <div
          onClick={() => {
            consult();
          }}
        >
          <div style={{ backgroundColor: '#f5f5f3', height: '5px' }}> </div>
          <List>
            <List.Item >
              <List.Item.Meta
                title="协商历史"
              />
            </List.Item>
          </List>
        </div>
        {/*商品详情*/}
        <div className='sale-shops-list' style={{ display: 'flex' }}>
          <div style={{ display: 'initial', width: '80px', height: '80px', overflow: 'hidden' }}>
            <img
              src={detail == undefined ? '' : detail.item.itemLogo}
              alt="足球"
              style={{ width: '100%', borderRadius: '20px', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <div className='sale-shops-title'>
            <p>{detail == undefined ? '' : detail.item.itemName}</p>
            <p>
              {detail == undefined ? '' : detail.item.specification}
              &nbsp;价格:¥ {detail == undefined ? '' : detail.item.actualCost}
              <span style={{ color: '#000', fontSize: '14px' }}>
                {' '}
                x {detail == undefined ? '' : detail.item.amount}
              </span>
            </p>
          </div>
        </div>

        {/*售后金额、进度、留言*/}
        <div className='sale-money'>
          <p>订单运费（元）：0</p>
          <p>
            申请金额（元）：{detail == undefined ? '' : detail.item.actualCost}
          </p>
          <p>
            退款原因：
            {detail == undefined
              ? ''
              : detail.histories.map((item, index) => {
                return (
                  <span key={index}>
                    {item.descTitle == '退款原因' ? item.description : ''}
                  </span>
                );
              })}
          </p>
          <p style={{ marginBottom: '15px' }}>售后进度：</p>
          {arry &&
            <Steps progressDot current={1} direction="vertical">
              {arry.map((item, index) =>
                <Step status={item.status === 7 ? 'finish' : 'wait'} title={item.statusName} description={item.createTime} />
              )}
            </Steps>
          }
          <p style={{ marginTop: '15px' }}>
            {detail == undefined
              ? ''
              : detail.histories.map((item, index) => {
                return (
                  <span key={index}>
                    {item.descTitle == '审核留言'
                      ? '审核留言：' + item.description
                      : ''}
                  </span>
                );
              })}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AfterSaleDetails
