import * as React from 'react';
import './placeOrder.css'
import { ServiceVoucher, VoucherBody } from '../../../../service/voucher'
import { ServicePay } from '../../../../service/pay'
import { ServiceRealName } from '../../../../service/member/realName'
import { CollectionService } from '../../../../service/member/collection'
import { ServiceAddress } from '../../../../service/address'
import { ServiceTicket } from '../../../../service/ticket'
import { message, Radio, Spin, Modal, Button } from 'antd';
import Util from '../../../../utils';
import { NavLink as Link } from 'react-router-dom';
import QRCode from 'qrcode';
import Address from '../../../personal-center/accountData/address'
import { jumpPage } from '../../../../CustomRouter';

class PlaceOrder extends React.Component<any, any>  {
  state = {
    ticketData: {} as any,
    distribution: [] as any,
    // 1无需，2快递，3自取
    pickAddress: [] as any,
    pickAddressType: null as any,
    address: {} as any,
    addressType: null,
    selectPickAddress: {} as any,
    selectedVouchers: [] as any,
    ticketItems: 0,
    memoInputValue: '' as any,
    vouchers: {} as VoucherBody,
    points: [] as any[],
    selectedPoints: [] as any[],
    money: {
      actualCost: 0,
      deliveryPrice: 0,
      discount: [],
      rawCost: 0,
      points: 0,
      goodsPrice: 0,
      pointsStep: 100,
      totalPoints: 0,
      pointsMoney: 0,
      rawDeliveryPrice: 0,
    } as any,
    //   积分抵扣
    pointDeduction: 0 as number,
    thmem: JSON.parse(sessionStorage.getItem('thmem') || '{}'),
    body: JSON.parse(sessionStorage.getItem('collectionPlaceOrder') || '{}') as any,
    loading: true,
    url: '',
    IsSuccess: false,
    showAddress: false,
    allIcon: JSON.parse(localStorage.getItem('icons') || '{}'),
    isDone: false,
    // 提交订单后的数据
    submitAfterData: {} as any,
    qrcode: '' as string,
    showCode: false as boolean,
    times: {} as any
  }


  componentDidMount() {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    console.log(this.state.body);
    this.getData()
  }

  async getData() {
    let { body, ticketData } = this.state
    try {
      if (body.arr === undefined) {
        const [ticket, points] = await Promise.all([
          ServiceTicket.queryShopInfo(body.goodsId), //查询周边商品详情
          ServiceVoucher.findAllByPoints(), //查询是否有积分  积分已经查出来了 取下标0 就可以了
        ]);
        ticketData.name = ticket.name
        ticketData.logo = ticket.logo
        this.setState({
          points: points.content,
          ticketData,
          express: ticket.express,
          expressMoney: ticket.expressMoney,
          selfPick: ticket.selfPick,
          delivery: ticket.delivery,
          selfPickAddresses: ticket.selfPickAddresses,
          loading: false
        })
        // 收货方式
        const list: any[] = [];

        //2 快递 3 自主 1 无需
        if (ticket.express) {
          list.push({
            id: 2,
            text: '快递配送',
            money: ticket.expressMoney,
          });
        }

        if (ticket.selfPick) {
          list.push({
            id: 3,
            text: '自提 (前往自提点取货)',
          });
          this.setState({
            pickAddress: ticket.selfPickAddresses,
            selectPickAddress: ticket.selfPickAddresses[0] || {}
          })
          // this.pickAddress = selfPickAddresses;
          // this.selectPickAddress = selfPickAddresses[0] || {};
        }

        if (ticket.delivery) {
          list.push({
            id: 1,
            text: '无需快递',
          });
        }
        // this.distribution = list;
        this.setState({ distribution: list })
        if (list.length === 1) {
          // this.addressType = list[0].id;
          this.setState({ addressType: list[0].id })
        }
      } else {
        const [points] = await Promise.all([ServiceVoucher.findAllByPoints()]);
        // this.points = points.content;
        this.setState({ points: points.content })
        const lists: any[] = [];
        const shopsExpress = body.express;
        const expressMoney = body.expressMoney;
        if (shopsExpress) {
          lists.push({
            id: 2,
            text: '快递配送',
            money: expressMoney,
          });
        }
        // this.distribution = lists;
        this.setState({ distribution: lists })
        if (lists.length === 1) {
          this.setState({ addressType: lists[0].id })
          // this.addressType = lists[0].id;
        }
      }
      await this.getAddress();
    } catch (error: any) {
      message.error(error.message)
    }
  }

  async getAddress() {
    try {
      const res = await ServiceAddress.getAddressList(0, 999);
      let first: any = null;
      for (const item of res.content) {
        if (item.entity.first) {
          first = item;
        }
      }
      // 如果有默认的收货地址就直接请求 价格
      // 如果没有默认收货地址,就自己计算一个
      if (first) {
        // this.address = first.entity;
        // setAddress(first.entity)
        first.entity.address = first?.address + first?.entity?.address
        this.setState({ address: first.entity })
      }
      await this.getMoney();
    } catch (err: any) {
      message.error(err.message);
      // 报错了也需要自己计算价格
      // 在用户信息不全,如没有收货地址,没有收货方式等,后台就会返回错误
      this.setMoneyInit();
    }
  }

  async getMoney() {
    let { body, money, addressType, address, memoInputValue, selectPickAddress } = this.state
    try {
      const data = {
        goodsId: body.goodsId,
        points: money.points,
        deliveryType: addressType, //取货方式
        receiptAddressId: address.id, //用户选择的收货地址
        userMemo: memoInputValue, //用户的备注内容
        //totalPrice: 0, //参考金额
        selfPickAddressId: selectPickAddress.id || '', //用户选择的自取地址 这个暂时不传 因为还没有自取的地址
      }
      money = await CollectionService.preReserve(data)
      let voucherParam = {
        goodsId: body.goodsId,
        totalPrice: money.goodsPrice,
        // voucherIds: param.voucherIds,
        delivery: addressType === 2, //是否快递
      };
      const [voucher] = await Promise.all([
        ServiceVoucher.findVoucherByOrder(voucherParam),
      ]);
      this.setState({ money, vouchers: voucher || [] })
    } catch (error: any) {
      // message.error('无法购买' + error.message)
      Modal.confirm({
        title: '无法购买',
        content: error.message,
        cancelText: '取消',
        okText: '确认'
      })
    }
  };

  setMoneyInit() {
    let momey = 0;
    let { body, ticketData, distribution } = this.state
    // 判断是否有赠品,
    if (body.isGift) {
      momey = body.locations.reduce((s, c) => (s += c.money), 0);
    } else {
      momey = body.peoples.reduce((s, c) => {
        if (c.student) {
          return (s += c.location.stuPrice);
        }
        return (s += c.location.money);
      }, 0);
    }
    // 快递费
    const em = ticketData.expressMoney || 0;

    let moneyBody: any = {};
    if (distribution.length === 1) {
      // 大概是快递的id为 2吧
      const isKuaidi = !!distribution.find(v => v.id === 2);
      moneyBody = {
        // actualCost: money + (isKuaidi ? em : 0),
        actualCost: '请选择取票方式',
        deliveryPrice: isKuaidi ? em : 0,
        rawDeliveryPrice: isKuaidi ? em : 0,
        discount: [],
        rawCost: momey + (isKuaidi ? em : 0),
      };
    } else {
      moneyBody = {
        // actualCost: money,
        actualCost: '请选择取票方式',
        deliveryPrice: '0.00',
        rawDeliveryPrice: '0.00',
        discount: [],
        rawCost: momey,
      };
    }
    // setMoney(moneyBody)
    // money = moneyBody;
    this.setState({ money: moneyBody })
  }

  // 清除选择的优惠券
  clearVouchers() {
    let { body } = this.state
    // setSelectPickAddress([])
    this.setState({ selectPickAddress: [] })
    const arr = body.gifts;
    for (const [k, v] of Object.entries(body.gifts)) {
      arr[+k].isRemove = false;
    }
    body.gifts = arr;
    this.setState({ body })
    // sessionStorage.setItem(
    //   sessionStorageEnum.SUBMIT_ORDER,
    //   JSON.stringify(this.data)
    // );
  }

  // 选择优惠券
  saveSelectedVouchers(value) {
    //  let {selectedVouchers} = this.state
    let selectedVoucherList = [...this.state.selectedVouchers]
    let selectedIds = selectedVoucherList.map(i => i.entity.id);
    if (selectedIds.includes(value?.entity?.id)) {
      //如果选择的是已经选中的
      selectedVoucherList.map((i, index) => {
        //去掉selectedVouchers中保存的这个数据
        if (i.entity.id === value?.entity?.id) {
          selectedVoucherList.splice(index, 1);
        }
      });
      this.setState({ selectedVouchers: selectedVoucherList }, () => {
        this.updateVouchers();

      })
    } else if (value?.selectable) {
      //如果选择的是没选过的,并且可以选择的
      selectedVoucherList.push(value);
      this.setState({ selectedVouchers: selectedVoucherList }, () => {
        this.updateVouchers();
      })
    }

  }

  // 当优惠券选择更改时调用,更新可用优惠券
  updateVouchers() {
    let { money, body, addressType } = this.state
    let voucherParam = {
      items: [{
        itemId: body.goodsId,
        itemType: 2
      }],
      totalPrice: money.goodsPrice,
      // voucherIds: param.voucherIds,
      delivery: addressType === 2,
    };
    this.getMoney()
    ServiceVoucher.findVoucherByOrder(voucherParam)
      .then(res => {
        this.setState({ vouchers: res || [] })
      })
      .catch(err => {
        message.error(err.message);
      });
  }


  // 选择收货方式
  changeSelect(key) {
    // setAddressType(key)
    this.setState({ addressType: key }, () => {
      if (key === 2) {
        this.getMoney();
      } else {
        this.getMoney();
      }
    })

  }

  async submitOrders() {
    let { addressType, body, money, address, memoInputValue, selectPickAddress } = this.state
    try {
      if (typeof addressType !== 'number') {
        message.error('请选择配送方式')
        return
      }
      const data = {
        goodsId: body.goodsId,
        points: money.points,
        deliveryType: addressType, //取货方式
        receiptAddressId: address.id, //用户选择的收货地址
        userMemo: memoInputValue, //用户的备注内容
        selfPickAddressId: selectPickAddress.id || '', //用户选择的自取地址 这个暂时不传 因为还没有自取的地址
      };
      const res = await CollectionService.reserve(data)
      // 暂时回到商品首页
      setTimeout(async () => {
        if (res.actualCost === 0) {
          await ServiceRealName.activeTicketByOrderId(res.orderId);
          this.setState({ url: '/shopping/mall', IsSuccess: true })
        } else {
          this.setState({ submitAfterData: res })
        }
      }, 300);
    } catch (error: any) {
      Modal.confirm({
        title: '无法购买',
        content: error.message,
        cancelText: '取消',
        okText: '确认'
      })
    }
  }

  async wxPay() {
    let { submitAfterData } = this.state
    const data = await ServicePay.wechatPay(submitAfterData?.orderId); //现网环境的微信付款
    if (data) {
      let qrcode = await QRCode.toDataURL(data?.orderString?.code_url);
      this.setState({
        qrcode,
        showCode: true
      }, () => {
        this.getPayStatus(submitAfterData?.orderId)
        this.setCountDown(submitAfterData?.expireTime)
      })
    }
  }
  async aliPay() {
    let { submitAfterData } = this.state
    try {
      const res = await ServicePay.aliPayByOtherBrowser(submitAfterData?.orderId);

      let from = res?.orderString as string
      let ht = this.refs.from as HTMLElement
      ht.innerHTML = from;
      document.forms[0].submit()
    } catch (e: any) {
      message.error(e.message);
    }
  }
  setCountDown(time: string) {
    const times1 = setInterval(() => {
      this.setState({ times: Util.showtime(time) })
    }, 1000)
  }
  // 轮询订单的支付状态,支付成功后跳转页面
  // let isDone = false;
  async getPayStatus(orderId) {
    let { isDone } = this.state
    try {
      const status = await ServicePay.getPayStatus(orderId);

      if (status) {
        jumpPage('/personal/center/my/order/0')
      } else {
        await Util.sleep(1000);
        // 轮询结果,在页面跳转的时候停止轮询
        if (!isDone) {
          await this.getPayStatus(orderId);
        }
        return;
      }
      this.setState({ isDone: true, showCode: false })

    } catch (e) {
    }
  }

  render() {
    let {
      body,
      selectedVouchers,
      points,
      address,
      vouchers,
      distribution,
      addressType,
      ticketData,
      money,
      thmem,
      loading,
      pointDeduction,
      submitAfterData,
      qrcode,
      showCode,
      allIcon,
      times
    } = this.state
    const { valid = [], invalid = [] } = vouchers;
    const len = body.amount; //购买的数量
    const vounchers =
      selectedVouchers.length > 0
        ? `使用${selectedVouchers.length}张`
        : valid.length > 0
          ? `可用${valid.length}张`
          : '暂无可用';

    const getTicketWay =
      distribution.find(v => v.id === addressType) &&
      distribution.find(v => v.id === addressType).id;
    const price = Util.toFixed(money.rawCost - money.rawDeliveryPrice);

    let maxJF = Math.min(
      Math.floor(money.actualCost / 2) * money.pointsStep,
      Math.floor(money.totalPoints / money.pointsStep) * money.pointsStep
    );
    let discountPrice = 0;
    money.discount.map((i: any) => {
      discountPrice += i.money;
      if (i.title == '积分优惠') {
        maxJF = Math.min(
          Math.floor((money.actualCost + i.money) / 2) * money.pointsStep,
          Math.floor(money.totalPoints / money.pointsStep) * money.pointsStep
        );
      }
    });
    let bool = false;
    let text = money.actualCost;
    if (money?.actualCost?.toString().includes('请选择')) {
      bool = true;
      if (addressType) {
        text = '请选择收货地址';
      }
    }

    let selectedIds = selectedVouchers.map(i => i.entity.id)

    return (
      <Spin spinning={loading}>
        <div className="container" style={{ width: '100%', minHeight: '100vh', position: 'relative', background: thmem?.background }}>
          <div className="purchase-box">
            <div className="purchase-box-left">

              <div className="item-box">
                <div className="item-box-titel">配送方式</div>
                <div className="item-box-list">
                  <Radio.Group onChange={(e) => this.changeSelect(e.target.value)} value={getTicketWay}>
                    {distribution.map((item, index) => (
                      <Radio
                        key={item.id}
                        value={item.id}
                      >
                        {item.text}
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
              </div>
              <div className="item-box">
                <div className="item-box-titel">收货地址</div>
                <div className="item-box-list">
                  {
                    JSON.stringify(address) === '{}' ?
                      <p>请选择收货地址</p>
                      :
                      <p>{address.name} {address.phone} {address.address}</p>
                  }
                </div>
                <div className="address">
                  <Button onClick={() => this.setState({ showAddress: true })}>切换地址</Button>
                </div>
              </div>

              <div className="item-box">
                <div className="item-box-titel">积分 <span>共{money.totalPoints || 0} 可用{maxJF || 0}</span></div>
                <div className="item-box-list">
                  <input disabled={maxJF <= 0} value={pointDeduction} min={0} step={100} max={maxJF} onChange={(e: any) => {
                    money.points = (Number(e.target.value) > maxJF ? maxJF : e.target.value)
                    this.setState({
                      money,
                      pointDeduction: (Number(e.target.value) > maxJF ? maxJF : e.target.value)
                    }, () => this.getMoney())
                  }} type="number" />
                  <p style={{ lineHeight: '30px', marginLeft: '30px' }}>抵扣 {pointDeduction < 100 ? 0 : pointDeduction / money.pointsStep}元</p>
                </div>
              </div>
            </div>
            <div className="purchase-box-right">
              <div className="purchase-right-title"></div>
              <div className="content-list">
                {body.arr !== undefined ?
                  body.arr?.map(el =>
                    <div className="content-list-one">
                      <div className="content-img">
                        <img src={el?.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                      </div>
                      <div className="content-info">
                        <p>{el?.name}</p>
                        <p>规格：{el.specification1} {el.specification2}</p>
                        <p>数量：x{el.amount}</p>
                      </div>
                      <div className="content-price">
                        ￥ {(el.rawCost - (el.rawDeliveryPrice || 0)).toFixed(2)}
                      </div>
                    </div>
                  )
                  :
                  <div className="content-list-one">
                    <div className="content-img">
                      <img src={body?.logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    </div>
                    <div className="content-info">
                      <span>{body?.name}</span>
                      <span>规格：{body?.specification1} {body?.specification2}</span>
                      <span>数量：x{body?.amount}</span>
                    </div>
                    <div className="content-price">
                      ￥ {(money.goodsPrice / len).toFixed(2)}
                    </div>
                  </div>

                }
              </div>
              <div className="sub-total">
                <div className="sub-total-list">
                  <p className="total-title">小计</p>
                  <p className="total-price">￥{Util.toFixed(money.rawCost - money.deliveryPrice)}</p>
                </div>
                {
                  money.discount?.map(el =>
                    <div className="sub-total-list">
                      <p className="total-title" style={{ fontSize: '13px' }}>{el.title}</p>
                      <p className="total-price" style={{ fontSize: '13px' }}>- ￥{el.money}</p>
                    </div>
                  )
                }
                {addressType === 2 &&
                  <div className="sub-total-list">
                    <p className="total-title" style={{ fontSize: '13px' }}>快递费用</p>
                    <p className="total-price" style={{ fontSize: '13px' }}>+ ￥ {Util.toFixed(money.rawDeliveryPrice)}</p>
                  </div>
                }
              </div>
              <div className="sub-total">
                <div className="sub-total-list">
                  <p className="total-title">总计</p>
                  <p className="total-price">{bool ? text : `￥ ${Util.toFixed(money.actualCost)}`}</p>
                </div>
                {
                  JSON.stringify(submitAfterData) === '{}' ?
                    <div className="pay-now" onClick={() => this.submitOrders()}>提交</div>
                    :
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                      <div onClick={() => this.wxPay()} style={{ width: '30%', height: '50px', margin: '0 10px', border: '1px solid #ccc', cursor: 'pointer' }}>
                        <img src={allIcon['wpc']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                      </div>
                      <div onClick={() => this.aliPay()} style={{ width: '30%', height: '50px', margin: '0 10px', border: '1px solid #ccc', cursor: 'pointer' }}>
                        <img src={allIcon['zpc']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />

                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
          <div ref="from" style={{ opacity: 0 }}></div>
        </div >
        <Modal title="提交成功" closable={false} visible={this.state.IsSuccess} footer={null}>
          <Button type="primary">
            <Link to={{ pathname: this.state.url }}>确认</Link>
          </Button>
        </Modal>
        <Modal
          title="常用地址"
          visible={this.state.showAddress}
          onCancel={() => this.setState({ showAddress: false })}
          footer={null}
        >
          <Address address={address} isChoose={true} onChoose={(e) => { this.setState({ address: { ...e }, showAddress: false }) }} />
        </Modal>
        <Modal
          title={"微信支付 " + `${times && '支付时间还剩余' + `${times?.minute || ''}:${times?.second || ''}`}`}
          visible={showCode}
          footer={null}
          onCancel={() => { this.setState({ showCode: false }) }}
        >
          <div style={{ width: '100%', margin: '30px auto', textAlign: 'center' }}>
            <img src={qrcode} alt="" />
          </div>
        </Modal>
      </Spin>
    );
  }
}
export default PlaceOrder