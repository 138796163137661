import { HandlerError } from './HandlerError';

//用正则表达式实现html编码（转义
export function htmlEncodeByRegExp(htmlText) {
  return htmlText.replace(/[<>&"]/g, function (c) {
    return { '<': '&lt;', '>': '&gt;', '&': '&amp;', '"': '&quot;' }[c];
  });
}

//用正则表达式实现html解码（反转义）
export function htmlDecodeByRegExp(htmlText) {
  var arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' };
  return htmlText.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
    return arrEntities[t];
  });
}

// 点击扫码
export function goScan(handlerScanEnd: Function): void {
  (window as any).wx.scanQRCode({
    desc: 'scanQRCode desc',
    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
    success(res): void {
      handlerScanEnd(res.resultStr);
    },
    fail(res): void {
      alert('调用微信扫码失败!');
      if (res.errMsg.indexOf('function_not_exist') > 0) {
        alert('微信版本过低, 不能调用扫码功能!');
      }
    },
  });
}

export function getDate(date: Date): string {
  let dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
  return dateStr;
}
export function getTime(date: Date): string {
  let dateStr = `${date.getHours()}:${date.getMinutes()}`;
  return dateStr;
}
export function getDateTime(date: Date): string {
  let dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
  return dateStr;
}

/**
 * 补零
 * @param n
 */
function zero(n): string {
  return n < 10 ? `0${n}` : n;
}

/**
 * 格式化时间   YYYY-MM-DD
 * @param date
 */
export function dateFormat(date: Date | string = new Date()): string {
  const nDate = new Date(date);
  const y = nDate.getFullYear();
  const m = zero(nDate.getMonth() + 1);
  const d = zero(nDate.getDate());
  return `${y}-${m}-${d}`;
}
/**
 * 格式化时间   HH:mm:ss
 * @param date
 */
export function timeFormat(date: Date | string = new Date()): string {
  const nDate = new Date(date);
  const h = zero(nDate.getHours());
  const mins = zero(nDate.getMinutes());
  const second = zero(nDate.getSeconds());
  return `${h}:${mins}:${second}`;
}
/**
 * 格式化时间  YYYY-MM-DD HH:mm:SS
 * @param date
 */
export function dateTimeFormat(date: number | Date = new Date()): string {
  const nDate = new Date(date);
  const y = nDate.getFullYear();
  const m = zero(nDate.getMonth() + 1);
  const d = zero(nDate.getDate());
  const h = zero(nDate.getHours());
  const mins = zero(nDate.getMinutes());
  const second = zero(nDate.getSeconds());
  return `${y}-${m}-${d} ${h}:${mins}:${second}`;
}

/**
 * 字符串截取(超过长度加...)
 * @param val: 要截取的字符串
 * @param len: 要截取的长度
 */
export function subStrEllipsis(val: string = '', len: number = 20) {
  return val.length > len ? val.substring(0, len) + '...' : val;
}
/**
 * 字符串截取
 * @param val: 要截取的字符串
 * @param start: 开始的下标
 * @param len: 要截取的长度
 */
export function subStr(val: string = '', start: number = 0, len: number = 20) {
  return val.substring(start, len);
}

/**
 * 判断图片是否需要旋转矫正
 * @param file
 */
export function imageHandle(file: File): Promise<any> {
  const or = 6 as any; //Exif.getTag(file, 'orientation');
  // 8 表示 顺时针转了90
  // 3 表示 转了 180
  // 6 表示 逆时针转了90
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      //@ts-ignore
      const imgData = e.target.result;
      if (or) {
        const img = new Image();
        img.src = imgData as string;
        img.onload = function () {
          let cvs = document.createElement('canvas');
          let ctx = cvs.getContext('2d');
          if (or === 8 || or === 6) {
            cvs.width = img.height;
            cvs.height = img.width;
          } else {
            cvs.width = img.width;
            cvs.height = img.height;
          }
          if (or == 6 && ctx) {
            //原图逆时针转了90, 所以要顺时针旋转90
            ctx.rotate((Math.PI / 180) * 90);
            ctx.drawImage(
              img,
              0,
              0,
              img.width,
              img.height,
              0,
              -img.height,
              img.width,
              img.height
            );
          }
          if (or == 3 && ctx) {
            //原图逆时针转了180, 所以顺时针旋转180
            ctx.rotate((Math.PI / 180) * 180);
            ctx.drawImage(
              img,
              0,
              0,
              img.width,
              img.height,
              -img.width,
              -img.height,
              img.width,
              img.height
            );
          }
          if (or == 8 && ctx) {
            //原图顺时针旋转了90, 所以要你时针旋转90
            ctx.rotate((Math.PI / 180) * -90);
            ctx.drawImage(
              img,
              0,
              0,
              img.width,
              img.height,
              -img.width,
              0,
              img.width,
              img.height
            );
          }
          resolve(cvs.toDataURL('image/png'));
        };
      } else {
        resolve(imgData);
      }
    };
  });
}
/**
 * 旋转
 * @param file
 */
export function handleImageRotate(
  file: File,
  or: 'left' | 'right',
  angle?: number
): Promise<File> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      //@ts-ignore
      const imgData = e.target.result;
      if (or) {
        const img = new Image();
        img.src = imgData as string;
        img.onload = function () {
          let cvs = document.createElement('canvas');
          let ctx = cvs.getContext('2d');

          cvs.width = img.height;
          cvs.height = img.width;
          if (or == 'left' && ctx) {
            //原图逆时针转了90, 所以要顺时针旋转90
            ctx.rotate((Math.PI / 180) * 90);
            ctx.drawImage(
              img,
              0,
              0,
              img.width,
              img.height,
              0,
              -img.height,
              img.width,
              img.height
            );
          } else if (or == 'right' && ctx) {
            //原图顺时针旋转了90, 所以要你时针旋转90
            ctx.rotate((Math.PI / 180) * -90);
            ctx.drawImage(
              img,
              0,
              0,
              img.width,
              img.height,
              -img.width,
              0,
              img.width,
              img.height
            );
          }
          resolve(dataURLtoFile(cvs.toDataURL('image/png'), file.name));
        };
      } else {
        resolve(dataURLtoFile(imgData, file.name));
      }
    };
  });
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

/**
 * @desc 函数防抖(延迟执行版)
 * @param timeoutObj 用来保存setTimeout的值
 * @param func 函数
 * @param wait 延迟执行毫秒数
 */

export function debounceDelay(timeoutObj, func: Function, wait: number) {
  return (function () {
    if (timeoutObj) {
      clearTimeout(timeoutObj);
    }
    timeoutObj = setTimeout(() => {
      func.apply(null, arguments);
    }, wait);
    return timeoutObj;
  })();
}



/**
 * @description 数组去重(支持整数数组\字符串数组\对象数组去重)
 * @param data 需要去重的数组元数据,可以是对象数组
 * @param keys 如果是对象数组需要传keys,表示每个对象可以作为唯一键值的属性(可以多层查找)
 */
export function deduplication(data = [] as any[], keys?: string[]) {
  let obj = {};
  return data.reduce((preVal, curVal) => {
    let val = curVal;
    keys && keys.map(i => (val = val[i]));

    if (!obj[val]) {
      obj[val] = true;
      preVal.push(curVal);
    }
    return preVal;
  }, []);
}

export function isNumber(theObj) {
  var reg = /^[0-9]+.?[0-9]*$/;
  if (reg.test(theObj)) {
    return true;
  }
  return false;
}
