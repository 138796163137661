import * as React from 'react';
import './orderDetail.css'
import utils from '../../../../utils';
import { Countdown } from '../../../../component/Countdown'
import { QueryOrderInfo, ServiceOrder, express, NFRContent } from '../../../../service/order';
import {
  DictContent,
  DictionaryEnum,
  ServiceDictionary,
} from '../../../../service/dictionary';
import { RenderCell, getTime, toFixed, getStatusName } from '../component/index'
import { message, Spin, Button, List } from 'antd';
/**
 * 数字藏品
 * @returns 
 */
interface OrderDetailsState {
  orderId: string
  onChange: () => void
}
function NftDetails(prop: OrderDetailsState) {
  let { orderId, onChange } = prop
  const [loadding, setLoadding] = React.useState<boolean>(false)
  const [info, setInfo] = React.useState<QueryOrderInfo>()
  const [express, setExpress] = React.useState<express>()
  const [discount, setDiscount] = React.useState<DictContent[]>([])
  const [delivery, setDelivery] = React.useState<any[]>([])
  const [nfrData, setNfrData] = React.useState<NFRContent>()
  const [errMsg, setMsg] = React.useState<string>('')

  async function getData() {
    try {
      const { detail, express, discount, nfr } = await ServiceOrder.orderQuery(orderId);
      let delivery = await ServiceDictionary.getDictionary(DictionaryEnum.配送方式);
      setInfo(detail)
      setExpress(express[0])
      setDelivery(delivery)
      setDiscount(discount)
      setNfrData(nfr)
      setLoadding(false)

    } catch (err: any) {
      message.error(err.message);
      setLoadding(false)
      setMsg(err.message)
    }
  }
  function logisticsDetail() {
    // utils.jumpPage(ROUTER_NAME.LOGISTICS_DETAIL.path, {
    //   id: utils.getUrlParams().get('id') as string,
    //   status: utils.getUrlParams().get('status') as string,
    // });
  }
  //点击售后状态按钮进行跳转
  function onClickStatus(e, i) {
    //   const textStatus = e.target.innerText;
    //   if (textStatus == '申请售后') {
    //     sessionStorage.setItem(
    //       'after', //SET数据到本地
    //       JSON.stringify(i)
    //     );
    //     utils.jumpPage(ROUTER_NAME.APPLY_AFTER.path, { orderId: info.orderId });
    //   } else {
    //     utils.jumpPage(ROUTER_NAME.APPLY_AFTER_STATUS.path, {
    //       pssId: i.item.pss.id,
    //     });
    //   }
  }
  React.useEffect(() => {
    getData();
  }, [orderId]);

  if (!info) {
    return null;
  }
  const allSeatMenoy = toFixed(
    info.seats.reduce((start: number, cu) => (start += cu.price), 0)
  );
  let aa = console.log();

  let expireTime = new Date();
  if (info.expireTime) {
    expireTime = new Date(Date.parse(info.expireTime.replace(/-/g, '/')));
  }
  const find = delivery.find(val => val.dictKey === info.deliveryType);
  const deliveryName = find ? find.dictValue : '无';
  let itemLogo = info.items ? info.items[0].item.itemLogo : '';
  let itemName = info.items ? info.items[0].item.itemName : '';
  return (
    <Spin spinning={loadding}>
      <div
        className='nfr-content'
        style={{ background: '#f5f5f5', paddingBottom: info.status === 1 ? '40px' : '10px' }}
      >
        <div className='tickets-details'>
          <div style={{ display: 'flex' }}>
            <img src={itemLogo} className='tickets-pic' />
            <div className='Order-box'>
              <p className='tickets-title-two'>{itemName}</p>
            </div>
          </div>
        </div>
        {/* 物流信息 */}
        {express && express.expressCode ? (
          <div
            className='card'
            style={{ paddingTop: '10px' }}
            onClick={() => logisticsDetail()}
          >
            <div style={{ display: 'flex', position: 'relative' }}>
              <div style={{ flex: 1 }}>物流详情</div>
              <div style={{ margin: 'auto 0' }}>
                {/* <Icon type="right" /> */}
              </div>
            </div>
          </div>
        ) : (
          <div className='card' style={{ paddingTop: '10px' }}>
            暂无物流
          </div>
        )}

        {/*收货人运费等等*/}

        {info.mobileRecipientAddress ? (
          <div className='card'>
            <div className='card-item'>
              <div className='card-item-left'>
                收货人: <span>{info.mobileRecipientAddress.name}</span>
              </div>
              <div className='card-item-right'>
                {info.mobileRecipientAddress.phone}
              </div>
            </div>
            <div className='address-row'>
              <span className='address-left'>收货地址：</span>
              <span className='address-right'>
                {info.mobileRecipientAddress.fullAddress}
              </span>
            </div>
          </div>
        ) : (
          info.selfPickAddressName && (
            <div className='card'>
              <div className='address-row'>
                <span className='address-left'>取票地址：</span>
                <span className='address-right'>
                  {info.selfPickAddressName}
                </span>
              </div>
            </div>
          )
        )}
        {/* <List
          dataSource={[1]}
          style={{ background: '#fff', margin: '5px 0', padding: '0 15px' }}
          renderItem={(item, index) => (
            <List.Item key={index} >
              <List.Item.Meta
                title={<span>{'数字藏品'}</span>}
              />
              <div onClick={() => onChange} style={{ color: '#888' }}>{'>'}</div>
            </List.Item>
          )}
        >
        </List> */}
        <div
          className='card'
          style={{ paddingTop: '10px' }}
          onClick={() => onChange()}
        >
          <div style={{ display: 'flex', position: 'relative', padding: '5px 0' }}>
            <div style={{ flex: 1 }}>数字藏品</div>
            <div style={{ margin: 'auto 0' }}>
              {'>'}
              {/* <Icon type="right" /> */}
            </div>
          </div>
        </div>
        <div className='card'>
          <RenderCell
            header="运费"
            red
            footer={`+ ￥${toFixed(info.deliveryPrice)}`}
          />
          <RenderCell
            header="优惠"
            red
            footer={`- ￥${toFixed(info.rawCost - info.actualCost)}`}
          />
          <List
            dataSource={discount}
            style={{ background: '#fff', margin: '5px 0', display: discount.length > 0 ? 'block' : 'none' }}
            renderItem={(item: any, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={item?.title}
                />
                <div>-￥ {toFixed(item?.money)}</div>
              </List.Item>
            )}
          >
          </List>
          <RenderCell header="订单总价" footer={`￥${toFixed(info.rawCost)}`} />
        </div>
        <div className='card'>
          <div className='cell'>
            <span>{info.status === 1 ? '待付款' : '实付款'}</span>
            <span className='cell-b' />
            <span className='color-red'>
              ￥{toFixed(info.actualCost)}
            </span>
          </div>
        </div>
        <div className='card'>
          <RenderCell header="订单编号" footer={info.orderId} />
          <RenderCell header="配送方式" footer={deliveryName} />
          <RenderCell header="订单状态" footer={getStatusName(info.status)} />
          <RenderCell header="用户备注" footer={info.userMemo || '无'} />
          <RenderCell header="下单时间" footer={info.createTime || '无'} />
          {info.status !== 1 && (
            <>
              <RenderCell
                header="支付渠道"
                footer={info.ttpOrderType || '无'}
              />
              <RenderCell
                header="渠道订单号"
                footer={info.ttpOrderId || '无'}
              />
              <RenderCell header="支付时间" footer={info.payoffTime || '无'} />
            </>
          )}
        </div>
      </div>
      {info.status === 1 && (
        <div className='footer'>
          <span className='money'>￥{toFixed(info.actualCost)}</span>
          <span className='time'>
            <span>剩余</span>
            <Countdown time={getTime(new Date(), expireTime)} />
          </span>
          <Button
            style={{ width: 'auto' }}
            className='payment'
            onClick={() => { }}
          >
            立即付款
          </Button>
        </div>
      )}
    </Spin>
  );
}
export default NftDetails