import * as React from 'react';
import './MyCollection.css'
import Login from '../../loginHome/index'
import Util from '../../../utils';
import { NoData } from '../../../component/noData';
import CollectionDetail from './collectionDetail'
import { MyCollectionContent, CollectionService } from '../../../service/member/collection'
import { message, Spin, Modal, Pagination } from 'antd';

/**
 * 数字藏品
 * @returns 
 */
function Collection() {
  const [list, setList] = React.useState<MyCollectionContent[]>([])
  const [totalElements, setTotalElements] = React.useState<number>(1)
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [pageNum, setPageNum] = React.useState<number>(1)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [show, setShow] = React.useState<boolean>(false)
  const [loadding, setLoadding] = React.useState<boolean>(true)
  const [orderId, setOrderId] = React.useState<any>(undefined)

  function getData(pageNum, pageSize) {
    Array.prototype.slice.call([])
    CollectionService.myCollection({ pageNum, pageSize })
      .then(res => {
        setList(res.content)
        setPageNum(pageNum)
        setPageSize(pageSize)
        setTotalElements(res.totalElements)
      })
      .catch(e => {
        message.error(e.message,);
      })
      .finally(() => {
        setLoadding(false)
      });
  }
  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setLoadding(false)
      return
    }
    getData(1, pageSize)
  }, []);


  async function toDetail(item) {
    setOrderId(item.orderId)
    setShow(true)
  }

  const goldFrame = React.useMemo(() => {
    let icons = JSON.parse(localStorage.getItem('icons') || '{}');
    return icons['Gold frame'];
  }, []);

  return (
    <Spin spinning={loadding}>
      {
        totalElements > 0 ?
          <div className='collection'>
            <div
              className='content'
            >
              {
                list.map(item => {
                  return (
                    <div
                      key={item.orderId}
                      className='collection-item'
                      style={{ backgroundColor: '#191d1d' }}
                      onClick={() => toDetail(item)}
                    >
                      <div className='box'>
                        <div
                          className='backgroud'
                          style={{ backgroundImage: `url(${item.fileUrl})` }}
                        />
                        <div
                          className='img-frame'
                        // style={{ backgroundImage: `url(${goldFrame})` }}
                        >
                          <img
                            src={goldFrame}
                            alt=""
                            className='img-main' />
                          <div style={{ width: '90%', height: '80%', position: 'absolute', top: 'calc(8%)', left: '5%', }}>
                            <img
                              src={item.fileUrl}
                              alt=""
                              className='img-main'
                            />
                          </div>

                        </div>
                      </div>
                      <div className='name'>藏品名称: {item.name}</div>
                      <div
                        className='name'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>ID: {item.serialId}</div>
                        <div className='gold-btn'>
                          全网限量{item.releaseCount}张
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <div className='goods-pagination'>
              <Pagination
                pageSize={pageSize}
                size="small"
                current={pageNum}
                total={totalElements}
                onChange={(page, pageSize) => { getData(page, pageSize) }}
                onShowSizeChange={(current, size) => { getData(current, size) }}
              />
            </div>
            <Modal
              title="数字藏品"
              visible={show}
              footer={null}
              wrapClassName="collectionModal"
              onCancel={() => setShow(false)}
            >
              <CollectionDetail orderId={orderId} />
            </Modal>
          </div>
          :
          <NoData show={true} />
      }
      {
        isLogin &&
        <Login onLogin={() => { getData(1, pageSize); setIsLogin(false) }} onCancel={() => setIsLogin(false)} />
      }
    </Spin>
  );
}
export default Collection