
import * as React from 'react';
import './personalIndex.css'
import Login from '../loginHome/index'
import Util from '../../utils';
import Auth from './component/auth'
import { ServiceAccount } from '../../service/account/index'
import { ServiceEnroll } from '../../service/enroll/index'
import { Button, message } from 'antd';
import { Link, useNavigate } from "react-router-dom";//引入routerdom
import { Net } from '../../utils/net';

export function Center() {
  const domain = localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]
  const navigate = useNavigate();
  const [thmem, setThmem] = React.useState<any>(JSON.parse(sessionStorage.getItem('thmem') || '{}'))
  const [icons, setIcons] = React.useState<any>(JSON.parse(sessionStorage.getItem('icons') || '{}'))
  const [enrollItem, setEnrollItem] = React.useState<any>([])
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [data, setData] = React.useState<any>({})
  const [loadding, setLoadding] = React.useState<boolean>(true)
  React.useEffect(() => {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    if (!Util.IsLogin()) {
      setIsLogin(true)
      setLoadding(false)
      return
    }
    setIcons(JSON.parse(sessionStorage.getItem('icons') || '{}'))
    console.log(JSON.parse(sessionStorage.getItem('icons') || '{}'));
    getIcons()
    getData()
  }, [])
  function getIcons() {
    Net(`/home/getAllIcon?domain=${domain}`)
      .then(res => {
        setIcons(res.data.list)
      })
      .catch(err => {
      });
  }
  function getData() {
    Promise.all([
      ServiceAccount.getAccountDetail(),
      ServiceEnroll.listMine({ pageNum: 1, pageSize: 1 }),
    ]).then(res => {
      setData(res[0])
      setEnrollItem(res[1]?.content)
      // this.setState({ data: res[0], enrollItem: res[1]?.content })
      localStorage.setItem(
        'USER_INFO',
        JSON.stringify(res[0])
      );
      sessionStorage.setItem(
        'myMemberCardName',
        res[0].currentMemberCard ? res[0].currentMemberCard.name : ''
      );
      setLoadding(false)

    }).catch(err => {
      if (err.code === 2) {
        setIsLogin(true)
        message.error('登陆已过期,请重新登陆!')
      } else {
        message.error(err.message)
      }
      setLoadding(false)
    })
  }

  function goNavigate(url, key) {
    sessionStorage.setItem('selectedKeys', key)
    navigate(url)
  }

  let { wallet } = data
  return (
    <div className="Vip-content">
      <div className="vip-cnter">
        <div className="vip-center-left">
          <img src={data?.topImg || icons['Default top']} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
        <div className="vip-center-right">
          <h2 className="vip-center-right-title">资产权益</h2>
          <div className="vip-center-right-message">
            {/* <Link to="/personal/center/my/vip/center"> */}
            <div onClick={() => goNavigate('/personal/center/my/vip/center', 'center')} className="vip-center-right-message-info">
              <p>0</p>
              <p>会员权益</p>
            </div>
            {/* </Link> */}
            {/* <Link to="/personal/center/my/collection"> */}
            <div onClick={() => goNavigate('/personal/center/my/collection', 'collection')} className="vip-center-right-message-info">
              <p>0</p>
              <p>数字藏品</p>
            </div>
            {/* </Link> */}
            {/* <Link to="/personal/center/my/fantasy"> */}
            <div onClick={() => goNavigate('/personal/center/my/fantasy', 'fantasy')} className="vip-center-right-message-info">
              <p>{wallet?.ftsMoney || 0}</p>
              <p>范特豆</p>
            </div>
            {/* </Link> */}
            {/* <Link to="/personal/center/my/order/0"> */}
            <div onClick={() => goNavigate('/personal/center/my/order/0', 'order')} className="vip-center-right-message-info">
              <p>{wallet?.money || 0}</p>
              <p>余额</p>
            </div>
            {/* </Link> */}
            {/* <Link to="/personal/center/my/integral"> */}
            <div onClick={() => goNavigate('/personal/center/my/integral', 'integral')} className="vip-center-right-message-info">
              <p>{wallet?.points || 0}</p>
              <p>积分</p>
            </div>
            {/* </Link> */}
            {/* <Link to="/personal/center/my/coupon"> */}
            <div onClick={() => goNavigate('/personal/center/my/coupon', 'coupon')} className="vip-center-right-message-info">
              <p> {wallet?.vouchers || 0}</p>
              <p>优惠券</p>
            </div>
            {/* </Link> */}
            {/* <Link to="/personal/center/my/bank"> */}
            <div onClick={() => goNavigate('/personal/center/my/bank', 'bank')} className="vip-center-right-message-info">
              <p>1</p>
              <p>银行卡</p>
            </div>
            {/* </Link> */}
          </div>
        </div>
      </div>
      <div className="vip-bottom">
        <div className="vip-bottom-one vip-center-right">
          <div style={{ width: '90%', margin: '0 auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2 className="vip-bottom-title vip-center-right-title">我的订单</h2>
            <Link to="/personal/center/my/order/0"><Button>全部订单</Button></Link>
          </div>
          <div className="vip-bottom-right-message vip-center-right-message">
            <Link style={{ marginRight: '6%' }} to="/personal/center/my/order/1">
              <div className="vip-bottom-right-message-one">
                <div className="vip-bottom-right-message-one-img">
                  <img src={icons['payment']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </div>
                <p>待付款</p>
              </div>
            </Link>

            <Link style={{ marginRight: '6%' }} to="/personal/center/my/order/2">
              <div className="vip-bottom-right-message-one">
                <div className="vip-bottom-right-message-one-img">
                  <img src={icons['deliver goods']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </div>
                <p>待发货</p>
              </div>
            </Link>
            <Link style={{ marginRight: '6%' }} to="/personal/center/my/order/3">
              <div className="vip-bottom-right-message-one">
                <div className="vip-bottom-right-message-one-img">
                  <img src={icons['receiving goods']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </div>
                <p>待收/取货</p>
              </div>
            </Link>
            <Link style={{ marginRight: '6%' }} to="/personal/center/my/order/4">
              <div className="vip-bottom-right-message-one">
                <div className="vip-bottom-right-message-one-img">
                  <img src={icons['completed']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </div>
                <p>已完成</p>
              </div>
            </Link>
            <Link style={{ marginRight: '6%' }} to="/personal/center/after/sale">
              <div className="vip-bottom-right-message-one">
                <div className="vip-bottom-right-message-one-img">
                  <img src={icons['after sales']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </div>
                <p>售后申请</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="vip-bottom-one vip-center-right" style={{ marginLeft: '3%' }}>
          <div style={{ width: '90%', margin: '0 auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2 className="vip-bottom-title vip-center-right-title">我的活动</h2>

            <Link to="/personal/center/my/enroll"><Button>全部活动</Button></Link>

          </div>
          <div className="vip-bottom-right-message vip-center-right-message">
            {enrollItem.length > 0 &&
              enrollItem?.map(el =>
                <div className="vip-bottom-right-message-active">
                  <div className="vip-bottom-right-message-active-img">
                    <img src={el.logo || icons['Default Avatar']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </div>
                  <div className="vip-bottom-right-message-active-info">
                    <h5 style={{ textAlign: 'left' }}>{el?.tag}</h5>
                    <p style={{ width: '100%', textAlign: 'left' }}>{el?.name} </p>
                  </div>
                </div>
              )
            }

          </div>
        </div>
      </div >
      {
        isLogin &&
        <Login onLogin={() => { getData(); setIsLogin(false) }} onCancel={() => { setIsLogin(false); setLoadding(false) }} />
      }
    </div >
  )
}