import { Net } from '../../utils/net';
import { _pageData } from '../common_i';
import { MemberCardsContent } from './member';

export interface CollectionContent {
  query: MemberCardsContent;
  nfr: {
    name: string;
    version: string;
    soldCount: number;
    releaseCount: number;
    creator: string;
    releaseTime: string;
    publisher: string;
  };
}

export interface MyCollectionContent {
  //查询详情用orderId
  orderId: string;
  accountName: string;
  // 购买的藏品id
  nftId: number;
  // 藏品hash
  hash: string;
  // 藏品图片
  fileUrl: string;
  // 藏品名称
  name: string;
  // 生成时间
  createTime: string;
  // 藏品id
  serialId: string;
  // 创作者logo
  creatorLogo: string;
  // 创作者名称
  creatorName: string;
  // 发售方logo
  publisherLogo: string;
  // 发送方名称
  publisherName: string;
  // 发售日期
  releaseTime: string;
  // 发行数量
  releaseCount: number;
  // 合约地址
  contractAddress: string;
  // 交易哈希
  transactionHash: string;
  // 合约协议
  nfrProtocol: string;
  // 认证网络
  nfrIssuer: string;
}

export class CollectionService {
  /** 藏品详情 */
  public static query(goodsId): Promise<CollectionContent> {
    let params = { goodsId };
    return Net('/member/goods/nfr/query', null, { params }).then(
      res => res.data
    );
  }
  /** 下单前询价 */
  public static preReserve(body): Promise<any> {
    return Net('/member/goods/nfr/order/preReserve', body).then(
      res => res.data
    );
  }
  /** 下单 */
  public static reserve(body): Promise<any> {
    return Net('/member/goods/nfr/order/reserve', body).then(
      res => res.data.order
    );
  }
  /** 我的藏品:支持分页 */
  public static myCollection(params?): Promise<_pageData<MyCollectionContent>> {
    return Net('/member/nfr/list', null, { params }).then(res => res.data.list);
  }
  /** 我的藏品详情 */
  public static myCollectionItem(params): Promise<MyCollectionContent> {
    return Net('/member/nfr/query', null, { params }).then(
      res => res.data.query
    );
  }
}
