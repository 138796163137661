/**
 * @author: hukun
 * @Date: 2019-05-24
 * @Time: 16:05
 * @function
 */
import { Net } from '../../utils/net';
import { localStorageEnum } from '../../enum';
const domain = localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]

export class ServiceSystem {
  /**
   * 登录
   * @param account
   * @param password
   */
  public static login(
    account: string,
    password: string
  ): Promise<{
    token: string;
    portraitUrl: string;
    orgName: string;
    domain: string;
  }> {
    let params = {
      account,
      password,
      domain: domain
    };
    return Net('/api/w/m/login', null, { params }).then(res => res.data);
  }

  public static logout() {
    return Net('/api/w/m/logout').then(res => res.data);
  }

  /**
   * 忘记密码
   * @param account
   * @param code
   * @param newPassword
   */
  public static forgetModifyPassword(
    account: string,
    code: string,
    newPassword: string
  ) {
    return Net('/home/changePasswordByCode', null, {
      params: { account, code, newPassword },
    }).then(res => res.data);
  }

  /**
   * 获取验证码
   * @param account
   * @param phone
   * @param type
   */
  public static getVerifyCode(
    account: string,
    phone: string,
    type: string
  ) {
    return Net('/api/w/m/verifyCode', null, {
      params: { account, phone, type, domain },
    }).then(res => res.data);
  }

  /**
   * token 登录
   * @param token
   */
  public static tokenLogin(token: string, domain: string) {
    return Net('/home/loginWithToken', null, {
      params: { token, domain },
    }).then(res => res.data);
  }

  /**
   * 注册
   * @param params
   * @param body
   */
  public static register(
    params: { phone: string, code: string; password: string; domain?: any, nickName: string },
  ) {
    params.domain = domain;
    return Net('/api/w/m//register', null, { params }).then(res => res.data);
  }

  /**
   * 是否已经绑定微信
   * @param phone
   * @param code
   */
  public static wxBind(code: string, phone: string) {
    return Net('/home/wxRegister', null, {
      params: { phone, code, domain },
    }).then(res => res.data);
  }

  /**
   * 微信注册
   * @param phone
   * @param code
   * @param password
   */
  public static wxRegister(code: string, phone: string, password: string) {
    return Net('/home/wxRegister', null, {
      params: { phone, code, password, domain },
    }).then(res => res.data);
  }

  /**
   * 获取微信config参数
   * @param url
   * @param domain
   */
  public static getWxConfigParams(url: string) {
    return Net('/home/wxJdkAuth', null, { params: { domain, url } }).then(
      res => res.data.config
    );
  }
}
