import * as React from 'react';
import './index.css'
import { ServiceTicket } from '../../service/ticket'
import { Spin } from 'antd'
interface TitleList {
  onChange: (key: number) => void
}

export function Recommend(prop: TitleList) {
  let { onChange } = prop
  const [sliderList, setSliderList] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  let background = ''
  thmem?.images?.map((el: any) => {
    if (el.imageType == 9) {
      background = el.imageUrl
    }
  })
  React.useEffect(() => {
    function init() {
      ServiceTicket.getHomeSlicer(2).then(res => {
        setSliderList(res)
      }).finally(() => {
        setLoading(false)
      })
    }
    init();
  }, []);


  return (
    <Spin spinning={loading}>
      <div className="recommend">
        <div className="recommend-banner" onClick={() => onChange(2)} style={{ backgroundImage: `url(${background})` }}>
          <h1 className="title">数字藏品</h1>
          <h1 className="see-more">查看更多</h1>
        </div>
        <div className="recommend-goods">
          {
            sliderList?.map((el: any) =>
              <div className="goods" style={{ width: (100 / Number(sliderList.length) - 1) + '%' }}>
                <div className="goods-img">
                  <img src={el.url} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="" />
                </div>
                {/* <div className="goosd-name"></div>
                <div className="goods-price">
                  <p className="moeny">￥ 39.00</p>
                  <div className="hyj">
                    <img src="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" />
                  </div>
                  <p className="invalid">$1440..</p> 
                </div>*/}
              </div>
            )
          }
        </div>
        <div className="recommend-banner" onClick={() => onChange(3)} style={{ backgroundImage: `url(${background})` }}>
          <h1 className="title">周边商品</h1>
          <a className="see-more">查看更多</a>
        </div>
        <div className="recommend-goods">
          {
            sliderList?.map((el: any) =>
              <div className="goods" style={{ width: (100 / Number(sliderList.length) - 1) + '%' }}>
                <div className="goods-img">
                  <img src={el.url} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="" />
                </div>
                {/* <div className="goosd-name"></div>
                <div className="goods-price">
                  <p className="moeny">￥ 39.00</p>
                  <div className="hyj">
                    <img src="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" />
                  </div>
                  <p className="invalid">$1440..</p> 
                </div>*/}
              </div>
            )
          }
        </div>
      </div>
    </Spin>
  );
}