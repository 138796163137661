import * as React from 'react';
import { Modal, message, Pagination } from 'antd'
import { ServiceTicket } from '../../../service/ticket';
import utils from '../../../utils';
import { ServiceVoucher } from '../../../service/voucher';
import { sessionStorageEnum } from '../../../enum';
import { ServiceRealName } from '../../../service/member/realName';
import './index.css';
import { deduplication } from '../../../utils/tool';
import { NoData } from '../../../component/noData';

const QRCode = require('../../../assets/icon/QR-code.png');

let num = 0;

async function handlerScanEnd(str): Promise<any> {
  let res: any = '';
  try {
    res = window.atob(str);
    res = JSON.parse(res);
  } catch (e) {
    try {
      if (num > 3) {
        return;
      }
      num += 1;
      if (str.includes('http')) {
        return handlerScanEnd(
          new window.URLSearchParams(str.split('?')[1]).get('json')
        );
      }
    } finally {
      message.error('二维码不正确!');
    }
  }
  try {
    const object: any = res;
    const result = await ServiceRealName.validateQR({
      rowId: res.rowId,
      colId: res.colId,
      ticketId: res.ticketId,
      tccSectionId: res.tccSectionId,
      code: res.code,
    });
    object.seatName = result.seatName;
    if (result.validate) {

    } else {
      post(object);
    }
  } catch (err: any) {
    message.error(err.message);
  }
}

async function post(params: any): Promise<void> {
  try {
    const end = await ServiceVoucher.activeQR({
      rowId: params.rowId,
      colId: params.colId,
      ticketId: params.ticketId,
      tccSectionId: params.tccSectionId,
      code: params.code,
      validate: '',
    });
    const o = Object.assign(
      {},
      {
        scanInfo: params,
        ticketInfo: end,
        isShowGifts: end.goods.length !== 0,
        seatName: end.seatName,
      }
    );
    const t = +new Date();
    sessionStorage.setItem(t.toString(), JSON.stringify(o));
    // utils.jumpPage(ROUTER_NAME.ACTIVATION.path, { p: t });
  } catch (err: any) {
    message.error(err.message);
  }
}

async function postPWD(password: string, params: any): Promise<any> {
  try {
    const res = await ServiceVoucher.activeQR({
      rowId: params.rowId,
      colId: params.colId,
      ticketId: params.ticketId,
      tccSectionId: params.tccSectionId,
      code: params.code,
      validate: password,
    });
    const object = Object.assign(
      {},
      {
        scanInfo: params,
        ticketInfo: res,
        isShowGifts: res.goods.length !== 0,
        seatName: params.seatName,
      }
    );
    const t = +new Date();
    sessionStorage.setItem(t.toString(), JSON.stringify(object));
    // utils.jumpPage(ROUTER_NAME.ACTIVATION.path, { p: t });
    return Promise.resolve('ok');
  } catch (err: any) {
    message.error(err.message);
  }
}

interface LastMyTicketState {
  tickets: any;
  show: boolean;
  current: string;
  loading: boolean;
  showInfo: boolean;
  data: any[];
  pageSize: any;
  totalElements: number;
  height: number;
}

function LastMyTicket(): JSX.Element {
  const [show, setShow] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [showInfo, setShowInfo] = React.useState<boolean>(false)
  const [chooseInfo, setChooseInfo] = React.useState<string>('')
  const [data, setData] = React.useState<any>([])
  const [tickets, setTickets] = React.useState<any>([])
  const [pageNum, setPageNum] = React.useState<number>(1)
  const [pageSize, setPageSize] = React.useState<number>(5)
  const [pageNumMin, setPageNumMin] = React.useState<number>(1)
  const [pageSizeMin, setPageSizeMin] = React.useState<number>(5)
  const [totalElements, setToatlElement] = React.useState<number>(0)
  const [totalElementsMin, setToatlElementMin] = React.useState<number>(0)

  function alertInfo(len): void {
    if (len > 0) {
      Modal.confirm({
        title: '请提前保存电子球票',
        content: '截屏保存或者长按保存到手机相册，以确保比赛日当天流畅通行',
        okText: '确认',
        cancelText: '取消',
        bodyStyle: { margin: '10px' },
      })

    }
  }

  function getData(pageNum, pageSize) {
    window.scrollTo(0, 0)
    setLoading(true)
    ServiceTicket.getTicketListLast('true', pageNum, pageSize)
      .then(res => {
        setTickets(res.content)
        setPageNumMin(pageNum)
        setPageSizeMin(pageSize)
        setToatlElementMin(res.totalElements)
      })
      .catch(e => {
        message.error(e.message);
      }).finally(() => {
        setLoading(false)
      })
  }
  function getData1(pageNum, pageSize) {
    window.scrollTo(0, 0)
    setLoading(true)
    ServiceTicket.getTicketListLast('false', pageNum, pageSize)
      .then(res => {
        setData(res.content)
        setPageNum(pageNum)
        setPageSize(pageSize)
        setToatlElement(res.totalElements)
      })
      .catch(e => {
        message.error(e.message);
      }).finally(() => {
        setLoading(false)
      })
  }

  React.useEffect((): void => {
    function getData2() {
      ServiceTicket.getTicketListLast()
        .then(res => {
          setTickets(res.content)
          setToatlElement(res.totalElements)
          setData(res.content)
          res.content && alertInfo(res.content.length);
        })
        .catch(e => {
          message.error(e.message);
        }).finally(() => {
          setLoading(false)
        })
    }

    (async function init(): Promise<void> {
      try {
        const code = sessionStorage.getItem(
          sessionStorageEnum.GIFT_CARD_ACTIVATION
        );
        if (code) {
          sessionStorage.removeItem(sessionStorageEnum.GIFT_CARD_ACTIVATION);
          await handlerScanEnd(code);
        } else {
          await getData2()
          await getData1(1, pageSize)
        }
      } catch (e: any) {
        setLoading(false)
        message.error(e.message);
      }
    })();
  }, []);

  function openPopup(value) {
    setChooseInfo(value)
    setShow(true)
  }


  switch (tickets.length > 0 || data.length > 0) {
    case false: return (<NoData show={true} />); break;
    case true: return (
      <div style={{ width: '100%', textAlign: 'left' }}>
        <h1 style={{ margin: '20px 1.6%' }}>本人球票</h1>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'start', flexWrap: 'wrap', alignItems: 'center' }}>
          {
            tickets?.map((r, index) =>
              <div
                key={index + r.ticketName + 'true'}
                className='ticket-card'
                onClick={() => openPopup(r.url)}
              >
                <div className='ticket-card-left'>
                  <img src={r.logo} />
                </div>
                <div style={{ width: '100%' }}>
                  <div style={{ height: '50px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '16px' }}>{r.ticketName}</div>
                  <div className='ticket-card-right'>
                    <div
                      style={{
                        color: '#777676',
                        fontWeight: 'normal',
                        marginTop: '20px',
                      }}
                    >
                      {r.seatName}
                    </div>
                    <img src={QRCode} />
                  </div>
                </div>
              </div>
            )
          }
          <div className='goods-pagination'>
            <Pagination
              pageSize={pageSizeMin}
              size="small"
              current={pageNumMin}
              total={totalElementsMin}
              onChange={(page, pageSize) => { getData(page, pageSize) }}
              onShowSizeChange={(current, size) => { getData(current, size) }}
            />
          </div>
        </div>
        <h1 style={{ margin: '20px 1.6%' }}>他人球票</h1>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'start', flexWrap: 'wrap', alignItems: 'center' }}>
          {
            data?.map((r, index) =>
              <div
                key={index + r.ticketName + 'false'}
                style={{ fontSize: '12px', width: '18%', marginLeft: '1.6%', background: '#f5f5f5' }}
              >
                <div style={{ margin: '5px auto', width: '90%', padding: '10px', borderRadius: '15px', background: '#fff' }}>
                  <div style={{ width: '100%', padding: '2px 0' }}>{r.ticketName}</div>
                  <div style={{ width: '100%', padding: '2px 0' }}>{r.contactsName}</div>
                  <div style={{ width: '100%', padding: '2px 0' }}>{r.seatName}</div>
                  <div style={{ width: '100%', padding: '2px 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p>{r.ticketName}</p>
                    <p>{r.price}</p>
                  </div>
                </div>
              </div>
            )
          }
          <div className='goods-pagination'>
            <Pagination
              pageSize={pageSize}
              size="small"
              current={pageNum}
              total={totalElements}
              onChange={(page, pageSize) => { getData1(page, pageSize) }}
              onShowSizeChange={(current, size) => { getData1(current, size) }}
            />
          </div>
        </div>
        <Modal
          title='电子球票'
          visible={show}
          footer={null}
          onCancel={() => { setChooseInfo(''); setShow(false) }}
        >
          <div style={{ width: '100%', backgroundColor: 'white' }}>
            <img
              src={chooseInfo}
              style={{ maxWidth: '100%', backgroundColor: '#f5f5f5', }}
            />
          </div>

        </Modal>
      </div>
    );
  }
}

export default LastMyTicket
