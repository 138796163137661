import * as React from 'react';
import './index.css'
import { ServiceTicket } from '../../../service/ticket'
import { useLocation, useNavigate } from 'react-router-dom'
import TicketBuyer from '../../ticketbuyer/index'
import { message, Spin } from 'antd';
import Gift from './gift'
import Login from '../../loginHome/index'
import { Net } from '../../../utils/net';
import Util from '../../../utils';

const maidiao = require('../../../assets/image/zuowei.svg');
const directionImage = require('../../../assets/image/direction.png');
function PurchaseTickets() {

  const [detail, setDetail] = React.useState<any>({})
  const [sections, setSections] = React.useState<any>([])
  const [svg, setSvg] = React.useState<any>([])
  const [seatSvg, setSeatSvg] = React.useState<any>([])
  const [info, setInfo] = React.useState<any>([])
  const [ticket, setTicket] = React.useState<any>([])
  const [locations, setLocations] = React.useState<any>([])
  const [count, setCount] = React.useState<number>(1)
  const [isChoosePeople, setIsChoosePeople] = React.useState<boolean>(false)
  const [chooseKey, setChooseKey] = React.useState<number>(-1)
  const [isInfo, setIsInfo] = React.useState<boolean>(true)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [isChooseGift, setIsChooseGift] = React.useState<boolean>(true)
  const [isLogin, setIsLogin] = React.useState<boolean>(false)
  const [price, setPrice] = React.useState<any>(undefined)
  let svgBox: any = null;
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const location = useLocation()
  const navigate = useNavigate()
  const state: any = location.state;

  React.useEffect(() => {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    async function init() {
      ServiceTicket.getTicketInfo(state.id).then(async res => {
        setDetail(res.ticket)
        let { sections = [] } = res.ticket
        setTicket(res.ticket)
        setCount(res.ticket.minimumAmount || 1)
        sections?.map(el => {
          el['num'] = res.ticket.minimumAmount || 1
          el['nextStep'] = 0
        })
        setSections(res.ticket?.sections)
        const svgStr = await ServiceTicket.getDomStr(
          (res.ticket.stadiumSvgUrl?.replace('http', 'https'))
        );
        setSvg(svgStr)
      }).finally(() => {
        setLoading(false)
      })

    }
    init()
  }, [])

  let backgroundImage = ''
  thmem?.images?.map((el: any) => {
    if (el.imageType === 7) {
      backgroundImage = el.imageUrl
    }
  })
  function changeNum(index, type) {
    if (type === 'plus') {
      if (detail.maximumAmount) {
        if (sections[index].num < detail.maximumAmount) {
          setCount(sections[index].num + 1)
          sections[index].num += 1
        } else {
          message.error('购票人数最多为' + detail.maximumAmount)
        }
      } else {
        setCount(sections[index].num + 1)
        sections[index].num += 1
      }


    } else {
      if (sections[index].num <= (detail.minimumAmount || 1)) {
        message.error('购票人数最少为' + (detail.minimumAmount || 1))

      } else {
        setCount(sections[index].num - 1)
        sections[index].num -= 1
      }
    }
    setSections([...sections])
  }
  function setStep(price) {
    sections.map(el => {
      if (el.price == price) {
        el.nextStep = 1
        setCount(el.num)
      } else {
        el.nextStep = 0
      }
    })
    setIsInfo(false)
    setSections([...sections])
  }

  async function clickSvg(ev) {
    try {
      const id = ev.target.getAttribute('id');
      if (id) {
        const res = await ServiceTicket.querySectionSvg(state.id, id);
        if (Object.keys(res).length > 0) {
          chooseSeat(res.section.svgUrl, id)
          setInfo(res)
          setStep(res?.section?.price)
        }
      }
    } catch (e: any) {
      message.error(e.message)
      // alert('意外的错误!'); // 暂时不弹出这个错误
    }
  }
  async function chooseSeat(svgUrl, svgId) {
    try {
      const [svg] = await Promise.all([
        ServiceTicket.getDomStr(svgUrl),
      ]);
      setSeatSvg(svg)
      // this.setState({ data: info, svg });
      // if (info.section.totalSeats - info.invalidSeats.length < num) {
      //   message.success('您选择的区域座位数量,已经不够票数量了,请重新选择!');
      // }
      if (info.section?.svgId !== svgId) {
        setLocations([])
        //   this.setState({ locations: locations || [] });
      }
    } catch (err: any) {
      message.error(err.message);
    }
  }

  function addCount(svgContainer) {
    const svgTarget = svgContainer.querySelector('svg');
    if (!(svgTarget && svgContainer)) {
      return;
    }
    //设置不可选区域的颜色
    for (const item of svgTarget.children) {
      if (item.getAttribute('fill') === '#888888') {
        item.setAttribute('fill', '#eeeeee');
      }
    }

    for (const item of sections) {
      const dom = document.getElementById(`p${(item as any).name}`);
      dom && (dom.innerHTML = (item as any).count);
    }
  }

  function renderSvg(r, isClear?) {
    // 渲染 svg 在 innerHTML 后面, 这样看起来 innerHTML 是同步的
    const images = r.getElementsByTagName('image');
    const svg = r.getElementsByTagName('svg')[0];
    if (!svg || images.length === 0) return;

    const invalidSeats = info.invalidSeats?.map(v => v.svgId);

    // 随机选座用到
    let selectdSears: number[] = [];
    if (isClear) {
      selectdSears = [];
    } else {
      selectdSears = locations?.map(v => v.id);
    }
    const { seatSelectedPicture } = info.section;
    const { seatPicture } = info.section;
    svg.setAttribute('style', 'width:1380px;height: 880px');

    for (const img of images) {
      const id: number = img.getAttribute('id');
      if (invalidSeats.includes(id)) {
        img.setAttribute('xlink:href', maidiao);
      } else if (selectdSears.includes(id)) {
        img.setAttribute('xlink:href', seatSelectedPicture);
      } else {
        img.setAttribute('xlink:href', seatPicture);
      }
    }
  }

  function selectSeat(ev) {
    const {
      seatSelectedPicture,
      seatPicture,
      price,
      name,
      stuPrice,
    } = info.section;

    const getL = id => {
      const e = id.indexOf('_x5D');
      const l = id.substring(5, e).split('-');
      return `${name}区${l[0]}排${l[1]}座`;
    };

    if (ev.target.tagName.toLocaleString() === 'image') {
      const id = ev.target.getAttribute('id');
      const href = ev.target.getAttribute('xlink:href');

      if (href === maidiao) {
        alert('该座位已经被抢走了哦!');
      } else if (href === seatSelectedPicture) {
        ev.target.setAttribute('xlink:href', seatPicture);
        removeLoction({ id, money: price, stuPrice, location: getL(id) });
      } else {
        if (locations.length === count) {
          return message.error(
            `您已经选择了${locations.length}个座位了哦!`
          );
        }
        ev.target.setAttribute('xlink:href', seatSelectedPicture);
        addendLoction({ id, money: price, stuPrice, location: getL(id) });
      }
    }
  }

  function addendLoction(item) {
    if (Array.isArray(item)) {
      // @ts-ignore
      locations = item;
    } else {
      locations.push(item);
    }
    setLocations([...locations])
  }

  function removeLoction(item) {
    const arr = locations;
    for (const [k, v] of Object.entries(locations)) {
      if ((v as any).id === item.id) {
        locations.splice(parseInt(k), 1);
      }
    }
    setLocations([...locations])

  }
  // 选人回来
  function choosePeople(item) {
    setIsChoosePeople(false)
    if (item.length > 0) {
      item?.map((el, index) => {
        locations[index] = { ...locations[index], ...el }
      })
    } else {
      locations[chooseKey] = { ...locations[chooseKey], ...item[0] }
    }
    setLocations([...locations])

  }

  function goPlaceOrder(index) {
    let body = {} as any
    if (detail.saleType === 1) {
      if (sections[index].num > locations.length) {
        message.warn(`请选择${sections[index].num}个座位`)
        return
      } else {
        let unChoose = locations.filter((i: any) => !i.entity?.name);
        if (unChoose.length > 0) {
          message.warn(`请选择${sections[index].num}个购票人`)
          return
        }
        if (info.goods.lenght > 0) {
          let unGift = locations.filter((i: any) => !i.gifts);
          if (unGift.length > 0) {
            message.warn(`请选择${sections[index].num}个赠品`)
            return
          }
        }

      }
      body = {
        ticketId: state.id,
        peoples: locations,
        sectionId: info.section.svgId,
        gifts: info.goods.length > 0 ? locations?.map(el => el.gifts) : [],
        selectable: ticket.selectable,
        ticketForms: ticket.forms,
      }
    } else {
      if (sections[index].num > locations.length) {
        message.warn(`请选择${sections[index].num}个座位`)
        return
      }
      body = {
        ticketId: state.id,
        peoples: locations,
        sectionId: info.section.svgId,
        gifts: info.goods.length > 0 ? locations?.map(el => el.gifts) : [],
        selectable: ticket.selectable,
        ticketForms: ticket.forms,
        isGift: true,
      }
    }
    sessionStorage.setItem('placeOrder', JSON.stringify(body))
    navigate('/ticket/placeOrder')
    // navigate('/placeOrder',{state:{body}})
  }
  // 选赠品
  function chooseGift(item) {
    locations[chooseKey]['gifts'] = item
    setLocations([...locations])
    setIsChooseGift(true)
    setChooseKey(-1);
  }

  function checkAccount(price) {
    setStep(price)
    if (Util.IsLogin()) {
      Net(`/ticket/checkAccount?ticketId=${state.id}`)
        .then(res => {
          if (res.message.length > 0) {
            let result = res.message.split('\n');
            message.error('无法购买')
          } else {
            if (typeof detail.totalAmount === 'number' && typeof detail.leftAmount === 'number') {
              if (detail.leftAmount <= 0) {
                message.info('秒杀票品以售尽！')
                return
              }
              setStep(price)
            } else {
              setStep(price)
            }
          }
        }).catch(err => {
          message.error(err.message)
          console.log(err);
        });
    } else {
      setPrice(price)
      setIsLogin(true)
    }
  }

  return (
    <Spin spinning={loading}>
      <div className="container" style={{ width: '100%', minHeight: '100vh', position: 'relative', background: thmem?.background }}>
        {
          isChooseGift ?
            <div className="purchase-box">
              <div className="purchase-box-left">
                {
                  isInfo &&
                  <div className="active-detail-message">
                    <div
                      dangerouslySetInnerHTML={{ __html: detail.description }}
                    />
                  </div>
                }
                {!isInfo &&
                  <div className="active-detail-message">
                    {seatSvg.length <= 0 &&
                      <div
                        className='svg'
                        style={{ width: '95%', marginTop: '-7%' }}
                        onClick={ev => clickSvg(ev)}
                        ref={r => {
                          if (r) {
                            r.innerHTML = svg;
                            addCount(r);
                          }
                        }}
                      />
                    }

                    {seatSvg.length > 0 &&
                      <div className="choose-seat">
                        <div className="choose-seat-title">
                          <p>{info.section?.name}区</p>
                          <p onClick={() => { setStep('undefind'); setSeatSvg([]); setInfo({}) }} className="back-button">更换区域</p>
                        </div>
                        <div
                          className='svgBox'
                          onClick={ev => selectSeat(ev)}
                          ref={r => {
                            if (r) {
                              svgBox = r;
                              r.innerHTML = seatSvg;
                              renderSvg(r);
                            }
                          }}
                        />
                      </div>
                    }
                  </div>
                }




              </div>
              <div className="purchase-box-right">
                <div className="purchase-right-title">{detail.name}</div>
                {
                  sections?.map((el, index) =>
                    <div className="ticket-area" onClick={() => { isInfo && checkAccount(el.price) }}>
                      <div className="area-title">
                        <div className="title-left">
                          <img src={el.picture} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        </div>
                        <p className="title-right">￥{el.price}</p>
                      </div>
                      <div style={{ display: el.nextStep > 0 ? 'block' : 'none' }}>
                        <div className="area-title">
                          <div className="cart-num">
                            <p
                              onClick={
                                () => changeNum(index, 'minus')
                              }
                            >-</p>
                            <input
                              value={el.num}
                              type="text" />
                            <p
                              onClick={() => changeNum(index, 'plus')
                              }
                            >+</p>
                          </div>
                          <p className="title-right" style={{ flex: 1, textAlign: 'right' }}>小计 ￥{el.num * el.price}</p>
                        </div>


                        <h3 style={{ textAlign: 'left' }}>请在左侧选择区域</h3>
                        {
                          JSON.stringify(info) != '{}' &&
                          <div className="choose-location">
                            <div className="location-title">{info?.section?.name}区</div>
                            {
                              locations.map((el, index) =>
                                <div className="location-list">
                                  <p>{index + 1}</p>
                                  <p className="location-name">{el.location?.split('区')[1]}</p>
                                  {
                                    detail.saleType === 1 &&
                                    <p onClick={() => { setChooseKey(index); setIsChoosePeople(true) }} className="add-name">{el?.entity?.name || '添加实名购票人'}</p>

                                  }
                                  {
                                    info.goods.length > 0 &&
                                    <p onClick={() => { setChooseKey(index); setIsChooseGift(false) }} className="add-name">
                                      {
                                        el?.gifts ?
                                          el?.gifts.specification1 + ' ' + el?.gifts?.specification2

                                          : '选择赠品'}


                                    </p>
                                  }
                                </div>
                              )
                            }
                            {
                              locations.length > 0 &&
                              <div className="next-ticket-button" onClick={() => goPlaceOrder(index)}>下一步</div>

                            }

                          </div>
                        }

                      </div>
                    </div>
                  )
                }


              </div>
            </div>
            :
            <Gift goods={info.goods[0]} chooseGift={(e) => chooseGift(e)} />
        }

        {
          isChoosePeople &&
          <TicketBuyer ticketId={state.id} people={locations.map(el => el?.entity?.id)} count={locations.length} choosePeople={(e) => choosePeople(e)} onCancel={() => setIsChoosePeople(false)} />
        }
        {
          isLogin &&
          <Login onCancel={() => setIsLogin(false)} onLogin={() => { checkAccount(price); setIsLogin(false) }} />
        }
      </div >
    </Spin>
  );
}
export default PurchaseTickets