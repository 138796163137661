import { useLayoutEffect, useState } from 'react';
//函数防抖(立即执行)
let timeout;
function debounceImmediately(func, wait) {
  return (function () {
    if (timeout) {
      clearTimeout(timeout);
    }
    let exec = !timeout;
    timeout = setTimeout(() => {
      timeout = null;
    }, wait);
    exec && func.apply(null, arguments);
  })();
}
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      // 写个防抖设计 
      debounceImmediately(() => jumpH5(), 1000)
    }
    function jumpH5() {
      if (window.innerWidth <= 500 && window.location.href.indexOf('/w/') !== -1) {
        let url = window.location.pathname.split('/')[1]
        window.location.href = window.location.pathname.split('/')[0] + '/' + url + '/pages/my'
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize()
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}