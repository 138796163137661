import * as React from 'react';
import './index.css'
import { useNavigate } from 'react-router-dom';
import { ServiceMember } from '../../service/member/member';
import { ServiceTicket } from '../../service/ticket/index'
import Util from '../../utils';
import { NavLink as Link } from 'react-router-dom';
import { Spin, Pagination } from 'antd'
import { table } from 'console';
import { NoData } from '../../component/noData';

export default function Goods() {
  const [goodsList, setGoodsList] = React.useState<any>([])
  const [total, setTotal] = React.useState<number>(1)
  const [title, setTitle] = React.useState<string>('全部')
  const [filter, setFilter] = React.useState<any>({
    pageSize: 40,
    pageNum: 1,
    clazzIds: '',
    clazzName: ''
  })
  const [clazzes, setClazzes] = React.useState<any>([])
  const [sliderList, setSliderList] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const domain = localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]
  const NoDataImage2 = JSON.parse(localStorage.getItem('icons') as '');
  let allIcon = JSON.parse(localStorage.getItem('icons') || '{}')
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0)
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    sessionStorage.setItem('headerActive', menuArray?.indexOf('/collection'))
    function init() {
      Promise.all([
        ServiceTicket.getHomeSlicer(6),
        ServiceMember.getGoodsClazz({ domain, itemType: '6' })
      ]).then(res => {
        let clazzes = [{ id: '', name: '全部', selected: false }, ...res[1]];
        setClazzes(clazzes)
        setSliderList(res[0])
      })
        .catch(err => {
          alert(err.message)
        });
      getData({ ...filter, itemType: '6' })
    }
    init()
  }, [])



  function getData(filter) {
    ServiceMember.shopAllList(filter).then(res => {
      setTotal(res.totalElements)
      setGoodsList(res.content)
      setFilter(filter)
    }).finally(() => {
      setLoading(false)
    })

  }
  function hanleFilter(key: any, value: any) {
    filter.pageNum = 1
    filter[key] = value
    setTotal(1)
    setLoading(true)
    getData(filter)
  }

  function goDetail(item) {
    let path = '/goods/digital/collection'
    navigate(path, { state: { goodsId: item.goodsId } })
  }

  return (
    <Spin spinning={loading} >
      <div style={{
        width: '100%', minHeight: '100vh', marginTop: '55px', background: thmem?.background
      }
      }>
        <div className="seripheral-box">
          {
            sliderList?.length > 0 &&
            <div className="seripheral-top">
              {
                sliderList?.map((slider: any) =>
                  <div className="top-img">
                    <img src={slider.url} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="" />
                  </div>
                )
              }

            </div>
          }

          <div className="seripheral">
            <div style={{ width: '80%', margin: '0 auto', display: 'flex', justifyContent: 'center', alignContent: 'flex-start' }}>
              <div className="left-selection">
                <ul className="selection-ul">
                  {
                    clazzes?.map((el: any) =>
                      <li className={filter?.clazzIds == el.id ? 'selection-li selection-li-choose' : "selection-li"} onClick={() => { hanleFilter('clazzIds', el.id); setTitle(el.name) }} key={el.id}>{el.name}</li>
                    )
                  }
                </ul>
              </div>

              <div className="right-goods-list" style={{ overflow: 'hidden' }}>
                {
                  total > 0 ?
                    <div style={{ width: '100%' }}>
                      <div className="title" style={{ color: 'black', fontWeight: 'bold' }}>
                        <h1>{title}</h1>
                        <p style={{ color: '#919191' }}>{total}项</p>
                      </div>
                      <div className="recommend-goods" style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        {
                          goodsList?.map((item: any) => {
                            let price = item.minimumPrice;
                            let fakePrice = item.maximumPrice || item.minimumPrice;
                            if (item.memberDiscountType && item.memberDiscountValue) {
                              if (item.memberDiscountType === 1) {
                                return;
                              } else if (item.memberDiscountType === 3) {
                                price = item.memberDiscountValue;
                              } else {
                                price = price * item.memberDiscountValue * 0.1; //计算打折价格
                              }
                              fakePrice = item.minimumPrice;
                            }
                            let money = item.minimumPrice == 0 ? '限时免费' : Util.toFixed(price);
                            let minmoney = Util.toFixed(fakePrice);
                            let isshow = money === minmoney || minmoney === '0.00'
                            return (
                              <div className="zb-goods goods" key={item.goodsId} onClick={() => goDetail(item)} >
                                <div className="goods-img">
                                  <img src={item.logo} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="" />
                                </div>
                                <p className="goosd-name">{item.name}</p>
                                <div className="goods-price">
                                  <p className="moeny">￥{money}</p>
                                  {
                                    item.memberDiscountType && item.memberDiscountType !== 1
                                      ?
                                      <div className="hyj">
                                        <img src={allIcon['membership price']} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" />
                                      </div>
                                      : null
                                  }
                                  {
                                    !isshow &&
                                    <p style={{ fontSize: '16px', textDecoration: item.memberDiscountType && item.memberDiscountType !== 1 ? 'unset' : 'line-through', }} className="invalid">1440</p>
                                  }
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                  <img src={item.orgInfo.orgLogo} alt="" style={{ width: '15px', height: '15px', margin: '0 5px' }} />
                                  <p style={{ color: '#d8d8d8', fontSize: '12px' }}>{item.orgInfo.orgName}</p>
                                </div>
                              </div>
                            )
                          }
                          )
                        }
                        <div className='goods-pagination'>
                          <Pagination pageSize={40} size="small" total={total} onChange={(e) => hanleFilter('pageNum', e)
                          } />
                        </div>
                      </div>
                    </div>
                    :
                    <div style={{ width: '50%', height: '100%', margin: '0 auto 10%' }}>
                      <img src={NoDataImage2['Default page']} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
