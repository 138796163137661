import { Net } from '../../utils/net';
import { IdAndName, _pageData } from '../common_i';

export interface VoteBody {
  voteId: any;
  myVoteId: any; //用于判断是否投过票了
  name: string;
  title: string;
  startTime: string;
  endTime: string;
  description: string;
  createTime: string;
  logo: string;
  shareAmount: number;
  itemCount: number;
  touchAmount: number;
  top: boolean;
  statusTag: string;
  items: OptionItem[];
}

export interface OptionItem {
  id: number;
  name: string;
  logo: string;
  fakeAmount: number;
  amount: number;
  orgId: number;
  mine: number; //0:没投, 1:已投
  percent: string;
}

export interface MineVoteBody {
  id: any;
  voteId: any;
  name: string;
  title: string;
  description: string;
  createTime: string;
  logo: string;
  top: boolean;
  statusTag: string;
  items: OptionItem[];
  reward: RewardItem;
  orgDomain: string;
  orgId: any;
  orgLogo: string;
  orgName: string;
}

export interface RewardItem {
  title: string;
  description: string;
  button: string;
  lotteryActivityId: number;
  tint: string;
  startTime: string;
  endTime: string;
  lotteryResult: string;
}

export interface OptionPeople {
  name: string;
  portraitUrl: string;
  phone: string;
  itemName: string;
  status: string;
  createTime: string;
}

interface SubmitBody {
  voteId: number | string;
  itemIds: number[];
}
const domain = localStorage.getItem('DOMAIN') || window.location.pathname.split('/')[1]

export class VoteService {
  public static query(voteId: any): Promise<VoteBody> {
    return Net('/member/vote/query', null, {
      params: { voteId, domain },
    }).then(res => res.data.query);
  }

  public static listMine(params = {}): Promise<_pageData<MineVoteBody>> {
    return Net('/member/vote/listMine', null, { params }).then(
      res => res.data.list
    );
  }

  public static queryMine(id): Promise<MineVoteBody> {
    return Net('/member/vote/queryMine', null, { params: { id } }).then(
      res => res.data.query
    );
  }

  public static submit(body: SubmitBody) {
    return Net('/member/vote/submit', body);
  }

  //检查是否能够报名
  public static check(voteId: any) {
    return Net('/member/vote/check', null, { params: { voteId } }).then(
      res => res.data
    );
  }

  public static addShare(voteId) {
    return Net('/member/vote/share', null, { params: { voteId } });
  }
}
