import { promises } from 'dns';
import { Net } from '../../utils/net';
export interface welfareList {
  content: welfareListContent[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  sort: null;
  totalElements: number;
  totalPages: number;
}

export interface welfareListContent {
  description: string;
  image: string;
  myId: number;
  tag: number; //1.已领取 2.待领取 3.不可领取
  ttpId: number;
  ttpType: number;
}
// 

export class ServiceClubbenefits {
  // 选中比赛场次
  public static wfList(params: any): Promise<welfareList> {
    return Net('/member/welfare/list', null, { params }).then(
      res => res.data.list
    );
  }

  public static wfSubmit(ttpType: number, ttpId: number, phone: number) {
    return Net(
      `/member/welfare/submit?ttpType=${ttpType}&ttpId=${ttpId}&phone=${phone}`,
      null
    ).then(res => res.data);
  }
}
