import md5 from 'md5';
import { history } from '../CustomRouter';
import { localStorageEnum, loginStatusEnum, sessionStorageEnum } from '../enum';
import { ServiceAccount } from '../service/account/index'
import { ServiceSystem } from '../service/system/index'

function getObjectType(data: any): string {
  return Object.prototype.toString
    .call(data)
    .split(' ')[1]
    .replace(']', '');
}

/**
 * 一些工具函数
 */
class Util {
  public static isNumber(data: any): boolean {
    return getObjectType(data) === 'Number' && !Number.isNaN(data);
  }

  public static isArray(data: any): boolean {
    return Array.isArray(data);
  }

  public static isFunction(data: any): boolean {
    return getObjectType(data) === 'Function';
  }

  public static isObject(data: any): boolean {
    return getObjectType(data) === 'Object';
  }

  public static isFormData(data: any): boolean {
    return getObjectType(data) === 'FormData';
  }

  public static isObjectEmpty(data: any): boolean {
    return !(Util.isObject(data) && Object.keys(data).length > 0);
  }
  /**
     * 根据日期字符串获取星期几
     * @param dateString 日期字符串（如：2020-05-02）
     * @returns {String}
     */
  public static getWeek(dateString: any) {
    var dateArray = dateString?.split("-")
    if (dateArray.length >= 3) {
      let date = new Date(Number(dateArray[0]), (Number(dateArray[1]) - 1, Number(dateArray[2])));
      return "周" + "日一二三四五六".charAt(date.getDay());
    } else {
      return ''
    }
  };
  public static getMonth(month: any) {
    const Month = {
      '01': '一',
      '02': '二',
      '03': '三',
      '04': '四',
      '05': '五',
      '06': '六',
      '07': '七',
      '08': '八',
      '09': '九',
      '10': '十',
      '11': '十一',
      '12': '十二'
    }

    return Month[month] + '月'
  };
  /**
   * 
   * @param dateString 
   * @returns 
   */
  public static checkEndTime(startTime, endTime) {
    var start = startTime // new Date(startTime.replace(/-/g, "/").replace(/-/g, "/"));
    var end = new Date(endTime.replace(/-/g, "/").replace(/-/g, "/"));
    if (end < start) {
      return false;
    }
    return true;
  };
  /**
     * 根据日期字符串获取某月某天
     * @param dateString 日期字符串（如：2020-05-02）
     * @returns {String}
     */
  public static getMonthDay(dateString: any) {
    var dateArray = dateString?.split("-")
    if (dateArray?.length >= 3) {
      return (Number(dateArray[1]) > 9 ? dateArray[1] : dateArray[1].replace("0", "")) + '月' + (Number(dateArray[2]) > 9 ? dateArray[2] : dateArray[2].replace("0", "")) + '日'
    } else {
      return ''
    }
  };

  /**
   * 滚动到页面顶部
   */
  public static scrollTop() {
    window.scrollTo(0, 0);
  }

  /**
   * 查看登录状态
   * @constructor
   */
  public static IsLogin() {
    return (
      localStorage.getItem(sessionStorageEnum.IS_LOGIN_) ===
      loginStatusEnum.yes
    );
  }

  /**
   * 清除登录信息
   */
  public static removeLoginInfo() {
    localStorage.removeItem(sessionStorageEnum.IS_LOGIN_);
    localStorage.removeItem(localStorageEnum.TOKEN);
    sessionStorage.removeItem(sessionStorageEnum.USER_INFO)
    window.location.reload()
  }

  public static setLoginInfo(token: string) {
    localStorage.setItem(sessionStorageEnum.IS_LOGIN_, loginStatusEnum.yes);
    localStorage.setItem(localStorageEnum.TOKEN, token);
  }

  /**
   * 对密码做简单加密
   * @param password
   */
  public static hashPassword(password: string): string {
    return md5(window.btoa(password));
  }

  /**
   * 用于 React.useState 的setState的合并修改
   * demo   setState(mergeWithState({loading: true}))
   * @param newState
   */
  public static mergeWithState(newState: { [key: string]: any }) {
    return (oldState: any) => Object.assign({}, oldState, newState);
  }

  /**
   * @param path
   * @param params
   */
  public static jumpPage(
    path: string,
    params?: { [key: string]: string | number }
  ): void {
    history.push(params ? `${path}?${Util.serialization(params)}` : path);
  }

  /**
   * @param path
   * @param params
   */
  public static replaceToPage(
    path: string,
    params?: { [key: string]: string | number }
  ): void {
    history.replace(params ? `${path}?${Util.serialization(params)}` : path);
  }

  /**
   * 页面返回
   * @param pageNum
   */
  public static back(pageNum?: number): void {
    if (pageNum) {
      history.go(pageNum);
    } else {
      history.go(-1);
    }
  }

  /**
   * 身份证隐藏部分
   * @param num
   */
  public static formatIdNumber(num: string): string {
    const n = `${num}`;
    try {
      const len = n.length;
      const before = n.substring(0, 5);
      const after = n.substring(len - 4);
      const arr = new Array(len - 5 - 4).fill('*');
      return before + arr.join('') + after;
    } catch (e) {
      return n;
    }
  }

  /**
   * base64 ===> file
   * 将base64转换为文件
   * @param dataurl
   * @param filename
   */
  public static dataURLtoFile(dataurl: any, filename: any): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.${mime.split('/')[1]}`, {
      type: mime,
    });
  }

  /**
   * 补零
   * @param n
   */
  public static zero(n: number): string {
    return n < 10 ? `0${n}` : `${n}`;
  }

  /**
   * 将数字转成字符分钟显示
   * @param n
   */
  public static toTimeMS(n: number): string {
    const m = Util.zero(Math.floor(n / 60));
    const s = Util.zero(Math.floor(n % 60));
    return `${m} : ${s}`;
  }

  /**
   * 保留2位小数
   * @param num
   * @param fraction
   */
  public static toFixed(num: number | string, fraction = 2): string {
    if (typeof num === 'number' && !Number.isNaN(num)) {
      return num.toFixed(fraction);
    }
    const fnum = parseFloat(num as string);
    if (!Number.isNaN(fnum)) {
      return fnum.toFixed(2);
    }
    return '0.00';
  }

  /**
   * YY-MM-DD hh:mm:ms
   * @param time
   */
  public static toDateString = (time: any): string => {
    if (time) {
      const date = new Date();
      const y = date.getFullYear();
      const m = Util.zero(date.getMonth() + 1);
      const d = Util.zero(date.getDate());
      const h = Util.zero(date.getHours());
      const mins = Util.zero(date.getMinutes());
      const ms = Util.zero(date.getMilliseconds());
      return `${y}-${m}-${d} ${h}:${mins}:${ms}`;
    }
    return '无';
  };

  /**
   * 对对象参数进行序列化为url字符串参数
   * @param param
   */
  public static serialization(param: {
    [key: string]: string | number;
  }): string {
    if (!Util.isObject(param)) {
      return '';
    }
    const array: string[] = [];
    for (const [key, value] of Object.entries(param)) {
      array.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(`${value}`)}`
      );
    }
    return array.join('&');
  }

  /**
   * 获取url上的参数
   * @param str
   */
  public static getUrlParams(
    str: string = window.location.search
  ): URLSearchParams {
    // TODO: 有时间修改一下, 吧返回参数改为 {[key:string]:string}
    // const urlSearch = new window.URLSearchParams( str.split( "?" )[ 1 ] );
    // const params:{[key:string]:string} = {};
    // urlSearch.forEach((value, key) => {
    //   params[key] = value
    // });
    // return params;
    return new window.URLSearchParams(str.split('?')[1]);
  }

  /**
   * 等待一段时间
   * @param time
   */
  public static sleep(time: any): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout((): void => {
        resolve(0);
      }, time);
    });
  }

  /**
   * 是否跳转到登录页面的弹窗
   */
  public static goLoginConfirm(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Modal.alert(
      //   '提示',
      //   '该页面有些信息需要登录才能正常浏览,需要前往登录页面吗?',
      //   [
      //     {
      //       text: '留下来',
      //       onPress: args => reject(),
      //     },
      //     {
      //       text: '前往',
      //       onPress: args => resolve(args),
      //     },
      //   ]
      // );
    });
  }

  /**
   * 取出base64 , 后面的部分
   * @param base64
   */
  public static getBase64Body(base64: string) {
    return base64.split(',')[1];
  }

  /**
   * 取出file 的type
   * @param file
   */
  public static getFileType(file: File) {
    return file.type.split('/')[1];
  }

  //检查是否已登录,如未登录有token则使用token登录,如果token也没有,
  //调用home/detailj接口会返回未登录状态,然后前端弹框提示
  public static async initLoginStatus(): Promise<boolean> {
    try {
      if (Util.IsLogin()) {
        return true;
      }
      let isLogin = false;
      const token = localStorage.getItem(localStorageEnum.TOKEN);
      const domain = localStorage.getItem(localStorageEnum.DOMAIN) as string;

      if (token && token.length > 10) {
        const res = await ServiceSystem.tokenLogin(token, domain);
        Util.setLoginInfo(res.token);
        localStorage.setItem('homeDomain', res?.domain);
        isLogin = true;
      }
      const info = await ServiceAccount.getAccountDetail();
      // 如果没有登录 detail 就是空的
      if (info.detail) {
        Util.setLoginInfo(info.token);
        isLogin = true;
      }
      if (isLogin) {
        // proxyLoginWithJump({ isTheHomePage: true });
      }
      return isLogin;
    } catch (e) {
      // HandlerError.alert(e.message, 'fail');
      // HandlerError.log(e);
      return false;
    }
  }

  public static showtime(dateEnd: string) {
    let nowtime = new Date()  //获取当前时间
    let endtime = new Date(dateEnd.replace(/-/g, "/"));  //定义结束时间
    // ("2018-02-05 15:55".replace(/-/g, "/")) //safari浏览器中必须使用replace替换掉-
    let lefttime = endtime.getTime() - nowtime.getTime(),  //距离结束时间的毫秒数
      day = Math.floor(lefttime / (1000 * 60 * 60 * 24)),  //计算天数
      hour = Math.floor(lefttime / (1000 * 60 * 60) % 24),  //计算小时数
      minute = Math.floor(lefttime / (1000 * 60) % 60),  //计算分钟数
      second = Math.floor(lefttime / 1000 % 60);  //计算秒数
    return {
      day,
      hour: hour < 10 ? '0' + hour : hour,
      minute: minute < 10 ? '0' + minute : minute,
      second: second < 10 ? '0' + second : second
    };  //返回倒计时的字符串
  }
  // 计算小时
  public static calculationTime(dateEnd: string) {
    let nowtime = new Date()  //获取当前时间
    let endtime = new Date(dateEnd.replace(/-/g, "/"));  //定义结束时间
    var ms = nowtime.getTime() - endtime.getTime();
    if (ms < 0) return 0;
    return Math.floor(ms / 1000 / 60 / 60)
  }
  public static getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}


export default Util;
