import * as React from 'react';
import './index.css'
import Util from '../../../utils/index'
import { QuizService, GroupItem } from '../../../service/quiz/index'
import { setgroups } from 'process';
import { group } from 'console';
import { message } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Login from '../../loginHome/index'
/**
 * 竞猜
 */
// interface QuizProp {
//   item: any,
//   onChange:()=>void
// }
export default function Quiz(prop: any) {
  let location = useLocation()
  const navigate = useNavigate()
  const thmem = JSON.parse(sessionStorage.getItem('thmem') || '{}')
  const Icons = JSON.parse(localStorage.getItem('icons') || '{}')
  const [isLogin, setIsLogin] = React.useState<boolean>(false);
  const [id, setId] = React.useState<any>('')
  const [quiz, setQuiz] = React.useState<any>({})
  const [groups, setGroups] = React.useState<GroupItem[]>([])
  const [points, setPoints] = React.useState<number>();
  const params = useParams()
  React.useEffect(() => {
    // 暂时没有找到更好的办法来控制路由切换导致页面保留上一页滚动位置
    window.scrollTo(0, 0)
    let menuArray = JSON.parse(sessionStorage.getItem('menuArray') as '[]')
    sessionStorage.setItem('headerActive', menuArray?.indexOf('/activity'))
    let state: any = location.state
    function init() {
      QuizService.query(state?.id || params?.id)
        .then(res => {
          //为了给输入框初始值,是数字控件的给初始值0
          res.groups.map(i =>
            i.items.map(p => {
              switch (p.itemType) {
                case 1:
                  p.result = 0;
                  break;
                case 2:
                  p.result = '';
                  break;
                case 3:
                  p.result = '0:0';
                  break;
              }
              return p;
            })
          );
          setQuiz(res)
          setId(state.id)
          setGroups(res?.groups)
          setPoints(res.bet.minimumPoints || 0)
        })
        .catch(err => {
          message.error(err.message)
        });
    }
    init();
  }, []);
  // 单个数字
  function SingleSubtraction(index: number, index1: number) {
    let result = groups[index]?.items[index1].result
    if (Number(result) <= 0) {
      result = 0
    } else {
      result = Number(result) - 1
    }
    groups[index].items[index1].result = result
    setGroups([...groups])
  }
  function SingleAddition(index: number, index1: number) {
    groups[index].items[index1].result = Number(groups[index]?.items[index1].result) + 1
    setGroups([...groups])
  }
  function SingInput(index, index1, value) {
    let result = groups[index]?.items[index1].result
    if (Number(value) <= 0) {
      result = 0
    } else {
      result = value
    }
    groups[index].items[index1].result = result
    setGroups([...groups])
  }
  // 比分
  function Subtraction(index, index1, key) {
    let result = groups[index]?.items[index1].result

    let result1 = result.length > 0 ? result?.split(':')[key] : ''
    let result2 = result.length > 0 ? result?.split(':') : ['', '']
    if (Number(result1) <= 0) {
      result1 = 0
    } else {
      result1 = Number(result1) - 1
    }
    groups[index].items[index1].result = key ? result2[0] + ':' + result1 : result1 + ':' + result2[1]
    setGroups([...groups])
  }
  function Addition(index, index1, key) {
    let result = groups[index]?.items[index1].result
    let result1 = result.length > 0 ? result?.split(':')[key] : ''
    let result2 = result.length > 0 ? result?.split(':') : ['', '']
    result1 = Number(result1) + 1
    groups[index].items[index1].result = key ? result2[0] + ':' + result1 : result1 + ':' + result2[1]
    setGroups([...groups])
  }
  // 单选
  function SingleChoice(index, index1, i) {
    groups[index].items[index1].result = i
    setGroups([...groups])

  }
  // 多选
  function MultipleChoice(index, index1, i) {

    let result = groups[index].items[index1].result || ''
    let key = result ? result?.split('、').indexOf(i) : -1

    if (key == -1) {
      groups[index].items[index1].result = result.length > 0 ? result + '、' + i : i
    } else {
      let result1 = result?.split('、')
      result1.splice(key, 1)
      groups[index].items[index1].result = result1.length > 0 ? result1.join('、') : ''
    }
    setGroups([...groups])
  }

  function debounceHandle(text) {
    if (!Util.IsLogin()) {
      // 未登录
      // setType(1)
      setIsLogin(true)
      // message.error('未登录')
    } else if (text === '您已报名') {
      alert('您已报名')
    } else if (text === '进行中' && quiz.points) {
      // Modal.alert(
      //   '积分支付',
      //   <a>
      //     本次报名需要支付<a style={{ color: '#c31c32' }}>{info.points}</a>
      //     积分,请确认哦
      //   </a>,
      //   [
      //     { text: '取消', onPress: () => {} },
      //     {
      //       text: '确认',
      //       onPress: () => {
      //         submit()
      //       },
      //     },
      //   ]
      // );
    } else if (text === '进行中') {
      submit()
    }
  }
  function submit() {
    let isok = true
    let list = {}
    let tempArr = groups.map(i =>
      i.items.map((p: any) => {
        if (!Boolean(p.result + '') || p.result == null) {
          isok = false;
        }
        if (p.itemType === 4) {
          list = { id: p.id, name: p.result };
        }
        return { id: p.id, name: p.result };
      })
    ) as any;
    let items = [].concat.apply([], tempArr);

    if (!isok) {
      message.error('请完善竞猜选项');
      return;
    }

    if (quiz.bet && quiz.bet.maximumPoints) {
      return;
    }

    let body = {
      quizId: id,
      items,
      points: points ? points : '',
    } as any;
    QuizService.submit(body)
      .then(res => {
        message.success('提交成功')
        // Util.jumpPage(ROUTER_NAME.ENROLL_RESULT.path, {
        //   type: 'quiz',
        //   id: res.data.submit,
        // });
        navigate(-1)

      })
      .catch(err => {
        message.error(err.message);
      });
  }

  return (
    <div style={{ width: '100%', marginTop: '45px', background: thmem?.background, minHeight: '100vh' }}>

      <div className="activity">
        <div className="activity-left-info">
          <div className="info-top">
            <h2 className="top-title">{quiz.name}</h2>
            <p>竞猜时间：{quiz.startTime} ~ {quiz.endTime}</p>
            <div className="info-bottom">
              <p>{quiz.showAmount ? `竞猜人数: ${quiz.joinCount || 0}` : ''}</p>
              <p>
                <span>阅读 {quiz.touchAmount}</span>
                <span>分享 {quiz.shareAmount}</span>
              </p>
            </div>
          </div>
          <div className="rech-text">
            <div
              dangerouslySetInnerHTML={{ __html: quiz.description }}
            />
          </div>
          <div className="active-detail-message">
            <div
              dangerouslySetInnerHTML={{ __html: quiz.description }}
            />
          </div>
          {
            groups?.map((group: any, index: number) =>
              <div>
                <div className="info-top">
                  <h2 className="top-title">{group.name}</h2>
                </div>
                <div className="active-detail-message">
                  <div dangerouslySetInnerHTML={{ __html: group.description }} />
                </div>
              </div>)
          }
        </div>
        <div className="right-operation">
          <div className='close' onClick={() => navigate('/activity')}>
            {/* 关闭 X */}
            关闭 <img src={Icons['close']} alt="" style={{width:'25px',height:'25px'}} />
          </div>
          {
            groups?.map((group: any, index: number) =>
              group?.items?.map((el: any, index1: number) =>
                <div>
                  <div className="right-operation-title">{el.name}</div>
                  {
                    el.itemType === 1
                      ?
                      <div className="input-number">
                        <div className="input">
                          <span onClick={() => SingleSubtraction(index, index1)}>-</span>
                          <input onChange={(e) => SingInput(index, index1, e.target.value)} value={el.result} type="number" />
                          <span onClick={() => SingleAddition(index, index1)}>+</span>
                        </div>
                      </div>
                      : el.itemType === 2 ?
                        <div className="line-up-list">
                          {
                            el.itemNames?.map((i: any) =>
                              <p onClick={() => SingleChoice(index, index1, i)} style={i == el.result ? {
                                backgroundColor: '#ec5435',
                                color: '#fff',
                                border: '1px solid #ec5435'
                              } : {}} className="line-up-name">{i}</p>
                            )
                          }
                        </div>
                        : el.itemType === 3 ?
                          <div className="input-number">
                            <div className="input">
                              <span onClick={() => Subtraction(index, index1, 0)}>-</span>
                              <input value={el.result?.split(':')[0]} type="number" />
                              <span onClick={() => Addition(index, index1, 0)}> +</span>
                            </div>
                            <span>:</span>
                            <div className="input">
                              <span onClick={() => Subtraction(index, index1, 1)}>-</span>
                              <input value={el.result?.split(':')[1]} type="number" />
                              <span onClick={() => Addition(index, index1, 1)}>+</span>
                            </div>
                          </div>
                          : el.itemType === 4 ?
                            <div className="line-up-list">
                              {
                                el.itemNames?.map((i: any) =>
                                  <p style={el.result?.split('、')?.indexOf(i) !== -1 && el.result?.indexOf(i) != undefined ? {
                                    backgroundColor: '#ec5435',
                                    color: '#fff',
                                    border: '1px solid #ec5435'
                                  } : {}} onClick={() => MultipleChoice(index, index1, i)} className="line-up-name">{i}</p>
                                )
                              }
                            </div>
                            : ''
                  }
                  <div style={{ width: '60%', margin: '10px auto', height: '2px', backgroundColor: '#efefef' }}></div>
                </div>
              )

            )
          }
          {
            groups.length > 0 &&
            <div onClick={() => debounceHandle(quiz.statusTag)} className="quiz-button">提交</div>
          }
        </div>

      </div>
      {
        isLogin &&
        <Login onLogin={() => setIsLogin(false)} onCancel={() => setIsLogin(false)} />
      }
    </div >
  );
}